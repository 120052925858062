import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from "react-select";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { FiSave } from "react-icons/fi";
import { useForm, Controller } from "react-hook-form";
import { showLoader } from "../../../../redux/actions/commonActions";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import {
  createQRSpecification,
  getCRCFields,
  setStepOneData,
  setStepTwoFields,
  updateQRSpecification,
  getQRSpecification,
} from "../../../../redux/actions/qrSpecificationActions";
import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG } from "../../../../utils/errorMessages";
import CreateNetworkModal from "../../../../components/modals/CreateNetworkModal";
import { getNetworks } from "../../../../redux/actions/networkActions";
import { getTransactionTypes } from "../../../../redux/actions/transactionTypeActions";
import { receiveMarketData } from "../../../../redux/actions/profileActions";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";

let singleSchema = yup.object().shape({
  template: yup
    .string()
    .max(50, "Specification name max length is 50")
    .required("Specificiation Name is required"),
  network: yup.string().required("Network is required"),
  market: yup.string().required("Market is required"),
  transactionTypes: yup
    .array()
    .test(
      "required",
      "Transaction Type is required",
      (value) => Array.isArray(value) && value.length > 0
    ),
  qrFormatType: yup.string().required("QR code format is required"),
  isLegacy: yup.boolean().default(false),
  crcFormat: yup
    .string()
    .nullable()
    .when("qrFormatType", {
      is: (qrFormatType) => qrFormatType === "EMVCO",
      then: yup.string().required("CRC format is required"),
    }),
});

const StepOne = ({
  saveAsDraft,
  getNetworkList,
  getCRCList,
  setStepTwoData,
  setStepOneData,
  createData,
  showLoader,
  previousStep,
  networkList,
  createFlow,
  cloneFlow,
  updateDraft,
  selectedTemplate,
  marketOptions = [],
  crcList,
  getQRSpecification,
  trxTypeOptions,
  getTransactionTypeList,
  receiveMarketData,
}) => {
  const [filters] = useState({
    isActiveOnly: true,
    isDropdown:true,
  });
  const [showCreateNetworkModal, setShowCreateNetworkModal] = useState(false);
  const [marketNetworks, setMarketNetworks] = useState([]);
  const [isNetworkFieldDisabled, setIsNetworkFieldDisabled] = useState(true);
  const history = useHistory();
  const [disableAllFields, setDisableAllFields] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(singleSchema),
    mode: "all",
    reValidateMode: "onChange",
  });

  useEffect(() => {
      getTransactionTypeList(filters);
      receiveMarketData();
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "market") {
        getNetworkList({ isActiveOnly: true, isDropdown: true });
        const filtered = networkList.filter((x) => x.market === value.market);
        setMarketNetworks(filtered);
        setIsNetworkFieldDisabled(value.market === "");
        reset({
          template: value.template,
          market: value.market,
          network: null,
          transactionTypes: value.transactionTypes,
          isLegacy: value.isLegacy,
          qrFormatType: value.qrFormatType,
          crcFormat: value.crcFormat,
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, networkList, reset]);

  const fetchNetworks = useCallback(async () => {
    try {
      showLoader(true);
      await getCRCList();
      if (createFlow) {
        await getNetworkList({ isActiveOnly: true, isDropdown: true });
      } else {
        await getNetworkList({
          isActiveOnly: true,
          isDropdown: true,
          market: selectedTemplate.market,
        });
      }
      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast.error(SOMETHING_WENT_WRONG);
    }
  }, [getNetworkList, showLoader]);

  useEffect(() => {
    let mounted = true;
    async function fetchData() {
      if (mounted) {
        showLoader(true);
        await fetchNetworks();

        if (createFlow) {
          if (previousStep > 0) {
            setIsNetworkFieldDisabled(false);
            reset({
              template: createData.template,
              market: createData.market,
              network: createData.network,
              transactionTypes: createData.transactionTypes,
              qrFormatType: createData.qrFormatType,
              isLegacy: createData.isLegacy,
              crcFormat: createData.crcFormat,
            });
          }
        } else {
          // edit / clone flow
          setIsNetworkFieldDisabled(false);
          reset({
            template: createData.template,
            market: createData.market,
            network: createData.network,
            transactionTypes: createData.transactionTypes,
            qrFormatType: createData.qrFormatType,
            isLegacy: createData.isLegacy,
            crcFormat: createData.crcFormat,
          });

          // if the version is not one then disable all the fields
          if (
            !cloneFlow &&
            createData.version !== null &&
            (createData.version.parentVersion !== "n" ||
              (createData.version.parentVersion === "n" &&
                createData.version.versionNumber > 1))
          ) {
            setDisableAllFields(true);
          }
        }
        showLoader(false);
      }
    }
    fetchData();

    return () => {
      mounted = false;
    };
  }, [reset, createFlow, showLoader, cloneFlow, getCRCList, fetchNetworks]);

  useEffect(() => {
    const market = watch("market");
    if (market !== null && market !== "") {
      const filtered = networkList.filter((x) => x.market === market);
      setMarketNetworks(filtered);
    } else {
      setMarketNetworks(networkList);
    }
  }, [networkList, setMarketNetworks, watch]);

  const toggleShow = () => setShowCreateNetworkModal((p) => !p);

  const handleCreateNetwork = (data) => {
    toggleShow();
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const toggleShowConfirmationModal = () => {
    setShowConfirmation((p) => !p);
  };

  const onSubmit = async (data, buttonType = "next") => {
    showLoader(true);
    let prevData = {};
    if (cloneFlow) {
      prevData = await getQRSpecification(selectedTemplate.id);
    }

    const fieldsArr = [];
    let lastNonIndented = null;
    createData.fields.forEach((field) => {
      if (field.indented && field.indented === true) {
        const updated = { ...field, mpaParentKey: lastNonIndented.mpaFieldId };
        delete updated.indented;
        const parent = fieldsArr[fieldsArr.length - 1];
        parent.children.push(updated);
        fieldsArr[fieldsArr.length - 1] = parent;
      } else {
        field.children = [];
        lastNonIndented = field;
        fieldsArr.push(field);
      }
    });
    if (buttonType === "saveAsDraft") {
      try {
        if (createFlow || cloneFlow) {
          if (cloneFlow) {
            await saveAsDraft({
              isClone: true,
              cloneName: prevData.data.template,
              specification: {
                ...data,
                fields: fieldsArr,
                stage: "draft",
                draftStep: 1,
                status: "in-progress",
              },
            });
          } else {
            await saveAsDraft({
              specification: {
                ...data,
                fields: fieldsArr,
                stage: "draft",
                draftStep: 1,
                status: "in-progress",
              },
            });
          }

          toast.success("Saved as a draft");
        } else {
          await updateDraft({
            specification: {
              ...data,
              id: selectedTemplate.id,
              fields: fieldsArr,
              stage: "draft",
              draftStep: 1,
              status: "pending",
              version: createData.version,
            },
          });
          toast.success("Updated draft");
        }
        history.push("/settings/qr-specs");
      } catch (e) {
        if (e.response) {
          toast.error(e.response.data.Description);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
      } finally {
        showLoader(false);
      }
    } else if (buttonType === "next") {
      setStepOneData({ ...data, fields: [], version: createData.version });
      setStepTwoData(fieldsArr);
      showLoader(false);
      GoToStepTwo();
    }
  };

  const GoToStepTwo = () => {
    history.push("/settings/qr-specs/create/step2");
  };

  const GoBack = () => {
    history.push("/settings/qr-specs/");
  };

  const handleConfirm = () => {};

  const handleSaveDraft = (data) => {
    onSubmit(data, "saveAsDraft");
  };

  return (
    <div className="template-page fill-parameter nw-specification">
      <CreateNetworkModal
        show={showCreateNetworkModal}
        getNetworkList={fetchNetworks}
        toggleShow={toggleShow}
      />
      <ConfirmationModal
        show={showConfirmation}
        toggleShow={toggleShowConfirmationModal}
        confirm={handleConfirm}
        body={
          "The changes you have made in Step 3 will be lost if you save as a draft, please move to step 3 to save"
        }
        title="Are you sure you want to save?"
      />
      <Row className="add-padding">
        <Col xl="7" lg="10" md="12" sm="12" className="left">
          <div className="page-title-row">
            <div className="title">
              <div className="breadcrumb">
                QR Specifications /{" "}
                {createFlow ? "New" : cloneFlow ? "Clone" : "Edit"}{" "}
                Specification
              </div>
            </div>
          </div>
          <Form
            className="form-2"
            id="new-spec-step1-form"
            data-testid="new-spec-step1-form"
            onSubmit={handleSubmit((data) => onSubmit(data, "next"))}
          >
            <div className="white-bg">
              <div className="title">
                <div className="sub-title1">Step 1 of 3</div>
                <div className="sub-title2">Basic Info</div>
              </div>

              <div className="frm2-content">
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    data-testid="nameField"
                    {...register("template", { required: true })}
                    autoComplete="off"
                    placeholder="Specification Name"
                    disabled={disableAllFields}
                    isInvalid={errors.template}
                  />
                  <Form.Control.Feedback id="nameErrors" type="invalid">
                    {errors.template?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="market">
                  <Form.Label>Market*</Form.Label>
                  <Form.Select
                    data-testid="marketField"
                    {...register("market", { required: true })}
                    aria-label="Select a Market"
                    disabled={disableAllFields}
                    isInvalid={errors.market}
                  >
                    <option value="">Select Market</option>
                    {marketOptions.map((market) => {
                      return (
                        <option key={market.value} value={market.value}>
                          {market.displayName}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Control.Feedback id="marketErrors" type="invalid">
                    {errors.market?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <div>
                  <Form.Group className="mb-3" controlId="market">
                    <Form.Label>Network*</Form.Label>
                    <div className="d-lg-block ">
                      <Form.Select
                        data-testid="networkDropdown"
                        {...register("network", { required: true })}
                        aria-label="Select a Network"
                        isInvalid={errors.network}
                        placeholder="Select Network"
                        disabled={
                          (isNetworkFieldDisabled && createFlow) ||
                          disableAllFields
                        }
                      >
                        <option value="">Select Network</option>
                        {marketNetworks.map((network) => (
                          <option key={network.id} value={network.name}>
                            {network.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback id="networkErrors" type="invalid">
                        {errors.network?.message}
                      </Form.Control.Feedback>
                      <Button
                        style={{ minWidth: "fit-content" }}
                        data-testid="addNewNetworkButton"
                        disabled={disableAllFields}
                        variant="primary"
                        className="my-3"
                        name="addNewNetwork"
                        onClick={handleCreateNetwork}
                      >
                        Add New Network
                      </Button>
                    </div>
                  </Form.Group>
                </div>
                <Form.Group className="mb-3" controlId="market">
                  <Form.Label>Transaction Types*</Form.Label>
                  <div data-testid="transaction-types-selector">
                  <Controller
                    control={control}
                    name="transactionTypes"
                    data-testid="transactionTypes"
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        className="multi-select-dropdown"
                        placeholder="Select Transaction Types"
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={trxTypeOptions}
                        value={trxTypeOptions.filter((option) =>
                          value?.includes(option.value)
                        )}
                        onChange={(val) => onChange(val.map((c) => c.value))}
                        isMulti
                      />
                    )}
                  />
                  </div>
                  <div className="invalid-err-msg">
                    {errors.transactionTypes?.message}
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="market">
                  <Form.Label>QR Code Format*</Form.Label>
                  <Form.Select
                    data-testid="qrFormatType"
                    {...register("qrFormatType", { required: true })}
                    aria-label="Select a QR Code Format"
                    disabled={disableAllFields}
                    isInvalid={errors.qrFormatType}
                  >
                    <option data-testid="defaultOption" value="">Select QR Code Format</option>
                    <option data-testid="emvcoOption" value="EMVCO">
                      Emvco
                    </option>
                    <option data-testid="jsonOption" value="JSON">
                      JSON{" "}
                    </option>
                    <option data-testid="pipedOption" value="Piped">
                      Piped
                    </option>
                    <option data-testid="stringOption" value="String">
                      String
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback id="qrCodeFormatErrors" type="invalid">
                    {errors.qrFormatType?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                {watch("qrFormatType") === "EMVCO" ? (
                  <Form.Group className="mb-3" controlId="crcFormat">
                    <Form.Label>CRC*</Form.Label>
                    <Form.Select
                      data-testid="crcField"
                      {...register("crcFormat", { required: true })}
                      aria-label="Select a CRC"
                      isInvalid={errors.crc}
                    >
                      <option value="">Select CRC</option>
                      {crcList.map((crc, index) => (
                        <option key={"crc-" + index + "-" + crc} value={crc}>
                          {crc}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback id="crcErrors" type="invalid">
                      {errors.crc?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : null}

                {watch("network") === "M-Pesa" ? (
                  <Form.Group className="mb-3" controlId="isLegacy">
                    <Form.Check
                      type="checkbox"
                      disabled={disableAllFields}
                      data-testid="isLegacy"
                      {...register("isLegacy", { required: false })}
                      label="Legacy QR Code"
                    />
                  </Form.Group>
                ) : null}
              </div>
            </div>
            <div className="frm-btn btn-section">
              <Button
                data-testid="backButton"
                variant="primary"
                className="btn-link back-btn"
                onClick={GoBack}
                name="back"
              >
                <MdKeyboardArrowLeft />
                Back
              </Button>
              <div>
                <Button
                  data-testid="saveAsDraftButton"
                  variant="primary"
                  className="btn-cancel mx-3"
                  disabled={disableAllFields}
                  onClick={handleSubmit((data) => handleSaveDraft(data))}
                  name="saveAsDraft"
                >
                  <FiSave />
                  Save as Draft
                </Button>
                <Button
                  data-testid="nextButton"
                  variant="primary"
                  type="submit"
                  name="next"
                  disabled={!isValid}
                >
                  Next
                  <MdKeyboardArrowRight />
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fieldsList: state.qrSpecifiction.createData.fields,
    initialStep: state.qrSpecifiction.initialStep,
    createData: state.qrSpecifiction.createData,
    networkList: state.network.list,
    previousStep: state.qrSpecifiction.previousStep,
    createFlow: state.qrSpecifiction.flow === "create",
    cloneFlow: state.qrSpecifiction.flow === "clone",
    selectedTemplate: state.qrSpecifiction.current,
    marketOptions: state.profile.markets,
    crcList: state.qrSpecifiction.crcList,
    trxTypeOptions: state.transactionType.trxTypeOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNetworkList: (filter) => dispatch(getNetworks(filter)),
  getCRCList: () => dispatch(getCRCFields()),
  saveAsDraft: (specification) =>
    dispatch(createQRSpecification(specification)),
  updateDraft: (specification) =>
    dispatch(updateQRSpecification(specification)),
  showLoader: (data) => dispatch(showLoader(data)),
  setStepOneData: (data) => dispatch(setStepOneData(data)),
  setStepTwoData: (data) => dispatch(setStepTwoFields(data)),
  getQRSpecification: (id) => dispatch(getQRSpecification(id)),
  getTransactionTypeList: (filters) => dispatch(getTransactionTypes(filters)),
  receiveMarketData: () => dispatch(receiveMarketData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
