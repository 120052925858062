import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from 'react-router';
import jwtDecode from "jwt-decode";
import Loader from "../../components/loaders/Loader";
import { checkIfMFAEnabled } from "../../redux/actions/userActions";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth.context";
import {
  receiveAccessToken,
  receiveIdToken,
} from "../../redux/actions/tokenActions";
import { receiveProfileData } from "../../redux/actions/profileActions";
import { getCurrentUserPermissions } from "../../redux/actions/permissionActions";
import { getTransactionTypes } from "../../redux/actions/transactionTypeActions";
import { showQRCodeSideBarItem, showAuditLogsSideBarItem, showSettingsSideBarItem, showTemplatesSideBarItem, showNetworksSecondarySideBarItem, showQRSpecSecondarySideBarItem, showLanguagesSecondarySideBarItem, showRolesSecondarySideBarItem, showUserManagementSideBarItem, showMarketsSecondarySideBarItem,showTransactionTypesSecondarySideBarItem } from "../../utils/permissionList";

const Callback = ({
  profile,
  receiveProfile,
  receiveTokens,
  checkIfMFAEnabled,
  getUserPermissions,
  currentUserPermissions,
  getTransactionTypeList,
}) => {
  const [filters] = useState({
    isActiveOnly: true,
    isDropdown:true,
  });
  const [mfaEnabled, setMFAEnabled] = useState(null);
  const [permissionListRecieved, setPermissionListRecieved] = useState(false);
  const authContext = useContext(AuthContext);
  const { signinRedirectCallback } = authContext;
  const history = useHistory();

  useEffect(() => {
    signinRedirectCallback()
      .then(async (user) => {
        await receiveTokens(user.access_token, user.idToken);
        await receiveProfile(jwtDecode(user.id_token));
      })
      .catch((error) => {
        console.log(error);
        if(error.response){
          if(error.response.data.title === "MFA is not enabled for current user."){
            history.push("/mfa-setup");
          }else{
            history.push("/");
          }
        }else{
          history.push("/");
        }
        
      });
  }, [history, receiveProfile, receiveTokens, getUserPermissions, signinRedirectCallback]);

  useEffect(() => {
    async function checkMFA() {
      try {
        const result = await checkIfMFAEnabled();
        setMFAEnabled(result.mfaEnable);
        if (result.mfaEnable) {
          await getUserPermissions();
          await getTransactionTypeList(filters);
          setPermissionListRecieved(true);
        }
      } catch (e) { }
    }

    if (profile?.sub != null) {
      checkMFA();
    }
  }, [checkIfMFAEnabled, setMFAEnabled, profile]);


  const getPathForSettings = () => {
    if (showRolesSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/roles";
    } else if (showQRSpecSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/qr-specs";
    } else if (showLanguagesSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/languages"
    } else if (showNetworksSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/networks";
    } else if (showMarketsSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/markets";
    } else if (showTransactionTypesSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/transaction-types";
    }
  }


  if (profile?.sub == null) {
    return <Loader />;
  } else {
    // check if MFA is enabled for the user
    if (mfaEnabled === null && !permissionListRecieved) {
      return <Loader />;
    } else if (mfaEnabled && permissionListRecieved) {
      if (showQRCodeSideBarItem(currentUserPermissions)) {
        return <Redirect push to="/generate-qr" />
      }

      else if (showUserManagementSideBarItem(currentUserPermissions)) {
        return <Redirect push to="/users" />
      }

      else if (showAuditLogsSideBarItem(currentUserPermissions)) {
        return <Redirect push to="/audits" />
      }

      else if (showTemplatesSideBarItem(currentUserPermissions)) {
        return <Redirect push to="/templates" />
      }

      else if (showSettingsSideBarItem(currentUserPermissions)) {
        return <Redirect push to={getPathForSettings()} />
      }
    } else if (mfaEnabled && !permissionListRecieved) {
      return <Loader />;
    } else {
      return <Redirect push to="/mfa-setup" />
    }
  }

  return <Loader />;
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  currentUserPermissions: state.permission.currentUserPermissions,
  state: state.token.state,
  nonce: state.token.nonce,
});

const mapDispatchToProps = (dispatch) => ({
  checkIfMFAEnabled: () => dispatch(checkIfMFAEnabled()),
  receiveProfile: (data) => dispatch(receiveProfileData(data)),
  getUserPermissions: () => dispatch(getCurrentUserPermissions()),
  receiveTokens: (accessToken, idToken) => {
    dispatch(receiveAccessToken(accessToken));
    dispatch(receiveIdToken(idToken));
  },
  getTransactionTypeList: (filters) => dispatch(getTransactionTypes(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
