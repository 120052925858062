import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ListGroup } from "react-bootstrap";
import { formatDateTime } from "../../utils/timeFormat";
import { capitalizeFirstLetter } from "../../utils/stringFormat";
import { spacedString } from "../../utils/stringFormat";

const ViewAuditModal = ({ audit, hideExternal }) => {
  const [show, setShow] = useState(true);
  const [additionalData, setAdditionalData] = useState({});

  const handleClose = () => {
    setShow(false);
    hideExternal(false);
  };

  useEffect(() => {
    if (show && audit.additionalData) {
      setAdditionalData(JSON.parse(audit.additionalData));
    }
  }, [show, audit]);

  const renderAdditionalData = Object.keys(additionalData).map(function (
    key
  ) {
    return (
      <ListGroup.Item key={key}>
        <label id={key+"-label"}>{spacedString(key)}</label>
        <span id={key+"-value"} className="word-break">{ typeof additionalData[key] !== 'object'?  additionalData[key] : JSON.stringify(additionalData[key] )}</span>
      </ListGroup.Item>
    );
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        className="view-modal view-audit-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="view-modal-title">View Details</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="subtitle-1 logdetail">
            <span>Log Details</span>
            <hr />
          </div>
          <ListGroup className="mb-3">
            <ListGroup.Item>
              <label id="created-date-label">Created Date</label>
              <span id="created-date-value">
                {formatDateTime(audit.createdDate)}
              </span>
            </ListGroup.Item>
            <ListGroup.Item>
              <label id="action-label">Action</label>
              <span id="action-value">{audit.action}</span>
            </ListGroup.Item>

            <ListGroup.Item>
              <label id="performed-by-label">Performed By</label>
              <span id="performed-by-value">{audit.user}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <label id="market-label">Market</label>
              <span id="market-value">{audit.market== null || audit.market == null  ? "-": capitalizeFirstLetter(audit.market)}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <label id="role-label">Role</label>
              <span id="role-value">{audit.role === "Auditor" ? "Audit User": audit.role}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <label id="description-label">Description</label>
              <span id="description-value" className="word-break">{audit.description}</span>
            </ListGroup.Item>
          </ListGroup>
          { renderAdditionalData.length > 0 ? (
          <div className="subtitle-1 logdetail">
            <span>Log Data</span>
            <hr />
          </div>) : null}
          <ListGroup>{renderAdditionalData}</ListGroup>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    audit: state.audits.current,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAuditModal);
