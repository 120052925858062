import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import RedLogo from "../assets/images/m-pesa-logo-red.png";
import GreenLogo from "../assets/images/m-pesa-logo-green.png";
import { RiLogoutBoxLine } from "react-icons/ri";
import { BiUser } from "react-icons/bi";
import { AuthService } from "./AuthService";
import { CLEAR_ALL } from "../redux/actions/actionTypes";
import { connect } from "react-redux";
import ConfirmationModal from "./modals/ConfirmationModal";
import { Dropdown } from "react-bootstrap";
import Avatar from "react-avatar";
import { useLocation } from 'react-router-dom';
import { Tooltip ,OverlayTrigger} from "react-bootstrap";

const SideBar = ({ items = [], logout, firstName, lastName }) => {
  const location = useLocation();
  const [helper] = useState(() => new AuthService());
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleOnClick = () => {
    setShowConfirmation(true);
  };

  const handleLogout = () => {
    logout();
    helper.logout();
  };

  const toggleShow = () => setShowConfirmation((p) => !p);
  
  return (
    <div className="sidebar sidebar--primary flex-column flex-shrink-0">
      <ConfirmationModal
        show={showConfirmation}
        toggleShow={toggleShow}
        confirm={handleLogout}
        title="Are you sure you want to logout?"
      />
      <div>
        <div className="nav-logo">
          <img src={RedLogo} alt="RedLogo" />
        </div>
        <div className="nav-logo" style={{ borderTop: "1px solid rgba(255, 255, 255, 0.2" }}>
          <img src={GreenLogo} alt="GreenLogo" />
        </div>
        {items.map((item) => (
          <OverlayTrigger
            key={item.path}
            placement="right"
            delay={{ show: 30, hide: 30 }}
            overlay={<Tooltip className="sidebar-tooltip" id={item.id}>{item.name}</Tooltip>}
          >
            <div  className="nav-item" id={item.id}>
              <NavLink className={location.pathname.includes("settings") && item.path.includes("settings") ? "active sidebar__link" : "sidebar__link"} to={item.path} exact={item.exact} >
                <div className="icon-label-group">
                  <div className="nav-icon" id={item.id + "-icon"}>
                    {item.icon}
                  </div>
                </div>
              </NavLink>
            </div>
          </OverlayTrigger>
        ))}
        <div className="nav-item logout">
          <Dropdown>
            <Dropdown.Toggle
              variant="link"
              id="logout-action-menu"
              data-testid="logout-action-menu"
            >
              <div className="sidebar__link">
                <div className="icon-label-group">
                  <div className="nav-icon">
                    <BiUser />
                  </div>
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item data-testid="viewAction">
                <Avatar
                  name={firstName + " " + lastName}
                  size="20"
                  round={true}
                ></Avatar>{" "}
                {firstName} {lastName}
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                id="btn-logout"
                data-testid="resetPasswordAction"
                onClick={handleOnClick}
              >
                <RiLogoutBoxLine id="icon-logout" /> Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch({ type: CLEAR_ALL }),
});

const mapStateToProps = (state) => ({
  token: state.token.idToken,
  firstName: state.profile?.firstName,
  lastName: state.profile?.lastName,
  groups: state.profile?.groups,
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
