import React from "react";
import { Spinner } from 'react-bootstrap';

const TransparentLoader = () => {
  return (
    <div data-testid="loader" className="loader-container">
      <Spinner className="loader" animation="border" variant="danger" />
    </div>
  );
};

export default TransparentLoader;
