import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ListGroup } from "react-bootstrap";
import { formatDateTime } from "../../utils/timeFormat";
import { capitalizeFirstLetter } from "../../utils/stringFormat";
import { getDeactivationReason, clearReason } from "../../redux/actions/userActions";

const ViewUserModal = ({user, hideExternal, reason, getDeactivationReason, clearReason}) => {
  const [show, setShow] = useState(true);

  const handleClose = () =>{ 
    setShow(false);
    clearReason();
    hideExternal(false);
  };

  useEffect(()=> {
    getDeactivationReason(user.email);
  },[user, getDeactivationReason])

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" className="view-modal">
        <Modal.Header closeButton>
          <Modal.Title id="view-modal-title">View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ListGroup>
            <ListGroup.Item> 
              <label id="created-date-label">Created Date</label> 
              <span id="created-date-value"> {formatDateTime(user.createdDate)}</span>
            </ListGroup.Item>
            <ListGroup.Item> 
              <label id="email-label"> Email </label> 
              <span id="email-value" className="word-break">{user.email}</span>
            </ListGroup.Item>
            <ListGroup.Item> 
              <label id="firstname-label"> First Name </label> 
              <span id="firstname-value" className="word-break"> {user.firstName}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <label id="lastname-label">Last Name</label>  
              <span id="lastname-value" className="word-break">{user.lastName}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <label id="phone-no-label"> Phone Number </label>
              <span id="phone-no-value">{user.phoneNo}</span>
            </ListGroup.Item>
            <ListGroup.Item> 
              <label id="market-label">Market</label>  
              <span id="market-value">{user.market==='drc'? 'DRC': capitalizeFirstLetter(user.market)}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <label id="designation-label"> Designation </label>
              <span id="designation-value" className="word-break"> {user.designation === null || user.designation ==='' ? '-': user.designation }</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <label id="role-label">Role</label> 
              <span id="role-value">{user.roleName}</span>
            </ListGroup.Item>
            <ListGroup.Item className="status">
              <label id="status-label">Status</label> 
              <span id="status-value" className={user.isActive ? "active" : "inactive"}>{user.isActive? 'ACTIVE': 'INACTIVE'}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              { reason !== null ? ( <label id="reason-label">Reason for {user.isActive ? "Reactivation": "Deactivation"}</label>) : null }
              <span id="reason-value" className="word-break">{reason}</span>
            </ListGroup.Item>
        </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reason: state.users.reason,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDeactivationReason: (email) => dispatch(getDeactivationReason(email)),
  clearReason: () => dispatch(clearReason()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUserModal);
