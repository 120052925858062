import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../components/AuthService";
import Logo from "../../assets/images/m-pesa-logo-green-and-red.png";
import { showLoader } from "../../redux/actions/commonActions";
import { Button } from "react-bootstrap";

const SessionExpired = () => {
  const history = useHistory();

  const loginAgain = () => {
    history.push("/");
  }
  return (
    <div className="page page-session mfa-setup-page">
      <div className="card session">
        <div className="session-logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="page-title-row">
          <div className="title m-auto">
            <h5 test-id="page-header">Your session has been expired</h5>
          </div>
        </div>
        <p className="d-flex justify-content-center">
          Please login again to continue
        </p>
        <Button id="btn-yes" variant="danger" onClick={() => loginAgain()}>
          Go to Login
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) => ({
  login: () => dispatch(AuthService.login()),
  showLoader: (data) => dispatch(showLoader(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionExpired);
