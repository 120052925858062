import React, { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = yup.object().shape({
  reason: yup.string().max(100).required("Reason is required").min(4),
});

const RejectQRModal = ({
  show,
  toggleShow,
  submitForReject,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "all",
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    clearErrors();
    reset();
    }, [show]);

  useEffect(() => {
    clearErrors();
    reset();
    }, [clearErrors, reset]);


  const onHandleSubmit = (data) => {
    submitForReject(data.reason);
    clearErrors();
    reset();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={()=>toggleShow(false)}
        backdrop="static"
        size="lg"
        dialogClassName="modal-template"
        data-testid="reject-modal"
      >
        <Modal.Header>
          <Modal.Title data-testid="modal-title" id="modal-title">
            Reason
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit((data) => onHandleSubmit(data))}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label data-testid="reason-label">Enter Reason *</Form.Label>
              <Form.Control
                id="reason"
                as="textarea"
                placeholder="Type here"
                rows={5}
                data-testid="reasonField"
                {...register("reason")}
                isInvalid={errors["reason"]}
              />
              <Form.Control.Feedback
                id="reasonErrors"
                data-testid="reasonErrorMsg"
                type="invalid"
              >
                {errors["reason"]?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-between flex-row">
            <Button
              data-testid="btnCancel"
              variant="primary"
              className="btn-cancel mx-3"
              onClick={() => toggleShow(false)}
            >
              Cancel
            </Button>

            <div className="d-flex align-items-center justify-content-between">
              <Button
                variant="danger"
                name="reject"
                data-testid="btnReject"
                type="submit"
              >
                Reject
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};


export default RejectQRModal;
