import React from "react";
import { Modal, Button } from "react-bootstrap";


const ConfirmationModal = ({ show, confirmButtonText="Yes", cancelButtonText="No", confirm, toggleShow, title, body = null }) => {

  const handleConfirm = () => {
    toggleShow();
    confirm();
  }
  return (
    <>
      <Modal show={show} onHide={toggleShow}>
        <Modal.Header closeButton>
          <Modal.Title className="question" data-testid="confimationModalTitle">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {body}
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-testid="cancel-button"
            id="btn-no"
            variant="primary"
            className="btn-cancel mx-3"
            onClick={toggleShow}
          > {cancelButtonText} </Button>
          <Button
            data-testid="confirm-button"
            id="btn-yess"
            variant="danger"
            onClick={() => handleConfirm()}
          > {confirmButtonText} </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ConfirmationModal;
