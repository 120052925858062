import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Tabs, Tab } from "react-bootstrap";
import { showLoader } from "../../../redux/actions/commonActions";
import { useHistory } from "react-router-dom";
import {
  getQRSpecification,
  getQRSpecificationNotes,
  rejectQRSpecification,
  moveToBackQRSpecification,
} from "../../../redux/actions/qrSpecificationActions";

import {
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACTION,
} from "../../../utils/errorMessages";
import { toast } from "react-toastify";
import TestingForm from "../../../components/forms/TestingForm";
import {
  testApiEncode,
  setInitialState,
} from "../../../redux/actions/testQrSpecificationActions";
import NotesModal from "../../../components/modals/NotesModal";
import { apiBaseURL } from "../../../config/apiConfig";
import ActionDropdown from "../../../components/ActionDropdown";
import RejectQRModal from "../../../components/modals/RejectQRModal";

const fmt2json = require("format-to-json");

const TestQRSpec = ({
  getQRSpecification,
  getQRSpecificationNotes,
  selectedSpec,
  showLoader,
  selectedId,
  setTestApiEncode,
  encodeResponseBody,
  decodeResponseBody,
  notes,
  setInitialState,
  rejectQRSpecification,
  moveToBackQRSpecification,
}) => {
  const history = useHistory();

  const [selectedActionItem, setSelectedActionItem] = useState("Test");
  const [selectedItemTitle, setSelectedItemTitle] = useState("Test");

  const [showNotesModal, setShowNotesModal] = useState(false);
  const [encodePayload, setEncodePayload] = useState("{}");
  const [decodePayload, setDecodePayload] = useState("{}");

  const [tabKey, setTabKey] = useState("encode");
  const [encodeURL] = useState(
    `${apiBaseURL}/qr/v1/test/encode?SpecificationId=` + selectedId
  );
  const [decodeURL] = useState(
    `${apiBaseURL}/qr/v1/test/decode?SpecificationId=` + selectedId
  );
  const [responseTabKey, setResponseTabKey] = useState("body");

  const [actionMenuItems] = useState([
    {
      title: "Testing to Review",
      badge: "REVIEW",
      bgColor: "#4b4dcb",
      type: "review",
      iconType: "FORWARD",
      selectedTitle: "Review",
    },
    {
      title: "Move to Draft",
      badge: "DRAFT",
      bgColor: "#807f80",
      type: "move",
      iconType: "BACKWARD",
      selectedTitle: "Draft",
    },
    {
      title: "Transition to",
      badge: "REJECT",
      bgColor: "#e74c3c",
      type: "reject",
      iconType: "FORWARD",
      selectedTitle: "Reject",
    },
  ]);

  const [showRejectModal, setShowRejectModal] = useState(false);

  useEffect(() => {
    setInitialState();
  }, []);

  useEffect(() => {
    async function fetchQRSpecifications() {
      try {
        showLoader(true);
        await getQRSpecification(selectedId);
        await getQRSpecificationNotes({
          id: selectedId,
          transitionType: "testing",
        });
        showLoader(false);
      } catch (e) {
        if (e.response) {
          if (e.response.status && e.response.status === 403) {
            toast.error(UNAUTHORIZED_ACTION);
          } else if (e.response.data.Description) {
            toast.error(e.response.data);
          }
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        showLoader(false);
      }
    }
    fetchQRSpecifications();
  }, [showLoader, getQRSpecification, getQRSpecificationNotes, selectedId]);

  const onSubmit = async (data) => {
    showLoader(true);
    const tmpPayloadData =
      data.type === "encode" ? encodePayload : decodePayload;
    const fmtPayload = await fmt2json(tmpPayloadData);
    const payloadData = {
      ...data,
      SpecificationId: selectedId,
      dataParams: JSON.parse(fmtPayload.result),
    };

    setTestApiEncode(payloadData);
  };

  const goToReview = () => {
    history.push("/settings/qr-specs/submit-for-review");
  };

  const showNotes = async () => {
    toggleShow();
  };

  const toggleShow = () => {
    setShowNotesModal((p) => !p);
  };

  const toggleRejectModalShow = (status) => {
    if(status === false){
      setSelectedActionItem("test");
      setSelectedItemTitle("Test");
    }
    setShowRejectModal(status);
  };

  const handleClickActionItem = (type) => {
    setSelectedActionItem(type)
    switch (type) {
      case "reject":
        toggleRejectModalShow(true);
        break;
      case "review":
        goToReview();
        break;
      case "move":
        moveToBackStage();
        break;
      default:
        break;
    }
  };

  const goToQRListPage = () => {
    history.push("/settings/qr-specs");
  };

  const submitForReject = async (reason) => {
    toggleRejectModalShow(false);

    try {
      showLoader(true);
      await rejectQRSpecification({
        id: selectedId,
        stage: selectedSpec.stage,
        status: selectedSpec.status,
        comment: reason,
      });
      toast.success("QR Specification rejected successfully");
      goToQRListPage();
      showLoader(false);
    } catch (e) {
      showLoader(false);
      setSelectedActionItem("test");
      setSelectedItemTitle("Test");
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data.Description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
  };

  const moveToBackStage = async () => {
    try {
      showLoader(true);
      await moveToBackQRSpecification({
        id: selectedId,
        currentStage: "testing",
        toStage: "draft",
      });
      toast.success("QR Specification moved to the draft stage.");
      goToQRListPage();
      showLoader(false);
    } catch (e) {
      showLoader(false);
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data.Description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
  };

  return (
    <div className="page test-qr-page">
      <RejectQRModal
        show={showRejectModal}
        toggleShow={toggleRejectModalShow}
        submitForReject={submitForReject}
      />
      <NotesModal
        type="view"
        show={showNotesModal}
        toggleShow={toggleShow}
        notes={notes}
      />
      <div className="breadcrumb-wrapper" data-testid="breadcrumb">
        QR Specifications / Test Specification
      </div>
      <div className="page-title-row">
        <div className="header-title-wrapper d-flex justify-content-between w-100">
          <div className="d-flex">
          <div className="page-title-row">
            <div className="title">
              <h1 data-testid="titleHeader">Test QR Specification -{" "}
              {selectedSpec.template && selectedSpec.template}</h1>
            </div>
          </div>
            {/* <h3 data-testid="titleHeader">
              Test QR Specification -{" "}
              {selectedSpec.template && selectedSpec.template}
            </h3> */}
            <Button
              variant="secondary"
              size="sm"
              className="show-note-btn"
              onClick={showNotes}
              data-testid="showNotesBtn"
            >
              Show Notes
            </Button>
          </div>
          <ActionDropdown
            title="Test"
            actionMenuItems={actionMenuItems}
            onChangeActionItem={handleClickActionItem}
            selectedActionItem={selectedActionItem}
            selectedItemTitle={selectedItemTitle}
            setSelectedItemTitle={setSelectedItemTitle}
          />
        </div>
      </div>

      <Tabs
        id="controlled-tab-example"
        activeKey={tabKey}
        onSelect={(k) => setTabKey(k)}
        className=""
      >
        <Tab eventKey="encode" title="Encode" data-testid="encodeTab">
          <TestingForm
            type="encode"
            url={encodeURL}
            payload={encodePayload}
            setPayload={setEncodePayload}
            responseTabKey={responseTabKey}
            setResponseTabKey={setResponseTabKey}
            onSubmit={onSubmit}
            response={encodeResponseBody}
          />
        </Tab>
        <Tab eventKey="decode" title="Decode" data-testid="decodeTab">
          <TestingForm
            type="decode"
            url={decodeURL}
            payload={decodePayload}
            setPayload={setDecodePayload}
            responseTabKey={responseTabKey}
            setResponseTabKey={setResponseTabKey}
            onSubmit={onSubmit}
            response={decodeResponseBody}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedSpec: state.qrSpecifiction.current,
    selectedId: state.qrSpecifiction.selectedId,
    encodeResponseBody: state.testQrSpecification.encodeResponseBody,
    decodeResponseBody: state.testQrSpecification.decodeResponseBody,
    notes: state.qrSpecifiction.notes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: (data) => dispatch(showLoader(data)),
  getQRSpecification: (id) => dispatch(getQRSpecification(id)),
  setTestApiEncode: (payload) => dispatch(testApiEncode(payload)),
  getQRSpecificationNotes: (data) => dispatch(getQRSpecificationNotes(data)),
  setInitialState: (data) => dispatch(setInitialState(data)),
  rejectQRSpecification: (data) => dispatch(rejectQRSpecification(data)),
  moveToBackQRSpecification: (data) =>
    dispatch(moveToBackQRSpecification(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestQRSpec);
