import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, Button, Form } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { FiSave } from "react-icons/fi";
import { showLoader } from "../../../../redux/actions/templateActions";
import { useHistory } from "react-router-dom";
import { SOMETHING_WENT_WRONG } from "../../../../utils/errorMessages";
import {
  createQRSpecification,
  setStepTwoFields,
  updateQRSpecification,
  getQRSpecification,
} from "../../../../redux/actions/qrSpecificationActions";
import { toast } from "react-toastify";
import NotesModal from "../../../../components/modals/NotesModal";

const regexAN = /[0-9A-Za-z]/;
const regexN = /[0-9]/;
const regexS = /[^A-Za-z0-9]/;

yup.addMethod(yup.string, "stripValue", function () {
  return this.transform((value) => (value === "" ? null : value));
});

yup.addMethod(yup.array, "unique", function (message, path) {
  return this.test("unique", message, function (list) {
    const mapper = (x) => x.mapFieldId;
    const fieldsArr = [];
    list.forEach((field, index) => {
      if (field.mapFieldId === null || field.mapFieldId === "") {
        return;
      }
      if (field.indented && field.indented === true) {
        const parentField = fieldsArr[fieldsArr.length - 1];
        parentField.children.push(field);
        fieldsArr[fieldsArr.length - 1] = parentField;
      } else {
        field.children = [];
        fieldsArr.push(field);
      }
    });
    // check for parent fields if the ids are unique
    const set = [...new Set(fieldsArr.map(mapper))];
    const isUnique = fieldsArr.length === set.length;
    //look for children;
    const errors = [];

    for (let i = 0; i < fieldsArr.length; i++) {
      const field = fieldsArr[i];
      if (field.children.length > 1) {
        const childSet = [...new Set(field.children.map(mapper))];
        const isChildUnique = field.children.length === childSet.length;
        //look for children;
        if (!isChildUnique) {
          const idx = field.children.find((l, i) => mapper(l) !== childSet[i]);
          errors.push({
            path: `fields[${idx.order - 1}].mapFieldId`,
            message: message,
          });
          break;
        }
      }
    }
    if (errors.length > 0) {
      return this.createError(errors[0]);
    }

    if (isUnique) {
      return true;
    }
    const idx = fieldsArr.find((l, i) => mapper(l) !== set[i]);
    return this.createError({
      path: `fields[${idx.order - 1}].mapFieldId`,
      message: message,
    });
  });
});

let singleSchema = yup.object().shape({
  isLegacy: yup.bool(),
  fields: yup
    .array()
    .of(
      yup
        .object()
        .nullable()
        .shape({
          order: yup.number(),
          mpaFieldId: yup.string().nullable(),
          mpaKey: yup.string(),
          mpaFieldName: yup.string(),
          qrFormatType: yup.string(),
          mapFieldId: yup
            .string()
            .nullable()
            .default(null)
            .when("qrFormatType", {
              is: (qrFormatType) => qrFormatType === "EMVCO",
              then: yup.string().typeError("Required").required("Required"),
            }),
          mapFieldName: yup.string(),
          validations: yup
            .object()
            .nullable()
            .shape({
              mpaFieldValidations: yup
                .object()
                .nullable()
                .shape({
                  required: yup.bool(),
                  format: yup.string(),
                  lengthType: yup.string(),
                  lengthMin: yup.number(),
                  lengthMax: yup.number(),
                })
                .default(null),
              required: yup.bool().typeError("Required").required("Required"),
              format: yup.string().typeError("Required").required("Required"),
              lengthType: yup
                .string()
                .typeError("Required")
                .required("Required"),
              lengthMin: yup
                .number()
                .min(1, "Must be > 0")
                .typeError("Required"),
              lengthMax: yup
                .number()
                .nullable()
                .when("lengthType", {
                  is: (lengthType) => lengthType === "ranged",
                  then: yup
                    .number()
                    .typeError("Required")
                    .min(1, "Must be > 0")
                    .required("Required")
                    .test({
                      name: "max-min-test",
                      exclusive: false,
                      params: {},
                      message: "Max < Min",
                      test: function (value) {
                        if (value) {
                          if (this.parent.lengthType === "ranged") {
                            return this.parent.lengthMin < value;
                          } else {
                            return true;
                          }
                        } else {
                          return true;
                        }
                      },
                    }),
                })
                .when("lengthType", {
                  is: (lengthType) => lengthType === "fixed",
                  then: yup
                    .number()
                    .notRequired()
                    .transform((value) => (isNaN(value) ? null : value))
                    .nullable()
                    .default(0),
                }),
            }),
          valueMap: yup
            .string()
            .nullable()
            .test({
              name: "check if valid json",
              exclusive: false,
              params: {},
              test: function (value, { createError, path }) {
                if (value !== null && value !== "") {
                  try {
                    JSON.parse(value);
                  } catch (e) {
                    return createError({ message: `Invalid JSON`, path });
                  }
                }
                return true;
              },
            }),
          rules: yup
            .string()
            .nullable()
            .test({
              name: "check if valid json",
              exclusive: false,
              params: {},
              test: function (value, { createError, path }) {
                if (value !== null && value !== "") {
                  try {
                    JSON.parse(value);
                  } catch (e) {
                    return createError({ message: `Invalid JSON`, path });
                  }
                }
                return true;
              },
            }),
          requiredUserInput: yup
            .bool()
            .nullable()
            .oneOf([true, false, null])
            .default(false),
          defaultValue: yup
            .string()
            .nullable()
            .typeError()
            .when("validations.format", {
              is: (format) => format === "N",
              then: yup
                .string()
                .nullable()
                .nullable()
                .when("defaultValue", {
                  is: (defaultValue) =>
                    defaultValue !== null && defaultValue !== "",
                  then: yup
                    .string()
                    .matches(regexN, "Numbers only(N)")
                    .nullable()
                    .notRequired(),
                }),
            })
            .when("validations.format", {
              is: (format) => format === "AN",
              then: yup
                .string()
                .nullable()
                .when("defaultValue", {
                  is: (defaultValue) =>
                    defaultValue !== null && defaultValue !== "",
                  then: yup
                    .string()
                    .matches(regexAN, "Numbers and Characters only (AN)")
                    .nullable()
                    .notRequired(),
                }),
            })
            .when("validations.format", {
              is: (format) => format === "SP",
              then: yup
                .string()
                .nullable()
                .nullable()
                .when("defaultValue", {
                  is: (defaultValue) =>
                    defaultValue !== null && defaultValue !== "",
                  then: yup
                    .string()
                    .matches(regexS, "Special Characters only (SP)")
                    .nullable()
                    .notRequired(),
                }),
            })
            .test({
              name: "max",
              exclusive: false,
              params: {},
              message: "Length is not valid",
              test: function (value) {
                if (value) {
                  if (this.parent.validations.lengthType === "fixed") {
                    return value.length === this.parent.validations.lengthMin;
                  } else {
                    return (
                      value.length >= this.parent.validations.lengthMin &&
                      value.length <= this.parent.validations.lengthMax
                    );
                  }
                } else {
                  return true;
                }
              },
            }),
        })
    )
    .unique("Duplicate id"),
});

const StepThree = ({
  fieldsList,
  createData,
  saveAsDraft,
  updateDraft,
  submitForTest,
  isLoading,
  createFlow,
  setStepTwoFields,
  selectedTemplate,
  cloneFlow,
  getQRSpecification,
}) => {
  const history = useHistory();
  const [localCreateData] = useState(createData);
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      qrFormatType: localCreateData.qrFormatType,
      isLegacy: localCreateData.isLegacy,
      fields: fieldsList,
    },
    resolver: yupResolver(singleSchema),
    mode: "all",
    reValidateMode: "onChange",
  });
  const { fields } = useFieldArray({
    control,
    name: "fields",
  });
  const watchFieldArray = watch("fields");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const [showIdField, setShowIdField] = useState(true);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [noteStep, setNoteStep] = useState(false);
  const [note, setNote] = useState("");
  const [formData, setFormData] = useState();

  useEffect(() => {
    if (createData.qrFormatType === "EMVCO") {
      setShowIdField(true);
    } else {
      setShowIdField(false);
    }
  }, [createData]);

  const toggleShow = () => {
    setShowNotesModal((p) => !p);
  };

  const goBack = () => {
    setStepTwoFields(getValues().fields);
    history.push("/settings/qr-specs/create/step2");
  };

  const handleSaveDraft = (data) => {
    onSubmit(data, "saveAsDraft");
  };

  const addNote = async (note) => {
    setNote(note);
    setNoteStep(true);
    toggleShow();
    onSubmit(formData, "save", note, true);
  };

  const onSubmit = async (data, buttonType = "save", note, noteStep) => {
    await setFormData(data);
    let prevData = {};
    if (cloneFlow) {
      prevData = await getQRSpecification(selectedTemplate.id);
    }

    const fieldsArr = [];
    const parsedFields = data.fields.map((x) => {
      let valueMap = null;
      let rules = null;
      try {
        if (x.valueMap !== "") {
          valueMap = JSON.parse(x.valueMap);
        }
      } catch (e) {
        toast.error("Invalid json for value map");
      }
      try {
        if (x.rules !== "") {
          rules = JSON.parse(x.rules);
        }
      } catch (e) {
        toast.error("Invalid json for rules");
      }

      if (x.validations.lengthType === "fixed") {
        return {
          ...x,
          validations: {
            ...x.validations,
            lengthMax: x.validations.lengthMin,
          },
          valueMap,
          rules,
        };
      } else {
        return {
          ...x,
          valueMap,
          rules,
        };
      }
    });
    parsedFields.forEach((field) => {
      field.mapFieldId =
        field.mapFieldId === "" || field.mapFieldId === null
          ? null
          : field.mapFieldId;
      if (field.indented && field.indented === true) {
        const updated = { ...field };
        delete updated.indented;
        const parent = fieldsArr[fieldsArr.length - 1];
        parent.children.push(updated);
        fieldsArr[fieldsArr.length - 1] = parent;
      } else {
        field.children = [];
        fieldsArr.push(field);
      }
    });

    if (buttonType === "saveAsDraft") {
      try {
        isLoading(true);
        if (createFlow || cloneFlow) {
          if (cloneFlow) {
            await saveAsDraft({
              specification: {
                ...createData,
                fields: fieldsArr,
                stage: "draft",
                draftStep: 3,
                status: "in-progress",
              },
              note: note,
              isClone: true,
              cloneName: prevData.data.template,
            });
          }else{
          await saveAsDraft({
            specification: {
              ...createData,
              fields: fieldsArr,
              stage: "draft",
              draftStep: 3,
              status: "in-progress",
            },
            note: note,
          });
        }
          toast.success("Saved as draft");
        } else {
          await updateDraft({
            specification: {
              ...createData,
              id: selectedTemplate.id,
              fields: fieldsArr,
              stage: "draft",
              draftStep: 3,
              status: "in-progress",
            },
          });
          toast.success("Updated draft");
        }

        history.push("/settings/qr-specs");
      } catch (e) {
        isLoading(false);
        if (e.response) {
          toast.error(e.response.data.Description);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
      } finally {
        isLoading(false);
      }
    } else if (buttonType === "save") {
      if (noteStep) {
        try {
          isLoading(true);
          if (createFlow || cloneFlow) {
            if (cloneFlow) {
              await submitForTest({
                specification: {
                  ...createData,
                  fields: fieldsArr,
                  stage: "testing",
                  draftStep: 3,
                  status: "in-progress",
                },
                note: note,
                isClone: true,
                cloneName: prevData.data.template,
              });
            } else {
              await submitForTest({
                specification: {
                  ...createData,
                  fields: fieldsArr,
                  stage: "testing",
                  draftStep: 3,
                  status: "in-progress",
                },
                note: note,
              });
            }
          } else {
            await updateDraft({
              specification: {
                ...createData,
                id: selectedTemplate.id,
                fields: fieldsArr,
                stage: "testing",
                draftStep: 3,
                status: "in-progress",
              },
              note: note,
            });
          }
          toast.success("Submitted for testing");
          history.push("/settings/qr-specs");
          isLoading(false);
        } catch (e) {
          isLoading(false);
          if (e.response) {
            toast.error(e.response.data.Description);
          } else {
            toast.error(SOMETHING_WENT_WRONG);
          }
        } finally {
          isLoading(false);
        }
      } else {
        toggleShow();
      }
    }
  };

  const renderFields = controlledFields.map((field, index) => {
    return (
      <tr
        key={field.order}
        className={
          field.indented ? "step-3-field sub-field border-b" : " border-b"
        }
        data-testid="rendered-row"
      >
        <td data-testid="mapFieldName">{field.mapFieldName}</td>
        <td data-testid="mpaFieldName">{field.mpaFieldName}</td>
        <span className="d-flex field-v">
          {showIdField ? (
            <td id="identity">
              <Form.Group>
                <Form.Control
                  data-testid="mapFieldIdField"
                  id={`field-${index}-id`}
                  isInvalid={errors.fields?.[index]?.mapFieldId}
                  type="number"
                  placeholder="Id"
                  {...register(`fields.${index}.mapFieldId`)}
                  min="0"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                />
                <Form.Control.Feedback
                  id={`mapFieldId-${index}-error`}
                  type="invalid"
                >
                  {errors.fields?.[index]?.mapFieldId?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </td>
          ) : null}
          <td id="format">
            <Form.Group>
              <Form.Select
                data-testid="mapFieldFormatDropdown"
                id={`field-${index}-format`}
                isInvalid={errors.fields?.[index]?.validations?.format}
                name={`fields[${index}].validations.format`}
                {...register(`fields.${index}.validations.format`)}
              >
                <option value="">-</option>
                <option value="ANS">ANS</option>
                <option value="AN">AN</option>
                <option value="N">N</option>
                <option value="S">S</option>
              </Form.Select>
              <Form.Control.Feedback
                id={`format-${index}-error`}
                type="invalid"
              >
                {errors.fields?.[index]?.validations?.format?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </td>

          <td>
            <Form.Group>
              <Form.Select
                data-testid="lengthTypeDropdown"
                id={`field-${index}-length-type`}
                isInvalid={errors.fields?.[index]?.validations?.lengthType}
                {...register(`fields.${index}.validations.lengthType`)}
              >
                <option value="">-</option>
                <option value="ranged">Ranged</option>
                <option value="fixed">Fixed</option>
              </Form.Select>
              <Form.Control.Feedback
                id={`lengthType-${index}-error`}
                type="invalid"
              >
                {errors.fields?.[index]?.validations?.lengthType?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </td>
          <td
            className={
              watch(`fields[${index}].validations.lengthType`) === "ranged"
                ? "length1"
                : ""
            }
            colSpan={
              watch(`fields[${index}].validations.lengthType`) === "ranged"
                ? 1
                : 2
            }
          >
            <Form.Group>
              <Form.Control
                data-testid="lengthMinField"
                id={`field-${index}-length-min`}
                isInvalid={errors.fields?.[index]?.validations?.lengthMin}
                type="number"
                placeholder={
                  watch(`fields[${index}].validations.lengthType`) === "ranged"
                    ? "Min"
                    : "Length"
                }
                {...register(`fields.${index}.validations.lengthMin`)}
                min="1"
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
              />
              <Form.Control.Feedback
                id={`lengthMax-${index}-error`}
                type="invalid"
              >
                {errors.fields?.[index]?.validations?.lengthMin?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </td>
          {watch(`fields[${index}].validations.lengthType`) === "ranged" ? (
            <td className="length1">
              <Form.Group>
                <Form.Control
                  data-testid="lengthMaxField"
                  {...register(`fields.${index}.validations.lengthMax`)}
                  id={`field-${index}-length-max`}
                  isInvalid={errors.fields?.[index]?.validations?.lengthMax}
                  type="number"
                  min="1"
                  placeholder="Max"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                />
                <Form.Control.Feedback
                  id={`lengthMax-${index}-error`}
                  type="invalid"
                >
                  {errors.fields?.[index]?.validations?.lengthMax?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </td>
          ) : null}
          <td>
            <Form.Group>
              <Form.Select
                data-testid="requirementDropdown"
                id={`field-${index}-required`}
                isInvalid={errors.fields?.[index]?.validations?.required}
                {...register(`fields.${index}.validations.required`)}
              >
                <option value="">-</option>
                <option value={false}>Optional</option>
                <option value={true}>Mandatory</option>
              </Form.Select>
              <Form.Control.Feedback
                id={`lengthMax-${index}-error`}
                type="invalid"
              >
                {errors.fields?.[index]?.validations?.required?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </td>

          <td>
            <Form.Group>
              <Form.Control
                data-testid="DdefaultValueField"
                id={`field-${index}-default-value`}
                type="text"
                placeholder="Default value"
                isInvalid={errors.fields?.[index]?.defaultValue}
                {...register(`fields.${index}.defaultValue`)}
              />
              <Form.Control.Feedback
                id={`defaultValue-${index}-error`}
                type="invalid"
              >
                {errors.fields?.[index]?.defaultValue?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </td>
          <td>
            <Form.Group>
              <Form.Control
                data-testid="valueMapField"
                id={`field-${index}-value-map-value`}
                type="text"
                placeholder="Value Map"
                isInvalid={errors.fields?.[index]?.valueMap}
                {...register(`fields.${index}.valueMap`)}
              />
              <Form.Control.Feedback
                id={`valueMap-${index}-error`}
                type="invalid"
              >
                {errors.fields?.[index]?.valueMap?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </td>
          {createData.network === "M-Pesa" && !createData.isLegacy ? (
            <td className="text-center">
              <Form.Group>
                <Form.Check>
                  <Form.Check.Input
                    id={`field-${index}-requiredUserInput-value`}
                    isInvalid={errors.fields?.[index]?.requiredUserInput}
                    {...register(`fields.${index}.requiredUserInput`)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fields?.[index]?.requiredUserInput?.message}
                  </Form.Control.Feedback>
                </Form.Check>
              </Form.Group>
            </td>
          ) : null}
          <td>
            <Form.Group>
              <Form.Control
                data-testid="rulesField"
                id={`field-${index}-rules-value`}
                type="text"
                placeholder="Rules"
                isInvalid={errors.fields?.[index]?.rules}
                {...register(`fields.${index}.rules`)}
              />
              <Form.Control.Feedback id={`rules-${index}-error`} type="invalid">
                {errors.fields?.[index]?.rules?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </td>
        </span>
      </tr>
    );
  });

  return (
    <div className="page qr-spec-list-page field-validation nw-specification">
      <NotesModal
        type="add"
        show={showNotesModal}
        toggleShow={toggleShow}
        addNote={addNote}
        note={note}
        handleChangeNote={setNote}
      />
      <div className="page-title-row">
        <div className="title">
          <div className="breadcrumb">
            QR Specifications / {createFlow || cloneFlow ? "New" : "Edit"}{" "}
            Specification
          </div>
        </div>
      </div>
      <Form
        onSubmit={handleSubmit((data) =>
          onSubmit(data, "save", note, noteStep)
        )}
      >
        <div className="white-bg">
          <div className="title">
            <div className="sub-title1">Step 3 of 3</div>
            <div className="sub-title2">Field Validations and Values</div>
          </div>
          <Table
            responsive
            className="tbl-field-Validations-and-values"
            data-testid="step3-table"
          >
            <thead>
              <tr className="border-b">
                <th>Market Fields</th>
                <th>Master Field Mapping</th>
                <span className="d-flex field-v">
                  {showIdField ? <th id="identity">ID</th> : null}
                  <th id="format">Format</th>
                  <th>Length Type</th>
                  <th>Length</th>
                  <th>Presence</th>
                  <th>Default Value</th>
                  <th>Value Map</th>
                  {createData.network === "M-Pesa" && !createData.isLegacy ? (
                    <th className="text-center">User input</th>
                  ) : null}
                  <th>Rules</th>
                </span>
              </tr>
            </thead>
            <tbody>{renderFields}</tbody>
          </Table>
        </div>
        <div className="frm-btn btn-section">
          <Button
            data-testid="backButton"
            variant="primary"
            className="btn-link px-0 back-btn"
            id="back-button"
            onClick={goBack}
          >
            <MdKeyboardArrowLeft />
            Back
          </Button>
          <div>
            <Button
              data-testid="saveAsDraftButton"
              variant="primary"
              className="btn-cancel mx-3"
              onClick={handleSubmit((data) => handleSaveDraft(data))}
              name="saveAsDraft"
            >
              <FiSave />
              Save as Draft
            </Button>
            <Button
              data-testid="submitForTestingButton"
              variant="primary"
              type="submit"
              name="save"
            >
              Submit for Testing
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fieldsList: state.qrSpecifiction.createData.fields,
    createData: state.qrSpecifiction.createData,
    sub: state.profile?.sub,
    createFlow: state.qrSpecifiction.flow === "create",
    cloneFlow: state.qrSpecifiction.flow === "clone",
    selectedTemplate: state.qrSpecifiction.current,
    initialStep: state.qrSpecifiction.initialStep,
  };
};

const mapDispatchToProps = (dispatch) => ({
  isLoading: (flag) => dispatch(showLoader(flag)),
  saveAsDraft: (specification) =>
    dispatch(createQRSpecification(specification)),
  setStepTwoFields: (fields) => dispatch(setStepTwoFields(fields)),
  updateDraft: (specification) =>
    dispatch(updateQRSpecification(specification)),
  submitForTest: (specification) =>
    dispatch(createQRSpecification(specification)),
  getQRSpecification: (id) => dispatch(getQRSpecification(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepThree);
