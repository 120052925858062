import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from "react-toastify";
import {
  PASSWORD_RESET_SUCCESS,
  FAILED_TO_RESET_PASSWORD,
  UNAUTHORIZED_ACTION,
} from "../../utils/errorMessages";
import {connect} from 'react-redux';
import { resetPassword } from "../../redux/actions/userActions";

//eslint-disable-next-line
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^\$\*\.\[\]\{\}\(\)\?\\\-“!@#%&/,><’:;|_~`])\S{8,99}$/

const resetPasswordSchema = yup.object().shape({
  password: yup.string().max(99).matches(passwordRegExp, 'Password must contains 8 characters, (including 1 upper-case, 1 lower-case, 1 numeric and 1 special) ').required('Password is required'),
  confirmPassword: yup.string().max(99).required('Confirm the password').oneOf([yup.ref('password'), null], 'Passwords must match')
});

const ResetPasswordModal = ({toggleShowRestPasswordModal, resetPassword, user}) => {
  const [show, setShow] = useState(true);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty }
  } = useForm({
    defaultValues: { },
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onChange'
  });


  const handleClose = () => {
    toggleShowRestPasswordModal(false);
    reset();
    setShow(false);
  };

  const handlePasswordReset = async (data) => {
    try {
      await resetPassword({ email: user.email, password: data.password});
      toast.success(PASSWORD_RESET_SUCCESS);
      toggleShowRestPasswordModal(false);
      reset();
      setShow(false);
    } catch (e) {
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data);
      }else {
        toast.error(FAILED_TO_RESET_PASSWORD);
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title data-testid="modal-title" id="modal-title">Reset Password</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(handlePasswordReset)}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                data-testid="password"
                {...register("password", { required: true })}
                autoComplete="new-password"
                placeholder="Enter New Password"
                isInvalid={errors.password}
              />
              <Form.Control.Feedback id="passwordErrors" type="invalid">
                {errors.password?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                data-testid="confirm-password"
                {...register("confirmPassword", { required: true })}
                autoComplete="new-password"
                placeholder="Confirm Password"
                isInvalid={errors.confirmPassword}
              />
              <Form.Control.Feedback id="confirmPasswordErrors" type="invalid">
                {errors.confirmPassword?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              data-testid="btn-cancel"
              variant="primary"
              className="btn-cancel mx-3"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit" data-testid="btn-reset" disabled={!isValid || !isDirty}>
              Reset
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sub: state.profile.sub
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (data) => dispatch(resetPassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal);
