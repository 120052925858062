
import React, { useEffect, useState, memo } from 'react'
import { getVersionsList } from '../../redux/actions/qrSpecificationActions';

import { connect } from "react-redux";
import { SOMETHING_WENT_WRONG, UNAUTHORIZED_ACTION } from '../../utils/errorMessages';
import { toast } from 'react-toastify';

const SubRows = ({ row, rowProps, visibleColumns, data, loading }) => {
    if (loading) {
        return (
            <tr className='sub-row'>
                <td />
                <td colSpan={visibleColumns.length - 1}>
                    Loading...
                </td>
            </tr>
        );
    }


    return (
        <>
            {data.map((x, i) => {
                return (
                    <tr
                        {...rowProps}
                        className="sub-row"
                        key={`${rowProps.key}-expanded-${i}`}
                    >
                        {row.cells.map((cell) => {
                            return (
                                <td
                                    {...cell.getCellProps({ className: cell.column.className })}
                                >
                                    {cell.render(cell.column.SubCell ? 'SubCell' : 'Cell', {
                                        value:
                                            cell.column.accessor &&
                                            cell.column.accessor(x, i),
                                        row: { ...row, original: x }
                                    })}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </>
    );
}


const SubRowAsync = ({ row, rowProps, visibleColumns, listVersions }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        let isMounted = true;
        if (row.original.version !== null) {

            listVersions(row.original.version.specificationId)
                .then((results) => {
                    if (isMounted) {
                        setData(results.data)
                        setLoading(false);
                    }
                }).catch(e => {

                    if (e.response.status && e.response.status === 403) {
                        toast.error(UNAUTHORIZED_ACTION);
                    } else if (e.response.data.Description) {
                        toast.error(e.response.data);
                    } else {
                        toast.error(SOMETHING_WENT_WRONG);
                    }
                })
        } else {
            setData([]);
            setLoading(false);
        }
        return () => {
            isMounted = false;
        }
    }, [listVersions, row.original.version]);

    return (
        <SubRows
            row={row}
            rowProps={rowProps}
            visibleColumns={visibleColumns}
            data={data}
            loading={loading}
        />
    );
}

const mapDispatchToProps = (dispatch) => ({
    listVersions: (specId) => dispatch(getVersionsList(specId)),
});

export default connect(null, mapDispatchToProps)(memo(SubRowAsync));
