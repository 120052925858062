import axios from "../../apis/qrCore";
import { v4 as uuidv4 } from 'uuid';

import {
	FETCH_NETWORKS,
	CREATE_NETWORK,
	UPDATE_NETWORK,
	DEACTIVATE_NETWORK,
	ACTIVATE_NETWORK
} from "./actionTypes";

const NETWORKS_ENDPOINT = 'networks';

export const getNetworks = (filters) => async (dispatch, getState) => {
	function onSuccess(response) {
		dispatch({
			type: FETCH_NETWORKS,
			payload: response.data,
		});
		
		return response;
	}
	function onError(error) {
		dispatch({
			type: FETCH_NETWORKS,
			payload: error.data,
		});
		throw error;
	} try {
		const response = await axios.get(`${NETWORKS_ENDPOINT}`, {
			headers: {
				"x-correlation-id": 1,
				"x-source-system": 'mpa-qr-code-web-app',
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
			params: filters
		});
		return onSuccess(response);
	} catch (error) {
		return onError(error);
	}
};

export const createNetwork = (network) => async (dispatch, getState) => {
	function onSuccess(response) {
		dispatch({
			type: CREATE_NETWORK,
			payload: response.data,
		});
		return response;
	}
	function onError(error) {
		dispatch({
			type: CREATE_NETWORK,
			payload: error.data,
		});
		throw error;
	}
	try {
		const response = await axios.post(`${NETWORKS_ENDPOINT}`, network, {
			headers: {
				"x-correlation-id": uuidv4(),
				"x-source-system": 'mpa-qr-code-web-app',
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		});
		return onSuccess(response);
	} catch (error) {
		return onError(error);
	}
};

export const updateNetwork = (network) => async (dispatch, getState) => {
	function onSuccess(success) {
		dispatch({
			type: UPDATE_NETWORK,
			payload: success.data,
		});
		return success;
	}
	function onError(error) {
		dispatch({
			type: UPDATE_NETWORK,
			payload: error,
		});
		throw error;
	}
	try {
		
		const response = await axios.put(`${NETWORKS_ENDPOINT}/`, network, {
			headers: {
				"x-correlation-id": uuidv4(),
				"x-source-system": 'mpa-qr-code-web-app',
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			}
		});
		return onSuccess(response);
	} catch (error) {
		return onError(error);
	}
};

export const activateOrDeactivateNetwork = (data) => async (dispatch, getState) => {
	if (data.isActive) {
	  function onSuccess(success) {
		dispatch({
		  type: DEACTIVATE_NETWORK,
		  payload: success.data,
		});
		return success;
	  }
	  function onError(error) {
		dispatch({
		  type: DEACTIVATE_NETWORK,
		  payload: error,
		});
		throw error;
	  }
	  
	  try {
		const payload = {
		  id: data.id
		};
		const response = await axios.put(`${NETWORKS_ENDPOINT}/deactivate`, payload, {
		  headers: {
			"x-correlation-id": uuidv4(),
			"x-source-system": 'mpa-qr-code-web-app',
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		  },
		});
		return onSuccess(response);
	  } catch (error) {
		return onError(error);
	  }
	} else {
	  function onSuccess(success) {
		dispatch({
		  type: ACTIVATE_NETWORK,
		  payload: success.data,
		});
		return success;
	  }
	  function onError(error) {
		dispatch({
		  type: ACTIVATE_NETWORK,
		  payload: error,
		});
		throw error;
	  }
	  
	  try {
		const payload = {
		  id: data.id
		};
		const response = await axios.put(`${NETWORKS_ENDPOINT}/activate`, payload, {
		  headers: {
			"x-correlation-id": uuidv4(),
			"x-source-system": 'mpa-qr-code-web-app',
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		  },
		});
		return onSuccess(response);
	  } catch (error) {
		return onError(error);
	  }
	}
  };
