import axios from "../../apis/auditAPI";
import { v4 as uuidv4 } from 'uuid';

import {
  FETCH_AUDIT_LOGS,
  EXPORT_AUDIT_LOGS,
  CHANGE_SELECTED_AUDIT,
} from "./actionTypes";

export const getAudits =
  (role, startDate, endDate, action, page = 1, pageSize = 15, username="") =>
    async (dispatch, getState) => {

      function onSuccess(data) {
        dispatch({
          type: FETCH_AUDIT_LOGS,
          payload: data,
        });
        return data;
      }
      function onError(error) {
        dispatch({
          type: FETCH_AUDIT_LOGS,
          payload: [],
        });
        throw error;
      }
      try {
        let params = { PageSize: pageSize };

        if (role !== null && role !== "all") {
          params.Role = role;
        }

        if (startDate !== null) {
          params.StartDate = startDate;
        }

        if (endDate !== null) {
          params.EndDate = endDate;
        }
  
        if (action !== null && action > 0) {
          params.Action = action;
        }
        
        if (username !== null && username.length > 0) {
          params.Username = username;
        }

        params.PageSize = pageSize;
        params.PageNumber = page;

        const response = await axios.get("/", {
          headers: {
            "x-correlation-id": uuidv4(),
            "x-source-system": 'mpa-qr-code-web-app',
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
          },
          params,
        });
        return onSuccess(response.data);
      } catch (error) {
        return onError(error);
      }
    };

export const exportAudits =
  (role, startDate, endDate, action, page = 1, pageSize = 15, username="") =>
    async (dispatch, getState) => {
      let params = { PageSize: pageSize };

      if (role !== null && role > 0) {
        params.Role = role;
      }

      if (startDate !== null) {
        params.StartDate = startDate;
      }

      if (endDate !== null) {
        params.EndDate = endDate;
      }
  
      if (action !== null && action > 0) {
        params.Action = action;
      }
  
      if (username !== null && username.length > 0) {
        params.Username = username;
      }

      params.PageSize = pageSize;
      params.PageNumber = page;

      function onSuccess(success) {
        dispatch({
          type: EXPORT_AUDIT_LOGS,
          payload: success.data,
        });
        return success;
      }
      function onError(error) {
        dispatch({
          type: EXPORT_AUDIT_LOGS,
          payload: error,
        });
        throw error;
      }
      try {
        const response = await axios.get("/export", {
          headers: {
            "x-correlation-id": uuidv4(),
            "x-source-system": 'mpa-qr-code-web-app',
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
          },
          params,
        });
        return onSuccess(response);
      } catch (error) {
        return onError(error);
      }
    };

export const changeSelectedAudit = (payload) => {
  return {
    type: CHANGE_SELECTED_AUDIT,
    payload: payload,
  };
};
