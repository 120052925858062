export const USER_CREATE = 10001;
export const USER_EDIT = 10002;
export const USER_ACTIVATE = 10003;
export const USER_DEACTIVATE = 10004;
export const USER_RESEND_INVITE = 10005;
export const USER_RESET_PASSWORD = 10006;
export const USER_RESET_MFA = 10007;
export const USER_LIST = 10008;
export const USER_VIEW_STATUS = 10010;

export const AUDIT_VIEW = 11001;
export const AUDIT_EXPORT  = 11002;

export const QR_GENERATE_INDIVIDUAL = 12001;
export const QR_GENERATE_BULK = 12002;
export const QR_GENERATE_TEMPLATE_LIST = 12003;

export const QR_TEMPLATE_CREATE = 13001;
export const QR_TEMPLATE_EDIT = 13002;
export const QR_TEMPLATE_LIST = 13003;
export const QR_TEMPLATE_VIEW = 13004;
export const QR_TEMPLATE_UPLOAD = 13005;
export const QR_TEMPLATE_ACTIVATE_DEACTIVATE = 13006;

export const PERMISSION_LIST = 14001;
export const PERMISSION_VIEW_BY_USER_ID = 14002;

export const ROLE_CREATE = 15001;
export const ROLE_EDIT = 15002;
export const ROLE_LIST = 15003;
export const ROLE_VIEW = 15004;
export const ROLE_ATTACH = 15005;
export const ROLE_RESET = 15006;
export const ROLE_DEACTIVATE_ACTIVATE = 15007;

export const QR_SPECIFICATION_LIST = 16001;
export const QR_SPECIFICATION_CREATE = 16002;
export const QR_SPECIFICATION_VIEW = 16003;
export const QR_SPECIFICATION_ACTIVATE = 16004;
export const QR_SPECIFICATION_DEACTIVATE = 16005;
export const QR_SPECIFICATION_UPDATE = 16006;
export const QR_SPECIFICATION_LIST_VERSION = 16007; 
export const QR_SPECIFICATION_CREATE_VERSION = 16008; 
export const QR_SPECIFICATION_ACTIVATE_VERSION = 16009;
export const QR_SPECIFICATION_MOVE_TO_REVIEW = 16010;
export const QR_SPECIFICATION_VIEW_HISTORY = 16011;
export const QR_SPECIFICATION_MOVE_TO_PUBLISH = 16012;


export const NETWORK_CREATE = 17001;
export const NETWORK_UPDATE = 17002;
export const NETWORK_ACTIVATE = 17003;
export const NETWORK_DEACTIVATE = 17004;
export const NETWORK_LIST = 17005;

export const LANGUAGE_LIST = 18001;
export const LANGUAGE_CREATE = 18002;
export const LANGUAGE_UPDATE = 18003;
export const LANGUAGE_ACTIVATE = 18004;
export const LANGUAGE_DEACTIVATE = 18005;

export const MARKET_LIST = 19001;
export const MARKET_CREATE = 19002;
export const MARKET_UPDATE = 19003;
export const MARKET_ACTIVATE = 19004;
export const MARKET_DEACTIVATE = 19005;
export const MARKET_VIEW = 19006;

export const TRANSACTION_TYPE_LIST = 20003;
export const TRANSACTION_TYPE_CREATE = 20001;
export const TRANSACTION_TYPE_UPDATE = 20002;
export const TRANSACTION_TYPE_ACTIVATE_DEACTIVATE = 20007;



export const showQRCodeSideBarItem = (permissions) => {
    const QRActions = [ QR_GENERATE_INDIVIDUAL ,QR_GENERATE_BULK, QR_GENERATE_TEMPLATE_LIST ];
    return QRActions.some(i => permissions.includes(i));
}

export const showTemplatesSideBarItem = (permissions) => {
    const QRActions = [ QR_TEMPLATE_CREATE, QR_TEMPLATE_EDIT, QR_TEMPLATE_LIST ,QR_TEMPLATE_VIEW, QR_TEMPLATE_UPLOAD , QR_TEMPLATE_ACTIVATE_DEACTIVATE];
    return QRActions.some(i => permissions.includes(i));
}

export const showAuditLogsSideBarItem = (permissions) => {
    const AuditActions = [AUDIT_VIEW, AUDIT_EXPORT];
    return AuditActions.some(i => permissions.includes(i));
}

export const showUserManagementSideBarItem = (permissions) => {
    const UserManagementActions = [USER_CREATE, USER_EDIT, USER_ACTIVATE, USER_DEACTIVATE, USER_RESEND_INVITE, USER_RESET_PASSWORD, USER_RESET_MFA, USER_LIST, USER_VIEW_STATUS];
    return UserManagementActions.some(i => permissions.includes(i));
}

export const showSettingsSideBarItem = (permissions) => {
    const SettingsActions = [ROLE_VIEW, ROLE_RESET, ROLE_CREATE, ROLE_DEACTIVATE_ACTIVATE, QR_SPECIFICATION_CREATE, QR_SPECIFICATION_LIST, NETWORK_CREATE, LANGUAGE_CREATE];
    return SettingsActions.some(i => permissions.includes(i));
}

export const showRolesSecondarySideBarItem = (permissions) => {
    const SettingsActions = [ROLE_VIEW, ROLE_RESET, ROLE_CREATE, ROLE_DEACTIVATE_ACTIVATE];
    return SettingsActions.some(i => permissions.includes(i));
}

export const showQRSpecSecondarySideBarItem = (permissions) => {
    const SettingsActions = [QR_SPECIFICATION_LIST, QR_SPECIFICATION_UPDATE, QR_SPECIFICATION_UPDATE, QR_SPECIFICATION_DEACTIVATE, QR_SPECIFICATION_LIST];
    return SettingsActions.some(i => permissions.includes(i));
}

export const showNetworksSecondarySideBarItem = (permissions) => {
    const SettingsActions = [NETWORK_LIST, NETWORK_UPDATE, NETWORK_CREATE, NETWORK_ACTIVATE, NETWORK_DEACTIVATE];
    return SettingsActions.some(i => permissions.includes(i));
}

export const showLanguagesSecondarySideBarItem = (permissions) => {
    const SettingsActions = [LANGUAGE_LIST, LANGUAGE_CREATE, LANGUAGE_UPDATE, LANGUAGE_DEACTIVATE, LANGUAGE_ACTIVATE];
    return SettingsActions.some(i => permissions.includes(i));
}

export const showMarketsSecondarySideBarItem = (permissions) => {
    const SettingsActions = [MARKET_LIST, MARKET_CREATE, MARKET_ACTIVATE, MARKET_DEACTIVATE, MARKET_UPDATE];
    return SettingsActions.some(i => permissions.includes(i));
}

export const showTransactionTypesSecondarySideBarItem = (permissions) => {
    const SettingsActions = [TRANSACTION_TYPE_LIST, TRANSACTION_TYPE_CREATE, TRANSACTION_TYPE_UPDATE, TRANSACTION_TYPE_ACTIVATE_DEACTIVATE];
    return SettingsActions.some(i => permissions.includes(i));
}