import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
	Form,
	Table,
	Col,
	Row,
	Pagination,
	Button,
} from "react-bootstrap";
import { toast } from "react-toastify";
import {
	SOMETHING_WENT_WRONG,
	UNAUTHORIZED_ACTION,
} from "../../utils/errorMessages";

import { LANGUAGE_CREATE, LANGUAGE_LIST } from "../../utils/permissionList";
import LanguageActionMenu from "../../components/action-menus/LanguageActionMenu";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { AiOutlinePlus } from "react-icons/ai";
import {
	getLanguages,
	activateOrDeactivateLanguage
} from "../../redux/actions/languageActions";
import { showLoader } from "../../redux/actions/commonActions";
import { ShowForPermission } from "../../components/ShowForPermission";
import { checkPermission } from "../../redux/actions/permissionActions";
import { receiveMarketData } from "../../redux/actions/profileActions";
import { capitalizeFirstLetter } from "../../utils/stringFormat";
import CreateLanguageModal from "../../components/modals/CreateLanguageModal";


const LanguagesPage = ({
	getLanguages,
	listOfLanguages = [],
	showLoader,
	noOfPages,
	currentPage,
	checkPermission,
	activateOrDeactivateLanguage,
	marketOptions =[],
	receiveMarketData,
}) => {

	const [isEditFlow, setIsEditFlow] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [language, setLanguage] = useState(false);
	const [showCreateLanguageModal, setShowCreateLanguageModal] = useState(false);
	const [filters, setFilters] = useState({
		pageNumber: 1,
		pageSize: 15,
		market: "all",
		isDropdown: false,
		IsActiveOnly: false
	});

	const fetchLanguageList = async () => {
		try {
			showLoader(true);
			await getLanguages(filters);
			showLoader(false);
		} catch (e) {

			if (e.response.status && e.response.status === 403) {
				toast.error(UNAUTHORIZED_ACTION);
			} else if (e.response.data.Description) {
				toast.error(e.response.data.Description);
			} else {
				toast.error(SOMETHING_WENT_WRONG);
			}
			showLoader(false);
		}
	};

	useEffect(() => {
		async function fetchLanguages() {
			try {
				showLoader(true);
				if (await checkPermission([LANGUAGE_LIST])) {
					await fetchLanguageList();
					await receiveMarketData();
				}
				showLoader(false);
			} catch (e) {

				if (e.response.status && e.response.status === 403) {
					toast.error(UNAUTHORIZED_ACTION);
				} else if (e.response.data.Description) {
					toast.error(e.response.data.Description);
				} else {
					toast.error(SOMETHING_WENT_WRONG);
				}
				showLoader(false);
			}
		}
		fetchLanguages();
	}, [filters, showLoader, checkPermission]);


	const handleCreateLanguage = () => {
		setIsEditFlow(false);
		toggleShowCreateLanguageModal();
	}

	const toggleLanguageActivityModal = (language) => {
		setLanguage(language);
		setShowConfirmation(true)
	};

	const toggleLanguageCreateModal = (language) => {
		setLanguage(language);
		toggleShowCreateLanguageModal()
	};

	const handleChangeMarket = (market) => {
		setFilters({ ...filters, pageNumber: 1, market });
	};

	const handleChangePageSize = (size) => {
		setFilters({ ...filters, pageNumber: 1, pageSize: size });
	};

	const handleEditLanguage = (language) => {
		setIsEditFlow(true);
		toggleLanguageCreateModal(language);
	}

	const handleLanguageActivationAndDeactivation = async () => {
		try {
			showLoader(true);
			await activateOrDeactivateLanguage(language)
			await fetchLanguageList();
			showLoader(false)
			toast.success(`Language ${language.isActive ? "Deactivated" : "Activated"}`);
		} catch (e) {
			if (e.response.status && e.response.status === 403) {
				toast.error(UNAUTHORIZED_ACTION);
			} else if (e.response.data.Description) {
				toast.error(e.response.data.Description);
			} else {
				toast.error(SOMETHING_WENT_WRONG);
			}
			showLoader(false);
		}
	};

	const toggleSetShowConfirmation = () => setShowConfirmation((p) => !p);
	const toggleShowCreateLanguageModal = () => setShowCreateLanguageModal((p) => !p);
	let items = [];
	let max = 6;
	let start = currentPage - 3 <= 0 ? 1 : currentPage - 3;
	let end = start + max > noOfPages ? noOfPages : start + max;

	for (let number = start; number <= end; number++) {
		items.push(
			<Pagination.Item
				key={number}
				active={number === currentPage}
				onClick={() => setFilters({ ...filters, pageNumber: number })}
			>
				{number}
			</Pagination.Item>
		);
	}

	const renderLanguages = listOfLanguages?.map((language) => {
		return (
			<tr key={language.id}>
				<td className="word-break">{language.name}</td>
				<td className="word-break">{capitalizeFirstLetter(language.market)}</td>
				<td className="word-break">{language.shortCode3}</td>

				<td>
					{language.isActive ? (
						<div className="status">
							<span className={language.isActive ? "active" : "inactive"}>
								Active
							</span>
						</div>
					) : (
						<div className="status wrap-inactive">
							<span className="inactive">Inactive</span>
						</div>
					)}
				</td>
				<td>
					<LanguageActionMenu
						editLanguage={handleEditLanguage}
						toggleLanguageActivity={toggleLanguageActivityModal}
						languageIsActive={language.isActive}
						language={language}
					/>
				</td>
			</tr>
		);
	});

	return (
		<div className="page language-page">
			<ConfirmationModal
				show={showConfirmation}
				toggleShow={toggleSetShowConfirmation}
				confirm={handleLanguageActivationAndDeactivation}
				title={`Are you sure you want to ${language.isActive ? "Deactivate" : "Activate"
					} this Language?`}
			/>
			<CreateLanguageModal
				show={showCreateLanguageModal}
				toggleShow={toggleShowCreateLanguageModal}
				getLanguageList={fetchLanguageList}
				language={language}
				isEditFlow={isEditFlow}
			/>
			<div className="page-title-row">
				<div className="title">
					<h1>Languages</h1>
				</div>
			</div>
			<div className="filter-section row">
				<div className="filter-components">
					<Form className="form w-100">
						<Row className="align-items-center">
							<Col xl="3" lg="4" md="6" sm="12">
								<Form.Select
									id="market-select"
									data-testid="market-select"
									onChange={(e) =>
										handleChangeMarket(e.target.value)
									}
								>
									<option value="all">All (Markets)</option>
									{marketOptions.map(market => {
										return <option key={market.value} value={market.value}>{market.displayName}</option>
									})}
								</Form.Select>
							</Col>
							{/* <Col xl="6" lg="3" md="0" sm="12">
							</Col> */}
							<Col xl="9" lg="8" md="6" sm="12" className="btn-csv d-flex justify-content-end">
								<ShowForPermission permissions={[LANGUAGE_CREATE]}>
									<Button
										variant="primary"
										id="add-language-button"
										data-testid="add-language-button"
										className="btn-special"
										onClick={handleCreateLanguage}
									>
										<AiOutlinePlus /> <span>Language</span>
									</Button>
								</ShowForPermission>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
			<ShowForPermission permissions={[LANGUAGE_LIST]} showBanner requiresAll>
				<Table data-testid="language-list-table">
					<thead>
						<tr>
							<th data-testid="table-header-name">Name</th>
							<th data-testid="table-header-market">Market</th>
							<th data-testid="table-header-code">Code</th>
							<th data-testid="table-header-status">Status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>{renderLanguages}</tbody>
				</Table>
			</ShowForPermission>
			<div className="footer-table d-flex justify-content-between">
				<div>
					<Form.Select onChange={(e) => handleChangePageSize(e.target.value)}>
						<option value="15">15</option>
						<option value="30">30</option>
						<option value="60">60</option>
					</Form.Select>
				</div>
				<div className="mt-2">{filters.pageNumber}</div>
				<div className="pagination">
					<Pagination size="sm">
						<Pagination.First
							onClick={() => setFilters({ ...filters, pageNumber: 1 })}
						/>
						<Pagination.Prev
							disabled={currentPage === 1}
							onClick={() => setFilters({ ...filters, pageNumber: filters.page - 1 })}
						/>
						{currentPage > 4 ? <Pagination.Ellipsis disabled /> : null}

						{items}
						{currentPage === noOfPages ? null : (
							<Pagination.Ellipsis disabled />
						)}
						<Pagination.Next
							disabled={currentPage === noOfPages}
							onClick={() => setFilters({ ...filters, pageNumber: filters.pageNumber + 1 })}
						/>
						<Pagination.Last
							onClick={() => setFilters({ ...filters, pageNumber: noOfPages })}
						/>
					</Pagination>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		listOfLanguages: state.language.list,
		currentPage: state.language.currentPage,
		noOfPages: state.language.noOfPages,
		totalCount: state.language.totalCount,
		marketOptions: state.profile.markets
	};
};

const mapDispatchToProps = (dispatch) => ({
	getLanguages: (filters) => dispatch(getLanguages(filters)),
	showLoader: (data) => dispatch(showLoader(data)),
	checkPermission: (permission) => dispatch(checkPermission(permission)),
	activateOrDeactivateLanguage: (data) => dispatch(activateOrDeactivateLanguage(data)),
	receiveMarketData: () => dispatch(receiveMarketData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesPage);
