import React from "react";
import SideBar from "../SideBar";
import { AiOutlineQrcode } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { ImInsertTemplate } from "react-icons/im";
import { VscSymbolFile } from "react-icons/vsc";
import { GrNotes } from "react-icons/gr";
import { FiSettings } from "react-icons/fi";
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom'
import { showQRCodeSideBarItem, showTemplatesSideBarItem, showAuditLogsSideBarItem, showUserManagementSideBarItem, showSettingsSideBarItem, showLanguagesSecondarySideBarItem, showNetworksSecondarySideBarItem, showQRSpecSecondarySideBarItem, showRolesSecondarySideBarItem, showMarketsSecondarySideBarItem,showTransactionTypesSecondarySideBarItem } from "../../utils/permissionList";
import SecondarySidebar from "../SecondarySidebar";

const Main = ({ children, currentUserPermissions }) => {
  const location = useLocation();
  let routes = [];
  let secondaryRoutes = [];

  const getPathForSettings = () => {
    if (showRolesSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/roles";
    } else if (showQRSpecSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/qr-specs";
    } else if (showLanguagesSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/languages"
    } else if (showNetworksSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/networks";
    } else if (showMarketsSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/markets";
    } else if (showTransactionTypesSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/transaction-types";
    }
  }

  if (showQRCodeSideBarItem(currentUserPermissions)) {
    routes.push({
      id: "generate-qr-nav-item",
      path: "/generate-qr",
      name: "Generate QR",
      icon: <AiOutlineQrcode />,
      uiResourceKey: "",
      exact: false,
    })
  }

  if (showUserManagementSideBarItem(currentUserPermissions)) {
    routes.push({
      id: "users-nav-item",
      path: "/users",
      name: "User Management",
      icon: <AiOutlineUser />,
      uiResourceKey: "",
      exact: true,
    })
  }

  if (showAuditLogsSideBarItem(currentUserPermissions)) {
    routes.push({
      id: "audits-nav-item",
      path: "/audits",
      name: "Audit Logs",
      icon: <VscSymbolFile />,
      uiResourceKey: "",
      exact: true,
    })
  }

  if (showTemplatesSideBarItem(currentUserPermissions)) {
    routes.push({
      id: "dynamic-templates-nav-item",
      path: "/templates",
      name: "QR Templates",
      icon: <ImInsertTemplate />,
      uiResourceKey: "",
      exact: false,
    })
  }

  if (showSettingsSideBarItem(currentUserPermissions)) {
    routes.push({
      id: "settings-nav-item",
      path: getPathForSettings(),
      name: "Settings",
      icon: <FiSettings />,
      uiResourceKey: "",
      exact: false,
    })
  }

  if (showRolesSecondarySideBarItem(currentUserPermissions)) {
    secondaryRoutes.push({
      id: "settings-nav-item",
      path: "/settings/roles",
      name: "User Roles",
      icon: <FiSettings />,
      uiResourceKey: "",
      exact: false,
    })
  }


  if (showQRSpecSecondarySideBarItem(currentUserPermissions)) {
    secondaryRoutes.push({
      id: "qr-specs-nav-item",
      path: "/settings/qr-specs",
      name: "QR Specifications",
      icon: <GrNotes />,
      uiResourceKey: "",
      exact: false,
    })
  }

  if (showLanguagesSecondarySideBarItem(currentUserPermissions)) {
    secondaryRoutes.push({
      id: "languages-nav-item",
      path: "/settings/languages",
      name: "Languages",
      icon: <GrNotes />,
      uiResourceKey: "",
      exact: false,
    })
  }

  if (showNetworksSecondarySideBarItem(currentUserPermissions)) {
    secondaryRoutes.push({
      id: "networks-nav-item",
      path: "/settings/networks",
      name: "Networks",
      icon: <GrNotes />,
      uiResourceKey: "",
      exact: false,
    })
  }

  if (showTransactionTypesSecondarySideBarItem(currentUserPermissions)) {
    secondaryRoutes.push({
      id: "transaction-types-nav-item",
      path: "/settings/transaction-types",
      name: "Transaction Types",
      icon: <GrNotes />,
      uiResourceKey: "",
      exact: false,
    })
  }

  if (showMarketsSecondarySideBarItem(currentUserPermissions)) {
    secondaryRoutes.push({
      id: "markets-nav-item",
      path: "/settings/markets",
      name: "Markets",
      icon: <GrNotes />,
      uiResourceKey: "",
      exact: false,
    })
  }

  return (
    <div className="structure">
      {(location.pathname === '/mfa-setup' ? null :
        <SideBar items={routes} />

      )}
      {(!location.pathname.includes('/settings') ? null :
        <SecondarySidebar items={secondaryRoutes} />
      )}

      <div className={location.pathname.includes('/settings') ? "secondary-nav-page container-fluid" : "offset-main container-fluid"}>
        <div className="row">
          {children}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  groups: state.profile?.groups,
  currentUserPermissions: state.permission.currentUserPermissions
});

export default connect(mapStateToProps, null)(Main);
