import React, {useContext} from "react";
import { connect } from "react-redux";
import Loader from "../../components/loaders/Loader";
import { generateStateAndNonce } from "../../redux/actions/tokenActions";
import { AuthService } from "../../components/AuthService";
import { AuthContext } from '../../context/Auth.context'

const LoginPage = ({ state, nonce, generateParams }) => {
  const { signinRedirect } = useContext(AuthContext)

    signinRedirect()

  return (
    <Loader/>
  );
};

const mapStateToProps = state => ({
  state: state.token.state,
  nonce: state.token.nonce
});

const mapDispatchToProps = dispatch => ({
  generateParams: () => dispatch(generateStateAndNonce()),
  login: () => dispatch(AuthService.login())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);