import moment from "moment";

// Eg: 2 hours 12 minutes ago
export const getTimeDifferenceWithSeconds = (timestamp) => {
  const timeDif = moment().diff(moment(timestamp));
  const duration = moment.duration(timeDif, "milliseconds");
  const durationFormat =
    duration < 60000
      ? "s [sec]"
      : duration < 3600000
      ? "m [min]"
      : "h [hours] m [min]";
  const formattedDuration = moment
    .utc(duration.asMilliseconds())
    .format(durationFormat);
  return formattedDuration + " ago";
};
// Eg: Few seconds ago, a minute ago (Rounded off time)
export const getTimeDifference = (timestamp) => {
  const timeDif = moment.utc(timestamp).diff(moment.utc());
  const duration = moment.duration(timeDif, "milliseconds").humanize(true);
  return duration;
};
// Eg: 2 hours 3 minutes 25 seconds
export const getDuration = (duration) => {
  const exDuration = moment.duration(duration, "hh:mm:ss:ms").asMilliseconds();
  const durationFormat =
    exDuration < 1000
      ? "SSS [ms]"
      : exDuration < 60000
      ? "s [sec]"
      : exDuration < 3600000
      ? "m [min] s [sec]"
      : "h [hours] m [min] s [sec]";
  return exDuration === 0
    ? "0 ms"
    : moment.utc(exDuration).format(durationFormat);
};
export const formatDateToISO = (date) => {
  if (date) {
    return moment(date).toISOString();
  }
};
// Eg: 12th Mar '21
export const formatDateForLoadStatus = (date) => {
  if (date) {
    return moment(date).format("DD MMM 'YY");
  }
};
// 12 Aug 2021
export const formatDatewithShortMonth = (date) => {
  if (date) {
    return moment(date).format("DD MMM YYYY");
  }
};
// 27/06/2021
export const formatDate = (date) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY");
  }
};
// 27/06/2021 03:12 pm
export const formatDateTime = (date) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY hh:mm A");
  }
};

export const formatDateTimeForHistory = (date) => {
  if (date) {
    return moment(date).format('MMMM Do YYYY, h:mm a');
  }
};
