import React from "react";
import { Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { ShowForPermission } from "../ShowForPermission";
import {
  TRANSACTION_TYPE_UPDATE,
  TRANSACTION_TYPE_ACTIVATE_DEACTIVATE,
} from "../../utils/permissionList";

const TransactionTypeActionMenu = ({
  toggleTransactionTypeActivity,
  openEditTransactionTypeModal,
  transactionType,
}) => {
  return (
    <Dropdown>
      <ShowForPermission
        permissions={[
          TRANSACTION_TYPE_UPDATE,
          TRANSACTION_TYPE_ACTIVATE_DEACTIVATE,
        ]}
      >
        <Dropdown.Toggle
          variant="link"
          id={"dropdown-basic-" + transactionType.id}
          data-testid="toggle-btn"
        >
          <BiDotsVerticalRounded />
        </Dropdown.Toggle>
      </ShowForPermission>
      <Dropdown.Menu>
        <ShowForPermission permissions={[TRANSACTION_TYPE_UPDATE]}>
          <Dropdown.Item
            data-testid="editAction"
            onClick={() => openEditTransactionTypeModal(transactionType)}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Divider />
        </ShowForPermission>
        <ShowForPermission permissions={[TRANSACTION_TYPE_ACTIVATE_DEACTIVATE]}>
          <Dropdown.Item
            data-testid="networkActivityAction"
            onClick={() => toggleTransactionTypeActivity(transactionType)}
          >
            {transactionType.isActive ? "Deactivate" : "Reactivate"}
          </Dropdown.Item>
        </ShowForPermission>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TransactionTypeActionMenu;
