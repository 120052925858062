import React, {  } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";


const SecondarySideBar = ({ items = []}) => {

  return (
    <div className="sidebar-secondary flex-column flex-shrink-0">
  
      <div className="sidebar-secondary-item">
        {items.map((item) => (
          <div key={item.path} className="nav-item" id={item.id}>
            <NavLink className="sidebar__link" to={item.path} exact={item.exact} activeClassName='is-active-secondry'>
              <div className="icon-label-group bot-border">
               {item.name}
              </div>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
  token: state.token.idToken,
  firstName: state.profile?.firstName,
  lastName: state.profile?.lastName,
  groups: state.profile?.groups,
});

export default connect(mapStateToProps, mapDispatchToProps)(SecondarySideBar);
