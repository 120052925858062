import {
  QR_SPEC_TEST_ENCODE_SUCCESS,
  QR_SPEC_TEST_ENCODE_ERROR,
  QR_SPEC_TEST_DECODE_SUCCESS,
  QR_SPEC_TEST_DECODE_ERROR,
  SET_INITIAL_STATE,
} from "../actions/actionTypes";

const defaultState = {
  encodeResponseBody: {},
  decodeResponseBody: {},
};

const testQrSpecificationReducer = (
  reduxState = defaultState,
  { type, payload }
) => {
  switch (type) {
    case QR_SPEC_TEST_ENCODE_SUCCESS:
      return {
        ...reduxState,
        encodeResponseBody: payload,
      };
    case QR_SPEC_TEST_ENCODE_ERROR:
      return {
        ...reduxState,
        encodeResponseBody: payload,
      };
    case QR_SPEC_TEST_DECODE_SUCCESS:
      return {
        ...reduxState,
        decodeResponseBody: payload,
      };
    case QR_SPEC_TEST_DECODE_ERROR:
      return {
        ...reduxState,
        decodeResponseBody: payload,
      };
    case SET_INITIAL_STATE:
      return {
        ...reduxState,
        encodeResponseBody: {},
        decodeResponseBody: {},
      };
    default:
      return reduxState;
  }
};

export default testQrSpecificationReducer;
