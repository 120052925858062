import React from "react";
import History from "./History";
import { Form, Pagination } from "react-bootstrap";


const HistoryList = ({
  historyDetails,
  handleChangePageSize,
  filters,
  setFilters,
  currentPage,
  noOfPages,
  items,
}) => {
  
  return (
    <div className="history-list">
      {historyDetails.length > 0 ? (
        <div>
          {historyDetails.map((history) => (
            <History key={history.id} history={history} />
          ))}

          <div className="footer-table d-flex justify-content-between">
            <div>
              <Form.Select
                onChange={(e) => handleChangePageSize(e.target.value)}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
              </Form.Select>
            </div>
            <div className="mt-2">{filters.pageNumber}</div>
            <div className="pagination">
              <Pagination size="sm">
                <Pagination.First
                  onClick={() => setFilters({ ...filters, pageNumber: 1 })}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      pageNumber: filters.pageNumber - 1,
                    })
                  }
                />
                {currentPage > 4 ? <Pagination.Ellipsis disabled /> : null}

                {items}
                {currentPage === noOfPages ? null : (
                  <Pagination.Ellipsis disabled />
                )}
                <Pagination.Next
                  disabled={currentPage === noOfPages}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      pageNumber: filters.pageNumber + 1,
                    })
                  }
                />
                <Pagination.Last
                  onClick={() =>
                    setFilters({ ...filters, pageNumber: noOfPages })
                  }
                />
              </Pagination>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-history-data">Not available history records.</div>
      )}
    </div>
  );
};

export default HistoryList;
