import React from "react";
import BackgroundLetterAvatars from "./Avatar";
import { formatDateTimeForHistory } from "../../utils/timeFormat";
import { VscArrowSmallRight } from "react-icons/vsc";
import { Badge } from "react-bootstrap";

const History = ({ history }) => {
  const createdAt = formatDateTimeForHistory(history.createdDate);

  return (
    <>
      <div className="history-item" key={history.id} data-testid="item">
        <div className="history-item-header">
          <div data-testid="avatar">
            <BackgroundLetterAvatars
              createdBy={history.createdBy ? history.createdBy : ""}
            />
          </div>
          <div className="content-wrapper">
            <div className="name" data-testid="userName">
              {history.createdBy ? history.createdBy : ""}
            </div>
            <div className="description" data-testid="description">
              {history.action + " " + history.target + " on "}
            </div>
            <div className="date" data-testid="date">
              {createdAt}
            </div>
          </div>
        </div>
        <div>
          {history.tags && history.tags.map((tag, index) => {
            return (
              <div className="history-tags-wrapper">
                {tag.from !== tag.to &&
                  <> {tag.to !== "rejected" ? "Stage changed": ""}   &nbsp;<Badge bg="secondary">{tag.from}</Badge><VscArrowSmallRight /><Badge bg= {tag.to !== "rejected" ? "secondary": "danger"}>{tag.to}</Badge></>}
              </div>
            )
          })}
          {history.details &&
            history.details.length > 0 &&
            history.details.map((detail, index) => {
              return (
                <div className="history-details-wrapper" key={index} data-testid="detail-item">
                  <div className="field-description">{detail.description}</div>
                  <div className="break"></div>
                  {detail.fromValue !== null || detail.toValue !== null ? <>
                    <div className="field" data-testid="history-action-field">{detail.property}</div>
                    <div className="fromValue" data-testid="history-action-fromValue">{detail.fromValue === null || detail.fromValue === "" ? "none" : detail.fromValue}</div>
                    <VscArrowSmallRight />
                    <div className="toValue" data-testid="history-action-toValue">{detail.toValue === null || detail.toValue === "" ? "none" : detail.toValue}</div></> : null}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default History;
