import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import {
  Form,
  Col,
  Row,
  Button,
  Pagination,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  activateOrDeactivateSpecification,
  clearSpecificationStore,
  createNewVersion,
  getJsonQRSpecification,
  getMPAFields,
  getQRSpecification,
  setFlow,
  setStepOneData,
  setStepTwoFields,
  moveToBackQRSpecification
} from "../../../redux/actions/qrSpecificationActions";
import { AiFillPlusSquare, AiFillMinusSquare } from "react-icons/ai";
import { showLoader } from "../../../redux/actions/commonActions";
import { getQRSpecifications, resetCreateFlow, setSelectedId } from "../../../redux/actions/qrSpecificationActions";
import { capitalizeFirstLetter } from "../../../utils/stringFormat";
import { ShowForPermission } from "../../../components/ShowForPermission";
import { QR_SPECIFICATION_CREATE, QR_SPECIFICATION_LIST } from "../../../utils/permissionList";
import { getNetworks } from "../../../redux/actions/networkActions";
import { receiveMarketData } from "../../../redux/actions/profileActions";
import { getTransactionTypes } from "../../../redux/actions/transactionTypeActions";
import QRSpecPipeline from "../../../components/pipeline/QRSpecPipeline";
import { SOMETHING_WENT_WRONG, UNAUTHORIZED_ACTION } from "../../../utils/errorMessages";
import SpecificationActionMenu from "../../../components/action-menus/SpecificationActionMenu";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { toast } from "react-toastify";
import download from "downloadjs";
import ExpandableTable from "../../../components/expandable-table/ExpandableTable";
import ConfirmationInReviewModal from "../../../components/modals/ConfirmationInReviewModal";

const QRSpecsList = ({
  qrSpecsList = [],
  openedSubComponents = null,
  marketOptions = [],
  noOfPages,
  currentPage,
  networkList,
  getQRSpecifications,
  showLoader,
  setFlow,
  getNetworkList,
  getMPAFields,
  resetCreateFlow,
  setSelectedId,
  setStepOneData,
  setStepTwoData,
  activateOrDeactivateSpecification,
  getQRSpecification,
  getJsonQRSpecification,
  createNewVersion,
  clearStore,
  moveToBackQRSpecification,
  trxTypeOptions,
  receiveMarketData,
  getTransactionTypes,
}) => {
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 15,
    market: null,
    network: null,
    transactionType: null,
    status: null,
    search: null
  });
  const [trxTypeFilters] = useState({
    isActiveOnly: true
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showJSONOverrideConfirmation, setShowJSONOverrideConfirmation] = useState(false);
  const [specification, setSpecification] = useState(false);
  const [showInReviewConfirmation, setShowInReviewConfirmation] = useState(false);
  const [moveToInReviewSelectedcSpec, setMoveToInReviewSelectedSpec] = useState(false);

  const toggleSpecificationActivityModal = (specification) => {
    setSpecification(specification)
    setShowConfirmation(true)
  };

  const history = useHistory();

  useEffect(() => {
    async function fetchQRSpecifications() {
      try {
        showLoader(true);
        await clearStore();
        resetCreateFlow(null);
        getMPAFields();
        getNetworkList({ isDropdown: true, market: filters.market });
        await getQRSpecifications(filters);
        await getTransactionTypes(trxTypeFilters);
        await receiveMarketData();
        showLoader(false);
      } catch (e) {
        if (e.response.status && e.response.status === 403) {
          toast.error(UNAUTHORIZED_ACTION);
        } else if (e.response.data.Description) {
          toast.error(e.response.data);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        showLoader(false);
      }
    }
    fetchQRSpecifications();
  }, [filters, showLoader, getQRSpecifications, resetCreateFlow, getNetworkList, clearStore]);

  // clear the store on unmount 
  useEffect( () => () =>clearStore(), [clearStore] );

  let items = [];
  let max = 6;
  let start = currentPage - 3 <= 0 ? 1 : currentPage - 3;
  let end = start + max > noOfPages ? noOfPages : start + max;

  for (let number = start; number <= end; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setFilters({ ...filters, pageNumber: number })}
      >
        {number}
      </Pagination.Item>
    );
  }

  const handleChangePageSize = (size) => {
    setFilters({ ...filters, pageNumber: 1, pageSize: size });
  };

  const OnSubmit = (e) => {
    e.preventDefault();
  };

  const GoToStepOne = () => {
    setFlow("create");
    history.push('/settings/qr-specs/create/step1');
  }

  const GoToViewDetails = (id) => {
    setSelectedId(id)
    history.push('/settings/qr-specs/view');
  }

  const GoToReview = (id) => {
    setSelectedId(id)
    history.push('/settings/qr-specs/review');
  }

  const toggleSetShowConfirmation = () => setShowConfirmation((p) => !p);
  const toggleSetShowJSONOverrideConfirmation = () => setShowJSONOverrideConfirmation((p) => !p);
  const toggleSetShowInReviewConfirmation = () => setShowInReviewConfirmation((p) => !p);

  const handleEditQRSpecification = async (specification, isCloneFlow = false) => {
    showLoader(true);
    const result = await getQRSpecification(specification.id);
    if (isCloneFlow) {
      setFlow("clone")
      result.data.template = ""
      setStepOneData(result.data)
      setStepTwoData(result.data.fields);
      history.push('/settings/qr-specs/create/step1');
    } else {
      setFlow("edit")
      const draftStep = result.data.draftStep;
      setStepOneData(result.data)
      setStepTwoData(result.data.fields);
      switch (draftStep) {
        case 1:
          history.push('/settings/qr-specs/create/step1');
          break;
        case 2:
          history.push('/settings/qr-specs/create/step2');
          break;
        case 3:
          history.push('/settings/qr-specs/create/step3');
          break;
        default:
      }
    }
    showLoader(false);
  }

  const handleSpecificationActivationAndDeactivation = useCallback(async () => {
    try {
      const data = { specificationId: specification.id, isActivated: specification.isActive, parentId: specification.version !== null ? specification.version.specificationId : specification.id };
      showLoader(true);
      await activateOrDeactivateSpecification(data);
      await getQRSpecifications(filters);
      toast.success(`QR Specification ${specification.isActive ? "Deactivated" : "Activated"}`);
      showLoader(false);
    } catch (e) {
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.description) {
        toast.error(e.response.data.description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
      showLoader(false);
    };
  }, [specification, showLoader, activateOrDeactivateSpecification, getQRSpecifications, filters])

  const handleExportQRSpecification = async (spec = null, override = false) => {
    try {
      showLoader(true);
      if (!override) {
        setSpecification(spec);
      } else {
        spec = specification;
      }
      const jsonSpec = await getJsonQRSpecification({ id: spec.id, isReplace: override })
      download(JSON.stringify(jsonSpec.data, null, 2), "qr_specification.json");
      showLoader(false);
    } catch (e) {
      if (e.response.status && e.response.status === 400) {
        if (e.response.data && e.response.data.Code === "ExistingPublishedSpecificationWarning") {
          setShowJSONOverrideConfirmation(true);
        } else if (e.response.data) {
          toast.error(e.response.data.Description);
        }
      } else if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.description) {
        toast.error(e.response.data.description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
      showLoader(false);
    }
  }

  const handleTestQRSpecification = async (specificationId) => {
    setSelectedId(specificationId)
    history.push('/settings/qr-specs/test');
  }
  
  const handleCloneSpecification = async (specification) => {
    await handleEditQRSpecification(specification, true)
  }

  const handleCreateNewVersion = useCallback(async (specId, firstSpecId = null) => {
    try {
      showLoader(true);
      const result = await createNewVersion({ id: specId, parentId: firstSpecId === null ? specId : firstSpecId })
      await getQRSpecifications(filters);
      toast.success(`New version created (${result.data})`);
      showLoader(false);
    } catch (e) {
      if (e.response.status && e.response.status === 400) {
        toast.error(e.response.data.Description);
      } else if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.description) {
        toast.error(e.response.data.description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
      showLoader(false);
    }
  }, [createNewVersion,filters, getQRSpecification, showLoader])

  const getTransactionDisplayName = (id) => {
    const trxType = trxTypeOptions.find(x => x.value === id);
    return trxType ? trxType.label : "Unknown";
  }

  const handleMoveToBackStage = async (d) => {
    try {
      showLoader(true);
      await moveToBackQRSpecification({
        id: d.id,
        currentStage: d.stage,
        toStage: "draft",
      });
      
      d.status === "approved" &&  toast.success("QR Specification moved to the In-review stage.");
      d.status === "rejected" &&  toast.success("QR Specification moved to the draft stage.");
      
      await getQRSpecifications(filters);
      showLoader(false);
    } catch (e) {
      showLoader(false);
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data.Description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
  }

  const handleMoveToInReviewStage = (d) => {
    setMoveToInReviewSelectedSpec(d);
    toggleSetShowInReviewConfirmation();
  }

  const proceedInReviewConfirmation = async (status) => {
    toggleSetShowInReviewConfirmation();
    const d = moveToInReviewSelectedcSpec;
    if(status){
      try {
        showLoader(true);
        await moveToBackQRSpecification({
          id: d.id,
          currentStage: d.stage,
          toStage: "draft",
        });
        
        d.status === "approved" &&  toast.success("QR Specification moved to the In-review stage.");
        d.status === "rejected" &&  toast.success("QR Specification moved to the draft stage.");
        
        await getQRSpecifications(filters);
        showLoader(false);
      } catch (e) {
        showLoader(false);
        if (e.response.status && e.response.status === 403) {
          toast.error(UNAUTHORIZED_ACTION);
        } else if (e.response.data.Description) {
          toast.error(e.response.data.Description);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
      }
    }
  }

  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }) => {
          if (row.original.version) {
            return (
              // Use Cell to render an expander for each row.
              // We can use the getToggleRowExpandedProps prop-getter
              // to build the expander.
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? <AiFillMinusSquare size={20}  /> : <AiFillPlusSquare size={20} />}
              </span>
            )
          } 
          return  <span></span>
          
        },
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null // No expander on an expanded row
      },
      {
        Header: 'Id',
        accessor: 'id',
        show: false
      },
      {
        Header: 'Version',
        accessor: d => {
          return d.versionNumber
        },
        show: true
      },
      {
        Header: 'Name',
        accessor: 'template',
        show: true
      },
      {
        Header: 'Market',
        id: "market",
        accessor: d => {
          return <span>{capitalizeFirstLetter(d.market)}</span>
        },
        show: true
      },
      {
        Header: 'Network',
        accessor: d => {
          return <span>{capitalizeFirstLetter(d.network)}</span>
        },
        show: true
      },
      {
        Header: 'Transaction Type',
        accessor: d => { 
          return <div>
            {
              d.transactionTypes ?
              d.transactionTypes.map((type,i) => {
                return <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                {getTransactionDisplayName(type)}
                              </Tooltip>
                            }
                          >
                            <span className="d-inline-block">
                                {d.transactionTypes.length-1 > i
                                  ? <label>{type+", "}</label>
                                  : <label>{type} </label>}
                            </span>
                          </OverlayTrigger>
              }) : "Unknown"
            }
            </div>
         },
        show: true
      },
      {
        Header: 'Format',
        accessor: 'qrFormatType',
        show: true
      },
      {
        Header: 'Legacy QR',
        accessor: d => {
          return <span>{d.isLegacy ? "Yes" : "No"}</span>
        },
        show: true
      },
      {
        Header: 'Stage',
        accessor: d => {
          return <span className={d.stage}>{capitalizeFirstLetter(d.stage)}</span>
        },
        className: 'status-qr-sp',
        show: true
      },
      {
        Header: 'Pipeline',
        accessor: d => <span className='pipeline-icon'><QRSpecPipeline stage={d.stage} status={d.status} /></span>,
        show: true
      },
      {
        Header: 'Active Status',
        accessor: d => {

          return d.isActive ? (
            <div className="status">
              <span className={d.isActive ? "active" : "inactive"}>
                Active
              </span>
            </div>
          ) : (
            <div className="status wrap-inactive">
              <span className="inactive">Inactive</span>
            </div>
          )
        },
        show: true
      },
      {
        Header: '',
        id: 'action',
        accessor: d => {
          return <SpecificationActionMenu
            specification={d}
            openActivityModal={() => toggleSpecificationActivityModal(d)}
            openViewPage={(id) => GoToViewDetails(id)}
            goToEdit={handleEditQRSpecification}
            goToReview={GoToReview}
            createNewVersion={handleCreateNewVersion}
            goToTest={handleTestQRSpecification}
            isDeactivationFlow={!specification.isActive}
            openViewModal={true}
            exportJson={handleExportQRSpecification}
            cloneSpecification={handleCloneSpecification}
            moveToBackStage={()=>handleMoveToBackStage(d)}
            moveToInReviewStage={()=>handleMoveToInReviewStage(d)}
          />
        },
        show: true
      },

    ], [filters])


  return (
    <div className="page qr-spec-list-page">
      <ConfirmationModal
        show={showConfirmation}
        toggleShow={toggleSetShowConfirmation}
        confirm={handleSpecificationActivationAndDeactivation}
        title={`Are you sure you want to ${specification.isActive ? "Deactivate" : "Activate"} this template?`} />

      <ConfirmationModal
        show={showJSONOverrideConfirmation}
        toggleShow={toggleSetShowJSONOverrideConfirmation}
        confirm={() => handleExportQRSpecification(null, true)}
        title={"Are you sure you want to override?"}
        body={`There is an already existing specification defined for the network and market. Click on Override to include the "Testing" stage configuration in the JSON file overriding the "Published" staged configuration`}
        cancelButtonText={"Cancel"}
        confirmButtonText={"Override"} />

<ConfirmationInReviewModal
        show={showInReviewConfirmation}
        toggleShow={setShowInReviewConfirmation}
        proceedConfirmation={proceedInReviewConfirmation}
      />


      <div className="page-title-row">
        <div className="title">
          <h1>QR Specifications</h1>
        </div>
      </div>
      <div className="special">
        <div className="filter-section">
          <div className="filter-components">
            <Form className="form w-100" onSubmit={OnSubmit}>
              <Row>
                <Col xl="9" lg="8" md="6" sm="12">
                  <Row className="align-items-center">
                    <Col lg="2" md="3" sm="12" className="my-1">
                      <Form.Select id="marketSelect" onChange={(e) => setFilters({ ...filters, pageNumber: 1, market: e.target.value })}>
                        <option value="all">All (Markets)</option>
                        {marketOptions.map(market => {
                          return <option key={market.value} value={market.value}>{market.displayName}</option>
                        })}
                      </Form.Select>
                    </Col>
                    <Col lg="2" md="3" sm="12" className="my-1">
                      <Form.Select
                        id="networkSelect"
                        onChange={(e) => setFilters({ ...filters, pageNumber: 1, network: e.target.value })}>
                        <option id="networkAll" value="all">
                          All (Networks)
                        </option>
                        {networkList.map(network => <option key={network.id} value={network.name}>{network.name}</option>)}
                      </Form.Select>
                    </Col>
                    <Col lg="3" md="3" sm="12" className="my-1">
                      <Form.Select id="transactionTypeSelect" onChange={(e) => setFilters({ ...filters, pageNumber: 1, transactionType: e.target.value })}>
                        <option id="transactionTypeAll" value="all">All (Transaction Types)</option>
                        {
                          trxTypeOptions.length > 0 && trxTypeOptions.map(trxType => {
                            return <option key={trxType.value} value={trxType.value}>{trxType.label}</option>
                          })
                        }
                      </Form.Select>
                    </Col>
                    <Col lg="2" md="3" sm="12" className="my-1">
                      <Form.Select
                        id="statusSelect"
                        onChange={(e) => {
                          const isAStage = ["draft", "in-review", "testing", "published"].includes(e.target.value);
                          if (isAStage) {
                            delete filters.status;
                            setFilters({ ...filters, pageNumber: 1, stage: e.target.value })
                          } else {
                            delete filters.stage;
                            setFilters({ ...filters, pageNumber: 1, status: e.target.value })
                          }
                        }}
                      >
                        <option id="statusAll" value="all"> All (Statuses)</option>
                        <option value="draft">Draft</option>
                        <option value="testing">Testing</option>
                        <option value="in-review">In-Review </option>
                        <option value="published">Published</option>
                        <option value="rejected">Rejected</option>
                      </Form.Select>
                    </Col>
                    
                  </Row>
                </Col>
                <Col className="d-flex justify-content-end">
                  <div className="btn-user btn-Specification">
                    <ShowForPermission permissions={[QR_SPECIFICATION_CREATE]} >
                      <Button onClick={GoToStepOne} > + New Specification </Button>
                    </ShowForPermission>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>

      </div>
      <ShowForPermission permissions={[QR_SPECIFICATION_LIST]} showBanner>
        <ExpandableTable data={qrSpecsList} expanded={openedSubComponents} collapseOnDataChange={false} columns={columns} />
      </ShowForPermission>
      <div className="footer-table d-flex justify-content-between">
        <div>
          <Form.Select onChange={(e) => handleChangePageSize(e.target.value)}>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="60">60</option>
          </Form.Select>
        </div>
        <div className="mt-2">{filters.pageNumber}</div>
        <div className="pagination">
          <Pagination size="sm">
            <Pagination.First
              onClick={() => setFilters({ ...filters, pageNumber: 1 })}
            />
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => setFilters({ ...filters, pageNumber: filters.pageNumber - 1 })}
            />
            {currentPage > 4 ? <Pagination.Ellipsis disabled /> : null}

            {items}
            {currentPage === noOfPages ? null : (
              <Pagination.Ellipsis disabled />
            )}
            <Pagination.Next
              disabled={currentPage === noOfPages}
              onClick={() => setFilters({ ...filters, pageNumber: filters.pageNumber + 1 })}
            />
            <Pagination.Last
              onClick={() => setFilters({ ...filters, pageNumber: noOfPages })}
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    qrSpecsList: state.qrSpecifiction.list,
    networkList: state.network.list,
    currentPage: state.qrSpecifiction.currentPage,
    noOfPages: state.qrSpecifiction.noOfPages,
    totalCount: state.qrSpecifiction.totalCount,
    marketOptions: state.profile.markets,
    openedSubComponents: state.qrSpecifiction.openedSubComponents,
    trxTypeOptions: state.transactionType.trxTypeOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNetworkList: (filters) => dispatch(getNetworks(filters)),
  getQRSpecifications: (filters) => dispatch(getQRSpecifications(filters)),
  showLoader: (data) => dispatch(showLoader(data)),
  resetCreateFlow: (data) => dispatch(resetCreateFlow(data)),
  setSelectedId: (id) => dispatch(setSelectedId(id)),
  activateOrDeactivateSpecification: (data) => dispatch(activateOrDeactivateSpecification(data)),
  clearStore: () => dispatch(clearSpecificationStore()),
  setFlow: (flow) => dispatch(setFlow(flow)),
  getQRSpecification: (id) => dispatch(getQRSpecification(id)),
  setStepOneData: (data) => dispatch(setStepOneData(data)),
  getMPAFields: () => dispatch(getMPAFields()),
  setStepTwoData: (data) => dispatch(setStepTwoFields(data)),
  getJsonQRSpecification: (obj) => dispatch(getJsonQRSpecification(obj)),
  createNewVersion: (specId) => dispatch(createNewVersion(specId)),
  moveToBackQRSpecification: (data) => dispatch(moveToBackQRSpecification(data)),
  receiveMarketData: () => dispatch(receiveMarketData()),
  getTransactionTypes: (filters) => dispatch(getTransactionTypes(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QRSpecsList);
