import { FETCH_USERS, CREATE_USER, EDIT_USER, DEACTIVATE_USER, CHANGE_PAGE,REACTIVATE_USER, GET_DEACTIVATION_REASON, CLEAR_REASON} from '../actions/actionTypes';

const defaultState = {
  rawUsersList: [],
  usersList: [],
  nextToken: null,
  tokens: [],
  currentPage: 1,
  totalUsers: 0,
  pageSize: 15,
  totalPages: 1,
  hasMorePages: true,
  reason: null
};

const usersReducer = (reduxState = defaultState, { type, payload }) => {
  switch (type) {
    case FETCH_USERS:
      const searchTerm = payload.searchTerm !== null ? payload.searchTerm.toLowerCase() : null;
      let usersFilterdBySearchTerm = (payload.searchTerm !== null) ? payload.users.filter(x => x.email.toLowerCase().includes(searchTerm) || x.firstName.toLowerCase().includes(searchTerm) || x.lastName.toLowerCase().includes(searchTerm)  || (x.isActive === true && "active".includes(searchTerm)) ||(!x.isActive && "inactive".includes(searchTerm) && searchTerm !== "active") || (x.market !== null && x.market.includes(searchTerm))): payload.users;
      let usersFilterdByRole = (payload.role !== "all") ? usersFilterdBySearchTerm.filter(x => x.role === payload.role): usersFilterdBySearchTerm;
      const totalUsers = usersFilterdByRole.length;
      const totalPages = Math.ceil(totalUsers / payload.pageSize);
      let usersFirstPage = usersFilterdByRole.slice(0,payload.pageSize);
      return {
        ...reduxState,
        rawUsersList: [...usersFilterdByRole],
        totalUsers: payload.users.length,
        pageSize: payload.pageSize,
        totalPages: totalPages,
        usersList: usersFirstPage,
        nextToken: payload.nextPageToken,
        currentPage: payload.page,
        hasMorePages: totalPages ===1 ? false : true,
        tokens: payload.page === 1 ? [payload.nextPageToken] : [...reduxState.tokens, payload.nextPageToken]
      };
    case CHANGE_PAGE:
        const start = reduxState.pageSize* (payload.page - 1);
        const end =  reduxState.pageSize* (payload.page);
        return {
          ...reduxState,
          usersList: reduxState.rawUsersList.slice(start, end),
          currentPage: payload.page,
          hasMorePages: reduxState.totalPages > payload.page
        };
    case CREATE_USER:
      return reduxState
    case EDIT_USER:
      return reduxState
    case DEACTIVATE_USER:
      return reduxState;
    case REACTIVATE_USER: 
      return reduxState;
    case GET_DEACTIVATION_REASON: 
      return {...reduxState, reason: payload.comment};
    case CLEAR_REASON: 
      return {...reduxState, reason : null}
    default:
      return reduxState
  }
};

export default usersReducer;
