import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const deactivateSchema = yup.object().shape({
  comment: yup.string().max(200).required("Comment is required"),
});

const DeactivateUserModal = ({
  toggleShowDeactivateModal,
  handleUserDeactivation,
  handleUserReactivation,
  isDeactivationFlow,
  selectedUser
}) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    reset();
    toggleShowDeactivateModal(false);
    setShow(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(deactivateSchema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (isDeactivationFlow) {
      handleUserDeactivation({
        ...data, email: selectedUser
      });
    } else {
      handleUserReactivation({
        ...data, email: selectedUser
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="question" data-testid="deactivationModalTitle">
            Are you sure you want to{" "}
            {isDeactivationFlow ? "Deactivate" : "Reactivate"} user?
          </Modal.Title>
        </Modal.Header>
        <Form  onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Reason *</Form.Label>
              <Form.Control
                id="deactivation-reason"
                as="textarea" rows={3}
                {...register("comment", { required: true })}
                autoComplete="off"
                placeholder="Enter reason"
                isInvalid={errors.comment}
              />
              <Form.Control.Feedback id="invalid-reason" type="invalid">
                {errors.comment?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="btn-cancel-deactivation"
              variant="primary"
              className="btn-cancel mx-3"
              onClick={handleClose}
            >
              Cancel
            </Button>
            {isDeactivationFlow ? (
              <Button
                id="btn-deactivate"
                variant="danger"
                type="submit"
                disabled={!isValid || !isDirty}
              >
                Deactivate
              </Button>
            ) : (
              <Button
                id="btn-reactivate"
                variant="danger"
                type="submit"
                disabled={!isValid || !isDirty}
              >
                Reactivate
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default DeactivateUserModal;
