import axios from "../../apis/qrCore";
import { v4 as uuidv4 } from "uuid";

import {
  FETCH_QR_SPECIFICATIONS,
  UPDATE_QR_SPECIFICATION,
  GET_QR_SPECIFICATION,
  CREATE_QR_SPECIFICATION,
  SET_CREATE_STEP_ONE_DATA,
  SET_CREATE_STEP_TWO_DATA,
  RESET_CREATE_FLOW,
  SET_QR_SPEC_SELECTED_ID,
  DEACTIVATE_SPECIFICATION,
  ACTIVATE_SPECIFICATION,
  SET_QR_SPECIFICATION_FLOW,
  FETCH_MPA_FIELDS,
  GET_QR_FIELD_VALIDATIONS,
  FETCH_CRC_FIELDS,
  EXPORT_QR_SPECIFICATION,
  GET_QR_SPECIFICATION_NOTES,
  GET_VERSIONS,
  CREATE_VERSION,
  CLEAR_SPECIFICATION_STORE,
  TOGGLE_LOADER,
  REJECT_QR_SPECIFICATION,
  PUBLISH_QR_SPECIFICATION,
  MOVE_TO_BACK_QR_SPECIFICATION,
  GET_QR_SPECIFICATION_HISTORY,
} from "./actionTypes";

const QR_SPECIFICATION_ENDPOINT = "qr-specifications";

export const setFlow = (data) => ({
  type: SET_QR_SPECIFICATION_FLOW,
  payload: data,
});

export const resetCreateFlow = (data) => ({
  type: RESET_CREATE_FLOW,
  payload: data,
});

export const setStepOneData = (data) => ({
  type: SET_CREATE_STEP_ONE_DATA,
  payload: data,
});

export const setStepTwoFields = (fields) => ({
  type: SET_CREATE_STEP_TWO_DATA,
  payload: fields,
});

export const setSelectedId = (fields) => ({
  type: SET_QR_SPEC_SELECTED_ID,
  payload: fields,
});

export const clearSpecificationStore = () => ({
  type: CLEAR_SPECIFICATION_STORE,
});

// gets the base mpa fields list
export const getMPAFields = () => async (dispatch) => {
  function onSuccess(response) {
    dispatch({
      type: FETCH_MPA_FIELDS,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: FETCH_MPA_FIELDS,
      payload: [],
    });
    throw error;
  }
  try {
    const response = await axios.get(`qr-mpa-specifications/`, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": "mpa-qr-code-web-app",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const getCRCFields = () => async (dispatch) => {
  function onSuccess(response) {
    dispatch({
      type: FETCH_CRC_FIELDS,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: FETCH_CRC_FIELDS,
      payload: [],
    });
    throw error;
  } try {
    const response = await axios.get(`${QR_SPECIFICATION_ENDPOINT}/crc-formats/`, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

//gets the list of QR specifications
export const getQRSpecifications = (params) => async (dispatch) => {
  function onSuccess(response) {
    dispatch({
      type: FETCH_QR_SPECIFICATIONS,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: FETCH_QR_SPECIFICATIONS,
      payload: { specifications: [] },
    });
    throw error;
  }
  try {
    const response = await axios.get(`${QR_SPECIFICATION_ENDPOINT}/`, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": "mpa-qr-code-web-app",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params,
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

// creates QR specification
export const uploadTestFile = (payload) => async (dispatch) => {
  function onSuccess(response) {
    dispatch({
      type: CREATE_QR_SPECIFICATION,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: CREATE_QR_SPECIFICATION,
      payload: error.data,
    });
    throw error;
  }
  try {
    let formData = new FormData();

    formData.append("File", payload.file);
    formData.append("Market", payload.market);
    formData.append("SpecificationId", payload.specificationId);

    const response = await axios.post(
      `${QR_SPECIFICATION_ENDPOINT}/files`,
      formData,
      {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

// creates QR specification
export const changeQRSpecificationStage = (payload) => async (dispatch) => {
  function onSuccess(response) {
    dispatch({
      type: CREATE_QR_SPECIFICATION,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: CREATE_QR_SPECIFICATION,
      payload: error.data,
    });
    throw error;
  }
  try {
    const response = await axios.put(
      `${QR_SPECIFICATION_ENDPOINT}/transitions`,
      payload,
      {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

// creates QR specification
export const createQRSpecification = (payload) => async (dispatch) => {
  function onSuccess(response) {
    dispatch({
      type: CREATE_QR_SPECIFICATION,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: CREATE_QR_SPECIFICATION,
      payload: error.data,
    });
    throw error;
  }
  try {
    payload.specification.fields.forEach((x) => {
      for (let i = 0; i < 2; i++) {
        if (typeof x.valueMap === "string") {
          x.valueMap = JSON.parse(x.valueMap);
        }
        if (typeof x.rules === "string") {
          x.rules = JSON.parse(x.rules);
        }
      }
    })
    
    if(payload.specification.qrFormatType !== "EMVCO"){
      payload.specification.CRCFormat=""
    }

    const response = await axios.post(`${QR_SPECIFICATION_ENDPOINT}/`, payload, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

// creates QR specification
export const createNewVersion = (params) => async (dispatch) => {
  function onSuccess(response) {
    dispatch({
      type: CREATE_VERSION,
      payload: params.parentId,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: CREATE_VERSION,
      payload: error.data,
    });
    throw error;
  }
  try {
    const response = await axios.post(
      `${QR_SPECIFICATION_ENDPOINT}/versions`,
      {},
      {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },

        params: { id: params.id },
      }
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

//gets the list of QR specifications
export const getVersionsList = (specId) => async (dispatch) => {
  function onSuccess(response) {
    dispatch({
      type: GET_VERSIONS,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: GET_VERSIONS,
      payload: { specifications: [] },
    });
    throw error;
  }
  try {
    const response = await axios.get(`${QR_SPECIFICATION_ENDPOINT}/versions`, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": "mpa-qr-code-web-app",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: { id: specId },
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

// edit qr specification
export const updateQRSpecification = (payload) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({
      type: UPDATE_QR_SPECIFICATION,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: UPDATE_QR_SPECIFICATION,
      payload: error,
    });
    throw error;
  }
  try {
    payload.specification.fields.forEach((x) => {
      for (let i = 0; i < 2; i++) {
        if (typeof x.valueMap === "string") {
          x.valueMap = JSON.parse(x.valueMap);
        }
        if (typeof x.rules === "string") {
          x.rules = JSON.parse(x.rules);
        }
      }
    });
    const response = await axios.put(`${QR_SPECIFICATION_ENDPOINT}/`, payload, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": "mpa-qr-code-web-app",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const getQRSpecification = (specificationId) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({
      type: GET_QR_SPECIFICATION,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: GET_QR_SPECIFICATION,
      payload: error,
    });
    throw error;
  }
  try {
    const response = await axios.get(
      `${QR_SPECIFICATION_ENDPOINT}/${specificationId}`,
      {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const getQRFieldValidations = (market, isTemplateCreate = false) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({
      type: GET_QR_FIELD_VALIDATIONS,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: GET_QR_FIELD_VALIDATIONS,
      payload: error,
    });
    throw error;
  }
  try {
    const response = await axios.get(
      `${QR_SPECIFICATION_ENDPOINT}/validations`,
      {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: { market, isTemplateCreate },
      }
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const activateOrDeactivateSpecification = (data) => async (dispatch) => {
  if (data.isActivated) {
    function onSuccess(success) {
      dispatch({
        type: DEACTIVATE_SPECIFICATION,
        payload: data.parentId,
      });
      return success;
    }
    function onError(error) {
      dispatch({
        type: DEACTIVATE_SPECIFICATION,
        payload: error,
      });
      throw error;
    }
    try {
      const payload = {
        id: data.specificationId,
      };
      const response = await axios.put(
        `${QR_SPECIFICATION_ENDPOINT}/deactivate`,
        payload,
        {
          headers: {
            "x-correlation-id": uuidv4(),
            "x-source-system": "mpa-qr-code-web-app",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  } else {
    function onSuccess(success) {
      dispatch({
        type: ACTIVATE_SPECIFICATION,
        payload: data.parentId,
      });
      return success;
    }
    function onError(error) {
      dispatch({
        type: ACTIVATE_SPECIFICATION,
        payload: error,
      });
      throw error;
    }
    try {
      const payload = {
        id: data.specificationId,
      };
      const response = await axios.put(
        `${QR_SPECIFICATION_ENDPOINT}/activate`,
        payload,
        {
          headers: {
            "x-correlation-id": uuidv4(),
            "x-source-system": "mpa-qr-code-web-app",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  }
};

export const getJsonQRSpecification = (payload) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({
      type: EXPORT_QR_SPECIFICATION,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: EXPORT_QR_SPECIFICATION,
      payload: error,
    });
    throw error;
  }
  try {
    const response = await axios.post(
      `${QR_SPECIFICATION_ENDPOINT}/generate-qr-specification-json-file`,
      payload,
      {
        headers: {
          "x-correlation-id": "12334",
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

//gets the list of QR specification notes
export const getQRSpecificationNotes = (params) => async (dispatch) => {
  function onSuccess(response) {
    dispatch({
      type: GET_QR_SPECIFICATION_NOTES,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: GET_QR_SPECIFICATION_NOTES,
      payload: { notes: [] },
    });
    throw error;
  }
  try {
    const response = await axios.get(
      `${QR_SPECIFICATION_ENDPOINT}/comments/${params.id}`,
      {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

const downloadFile = (url, name) => {
  axios({
    url,
    responseType: "blob",
  })
    .then((resp) => {
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw err;
    });
};

// get presigned url
export const getPresignedUrl = (payload) => async (dispatch) => {
  function onSuccess(response) {
    dispatch({
      type: TOGGLE_LOADER,
      payload: false,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: TOGGLE_LOADER,
      payload: false,
    });
    throw error;
  }
  try {
    const data = {
      url: payload.path,
    };

    const response = await axios.post(
      `${QR_SPECIFICATION_ENDPOINT}/files/presign`,
      data,
      {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    downloadFile(response.data, payload.fileName);

    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};


// reject QR specification
export const rejectQRSpecification = (payload) => async (dispatch) => {
  function onSuccess(response) {
    dispatch({
      type: REJECT_QR_SPECIFICATION,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: REJECT_QR_SPECIFICATION,
      payload: error.data,
    });
    throw error;
  }
  try {
    const response = await axios.put(
      `${QR_SPECIFICATION_ENDPOINT}/${payload.id}/transitions/reject`,
      payload,
      {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};


// publish QR specification
export const publishQRSpecification = (payload) => async (dispatch) => {
  function onSuccess(response) {
    dispatch({
      type: PUBLISH_QR_SPECIFICATION,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: PUBLISH_QR_SPECIFICATION,
      payload: error.data,
    });
    throw error;
  }
  try {
    const response = await axios.put(
      `${QR_SPECIFICATION_ENDPOINT}/${payload.id}/transitions/publish`,
      payload,
      {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};


// Move to back QR specification
export const moveToBackQRSpecification = (payload) => async (dispatch) => {
  function onSuccess(response) {
    dispatch({
      type: MOVE_TO_BACK_QR_SPECIFICATION,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: MOVE_TO_BACK_QR_SPECIFICATION,
      payload: error.data,
    });
    throw error;
  }
  try {
    const response = await axios.put(
      `${QR_SPECIFICATION_ENDPOINT}/${payload.id}/move`,
      payload,
      {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const getQRSpecificationHistory = ({specificationId,pageSize,pageNumber}) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({
      type: GET_QR_SPECIFICATION_HISTORY,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: GET_QR_SPECIFICATION_HISTORY,
      payload: [],
    });
    throw error;
  }

  try {
    const response = await axios.get(
      `${QR_SPECIFICATION_ENDPOINT}/history/`,
      {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: { id: specificationId, pageSize: pageSize, pageNumber: pageNumber },
      }
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

