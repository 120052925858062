import { combineReducers } from 'redux';

import profileReducer from './profileReducer';
import tokenReducer from './tokenReducer';
import usersReducer from './usersReducer';
import templatesReducer from './templatesReducer';
import auditReducer from './auditReducer';
import commonReducer from './commonReducer';
import qrSpecificationReducer from './qrSpecificationReducer';
import roleReducer from './roleReducer';
import permissionReducer from './permissionReducer';
import networkReducer from './networkReducer';
import languageReducer from './languageReducer';
import marketReducer from './marketReducer';
import testQrSpecificationReducer from './testQrSpecificationReducer';
import transactionTypeReducer from './transactionTypeReducer';

const rootReducer = combineReducers({
    profile: profileReducer,
    token: tokenReducer,
    users: usersReducer,
    templates: templatesReducer,
    audits: auditReducer,
    common: commonReducer,
    qrSpecifiction: qrSpecificationReducer,
    network: networkReducer,
    role: roleReducer,
    permission: permissionReducer,
    language: languageReducer,
    testQrSpecification: testQrSpecificationReducer,
    transactionType: transactionTypeReducer,
    market: marketReducer,
});

export default rootReducer;