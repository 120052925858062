export const capitalizeFirstLetter = (word) => {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : "";
};

export const getUserRole = (enumId) => {
  switch (enumId) {
    case 1:
      return "Super Admin";
    case 2:
      return "Market Admin";
    case 3:
      return "General User";
    case 4:
      return "Audit User";
    default:
      return "Unknown";
  }
};

export const spacedString = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return text ? result.charAt(0).toUpperCase() + result.slice(1) : "";
};
