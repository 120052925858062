import { userAPIConfig } from "../config/apiConfig";
import axios from "axios";


export default axios.create({
  baseURL: userAPIConfig.baseURL,
  headers: {
    "Content-Type": "application/json",
    "x-correlation-id": 1, 
    "x-source-system": 'mpa-qr-code-web-app',
  },
});