import React, { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createLanguage, editLanguage } from "../../redux/actions/languageActions";
import { connect } from "react-redux";
import { CREATE_LANGUAGE_SUCCESS, SOMETHING_WENT_WRONG, UNAUTHORIZED_ACTION, UPDATE_LANGUAGE_SUCCESS } from "../../utils/errorMessages";
import { toast } from "react-toastify";
import { showLoader } from "../../redux/actions/commonActions";
import { receiveMarketData } from "../../redux/actions/profileActions";
import {regexForNames} from "../../utils/common";

let createLanguageSchema = yup.object().shape({
	market: yup.string().required("Language market is required"),
	name: yup.string().matches(regexForNames, "Allows only a-z, A-Z, 0-9, space, - and _").max(50).required("Language name is required"),
	shortCode2: yup.string().min(2).max(2).required("Two letter code is required"),
	shortCode3: yup.string().min(3).max(3).required("Three letter code id is required"),
});

const CreateLanguageModal = ({
	show,
	toggleShow,
	showLoader,
	getLanguageList,
	updateLanguage,
	isEditFlow = false,
	createLanguage,
	marketOptions = [],
	language,
	receiveMarketData, }) => {

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: { qrSize: null },
		reValidateMode: "onChange",
		resolver: yupResolver(createLanguageSchema),
		mode: "all",
	});

	useEffect(() => {
		if (isEditFlow) {
			reset(language)
		} else {
			reset({});
		}
	}, [reset, isEditFlow, language, showLoader]);

	const handleToggleShow = () => {
		toggleShow();
		reset();
	};

	const handleCreateLanguage = async (data) => {
		showLoader(true);
		try {
			if (isEditFlow) {
				await updateLanguage(data);
				toast.success(UPDATE_LANGUAGE_SUCCESS);
			} else {
				await createLanguage(data);
				toast.success(CREATE_LANGUAGE_SUCCESS);
			}
			await getLanguageList();

			handleToggleShow();
			showLoader(false);
			reset();
		} catch (e) {
			if (e.response.status && e.response.status === 403) {
				toast.error(UNAUTHORIZED_ACTION);
			} else if (e.response.data.Description) {
				toast.error(e.response.data.Description);
			} else {
				toast.error(SOMETHING_WENT_WRONG);
			}
			showLoader(false);
		}
	};

	useEffect(() => {
		receiveMarketData();
		reset();
	}, [show, reset])

	return (
		<>
			<Modal show={show} onHide={toggleShow}>
				<Modal.Header closeButton>
					<Modal.Title data-testid="confimationModalTitle">
						{isEditFlow ? "Edit" : "Create"} Language
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleSubmit(handleCreateLanguage)}>
					<Modal.Body>
						<Form.Group className="mb-3" controlId="name">
							<Form.Label>Name*</Form.Label>
							<Form.Control
								data-testid="name"
								type="text"
								{...register("name", { required: true })}
								placeholder="Enter Name"
								isInvalid={errors.name}
							/>
							<Form.Control.Feedback id="nameErrors" type="invalid">
								{errors.name?.message}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="mb-3" controlId="market">

							<Form.Label>Market* </Form.Label>
							<Form.Select
								data-testid="market"
								{...register("market", { required: true })}
								aria-label="Select a Market"
								isInvalid={errors.market}
							>
								<option value="">Select Market</option>
								{marketOptions.map(market => {
									return <option key={market.value} value={market.value}>{market.displayName}</option>
								})}
							</Form.Select>
							<Form.Control.Feedback id="marketErrors" type="invalid"> {errors.market?.message} </Form.Control.Feedback>

						</Form.Group>
						<Form.Group className="mb-3" controlId="shortCode2">
							<Form.Label>2 Letter code*</Form.Label>
							<Form.Control
								data-testid="shortCode2"
								type="text"
								{...register("shortCode2", { required: true })}
								placeholder="Enter 2 Leter Code"
								isInvalid={errors.shortCode2}
							/>
							<Form.Control.Feedback id="shortCode2Errors" type="invalid">
								{errors.shortCode2?.message}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="mb-3" controlId="shortCode3">
							<Form.Label>3 Letter Code*</Form.Label>
							<Form.Control
								data-testid="shortCode3"
								type="text"
								{...register("shortCode3", { required: true })}
								placeholder="Enter 2 Leter Code"
								isInvalid={errors.shortCode3}
							/>
							<Form.Control.Feedback id="shortCode3Errors" type="invalid">
								{errors.shortCode3?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="btn-cancel"
							className="btn-cancel mx-3"
							onClick={toggleShow}
						> Cancel </Button>

						<Button
							id="btn-create"
							variant="primary"
							type="submit"
						> {isEditFlow ? "Save" : "Create"} </Button>

					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};


const mapStateToProps = (state) => {
	return {
		marketOptions: state.profile.markets
	};
};

const mapDispatchToProps = (dispatch) => ({
	showLoader: (data) => dispatch(showLoader(data)),
	updateLanguage: (data) => dispatch(editLanguage(data)),
	createLanguage: (data) => dispatch(createLanguage(data)),
	receiveMarketData: () => dispatch(receiveMarketData())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateLanguageModal);
