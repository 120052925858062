import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { clearReason } from "../../redux/actions/userActions";

const DeactivateReasonModal = ({ reason, toggleExternal, changeExternalState , clearReason}) => {
  const [show, setShow] = useState(false);

  useEffect(()=> {
    setShow(toggleExternal) 
  },[toggleExternal])

  const handleClose = () => {
    setShow(false);
    clearReason();
    changeExternalState(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header  closeButton>
          <Modal.Title id="view-reason-modal-title" className="question" data-testid="modalTitle">The user has been deactivated.</Modal.Title>
        </Modal.Header>
        <Modal.Body id="comment-content"  className="word-break" data-testid="modalBody">{reason}</Modal.Body>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearReason: () => dispatch(clearReason()),
});

export default connect(null, mapDispatchToProps)(DeactivateReasonModal);
