import axios from "../../apis/languageAPI";

import { v4 as uuidv4 } from 'uuid';

import {
  FETCH_LANGUAGES,
  CREATE_LANGUAGE,
  DEACTIVATE_LANGUAGE,
  ACTIVATE_LANGUAGE,
  UPDATE_LANGUAGE
} from "./actionTypes";

export const getLanguages = (filters) => async (dispatch, getState) => {
  function onSuccess(data) {
    dispatch({
      type: FETCH_LANGUAGES,
      payload: data,
    });
    return data;
  }
  function onError(error) {
    dispatch({
      type: FETCH_LANGUAGES,
      payload: [],
    });
    throw error;
  }
  try {
    
    const response = await axios.get("/", {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      params: filters
    });
    return onSuccess(response.data);
  } catch (error) {
    return onError(error);
  }
};

export const createLanguage = (language) => async (dispatch, getState) => {
  function onSuccess(payload) {
    dispatch({
      type: CREATE_LANGUAGE,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: CREATE_LANGUAGE,
      payload: null,
    });
    throw error;
  }
  try {
    const response = await axios.post("/", language, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    return onSuccess(response.data);
  } catch (e) {
    return onError(e);
  }
};

export const editLanguage = (language) => async (dispatch, getState) => {

  function onSuccess(payload) {
    dispatch({
      type: UPDATE_LANGUAGE,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: UPDATE_LANGUAGE,
      payload: null,
    });
    throw error;
  }
  try {
    const response = await axios.put("/", language, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    return onSuccess(response.data);
  } catch (e) {
    return onError(e);
  }
};

export const activateOrDeactivateLanguage = (data) => async (dispatch, getState) => {
  if (data.isActive) {
    function onSuccess(success) {
      dispatch({
        type: DEACTIVATE_LANGUAGE,
        payload: success.data,
      });
      return success;
    }
    function onError(error) {
      dispatch({
        type: DEACTIVATE_LANGUAGE,
        payload: error,
      });
      throw error;
    }
    
    try {
      const payload = {
        id: data.id
      };
      const response = await axios.put(`/deactivate`, payload, {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": 'mpa-qr-code-web-app',
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  } else {
    function onSuccess(success) {
      dispatch({
        type: ACTIVATE_LANGUAGE,
        payload: success.data,
      });
      return success;
    }
    function onError(error) {
      dispatch({
        type: ACTIVATE_LANGUAGE,
        payload: error,
      });
      throw error;
    }
    
    try {
      const payload = {
        id: data.id
      };
      const response = await axios.put(`/activate`, payload, {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": 'mpa-qr-code-web-app',
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  }
};
