import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Form } from "react-bootstrap";
import { showLoader } from "../../../../redux/actions/commonActions";
import { useHistory } from "react-router-dom";
import DropzoneComp from "../../../../components/file-uploaders/DropzoneComp";
import { changeQRSpecificationStage, uploadTestFile } from "../../../../redux/actions/qrSpecificationActions";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG, UNAUTHORIZED_ACTION } from "../../../../utils/errorMessages";

let reviewSchema = yup.object().shape({
  notes: yup.string().max(1000, "Notes max length is 1000").required("Note is required"),
  files: yup
    .array()
    .min(1, "Upload at least one file")
    .of(
      yup.object().nullable().shape(
        {
          name: yup.string(),
          size: yup.string(),
          path: yup.string(),
        })
    )
    .nullable()
    .required("Test results are required")
});

const SubmitForReview = ({ selectedSpec, showLoader, selectedId, moveToReviewStage }) => {

  const {
    register,
    handleSubmit,
    trigger,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {note: null, files: []},
    resolver: yupResolver(reviewSchema),
    mode: "all",
    reValidateMode: 'onChange',
    shouldUnregister: true
  });

  const history = useHistory();


  const { fields,  append, remove } = useFieldArray({
    control,
    name: "files"
  });
  const watchFieldArray = watch("files");

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });


  const [breadcrumbs] = useState([
    "QR Specifications",
    "Submit For Review",
  ]);


  const goToQRListPage = () => {
    history.push("/settings/qr-specs");
  };

  const handleSubmitForReview = async (data) => {
    try {
      showLoader(true);
      const files = data.files.map(x => {return {fileName: x.name, fileSize: x.size, path: x.path}})
      await moveToReviewStage({
        id: selectedId,
        stage: "in-review",
        status: "in-progress",
        comment: data.notes,
        files: files
      })
      toast.success("QR Specification moved to In-Review stage");
      goToQRListPage()
      showLoader(false);
    } catch (e) {
      showLoader(false);
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
  }

  return (
    <div className="page">
      <div className="breadcrumb-wrapper" data-testid="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => {
          return (
            <div key={index}>
              {index === 0 ? (
                <div className="breadcrumb">{breadcrumb + " /"}</div>
              ) : (
                breadcrumb
              )}
            </div>
          );
        })}
      </div>
      <div className="page-title-row">
        <div className="header-title-wrapper d-flex justify-content-between w-100">
          <div className="page-title-row">
            <div className="title">
              <h1 data-testid="main-header">Submit for Review - {selectedSpec.template && selectedSpec.template}</h1>
            </div>
          </div>
          <Button
            variant="primary"
            size="lg"
            data-testid="submit-for-test"
            className="review-submit-btn"
            type="submit"
            form="review-form"
          >
            Submit For Review
          </Button>
        </div>
      </div>

      <Form className="form-2" id="review-form" onSubmit={handleSubmit(handleSubmitForReview)}>
        <Row>
          <Col>
            <div className="b-detail">
              <h4 data-testid="headersTitle">Notes*</h4>
            </div>
            <Form.Control
              id="note"
              as="textarea"
              {...register("notes")}
              placeholder="Type here"
              rows={5}
              isInvalid={errors.notes}
            />
            <Form.Control.Feedback id="noteErrors" type="invalid">
              {errors.notes?.message}
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="upload-container">
              <div className="b-detail">
                <h4 data-testid="headersTitle">Test Result Documents*</h4>
              </div>
              <DropzoneComp className="m-0 p-0" register={register} append={append} remove={remove} errors={errors} controlledFields={controlledFields} trigger={trigger} />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedSpec: state.qrSpecifiction.current,
    selectedId: state.qrSpecifiction.selectedId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: (data) => dispatch(showLoader(data)),
  moveToReviewStage: (data) => dispatch(changeQRSpecificationStage(data)),
  uploadFile: (file) => dispatch(uploadTestFile(file))
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitForReview);
