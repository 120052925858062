import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
    Form,
    Table,
    Col,
    Row,
    Button
} from "react-bootstrap";
import { toast } from "react-toastify";
import {
    CREATE_ROLE_SUCCESS,
    EDIT_ROLE_SUCCESS,
    SOMETHING_WENT_WRONG,
    UNAUTHORIZED_ACTION,
} from "../../../utils/errorMessages";
import { useHistory } from "react-router-dom";
import { createRole, getRole, updateRole } from "../../../redux/actions/roleActions";
import { getAllRoleActions } from "../../../redux/actions/permissionActions";
import { showLoader } from "../../../redux/actions/commonActions";
import { receiveMarketData } from "../../../redux/actions/profileActions";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const createSchema = yup.object().shape({
    roleName: yup.string().max(50).required("Role Name is required"),
    market: yup.string(),
    description: yup.string().max(100).required("Description is required"),
    permissions: yup
        .array()
        .of(
            yup.object().nullable().shape({ permissionId: yup.number(), cheked: yup.bool() })
        )
        .default([])
        .test('test-if-at-least-one-checked', 'Select at least one action', permissions => {
            return permissions.some(permission => permission.checked)
        })
});


const CreateRolePage = ({
    getActions,
    showLoader,
    createRole,
    isEditing,
    selectedRoleForEdit,
    getRole,
    actions,
    isSuperAdmin,
    updateRole,
    marketOptions=[],
    receiveMarketData
}) => {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid, isDirty },
    } = useForm({
        defaultValues: { roleName: null, description: null, permissions: [] },
        reValidateMode: "onChange",
        resolver: yupResolver(createSchema),
        mode: "all",
    });
    const history = useHistory();

    useEffect(() => {
        async function getInitialValues() {
            try {
                showLoader(true);
                const actionResult = await getActions();
                const actions = actionResult.data;
                if (isEditing) {
                    const result = await getRole(selectedRoleForEdit.id)
                    const withChecked = result.data.permissions.map(x => { return { checked: true, ...x } })
                    const arrayOfPermissionArrays = actions.map(action => { return action.permissions });
                    const merged = [].concat.apply([], arrayOfPermissionArrays);
                    const updatedActions = merged.map(obj => withChecked.find(o => o.permissionId === obj.permissionId) || obj);
                    reset({ roleName: selectedRoleForEdit.roleName, description: selectedRoleForEdit.roleDescription, permissions: updatedActions, market: selectedRoleForEdit.market })
                }
            } catch (e) {
                toast.error(SOMETHING_WENT_WRONG)
            } finally {
                showLoader(false);
            }
        }
        getInitialValues();
    }, [getActions, showLoader, reset, getRole, isEditing, selectedRoleForEdit.id, selectedRoleForEdit.market, selectedRoleForEdit.roleDescription, selectedRoleForEdit.roleName]);

    useEffect(() => {
        receiveMarketData();
    }, []);

    const handleCreateRole = async (role) => {
        let payload = {
            roleName: role.roleName,
            roleDescription: role.description,
            market: role.market,
            isMarketSpecific: true,
            permissions:
                role.permissions.filter(x => x.checked).map(y => {
                    delete y.checked;
                    return y;
                })
        }

        try {
            showLoader(true);
            if (isEditing) {
                payload.id = selectedRoleForEdit.id;
                await updateRole(payload);
                toast.success(EDIT_ROLE_SUCCESS);
            } else {
                await createRole(payload);
                toast.success(CREATE_ROLE_SUCCESS);
            }
            showLoader(false);
            history.push('/settings/roles')

        } catch (e) {
            if (e.response.status && e.response.status === 403) {
                toast.error(UNAUTHORIZED_ACTION);
            } else if (e.response.data) {
                toast.error(e.response.data);
            } else {
                toast.error(SOMETHING_WENT_WRONG);
            }
            showLoader(false)
        }
    }

    let p = -1;
    const renderActions = actions.map((action, index) => {

        return (
            <tr key={index}>
                <td>{action.moduleName}</td>
                <td>
                    <Row>
                        {action.permissions.map((permission, k) => {
                            p++;
                            return (<Col
                                key={p}
                                md="6"
                                lg="4"
                                xl="3"
                            >
                                <Form.Group className="mb-1 mt-1" >
                                    <Form.Control
                                        type="hidden"
                                        name={`permissions[${p}]permissionId`}
                                        {...register(`permissions.${p}.permissionId`, { required: true })}
                                        value={permission.permissionId}
                                    />
                                    <Form.Control
                                        type="hidden"
                                        name={`permissions[${p}]value`}
                                        {...register(`permissions.${p}.value`, { required: true })}
                                        value={permission.value}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        name={`permissions[${p}]checked`}
                                        data-testid={permission.id}
                                        {...register(`permissions.${p}.checked`, { required: true })}
                                        checked={permission.checked}
                                        label={permission.permissionName}
                                    />
                                </Form.Group>
                            </Col>)
                        })}
                    </Row>
                </td>

            </tr>
        );
    });

    return (
        <div className="page create-role-page">
            <div className="page-title-row">
                <div className="title">
                    <h1> {isEditing ? "Edit" : "Create"} Role</h1>
                </div>
            </div>
            <Form className="form form3" onSubmit={handleSubmit(handleCreateRole)}>
                <div className="special">
                    <div className="filter-section">
                        <div className="filter-components d-block">

                            <Row className="align-items-center">
                                <div className="col-8 p-0 wrap-create-role-form">
                                    <div className="d-lg-flex d-md-block">
                                        <Form.Group className="mb-3 mx-3 role-name" controlId="roleName">
                                            <Form.Label>Role Name*</Form.Label>
                                            <Form.Control
                                                data-testid="role-name"
                                                type="text"
                                                {...register("roleName", { required: true })}
                                                autoComplete="off"
                                                placeholder="Enter Role Name"
                                                isInvalid={errors.roleName}
                                                maxLength={50}
                                            />
                                            <Form.Control.Feedback id="roleNameErrors" type="invalid">
                                                {errors.roleName?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {isSuperAdmin ? (
                                            <Form.Group className="role-name">
                                                <Form.Label>Market*</Form.Label>
                                                <Form.Select
                                                    id="marketSelect"
                                                    placeholder="Select a Market"
                                                    {...register("market", { required: true })}
                                                    isInvalid={errors.market}
                                                >
                                                    <option value="">Select a Market</option>
                                                    {marketOptions.map(market => {
                                                        return <option key={market.value} value={market.value}>{market.displayName}</option>
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback id="marketErrors" type="invalid">
                                                    {errors.market?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        ) : null}

                                        <Form.Group className="mb-3 mx-3 role-desc" controlId="description">
                                            <Form.Label>Description*</Form.Label>
                                            <Form.Control
                                                data-testid="description"
                                                type="text"
                                                {...register("description", { required: true })}
                                                autoComplete="off"
                                                placeholder="Enter a description"
                                                isInvalid={errors.description}
                                                maxLength={100}
                                            />
                                            <Form.Control.Feedback id="descriptionErrors" type="invalid">
                                                {errors.description?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Button
                                            variant="primary mx-3"
                                            id="create-role-button"
                                            type="submit"
                                            disabled={!isValid || !isDirty}
                                        >
                                            <span className="mx-2">{isEditing ? "Save" : "Create"}</span>
                                        </Button>
                                    </div>
                                </div>
                            </Row>

                        </div>
                    </div>
                </div>
                <Table responsive className="mx-1">
                    <thead>
                        <tr>
                            <th>Module</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>{renderActions}</tbody>
                </Table>
            </Form>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        sub: state.profile?.sub,
        actions: state.permission.allActions,
        isEditing: state.role.isEditFlow,
        selectedRoleForEdit: state.role.selectedRoleForEdit === null ? { id: null, market: null, roleName: null, roleDescription: null } : state.role.selectedRoleForEdit,
        isSuperAdmin: state.permission.isSuperAdmin,
        marketOptions: state.profile.markets
    };
};

const mapDispatchToProps = (dispatch) => ({
    createRole: (data) => dispatch(createRole(data)),
    getActions: (data) => dispatch(getAllRoleActions(data)),
    getRole: (roleId) => dispatch(getRole(roleId)),
    showLoader: (flag) => dispatch(showLoader(flag)),
    updateRole: (role) => dispatch(updateRole(role)),
    receiveMarketData: () => dispatch(receiveMarketData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateRolePage);
