import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

const CanvasErrorModal = ({ errorText, show, toggleShow}) => {
  return (
    <>
      <Modal show={show} onHide={toggleShow}>
        <Modal.Header  closeButton>
          <Modal.Title id="view-reason-modal-title" className="question" data-testid="modalTitle">Error</Modal.Title>
        </Modal.Header>
        <Modal.Body id="comment-content"  className="word-break" data-testid="modalBody">{errorText}</Modal.Body>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(null, mapDispatchToProps)(CanvasErrorModal);
