import "./styles/style.scss";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./pages/Routes";
import React, { Suspense, useContext } from "react";
import Loader from "./components/loaders/Loader";
import { AuthContext } from './context/Auth.context'
import { CLEAR_ALL } from "./redux/actions/actionTypes";
import { connect } from "react-redux";
import { useIdleTimer } from "react-idle-timer";

function App({ clearAll }) {
  const { expireSession } = useContext(AuthContext)
  const handleOnIdle = () => {
    clearAll();
    expireSession();
  };

  const handleOnActive = (event) => {};

  const handleOnAction = (event) => {};

  useIdleTimer({
    timeout: 1000 * 60 * 5,
    crossTab: {
      emitOnAllTabs: true,
    },
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  return (
    <Suspense fallback={Loader}>
      <Routes />
    </Suspense>
  );
}

const mapDispatchToProps = (dispatch) => ({
  clearAll: () => dispatch({ type: CLEAR_ALL }),
});

export default connect(null, mapDispatchToProps)(App);
