import React, { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const createSchema = yup.object().shape({
    qrSize: yup
        .string()
        .nullable()
        .required("QR code size is required"),
});


const SelectQrImageSize = ({ show, toggleShow, submitSize }) => {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: { qrSize: null },
        reValidateMode: "onChange",
        resolver: yupResolver(createSchema),
        mode: "all",
    });

    const selectSize = (data) => {
        submitSize(data.qrSize);
        toggleShow();
    }

    useEffect(() => {
        reset();
    }, [show, reset])

    return (
        <>
            <Modal show={show} onHide={toggleShow} data-testid="qrImageSizeSelectionModal">
                <Modal.Header closeButton>
                    <Modal.Title className="question" data-testid="confimationModalTitle">
                        {"QR Code Image Dimensions"}
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(selectSize)}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Select
                                data-testid="qrImageSizeDropdown"
                                id="qrImageSize"
                                {...register("qrSize", { required: true })}
                                isInvalid={errors.qrSize}
                            >
                                <option value="">Select the dimensions for the image</option>
                                <option value="100">100 x 100</option>
                                <option value="200">200 x 200</option>
                                <option value="300">300 x 300</option>
                                <option value="400">400 x 400</option>
                                <option value="500">500 x 500</option>
                            </Form.Select>
                            <Form.Control.Feedback id="qrSizeErrors" type="invalid">
                                {errors.qrSize?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            data-testid="cancelButton"
                            id="btn-cancel"
                            className="btn-cancel mx-3"
                            onClick={toggleShow}
                        > Cancel </Button>

                        <Button
                          data-testid="confirmButton"
                            id="btn-confirm"
                            variant="primary"
                            type="submit"
                        > Generate </Button>

                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};
export default SelectQrImageSize;
