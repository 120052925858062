import React, { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import { showLoader } from "../../redux/actions/commonActions";
import { createNetwork, updateNetwork } from "../../redux/actions/networkActions";
import { receiveMarketData } from "../../redux/actions/profileActions";
import { toast } from "react-toastify";
import {
	CREATE_NETWORK_SUCCESS,
	UPDATE_NETWORK_SUCCESS,
	SOMETHING_WENT_WRONG,
	UNAUTHORIZED_ACTION,
} from "../../utils/errorMessages";
import {regexForNames} from "../../utils/common";

let createNetworkSchema = yup.object().shape({
	market: yup.string().required("Network market is required"),
	name: yup.string().matches(regexForNames, "Allows only a-z, A-Z, 0-9, space, - and _").max(50, "Network name must be at most 50 characters").required("Network name is required"),
	description: yup.string().max(50, "Description must be at most 50 characters").required("Network description is required"),
	networkId: yup.string().required().matches(/^[0-9]+$/, "Network ID must be only digits").max(5, 'Must be 5 digits or less')
});

const CreateNetworkModal = ({
	show,
	toggleShow,
	selectedMarket,
	showLoader,
	getNetworkList,
	updateNetwork,
	isEditFlow = false,
	createNetwork,
	network,
	marketOptions =[],
	receiveMarketData
}) => {
	const {
		register, handleSubmit, reset, formState: { errors },
	} = useForm({
		defaultValues: {}, resolver: yupResolver(createNetworkSchema), mode: "onChange",
	});

	useEffect(() => {
		async function initModal() {
			if (isEditFlow) {
				reset(network)
			} else {
				reset({});
			}
		}
		initModal()
	}, [reset, isEditFlow, network, selectedMarket, showLoader]);

	useEffect(() => {
		receiveMarketData();
	}, [show])


	const handleToggleShow = () => {
		toggleShow();
		reset({});
	};

	function addLeadingZerosToNetworkId(data) {
		if (data.networkId.length !== 5 && (data.networkId === "0" || data.networkId === "00" || data.networkId === "000" || data.networkId === "0000")) {
			data.networkId = data.networkId.padStart(5, '0')
			return data
		} else {
			return data
		}
	}

	const handleCreateNetwork = async (data) => {
		showLoader(true);
		try {
			if (isEditFlow) {
				data = { ...data, networkId: data.networkId.toString() }
				await updateNetwork(addLeadingZerosToNetworkId(data));
				toast.success(UPDATE_NETWORK_SUCCESS);
			} else {
				data = { ...data, networkId: data.networkId.toString(), isActive: true }
				await createNetwork(addLeadingZerosToNetworkId(data));
				toast.success(CREATE_NETWORK_SUCCESS);
			}
			await getNetworkList();

			handleToggleShow();
			showLoader(false);
			reset();
		} catch (e) {
			if (e.response.status && e.response.status === 403) {
				toast.error(UNAUTHORIZED_ACTION);
			} else if (e.response.data.Description) {
				toast.error(e.response.data.Description);
			} else {
				toast.error(SOMETHING_WENT_WRONG);
			}
			showLoader(false);
		}
	};

	return (<>
		<Modal
			show={show}
			onHide={handleToggleShow}
			backdrop="static"
			keyboard={false}
			size="md"
			dialogClassName="modal-network"
		> <Modal.Header>
				<Modal.Title data-testid="modal-title" id="modal-title">{isEditFlow ? "Edit" : "Create"} Network</Modal.Title> </Modal.Header>
			<Form onSubmit={handleSubmit(handleCreateNetwork)}>
				<Modal.Body>
					<Form.Label> Network Market* </Form.Label>
					<Form.Select
						data-testid="market"
						{...register("market", { required: true })}
						aria-label="Select a Market"
						isInvalid={errors.market}
						defaultValue={selectedMarket}
					>
						<option value="">Select Market</option>
						{marketOptions.map(market => {
							return <option key={market.value} value={market.value}>{market.displayName}</option>
						})}
					</Form.Select>
					<Form.Control.Feedback id="marketErrors" type="invalid"> {errors.market?.message} </Form.Control.Feedback>

					<br /> <Form.Label> Network Name* </Form.Label>
					<Form.Control
						data-testid="name"
						type="string" {...register("name", { required: true })}
						autoComplete="off"
						placeholder="Network Name"
						isInvalid={errors.name}
					/>
					<Form.Control.Feedback id="nameErrors" type="invalid"> {errors.name?.message} </Form.Control.Feedback>

					<br /> <Form.Label> Network Description* </Form.Label>
					<Form.Control
						data-testid="description"
						type="string" {...register("description", { required: true })}
						autoComplete="off"
						placeholder="Network Description"
						isInvalid={errors.description}
					/>
					<Form.Control.Feedback id="descriptionErrors" type="invalid"> {errors.description?.message} </Form.Control.Feedback>

					<br /> <Form.Label> Network Id* </Form.Label>
					<Form.Control
						data-testid="networkId"
						type="string" {...register("networkId", { required: true })}
						autoComplete="off"
						placeholder="Network Id"
						isInvalid={errors.networkId}
					/>
					<Form.Control.Feedback id="networkIdErrors" type="invalid"> {errors.networkId?.message} </Form.Control.Feedback>
				</Modal.Body>
				<Modal.Footer>

					<Button
						data-testid="btnCancel"
						variant="primary"
						className="btn-cancel"
						onClick={() => handleToggleShow()}
					> Cancel </Button>

					<Button
						variant="primary"
						type="submit"
						name="next"
						data-testid="btnNext"
						className="ms-3"
					> Save </Button>

				</Modal.Footer>
			</Form>
		</Modal>
	</>);
};


const mapStateToProps = (state) => {
	return {
	  marketOptions: state.profile.markets
	};
  };
  
const mapDispatchToProps = (dispatch) => ({
	showLoader: (data) => dispatch(showLoader(data)),
	updateNetwork: (data) => dispatch(updateNetwork(data)),
	createNetwork: (data) => dispatch(createNetwork(data)),
	receiveMarketData: () => dispatch(receiveMarketData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNetworkModal);
