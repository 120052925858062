export const BEGIN_API_CALL = 'BEGIN_API_CALL';
export const API_CALL_ERROR = 'API_CALL_ERROR';

export const FETCH_USERS = 'FETCH_USERS';
export const CREATE_USER = 'ÇREATE_USER';
export const RESEND_USER_INVITE = "RESEND_USER_INVITE";
export const EDIT_USER = 'EDIT_USER';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const REACTIVATE_USER = 'REACTIVATE_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_DEACTIVATION_REASON = 'GET_DEACTIVATION_REASON';
export const CLEAR_REASON = 'CLEAR_REASON';
export const CHECK_IF_MFA_ENABLED = "CHECK_IF_MFA_ENABLED";
export const VERIFY_MFA_CODE = "VERIFY_MFA_CODE";
export const GET_MFA_QR_CODE = "GET_MFA_QR_CODE";
export const RESET_USER_MFA = "RESET_USER_MFA";

export const FETCH_ROLES = "FETCH_ROLES";
export const GET_ROLE = "GET_ROLE";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const GET_ROLE_ACTIONS = "GET_ROLE_ACTIONS";
export const SET_IS_ROLE_EDIT_FLOW = "SET_IS_ROLE_EDIT_FLOW";
export const SET_SELECTED_ROLE_FOR_EDIT = "SET_SELECTED_ROLE_FOR_EDIT";
export const ACTIVATE_ROLE = "ACTIVATE_ROLE";
export const DEACTIVATE_ROLE = "DEACTIVATE_ROLE";


export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS";
export const CHECK_PERMISSION = "CHECK_PERMISSION";

export const RECEIVE_ACCESS_TOKEN = "RECEIVE_ACCESS_TOKEN";
export const RECEIVE_ID_TOKEN = "RECEIVE_ID_TOKEN";
export const RECEIVE_PROFILE_DATA = "RECEIVE_PROFILE_DATA";
export const RECEIVE_MARKET_DATA = "RECEIVE_MARKET_DATA";
export const GENERATE_STATE_AND_NONCE = "GENERATE_STATE_AND_NONCE";

export const CHANGE_PAGE = "CHANGE_PAGE";

export const CLEAR_ALL = "CLEAR_ALL";


export const FETCH_TEMPLATES = "FETCH_TEMPLATES";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const GET_TEMPLATE = "GET_TEMPLATE";
export const GET_TEMPLATE_SIZE = "GET_TEMPLATE_SIZE";
export const SAVE_TEMPLATE = "SAVE_TEMPLATE";
export const CHANGE_SELECTED_TEMPLATE = "CHANGE_SELECTED_TEMPLATE";
export const CHANGE_TEMPLATE_FLOW = "CHANGE_TEMPLATE_FLOW";
export const SET_CREATE_TEMPLATE_DETAILS = "SET_CREATE_TEMPLATE_DETAILS";
export const IMPORT_CSV = "IMPORT_CSV";
export const GENERATE_QR_CODES = "GENERATE_QR_CODES";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const CLEAR_TEMPLATE_STORE = "CLEAR_TEMPLATE_STORE";

export const FETCH_AUDIT_LOGS = "FETCH_AUDIT_LOGS";
export const EXPORT_AUDIT_LOGS = "EXPORT_AUDIT_LOGS";
export const CHANGE_SELECTED_AUDIT = "CHANGE_SELECTED_AUDIT";

export const TOGGLE_LOADER = "TOGGLE_LOADER";

export const ACTIVATE_TEMPLATE = "ACTIVATE_TEMPLATE";
export const DEACTIVATE_TEMPLATE = "DEACTIVATE_TEMPLATE";


export const FETCH_QR_SPECIFICATIONS = "FETCH_QR_SPECIFICATIONS";
export const CREATE_QR_SPECIFICATION = "CREATE_QR_SPECIFICATION";
export const GET_QR_SPECIFICATION = "GET_QR_SPECIFICATION";
export const UPDATE_QR_SPECIFICATION = "UPDATE_QR_SPECIFICATION";
export const SET_CREATE_STEP_ONE_DATA = "SET_CREATE_STEP_ONE_DATA";
export const SET_CREATE_STEP_TWO_DATA = "SET_CREATE_STEP_TWO_DATA";
export const RESET_CREATE_FLOW = "RESET_CREATE_FLOW";
export const SET_QR_SPEC_SELECTED_ID = "SET_QR_SPEC_SELECTED_ID";
export const SET_QR_SPECIFICATION_FLOW = "SET_QR_SPECIFICATION_FLOW";
export const EXPORT_QR_SPECIFICATION = "EXPORT_QR_SPECIFICATION";
export const GET_QR_SPECIFICATION_NOTES = "GET_QR_SPECIFICATION_NOTES";
export const CLEAR_SPECIFICATION_STORE = "CLEAR_SPECIFICATION_STORE";
export const REJECT_QR_SPECIFICATION = "REJECT_QR_SPECIFICATION";
export const PUBLISH_QR_SPECIFICATION = "PUBLISH_QR_SPECIFICATION";
export const MOVE_TO_BACK_QR_SPECIFICATION = "MOVE_TO_BACK_QR_SPECIFICATION";
export const GET_QR_SPECIFICATION_HISTORY = "GET_QR_SPECIFICATION_HISTORY";

export const FETCH_NETWORKS = "FETCH_NETWORKS";
export const CREATE_NETWORK = "CREATE_NETWORK";
export const UPDATE_NETWORK = "UPDATE_NETWORK";
export const ACTIVATE_NETWORK = "ACTIVATE_NETWORK";
export const DEACTIVATE_NETWORK = "DEACTIVATE_NETWORK";

export const ACTIVATE_SPECIFICATION = "ACTIVATE_SPECIFICATION";
export const DEACTIVATE_SPECIFICATION = "DEACTIVATE_SPECIFICATION";

export const FETCH_LANGUAGES = "FETCH_LANGUAGES";
export const CREATE_LANGUAGE = "CREATE_LANGUAGE";
export const DEACTIVATE_LANGUAGE = "DEACTIVATE_LANGUAGE";
export const ACTIVATE_LANGUAGE = "REACTIVATE_LANGUAGE";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

export const FETCH_MPA_FIELDS = "FETCH_MPA_FIELDS";

export const FETCH_MARKETS = "FETCH_MARKETS";
export const CREATE_MARKET = "CREATE_MARKET";
export const UPDATE_MARKET = "UPDATE_MARKET";
export const ACTIVATE_MARKET = "ACTIVATE_MARKET";
export const DEACTIVATE_MARKET = "DEACTIVATE_MARKET";

export const FETCH_CRC_FIELDS = "FETCH_CRC_FIELDS";
export const GET_QR_FIELD_VALIDATIONS = "GET_QR_FIELD_VALIDATIONS";

export const GET_VERSIONS = "GET_VERSIONS";
export const CREATE_VERSION = "CREATE_VERSION";
export const ACTIVATE_VERSION = "ACTIVATE_VERSION";
export const QR_SPEC_TEST_ENCODE_SUCCESS = "QR_SPEC_TEST_ENCODE_SUCCESS";
export const QR_SPEC_TEST_ENCODE_ERROR = "QR_SPEC_TEST_ENCODE_ERROR";
export const QR_SPEC_TEST_DECODE_SUCCESS = "QR_SPEC_TEST_DECODE_SUCCESS";
export const QR_SPEC_TEST_DECODE_ERROR = "QR_SPEC_TEST_DECODE_ERROR";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const GET_PRESIGN_URL = "GET_PRESIGN_URL";

export const FETCH_TRANSACTION_TYPE = "FETCH_TRANSACTION_TYPE";
export const CREATE_TRANSACTION_TYPE = "CREATE_TRANSACTION_TYPE";
export const UPDATE_TRANSACTION_TYPE = "UPDATE_TRANSACTION_TYPE";
export const ACTIVATE_TRANSACTION_TYPE = "ACTIVATE_TRANSACTION_TYPE";
export const DEACTIVATE_TRANSACTION_TYPE = "DEACTIVATE_TRANSACTION_TYPE";

