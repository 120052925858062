
export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getFormatString = (hasNumbers, hasAlphabets, hasSpecialChars) => {
    let format = "";
    if (hasNumbers && !hasAlphabets && !hasSpecialChars) {
        format = "N";
    } else if (!hasNumbers && hasAlphabets && !hasSpecialChars) {
        // only alphabatical allowed
        format = "A";
    } else if (!hasNumbers && !hasAlphabets && hasSpecialChars) {
        // only speical chars allowed
        format = "SP";
    } else if (!hasNumbers && hasAlphabets && hasSpecialChars) {
        // alphabatical and special 
        format = "AS";
    } else if (hasNumbers && !hasAlphabets && hasSpecialChars) {
        // numbers and special
        format = "NS";
    } else if (hasNumbers && hasAlphabets && !hasSpecialChars) {
        // numbers and alphabatical
        format = "AN";
    } else if (hasNumbers && hasAlphabets && hasSpecialChars) {
        format = "ANS"
    }
    return format;
}
export const regexForNames = /^[a-zA-Z0-9\s\-_]*$/;