import { FETCH_NETWORKS } from "../actions/actionTypes";

const defaultState = {
  list: [],
  current: null
};

const networkReducer = (reduxState = defaultState, { type, payload }) => {
  switch (type) {
    case FETCH_NETWORKS:
      return {
        ...reduxState,
        list: payload.networks,
        currentPage: payload.currentPage,
        noOfPages: payload.noOfPages,
        totalCount: payload.totalCount
      };
    default:
      return reduxState;
  }
};

export default networkReducer;
