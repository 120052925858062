import React from "react";
import { Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { ShowForPermission } from "../ShowForPermission";
import { QR_TEMPLATE_EDIT, QR_TEMPLATE_ACTIVATE_DEACTIVATE } from "../../utils/permissionList";

const TemplateMenu = ({
  template,
  toggleTemplateActivity,
  viewTemplate,
  editTemplate,
  templateIsActive
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="link"
        id={"dropdown-basic-" + template.id}
        data-testid="toggle-btn"
      >
        <BiDotsVerticalRounded />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          data-testid="viewTemplateAction"
          onClick={() => viewTemplate(template)}
        > View
        </Dropdown.Item>
        <ShowForPermission permissions={[QR_TEMPLATE_ACTIVATE_DEACTIVATE]}>
          <Dropdown.Divider />
          <Dropdown.Item
            data-testid="deactivateTemplateAction"
            onClick={() => toggleTemplateActivity(template)}
          > {templateIsActive ? 'Deactivate' : 'Activate'}
          </Dropdown.Item>
        </ShowForPermission>
        <ShowForPermission permissions={[QR_TEMPLATE_EDIT]}>
          <Dropdown.Divider />
          <Dropdown.Item
            data-testid="editTemplateAction"
            onClick={() => editTemplate(template)}
          >
            Edit
          </Dropdown.Item>
        </ShowForPermission>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TemplateMenu;
