import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from "react-redux";
import { showLoader } from '../../redux/actions/commonActions';
import { uploadTestFile } from '../../redux/actions/qrSpecificationActions';
import { Table, Form } from "react-bootstrap";
import ReviewSpecTableMenu from '../action-menus/ReviewSpecTableMenu';
import { BiCloudUpload } from "react-icons/bi";
import { formatBytes } from "../../utils/common";
import { AiFillFileText } from "react-icons/ai";


function DropzoneComp({ uploadFile, selectedId, selectedSpec, showLoader, register, errors, controlledFields, append, remove }) {

  const [allFiles, setAllFilesList] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const maxFileSize = 2000000;
  const maxNumberOfFiles = 10;

  const fileSizeValidator = (file) => {
    if (file.exceededFile) {
      return {
        code: "too many files",
        message: `Maximum number of files that can be uploaded is 10`
      };
    }
    if (file.size > maxFileSize) {
      return {
        code: "file is too large",
        message: `File is larger than 2mb`
      };
    }
    return null
  }



  const onDrop = React.useCallback(
    acceptedFiles => {
      setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
    },
    [uploadedFiles]
  );

  const {
    isFocused,
    isDragAccept,
    isDragReject, fileRejections, getRootProps, getInputProps } = useDropzone({
      getFilesFromEvent: event => CustomFileGetter(event),
      useFsAccessApi: false,
      maxFiles: 10,
      accept: 'image/jpeg,image/png,.txt,application/pdf,application/vnd.ms-excel,.doc,.docx,.csv',
      validator: fileSizeValidator,
      onDrop
    });

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '80px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const focusedStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    borderColor: '#FF0000'
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };


  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  useEffect(() => {
    async function upload() {
      if (uploadedFiles.length > 0 && allFiles.length < 10) {
        showLoader(true);
        const files = [];
        for (let i = 0; i < uploadedFiles.length; i++) {
          const result = await uploadFile({ file: uploadedFiles[i], market: selectedSpec.market, specificationId: selectedId });
          files.push({ name: uploadedFiles[i].name, size: uploadedFiles[i].size, fileKey: result.data.docId });
          append({ name: uploadedFiles[i].name, size: uploadedFiles[i].size, fileKey: result.data.docId })
        }
        setUploadedFiles([]);
        setAllFilesList([...allFiles, ...files]);
        showLoader(false);
      }
    }
    upload();
  }, [uploadedFiles, allFiles, setAllFilesList, uploadFile, showLoader]);


  const CustomFileGetter = async (event) => {
    try {
      const files = [];
      const fileList = event.dataTransfer ? event.dataTransfer.files : event.target ? event.target.files : event;
      let maxNoFilesExceeded = fileList.length + allFiles.length > maxNumberOfFiles;
      let exceededCount = fileList.length + allFiles.length - maxNumberOfFiles;

      showLoader(true);
      for (var i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        Object.defineProperty(file, 'fileKey', {
          value: null
        });

        if (maxNoFilesExceeded && (fileList.length - i <= exceededCount)) {
          Object.defineProperty(file, 'exceededFile', {
            value: true
          });
        } else {
          Object.defineProperty(file, 'exceededFile', {
            value: false
          });
        }
        files.push(file);
      }
      showLoader(false);
      return files;
    } catch (e) {
      showLoader(false);
      return []
    }
  }


  const handleRemoveFile = (index) => {
    if (index !== -1) {
      remove(index);
      const newArr = allFiles;
      newArr.splice(index, 1);
      setAllFilesList([...newArr])
    }
  }


  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.name}
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <section>
      <div {...getRootProps({ className: 'dropzone', style })} className='upload-field-wrapper' data-testid="dropzone">
        <input {...getInputProps()} />
        <BiCloudUpload />
        <p>Drag and drop test result files here, or click to select files</p>
      </div>


      {(errors.files?.message && allFiles.length === 0 )?  <span className="error-file-msg">{errors.files?.message}</span> : null}
      <aside>
        {fileRejectionItems.length > 0 ? (
          <> <h6 className="mt-4 error-file-msg">Rejected Files</h6>
            <ul className="error-file-msg">{fileRejectionItems}</ul></>
        ) : null
        }
        {allFiles.length > 0 ?
          <div className="doc-table-wrapper">
            <Table>
              <thead>
                <tr>
                  <th>Document Name</th>
                  <th className="text-center">Size</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>{controlledFields.map((file, index) => {
                return (
                  <tr key={file.id}>

                    <td className="set-min-width">
                      <AiFillFileText className="doc-icon" />
                      {file.name}
                      <Form.Control
                        type="hidden"
                        name={`files[${index}]`}
                        {...register(`files.${index}`)}
                      />
                      <Form.Control
                        type="hidden"
                        name={`files[${index}].path`}
                        {...register(`files.${index}.path`)}
                        value={file.fileKey}
                      />
                      <Form.Control
                        type="hidden"
                        name={`files[${index}].name`}
                        {...register(`files.${index}.name`)}
                        value={file.name}
                      />
                      <Form.Control
                        type="hidden"
                        name={`files[${index}].size`}
                        {...register(`files.${index}.size`)}
                        value={file.size}
                      />
                    </td>
                    <td className="text-center">{formatBytes(file.size)}</td>
                    <td className="text-center">
                      <ReviewSpecTableMenu remove={handleRemoveFile} file={file} index={index} />
                    </td>
                  </tr>
                );
              })}</tbody>
              <tbody></tbody>
            </Table>
          </div>
          : null}
      </aside>
    </section>
  );
}


const mapStateToProps = (state) => {
  return {
    selectedSpec: state.qrSpecifiction.current,
    selectedId: state.qrSpecifiction.selectedId,
  };
};


const mapDispatchToProps = (dispatch) => ({
  uploadFile: (file) => dispatch(uploadTestFile(file)),
  showLoader: (flag) => dispatch(showLoader(flag))
});

export default connect(mapStateToProps, mapDispatchToProps)(DropzoneComp);
