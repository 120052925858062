import axios from "../../apis/permissionsAPI";
import { v4 as uuidv4 } from 'uuid';

import {
  GET_ROLE_ACTIONS,
  GET_USER_PERMISSIONS,
  CHECK_PERMISSION
} from "./actionTypes";

export const checkPermission = (permissions) => async (dispatch, getState) => {
  function onSuccess(success) {
    dispatch({
      type: CHECK_PERMISSION,
      payload: null,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: CHECK_PERMISSION,
      payload: [],
    });
    throw error;
  }
  try {
    const currentState = getState();
    return onSuccess(currentState.permission.currentUserPermissions.some(i => permissions.includes(i)));
  } catch (error) {
    return onError(error);
  }
};

export const getAllRoleActions = () => async (dispatch, getState) => {
  function onSuccess(success) {
    dispatch({
      type: GET_ROLE_ACTIONS,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: GET_ROLE_ACTIONS,
      payload: [],
    });
    throw error;
  }
  try {
    const response = await axios.get(`/`, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};


export const getCurrentUserPermissions = () => async (dispatch, getState) => {
  function onSuccess(success) {
    dispatch({
      type: GET_USER_PERMISSIONS,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: GET_ROLE_ACTIONS,
      payload: error,
    });
    throw error;
  }
  try {
    const response = await axios.get(`/current`, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};