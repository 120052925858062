import { FETCH_AUDIT_LOGS, EXPORT_AUDIT_LOGS, CHANGE_SELECTED_AUDIT } from "../actions/actionTypes";

const defaultState = {
  list: [],
  current: null,
  currentPage: 1,
  noOfPages: 0,
  totalCount: 0,
  csvContent: null,
};

const auditReducer = (reduxState = defaultState, { type, payload }) => {
  switch (type) {
    case FETCH_AUDIT_LOGS:
      return {
        ...reduxState,
        list: payload.auditLogs ? [...payload.auditLogs] : [],
        currentPage: payload.currentPage,
        noOfPages: payload.noOfPages,
        totalCount: payload.totalCount,
      };
    case EXPORT_AUDIT_LOGS:
      return {
        ...reduxState,
        csvContent: payload,
      };
    case CHANGE_SELECTED_AUDIT:
      return {
        ...reduxState,
        current: payload,
      };
    default:
      return reduxState;
  }
};

export default auditReducer;
