import React from "react";
import { Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { connect } from "react-redux";
import { ShowForPermission } from "../ShowForPermission";
import { ROLE_EDIT, ROLE_DEACTIVATE_ACTIVATE } from "../../utils/permissionList";


const RoleTableActionMenu = ({
  openEditRole,
  role,
  toggleRoleActivity
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" id={"dropdown-basic-" + role.id} data-testid="toggle-btn">
        <BiDotsVerticalRounded />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <ShowForPermission permissions={[ROLE_DEACTIVATE_ACTIVATE]}>
          <Dropdown.Item
            data-testid="deactivateRoleAction"
            onClick={() => toggleRoleActivity(role)}
          > {role.isActive ? 'Deactivate' : 'Reactivate'}
          </Dropdown.Item>
        </ShowForPermission>
        <ShowForPermission permissions={[ROLE_EDIT]}>
          <Dropdown.Divider />
          <Dropdown.Item
            data-testid="editAction"
            onClick={() => openEditRole(role)}
          > Edit </Dropdown.Item>
        </ShowForPermission>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => {
  return {
    userGroups: state.profile.groups,
  };
};

export default connect(mapStateToProps, null)(RoleTableActionMenu);
