import axios from "../../apis/userManagement";
import { v4 as uuidv4 } from 'uuid';

import {
  FETCH_USERS,
  CREATE_USER,
  EDIT_USER,
  DEACTIVATE_USER,
  REACTIVATE_USER,
  CHANGE_PAGE,
  GET_DEACTIVATION_REASON,
  RESET_PASSWORD,
  CLEAR_REASON,
  CHECK_IF_MFA_ENABLED,
  VERIFY_MFA_CODE,
  GET_MFA_QR_CODE,
  RESEND_USER_INVITE,
  RESET_USER_MFA
} from "./actionTypes";

export const createUser = (user) => async (dispatch, getState) => {
  function onSuccess(payload) {
    dispatch({
      type: CREATE_USER,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: CREATE_USER,
      payload: null,
    });
    throw error;
  }
  try {
    const response = await axios.post("/", user, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    return onSuccess(response.data);
  } catch (e) {
    return onError(e);
  }
};

export const resendUserInvite = (user) => async (dispatch, getState) => {
  function onSuccess(payload) {
    dispatch({
      type: RESEND_USER_INVITE,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: RESEND_USER_INVITE,
      payload: null,
    });
    throw error;
  }
  try {
    const response = await axios.post("/resend-invite", user, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    return onSuccess(response.data);
  } catch (e) {
    return onError(e);
  }
};

export const editUser = (user) => async (dispatch, getState) => {

  function onSuccess(payload) {
    dispatch({
      type: EDIT_USER,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: EDIT_USER,
      payload: null,
    });
    throw error;
  }
  try {
    const response = await axios.put("/", user, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    return onSuccess(response.data);
  } catch (e) {
    return onError(e);
  }
};

export const deactivateUser = (data) => async (dispatch, getState) => {
  function onSuccess(payload) {
    dispatch({
      type: DEACTIVATE_USER,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: DEACTIVATE_USER,
      payload: null,
    });
    throw error;
  }
  try {
    const response = await axios.post("/deactivate", data, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    return onSuccess(response.data);
  } catch (e) {
    return onError(e);
  }
};

export const reactivateUser = (data) => async (dispatch, getState) => {
  function onSuccess(payload) {
    dispatch({
      type: REACTIVATE_USER,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: REACTIVATE_USER,
      payload: null,
    });
    throw error;
  }
  try {
    const response = await axios.post("/reactivate", data, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    return onSuccess(response.data);
  } catch (e) {
    return onError(e);
  }
};

export const resetPassword = (data) => async (dispatch, getState) => {
  function onSuccess(payload) {
    dispatch({
      type: RESET_PASSWORD,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: RESET_PASSWORD,
      payload: null,
    });
    throw error;
  }
  try {
    const response = await axios.post("/reset-password", data, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    return onSuccess(response.data);
  } catch (e) {
    return onError(e);
  }
};

export const getDeactivationReason = (email) => async (dispatch, getState) => {
  function onSuccess(payload) {
    dispatch({
      type: GET_DEACTIVATION_REASON,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: GET_DEACTIVATION_REASON,
      payload: null,
    });
    throw error;
  }
  try {
    const response = await axios.get("/get-user-status-comment", {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      params: { Email: email },
    });
    return onSuccess(response.data);
  } catch (e) {
    return onError(e);
  }
};

export const clearReason = () => {
  return {
    type: CLEAR_REASON,
  };
};

export const changePage = (payload) => {
  return { type: CHANGE_PAGE, payload };
};

// gets a list of users
export const fetchUsers = (pageSize = 15, searchTerm = null, role = null, page = 1) => async (dispatch, getState) => {
  function onSuccess(payload) {
    dispatch({
      type: FETCH_USERS,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: FETCH_USERS,
      payload: {
        searchTerm: null,
        users: []
      },
    });
    throw error;
  }
  try {
    let params = {};
    if (role !== null && role > 0) {
      params.Role = role;
    }

    if (searchTerm !== null) {
      params.Search = searchTerm;
    }

    const response = await axios.get("/", {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      params,
    });

    let payload = response.data;
    payload.searchTerm = searchTerm;
    payload.page = page;
    payload.pageSize = pageSize;
    payload.role = role;

    return onSuccess(payload);
  } catch (error) {
    return onError(error);
  }
};

// checks if the current user has enabled MFA
export const checkIfMFAEnabled = () => async (dispatch, getState) => {
  function onSuccess(payload) {
    dispatch({
      type: CHECK_IF_MFA_ENABLED,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: CHECK_IF_MFA_ENABLED,
      payload: error,
    });
    throw error;
  }
  try {
    const response = await axios.get("/get-mfa-status", {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    let payload = response.data;
    return onSuccess(payload);
  } catch (error) {
    return onError(error);
  }
};

// verify the mfa code
export const verifyMFACode = (code) => async (dispatch, getState) => {
  function onSuccess(payload) {
    dispatch({
      type: VERIFY_MFA_CODE,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: VERIFY_MFA_CODE,
      payload: error,
    });
    throw error;
  }
  try {
    const response = await axios.post("/verify-totp", {
      otpToken: code
    }, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    let payload = response.data;
    return onSuccess(payload);
  } catch (error) {
    return onError(error);
  }
};

// generate mfa QR Code
export const getMFAQRCode = () => async (dispatch, getState) => {
  function onSuccess(payload) {
    dispatch({
      type: GET_MFA_QR_CODE,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: GET_MFA_QR_CODE,
      payload: error,
    });
    throw error;
  }
  try {
    const response = await axios.get("/get-mfa-qr", {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    let payload = response.data;
    return onSuccess(payload);
  } catch (error) {
    return onError(error);
  }
};

// resets the MFA configuration of a user
export const resetUserMFA = (email) => async (dispatch, getState) => {
  function onSuccess(payload) {
    dispatch({
      type: RESET_USER_MFA,
      payload,
    });
    return payload;
  }
  function onError(error) {
    dispatch({
      type: RESET_USER_MFA,
      payload: error,
    });
    throw error;
  }
  try {
    const response = await axios.post("/reset-mfa", {
      email
    }, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    let payload = response.data;
    return onSuccess(payload);
  } catch (error) {
    return onError(error);
  }
};
