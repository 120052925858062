import React from "react";
import { Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { connect } from "react-redux";
import { ShowForPermission } from "../ShowForPermission";
import { QR_SPECIFICATION_DEACTIVATE, QR_SPECIFICATION_ACTIVATE, QR_SPECIFICATION_CREATE_VERSION, QR_SPECIFICATION_MOVE_TO_REVIEW, QR_SPECIFICATION_UPDATE, QR_SPECIFICATION_CREATE, QR_SPECIFICATION_VIEW, QR_SPECIFICATION_VIEW_HISTORY, QR_SPECIFICATION_MOVE_TO_PUBLISH } from "../../utils/permissionList";

const SpecificationActionMenu = ({
	openActivityModal,
	isDeactivationFlow,
	openViewPage,
	createNewVersion,
	goToEdit,
	goToTest,
	goToReview,
	moveToBackStage,
	specification,
	exportJson,
	cloneSpecification,
	moveToInReviewStage,
}) => {
	return (
		<Dropdown>
			<Dropdown.Toggle variant="link" id={"dropdown-basic-"} data-testid="specificationActionMenuToggleBtn">
				<BiDotsVerticalRounded />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<ShowForPermission permissions={[QR_SPECIFICATION_VIEW, QR_SPECIFICATION_VIEW_HISTORY]}>
					<><Dropdown.Item
						data-testid="viewAction"
						onClick={() => openViewPage(specification.id)}
					>
						View

					</Dropdown.Item>
					</>
				</ShowForPermission>
				{specification.stage !== "draft" && specification.status === "rejected" ?
					<>
					<Dropdown.Divider />
					<Dropdown.Item
						data-testid="testAction"
						onClick={() => moveToBackStage(specification.id)}
					>
						Move To Draft

					</Dropdown.Item>
					</>
					: null}

				{specification.stage === "published" ?
					<>
						<ShowForPermission permissions={[QR_SPECIFICATION_MOVE_TO_REVIEW]}>
							<Dropdown.Divider />
							<Dropdown.Item
								data-testid="testAction"
								onClick={() => moveToInReviewStage(specification.id)}
							>
								Move To In-review

							</Dropdown.Item>
						</ShowForPermission>
					</>
					: null}

				{specification.stage === "testing" && specification.status !== "rejected" ?
					<>
						<ShowForPermission permissions={[QR_SPECIFICATION_MOVE_TO_REVIEW]}>
							<Dropdown.Divider />
							<Dropdown.Item
								data-testid="testAction"
								onClick={() => goToTest(specification.id)}
							>
								Test

							</Dropdown.Item>
						</ShowForPermission>
					</>
					: null}
				{specification.stage === "in-review" && specification.status !== "rejected" ?
					<ShowForPermission permissions={[QR_SPECIFICATION_MOVE_TO_PUBLISH]}>
						<>
							<Dropdown.Divider />
							<Dropdown.Item
								data-testid="reviewAction"
								onClick={() => goToReview(specification.id)}
							>
								Review

							</Dropdown.Item>
						</>
					</ShowForPermission>
					: null}
				{specification.stage === "draft" ?
					<ShowForPermission permissions={[QR_SPECIFICATION_UPDATE]}>
						<>
							<Dropdown.Divider />
							<Dropdown.Item
								data-testid="editAction"
								onClick={() => goToEdit(specification)}
							>
								Edit
							</Dropdown.Item>
						</>
					</ShowForPermission>
					: null}
				<ShowForPermission permissions={[QR_SPECIFICATION_CREATE_VERSION]}>
					<Dropdown.Divider />
					<Dropdown.Item
						data-testid="createVersionAction"
						onClick={() => createNewVersion(specification.id, specification.version?.specificationId)}
					>
						Create New Version
					</Dropdown.Item>
				</ShowForPermission>
				{specification.isActive ? (
					<>
						<ShowForPermission permissions={[QR_SPECIFICATION_ACTIVATE]}>
							<Dropdown.Divider />
							<Dropdown.Item
								data-testid="deactivateAction"
								onClick={() =>
									openActivityModal(true, isDeactivationFlow, specification)
								}
							>
								Deactivate
							</Dropdown.Item>
						</ShowForPermission>
					</>
				) : (
					<ShowForPermission permissions={[QR_SPECIFICATION_DEACTIVATE]}>
						<Dropdown.Divider />
						<Dropdown.Item
							data-testid="activateAction"
							onClick={() =>
								openActivityModal(true, isDeactivationFlow, specification)
							}
						>
							Reactivate
						</Dropdown.Item>
					</ShowForPermission>
				)}
				{(specification.stage === "testing" || specification.stage === "published") && specification.isActive ?
					<>
						<Dropdown.Divider />
						<Dropdown.Item
							data-testid="exportAsJsonAction"
							onClick={() => exportJson(specification)}
						>
							Export JSON
						</Dropdown.Item>
					</>
					: null}


				<ShowForPermission permissions={[QR_SPECIFICATION_CREATE]}>
					<Dropdown.Divider />
					<Dropdown.Item
						data-testid="cloneSpecificationAction"
						onClick={() => cloneSpecification(specification)}
					>
						Clone
					</Dropdown.Item>
				</ShowForPermission>
			</Dropdown.Menu>
		</Dropdown>
	);
};

const mapStateToProps = (state) => {
	return {
		userGroups: state.profile.groups,
	};
};

export default connect(mapStateToProps, null)(SpecificationActionMenu);
