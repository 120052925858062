import React from "react";
import { Spinner } from 'react-bootstrap';

const Loader = () => {
  return (
    <div data-testid="loader" className="loader-container">
      <Spinner className="loader" animation="border" variant="danger" />
    </div>
  );
};

export default Loader;
