import React, { memo, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { showLoader } from "../../redux/actions/templateActions";
import { useForm } from "react-hook-form";

const ConfirmationInReviewModal = ({
  show,
  toggleShow,
  proceedConfirmation,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "all",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    clearErrors();
    reset();
  }, [show]);

  const setHandleSubmit = (data) => {
      if (data.inreviewConfirmation.toLowerCase() !== "inreview") {
        setError("inreviewConfirmation", {
          type: "manual",
          message: "Please type the correct wording for back to in-review confirmation",
        });
        return false;
      } else {
        clearErrors("inreviewConfirmation");
        proceedConfirmation(true);
        return true;
      }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => toggleShow(false)}
        backdrop="static"
        size="lg"
        dialogClassName="modal-template"
        data-testid="note-modal"
      >
        <Modal.Header>
          <Modal.Title data-testid="modal-title" id="modal-title">
            Confirmation
          </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit((data) => setHandleSubmit(data))}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>
                To confirm back to in-review, type <b>inreview</b> in the text
                input field
              </Form.Label>
              <Form.Control
                id="inreviewConfirmation"
                placeholder={"Type here"}
                data-testid="inreviewConfirmation"
                {...register("inreviewConfirmation")}
                autoComplete="off"
                isInvalid={errors["inreviewConfirmation"]}
              />
              <Form.Control.Feedback
                id="inreviewConfirmationErrors"
                data-testid="inreviewConfirmationErrorMsg"
                type="invalid"
              >
                {errors["inreviewConfirmation"]?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-between flex-row">
            <Button
              data-testid="btnCancel"
              variant="primary"
              className="btn-cancel mx-3"
              onClick={() => toggleShow(false)}
            >
              Cancel
            </Button>

            <div className="d-flex align-items-center justify-content-between">
              <Button
                variant="primary"
                name="saveChanges"
                data-testid="btnNext"
                type="submit"
              >
                Proceed
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: (data) => dispatch(showLoader(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(ConfirmationInReviewModal));
