import { OverlayTrigger, Tooltip } from "react-bootstrap";

const OverlayToolTip = ({ children, stage, status }) => {
    return (
        <OverlayTrigger
            overlay={
                <Tooltip>
                    {stage + " : " + status}
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    )
};
export default OverlayToolTip;