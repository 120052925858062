import React from "react";
import { Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { connect } from "react-redux";
import { ShowForPermission } from "../ShowForPermission";
import { USER_EDIT, USER_DEACTIVATE, USER_ACTIVATE, USER_RESEND_INVITE, USER_RESET_PASSWORD, USER_RESET_MFA } from "../../utils/permissionList";

const UserActionMenu = ({
  openDeactivationForm,
  openResetPasswordForm,
  isDeactivationFlow,
  openUserEditForm,
  resendUserInvite,
  openViewModal,
  resetUserMFA,
  username,
  user,
  userGroups,
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" id={"dropdown-basic-" + user.id} data-testid="toggle-btn">
        <BiDotsVerticalRounded />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          data-testid="viewAction"
          href="#/reset-password"
          onClick={() => openViewModal(true, user)}
        >
          View
        </Dropdown.Item>

        {isDeactivationFlow ? (
          <>
            <ShowForPermission permissions={[USER_RESET_PASSWORD]}>
              <Dropdown.Divider />
              <Dropdown.Item
                data-testid="resetPasswordAction"
                href="#/reset-password"
                onClick={() => openResetPasswordForm(true, user)}
              >
                Reset Password
              </Dropdown.Item>
            </ShowForPermission>
            
            <ShowForPermission permissions={[USER_EDIT]}>
              <Dropdown.Divider />
              <Dropdown.Item
                data-testid="editAction"
                href="#/edit"
                onClick={() => openUserEditForm(true, username, user)}
              >
                Edit
              </Dropdown.Item>
            </ShowForPermission>
            
            <ShowForPermission permissions={[USER_DEACTIVATE]}>
              <Dropdown.Divider />
              <Dropdown.Item
                data-testid="deactivateAction"
                href="#/deactivate"
                onClick={() =>
                  openDeactivationForm(true, isDeactivationFlow, username)
                }
              >
                Deactivate User
              </Dropdown.Item>
            </ShowForPermission>
            
            <ShowForPermission permissions={[USER_RESET_MFA]} >
              {
                user.totpStatus ?
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      data-testid="mfaResetAction"
                      href="#/deactivate"
                      onClick={() =>
                        resetUserMFA(user)
                      }
                    >
                      Reset MFA
                    </Dropdown.Item>
                  </> : null}
            </ShowForPermission>
            
            {user.status === "FORCE_CHANGE_PASSWORD" ? (
              <ShowForPermission permissions={[USER_RESEND_INVITE]}>
                <Dropdown.Divider />
                <Dropdown.Item
                  data-testid="resendInviteAction"
                  href="#/resend-invite"
                  onClick={() =>
                    resendUserInvite(user)
                  }
                >
                  Resend Invite
                </Dropdown.Item>
              </ShowForPermission>
              
            ) : null}
          </>
        ) : (
          <ShowForPermission permissions={[USER_ACTIVATE]}>
            <Dropdown.Divider />
            <Dropdown.Item
              data-testid="reactivateAction"
              href="#/reactivate"
              onClick={() =>
                openDeactivationForm(true, isDeactivationFlow, username)
              }
            >
              Reactivate User
            </Dropdown.Item>
          </ShowForPermission>
        )}

      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => {
  return {
    userGroups: state.profile.groups,
  };
};

export default connect(mapStateToProps, null)(UserActionMenu);
