import axios from "../../apis/qrCore";
import { v4 as uuidv4 } from "uuid";

import {
  FETCH_TRANSACTION_TYPE,
  CREATE_TRANSACTION_TYPE,
  UPDATE_TRANSACTION_TYPE,
  DEACTIVATE_TRANSACTION_TYPE,
  ACTIVATE_TRANSACTION_TYPE,
} from "./actionTypes";

const TRANSACTION_TYPES_ENDPOINT = "transaction-types";

export const getTransactionTypes = (filters) => async (dispatch, getState) => {
  function onSuccess(response) {
    dispatch({
      type: FETCH_TRANSACTION_TYPE,
      payload: response,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: FETCH_TRANSACTION_TYPE,
      payload: error.data,
    });
    throw error;
  }
  try {
    const response = await axios.get(`${TRANSACTION_TYPES_ENDPOINT}`, {
      headers: {
        "x-correlation-id": 1,
        "x-source-system": "mpa-qr-code-web-app",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: filters,
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const createTransactionType =
  (transactionType) => async (dispatch, getState) => {
    function onSuccess(response) {
      dispatch({
        type: CREATE_TRANSACTION_TYPE,
        payload: response.data,
      });
      return response;
    }
    function onError(error) {
      dispatch({
        type: CREATE_TRANSACTION_TYPE,
        payload: error.data,
      });
      throw error;
    }
    try {
      const response = await axios.post(
        `${TRANSACTION_TYPES_ENDPOINT}`,
        transactionType,
        {
          headers: {
            "x-correlation-id": uuidv4(),
            "x-source-system": "mpa-qr-code-web-app",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };

export const updateTransactionType =
  (transactionType) => async (dispatch, getState) => {
    function onSuccess(success) {
      dispatch({
        type: UPDATE_TRANSACTION_TYPE,
        payload: success.data,
      });
      return success;
    }
    function onError(error) {
      dispatch({
        type: UPDATE_TRANSACTION_TYPE,
        payload: error,
      });
      throw error;
    }
    try {
      const response = await axios.put(
        `${TRANSACTION_TYPES_ENDPOINT}/`,
        transactionType,
        {
          headers: {
            "x-correlation-id": uuidv4(),
            "x-source-system": "mpa-qr-code-web-app",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };

export const activateOrDeactivateTransactionType =
  (data) => async (dispatch, getState) => {
    let config = {};
    if (data.isActive) {
      config = {
        method: "put",
        url: `${TRANSACTION_TYPES_ENDPOINT}/${data.id}/deactivate`,
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      function onSuccess(success) {
        dispatch({
          type: DEACTIVATE_TRANSACTION_TYPE,
          payload: success.data,
        });
        return success;
      }
      function onError(error) {
        dispatch({
          type: DEACTIVATE_TRANSACTION_TYPE,
          payload: error,
        });
        throw error;
      }

      try {
        const response = await axios(config);
        return onSuccess(response);
      } catch (error) {
        return onError(error);
      }
    } else {
      config = {
        method: "put",
        url: `${TRANSACTION_TYPES_ENDPOINT}/${data.id}/activate`,
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": "mpa-qr-code-web-app",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      function onSuccess(success) {
        dispatch({
          type: ACTIVATE_TRANSACTION_TYPE,
          payload: success.data,
        });
        return success;
      }
      function onError(error) {
        dispatch({
          type: ACTIVATE_TRANSACTION_TYPE,
          payload: error,
        });
        throw error;
      }

      try {
        const response = await axios(config);
        return onSuccess(response);
      } catch (error) {
        return onError(error);
      }
    }
  };
