import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Form, Table } from "react-bootstrap";
import { showLoader } from "../../../../redux/actions/commonActions";
import { AiFillFileText } from "react-icons/ai";
import { toast } from "react-toastify";
import {
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACTION,
} from "../../../../utils/errorMessages";
import {
  getQRSpecificationNotes,
  getQRSpecification,
  getPresignedUrl,
  rejectQRSpecification,
  publishQRSpecification,
  moveToBackQRSpecification,
} from "../../../../redux/actions/qrSpecificationActions";
import { useHistory } from "react-router-dom";
import { formatBytes } from "../../../../utils/common";
import ActionDropdown from "../../../../components/ActionDropdown";
import RejectQRModal from "../../../../components/modals/RejectQRModal";
import NotesModal from "../../../../components/modals/NotesModal";
import { formatDate } from "../../../../utils/timeFormat";
import {
  TESTING,
  PUBLISH,
  REJECT,
  REVIEW,
} from "../../../../utils/qrSpecStageColors";

const InReview = ({
  selectedSpec,
  selectedId,
  getQRSpecificationNotes,
  getQRSpecification,
  qrComments,
  getPresignedUrl,
  showLoader,
  rejectQRSpecification,
  publishQRSpecification,
  moveToBackQRSpecification,
}) => {
  const history = useHistory();
  useEffect(() => {
    async function fetchQRSpecifications() {
      try {
        showLoader(true);
        await getQRSpecification(selectedId);
        await getQRSpecificationNotes({
          id: selectedId,
          transitionType: "ToReview",
        });
        showLoader(false);
      } catch (e) {
        if (e.response) {
          if (e.response.status && e.response.status === 403) {
            toast.error(UNAUTHORIZED_ACTION);
          } else if (e.response.data.Description) {
            toast.error(e.response.data);
          }
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        showLoader(false);
      }
    }
    fetchQRSpecifications();
  }, [showLoader, getQRSpecificationNotes, selectedId]);

  const [selectedActionItem, setSelectedActionItem] = useState("Review");
  const [selectedItemTitle, setSelectedItemTitle] = useState("Review");

  const [publishNote, setPublishNote] = useState("");

  const [actionMenuItems] = useState([
    {
      title: "Review to Publish",
      badge: "PUBLISH",
      bgColor: "#6fb973",
      type: "publish",
      iconType: "FORWARD",
      selectedTitle: "Publish",
    },
    {
      title: "Move to Testing",
      badge: "TEST",
      bgColor: "#e3b748",
      type: "move",
      iconType: "BACKWARD",
      selectedTitle: "Test",
    },
    {
      title: "Transition to",
      badge: "REJECT",
      bgColor: "#e74c3c",
      type: "reject",
      iconType: "FORWARD",
      selectedTitle: "Reject",
    },
  ]);

  useEffect(() => {
    if (qrComments.length > 0) {
      qrComments.reverse();
    }
  }, [qrComments]);

  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);

  const downloadFile = async (file) => {
    showLoader(true);
    await getPresignedUrl(file);
  };

  const toggleRejectModalShow = (status) => {
    if(status === false){
      setSelectedActionItem("review");
      setSelectedItemTitle("Review");
    }
    setShowRejectModal(status);
  };

  const handleClickActionItem = (type) => {
    setSelectedActionItem(type)
    switch (type) {
      case "reject":
        toggleRejectModalShow(true);
        break;
      case "publish":
        toggleShow(true);
        break;
      case "move":
        moveToBackStage();
        break;
      default:
        break;
    }
  };

  const toggleShow = (status) => {
    if(status === false){
      setSelectedActionItem("review");
      setSelectedItemTitle("Review");
    }
    setShowNotesModal(status);
  };

  const goToQRListPage = () => {
    history.push("/settings/qr-specs");
  };

  const submitForReject = async (reason) => {
    toggleRejectModalShow(false);

    try {
      showLoader(true);
      await rejectQRSpecification({
        id: selectedId,
        stage: selectedSpec.stage,
        status: selectedSpec.status,
        comment: reason,
      });
      toast.success("QR Specification rejected successfully");
      goToQRListPage();
      showLoader(false);
    } catch (e) {
      showLoader(false);
      setSelectedActionItem("review");
      setSelectedItemTitle("Review");
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data.Description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
  };

  const submitForPublish = async () => {
    toggleShow();

    try {
      showLoader(true);
      await publishQRSpecification({
        id: selectedId,
        stage: selectedSpec.stage,
        status: selectedSpec.status,
        comment: publishNote,
      });
      toast.success("QR Specification published successfully");
      goToQRListPage();
      showLoader(false);
    } catch (e) {
      showLoader(false);
      setSelectedActionItem("review");
      setSelectedItemTitle("Review");
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data.Description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
  };

  const moveToBackStage = async () => {
    try {
      showLoader(true);
      await moveToBackQRSpecification({
        id: selectedId,
        currentStage: "in-review",
        toStage: "testing",
      });
      toast.success("QR Specification moved to the testing stage.");
      goToQRListPage();
      showLoader(false);
    } catch (e) {
      showLoader(false);
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data.Description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
  };

  const renderNoteStage = (stage) => {
    switch (stage) {
      case "ToTesting":
        return (
          <div className="stage-text" style={{ color: TESTING }}>
            ( Testing )
          </div>
        );
      case "ToReview":
        return (
          <div className="stage-text" style={{ color: REVIEW }}>
            ( Review )
          </div>
        );
      case "ToRejected":
        return (
          <div className="stage-text" style={{ color: REJECT }}>
            ( Rejected )
          </div>
        );
      case "ToPublished":
        return (
          <div className="stage-text" style={{ color: PUBLISH }}>
            ( Publish )
          </div>
        );
      default:
        return "";
    }
  };

  return (
    <div className="page test-qr-page">
      <RejectQRModal
        show={showRejectModal}
        toggleShow={toggleRejectModalShow}
        submitForReject={submitForReject}
      />

      <NotesModal
        type="publish"
        show={showNotesModal}
        toggleShow={toggleShow}
        addNote={submitForPublish}
        note={publishNote}
        handleChangeNote={setPublishNote}
      />

      <div className="breadcrumb-wrapper" data-testid="breadcrumb">
        QR Specifications / Review Specification
      </div>
      <div className="page-title-row w-100">
        <div className="header-title-wrapper w-100 d-flex justify-content-between">
          <h3 data-testid="titleHeader">
            Review QR Specification -{" "}
            {selectedSpec.template && selectedSpec.template}
          </h3>
          <ActionDropdown
            title={selectedItemTitle}
            actionMenuItems={actionMenuItems}
            onChangeActionItem={handleClickActionItem}
            selectedActionItem={selectedActionItem}
            selectedItemTitle={selectedItemTitle}
            setSelectedItemTitle={setSelectedItemTitle}
          />
        </div>
      </div>

      <Form className="form-2 page-demo" id="review-form">
        <div className="">
        <Row>
          <Col>
            <div data-testid="notesAccordian">
              {qrComments.length > 0 &&
                qrComments.map((comment, idx) => {
                  return (
                    <div key={idx}>
                      {idx === 0 ? (
                        <h5>
                          All Notes
                        </h5>
                      ) : null}
                      <div className="noteWrapper review-table">
                        <div className="noteBody" data-testid="notesBody">
                        {renderNoteStage(comment.transitionType)}{" "}
                          {idx === 0 ? (
                            <div>
                              <span
                                className="current-status-text badge text-dark bg-light"
                                // style={{ color: PUBLISH }}
                              >
                              CURRENT
                              </span>
                            </div>
                          ) : null}
                          <div key={idx} className="singleNoteWrapper">
                            <div className="info">
                              <div className="date-wrapper">
                                <strong>
                                  Date :{" "}
                                  {formatDate(comment.createdDate)}
                                </strong>
                              </div>
                              <div className="comment-text">
                                <strong>NOTE : </strong> {comment.comment}
                              </div>
                            </div>

                            {comment.transitionType !== "ToRejected" && (
                              <div className="file-wrapper">
                                <Row className="download-all-section">
                                  <Col className="text-start">
                                    <div className="b-detail">
                                      <h4 data-testid="documentListTitle">
                                        Test Result Documents
                                      </h4>
                                    </div>
                                  </Col>
                                </Row>

                                <div>
                                  <Table data-testid="documentsTable">
                                    <thead>
                                      <tr>
                                        <th data-testid="table-header-docName">
                                          Document Name
                                        </th>
                                        <th
                                          data-testid="table-header-docSize"
                                          className="text-center"
                                        >
                                          Size
                                        </th>
                                        <th> </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {comment.files !== null ? (
                                        comment.files.map((file, idx) => (
                                          <tr key={idx}>
                                            <td className="set-min-width">
                                              <AiFillFileText className="doc-icon" />{" "}
                                              {file.fileName}
                                            </td>
                                            <td className="text-center">
                                              {formatBytes(file.fileSize)}
                                            </td>
                                            <td className="text-end">
                                              <Button
                                                variant="secondary-outline"
                                                onClick={() =>
                                                  downloadFile(file)
                                                }
                                              >
                                                Download
                                              </Button>
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <div className="noData">
                                          No uploaded documents
                                        </div>
                                      )}
                                    </tbody>
                                    <tbody></tbody>
                                  </Table>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {idx === 0 ? (
                        <hr className="mb-5"/>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          </Col>
        </Row>
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedSpec: state.qrSpecifiction.current,
    selectedId: state.qrSpecifiction.selectedId,
    qrComments: state.qrSpecifiction.notes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: (data) => dispatch(showLoader(data)),
  getQRSpecificationNotes: (data) => dispatch(getQRSpecificationNotes(data)),
  getQRSpecification: (data) => dispatch(getQRSpecification(data)),
  getPresignedUrl: (url) => dispatch(getPresignedUrl(url)),
  rejectQRSpecification: (data) => dispatch(rejectQRSpecification(data)),
  publishQRSpecification: (data) => dispatch(publishQRSpecification(data)),
  moveToBackQRSpecification: (data) =>
    dispatch(moveToBackQRSpecification(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InReview);
