import React,{useEffect} from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import { showLoader } from "../../redux/actions/commonActions";
import { createMarket,updateMarket } from "../../redux/actions/marketActions";
import { toast } from "react-toastify";
import {
  CREATE_MARKET_SUCCESS,
  UPDATE_MARKET_SUCCESS,
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACTION,
} from "../../utils/errorMessages";
import {regexForNames} from "../../utils/common";

let createMarketSchema = yup.object().shape({
  name: yup.string().matches(regexForNames, "Allows only a-z, A-Z, 0-9, space, - and _").required("Market name is required").max(50, "Name must be at most 20 characters"),
  shortCode2: yup.string().required("Two letters Code is required").min(2, "Minimum is two letter").max(2, "Maximum is two letter"),
  shortCode3: yup.string().required("Three letters Code is required").min(3, "Minimum is three letter").max(3, "Maximum is three letter"),
  numericShortCode: yup.string().required("Numeric ShortCode is required").matches(/^[0-9]+$/, "Must be only digits").max(4, "Maximum is four digits"),
  description: yup.string().max(50, "Description must be at most 50 characters")
});

const CreateMarketModal = ({
  show,
	toggleShow,
	selectedMarket,
	showLoader,
	getMarketList,
	updateMarket,
	isEditFlow = false,
	createMarket,
	market,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(createMarketSchema),
    mode: "onChange",
  });

  useEffect(() => {
		async function initModal() {
			if (isEditFlow) {
				reset(market)
			} else {
				reset({});
			}
		}
		initModal()
	}, [reset, isEditFlow, market, selectedMarket, showLoader,show]);

  const handleToggleShow = () => {
    toggleShow();
    reset({});
  };

  const handleCreateMarket = async (data) => {
    showLoader(true);
    try {
      if (isEditFlow) {
				data = { ...data }
				await updateMarket(data);
				toast.success(UPDATE_MARKET_SUCCESS);
			} else {
				data = { ...data, isActive: true }
				await createMarket(data);
				toast.success(CREATE_MARKET_SUCCESS);
			}
			await getMarketList();

			handleToggleShow();
			showLoader(false);
			reset();
    } catch (e) {
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data.Description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
      showLoader(false);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleToggleShow}
        backdrop="static"
        keyboard={false}
        size="md"
        data-testid="market-modal"
      >
        <Modal.Header>
          <Modal.Title data-testid="modal-title" id="modal-title">{isEditFlow ? "Edit" : "Create"} Market</Modal.Title> 
        </Modal.Header>
        <Form onSubmit={handleSubmit(handleCreateMarket)}>
          <Modal.Body>
            <Form.Label> Name* </Form.Label>
            <Form.Control
              data-testid="name"
              type="string"
              {...register("name", { required: true })}
              autoComplete="off"
              placeholder="Market Name"
              isInvalid={errors.name}
            />
            <Form.Control.Feedback id="nameErrors" data-testid="nameErrors" type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
            <br /> <Form.Label>2 Letter Code* </Form.Label>
            <Form.Control
              data-testid="twoLetterCode"
              type="string"
              {...register("shortCode2", { required: true })}
              autoComplete="off"
              placeholder="Two Letter Code"
              isInvalid={errors.shortCode2}
            />
            <Form.Control.Feedback id="twoLetterCodeErrors" data-testid="twoLetterCodeErrors" type="invalid">
              {errors.shortCode2?.message}
            </Form.Control.Feedback>
            <br /> <Form.Label>3 Letter Code* </Form.Label>
            <Form.Control
              data-testid="threeLetterCode"
              type="string"
              {...register("shortCode3", { required: true })}
              autoComplete="off"
              placeholder="Three Letter Code"
              isInvalid={errors.shortCode3}
            />
            <Form.Control.Feedback id="threeLetterCodeErrors" data-testid="threeLetterCodeErrors"  type="invalid">
              {errors.shortCode3?.message}
            </Form.Control.Feedback>
            <br /> <Form.Label>Numeric ShortCode* </Form.Label>
            <Form.Control
              data-testid="numericShortCode"
              type="string"
              {...register("numericShortCode", { required: true })}
              autoComplete="off"
              placeholder="Numeric Short Code"
              isInvalid={errors.numericShortCode}
            />
            <Form.Control.Feedback id="numericShortCodeErrors" data-testid="numericShortCodeErrors" type="invalid">
              {errors.numericShortCode?.message}
            </Form.Control.Feedback>
            <br /> <Form.Label>Description</Form.Label>
            <Form.Control
              data-testid="description"
              type="string"
              {...register("description", { required: true })}
              autoComplete="off"
              placeholder="Description"
              isInvalid={errors.description}
            />
            <Form.Control.Feedback id="descriptionErrors" type="invalid">
              {errors.description?.message}
            </Form.Control.Feedback>
          </Modal.Body>
          <Modal.Footer>
            <Button
              data-testid="btnCancel"
              variant="primary"
              className="btn-cancel"
              onClick={() => handleToggleShow()}
            >
              Cancel
            </Button>

            <Button
              variant="primary"
              type="submit"
              name="next"
              data-testid="btnSave"
              className="ms-3"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: (data) => dispatch(showLoader(data)),
  updateMarket: (data) => dispatch(updateMarket(data)),
  createMarket: (data) => dispatch(createMarket(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMarketModal);
