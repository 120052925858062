import React, { useEffect } from "react";
import { Modal, Button, Container, Form, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCanvas } from "../../context/CanvasContext";
import { connect } from "react-redux";
import {
    showLoader
} from "../../redux/actions/templateActions";

let previewTemplateSchema = yup.object().shape({
    creditPartyIdentifier: yup
        .string(),
    creditPartyName: yup
        .string()
        .nullable()
});

const PreviewTemplateModal = ({
    show,
    toggleShow,
    template,
    showLoader
}) => {
    const { previewTemplate, previewCanvasRef } = useCanvas();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: { creditPartyName: "Credit Party Name" },
        resolver: yupResolver(previewTemplateSchema),
        mode: "onChange",
    });
    useEffect(() => {
        if (show) {
            let creditPartyIdentifierValue = "";
            let creditPartyNameValue = "";
            const alphabet = "abcdefghijklmnopqrstuvwxyz";
            const numeric = "123456789123456789123456789";

            if (template.validations.cpiFormat.hasAlphabets && !template.validations.cpiFormat.hasNumbers) {
                creditPartyIdentifierValue = alphabet.substring(0, template.validations.cpiLength)
            } else if (!template.validations.cpiFormat.hasAlphabets && template.validations.cpiFormat.hasNumbers) {
                creditPartyIdentifierValue = numeric.substring(0, template.validations.cpiLength)
            } else if (template.validations.cpiFormat.hasAlphabets && template.validations.cpiFormat.hasNumbers) {
                creditPartyIdentifierValue = alphabet.substring(0, template.validations.cpiLength)
            }


            if (template.validations.cpnFormat.hasAlphabets && !template.validations.cpnFormat.hasNumbers) {
                creditPartyNameValue = alphabet.substring(0, template.validations.cpnLength)
            } else if (!template.validations.cpnFormat.hasAlphabets && template.validations.cpnFormat.hasNumbers) {
                creditPartyNameValue = numeric.substring(0, template.validations.cpnLength)
            } else {
                creditPartyNameValue = alphabet.substring(0, template.validations.cpnLength)
            }
            reset({ creditPartyIdentifier: creditPartyIdentifierValue, creditPartyName: creditPartyNameValue })
            previewTemplate(creditPartyIdentifierValue, creditPartyNameValue);
        }
    }, [previewTemplate, template, show, reset]);


    const handleToggleShow = () => {
        toggleShow();
        reset();
    };

    const handleChange = (data) => {
        showLoader(true);
        previewTemplate(data.creditPartyIdentifier, data.creditPartyName)
        setTimeout(function () {
            showLoader(false);
        }, 2000)

    };

    return (

        <Modal
            id="full-screen-modal"
            show={show}
            onHide={handleToggleShow}
            backdrop="static"
            keyboard={false}
            fullscreen
        >
            <Modal.Header>
                <Modal.Title data-testid="modal-title" id="modal-title" className="m-auto mb-4">
                    Preview Template
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={handleSubmit(handleChange)} className="preview-temp">
                    <Row>
                        <Col md="4">
                            <Form.Group className="mb-3" controlId="creditPartyIdentifier">
                                <Form.Label>Credit Party Identifier *</Form.Label>
                                <Form.Control placeholder="Credit Party Identifier" autoComplete="false" isInvalid={errors.creditPartyIdentifier} {...register("creditPartyIdentifier", { required: true })} />
                                <Form.Control.Feedback id="cpiErrors" type="invalid">
                                    {errors.creditPartyIdentifier?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Form.Group className="mb-3" controlId="creditPartyName">
                                <Form.Label>Credit Party Name *</Form.Label>
                                <Form.Control placeholder="Credit Party Name" isInvalid={errors.creditPartyName} {...register("creditPartyName", { required: true })} />
                                <Form.Control.Feedback id="cpnErrors" type="invalid">
                                    {errors.creditPartyName?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Button variant="primary" type="submit" name="submit" data-testid="btnUpdate" className="update-btn">
                                Update
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <Container className="preview-canvas-container">
                    <canvas ref={previewCanvasRef} id="preview-canvas"></canvas>
                </Container>
            </Modal.Body>
            <Modal.Footer className="m-auto">
                <Button
                    data-testid="btnCancel"
                    variant="primary"
                    className="btn-cancel mx-3"
                    onClick={() => handleToggleShow()}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        template: state.templates.createTemplateData,
    };
};

const mapDispatchToProps = (dispatch) => ({
    showLoader: (data) => dispatch(showLoader(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewTemplateModal);
