import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Form,
  Table,
  Col,
  InputGroup,
  FormControl,
  Row,
  Button,
  Badge,
  Pagination
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import RoleTableActionMenu from "../../../components/action-menus/RoleTableActionMenu";
import { AiOutlinePlus } from "react-icons/ai";
import { SOMETHING_WENT_WRONG, UNAUTHORIZED_ACTION } from "../../../utils/errorMessages";

import {
  changePage,
} from "../../../redux/actions/userActions";

import { showLoader } from "../../../redux/actions/commonActions";
import { fetchRoles, setIsEditFlow, setSelectedRoleForEdit, activateOrDeactivateRole } from "../../../redux/actions/roleActions";
import { ShowForPermission } from "../../../components/ShowForPermission";
import { ROLE_CREATE } from "../../../utils/permissionList";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { capitalizeFirstLetter } from "../../../utils/stringFormat";

const Roles = ({
  fetchRoles,
  rolesList = [],
  setIsEditFlow,
  setSelectedRoleForEdit,
  noOfPages,
  currentPage,
  showLoader,
  isLoading,
  activateOrDeactivateRole
}) => {

  const [selectedRole, setSelectedRole] = useState(-1);
  const [searchTerm, setSearchTerm] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [timeoutId, setTimeoutId] = useState(null);
  const history = useHistory();
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 15
  });

  const [showConfirmation, setShowConfirmation] = useState(false);
  const toggleRoleActivityModal = (role) => {
    setSelectedRole(role);
    setShowConfirmation(true)
  };

  useEffect(() => {
    async function fetchList() {
      try {
        showLoader(true);
        await fetchRoles(pageSize, filters.page, searchTerm, false);
      } catch (e) {
        if (e.response.status && e.response.status === 403) {
          toast.error(UNAUTHORIZED_ACTION);
        } else if (e.response.data.Description) {
          toast.error(e.response.data.Description);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
      } finally {
        showLoader(false);
      }
    }
    fetchList();
  }, [searchTerm, fetchRoles, pageSize, filters, showLoader]);

  const handleChangePageSize = (size) => {
    setPageSize(size);
    setFilters({ ...filters, page: 1 });
  };

  const OnSubmit = (e) => {
    e.preventDefault();
  };

  const OnChangeSearchTerm = (e) => {
    e.preventDefault();
    clearTimeout(timeoutId);
    const id = setTimeout(() => {
      setSearchTerm(e.target.value);
    }, 600);
    setTimeoutId(id);
  };

  const goToCreateRolePage = () => {
    setIsEditFlow(false);
    setSelectedRoleForEdit(null)
    history.push('/settings/roles/create')
  }


  const goToEditRolePage = (role) => {
    setIsEditFlow(true);
    setSelectedRoleForEdit(role);
    history.push('/settings/roles/edit')
  }

  const toggleSetShowConfirmation = () => setShowConfirmation((p) => !p);

  const renderUsers = rolesList.map((role) => {
    return (
      <tr key={role.id}>
        <td className="word-break">{role.roleName}</td>
        <td className="word-break">{role.roleDescription}</td>
        <td className="word-break">{capitalizeFirstLetter(role.market)}</td>
        <td>
          {role.isActive ? (
            <div className="status">
              <span className={role.isActive ? "active" : "inactive"}>
                Active
              </span>
            </div>
          ) : (
            <div className="status wrap-inactive">
              <span className="inactive">Inactive</span>
            </div>
          )}
        </td>
        <td>
          {role.isPreDefined ? (
            <Badge bg="light" text="dark">Predefined</Badge>

          ) : (
            <RoleTableActionMenu
              isDeactivationFlow={role.isActive}
              openEditRole={goToEditRolePage}
              role={role}
              toggleRoleActivity={toggleRoleActivityModal}
            />
          )}
        </td>
      </tr>
    );
  });

  let items = [];
  let max = 6;
  let start = currentPage - 3 <= 0 ? 1 : currentPage - 3;
  let end = start + max > noOfPages ? noOfPages : start + max;

  for (let number = start; number <= end; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setFilters({ ...filters, page: number })}
      >
        {number}
      </Pagination.Item>
    );
  }

  const handleRoleActivationAndDeactivation = () => {
    const data = { roleId: selectedRole.id, isActivated: selectedRole.isActive };

    isLoading(true);
    activateOrDeactivateRole(data)
      .then((result) => {
        fetchRoles(pageSize, filters.page, searchTerm)
          .then((result) => {
            toast.success(`Role ${selectedRole.isActive ? "Deactivated" : "Activated"}`);
            isLoading(false);
          })
          .catch((err) => {
            toast.error(SOMETHING_WENT_WRONG);
            isLoading(false);
          });
      })
      .catch((e) => {
        if (e.response.status && e.response.status === 403) {
          toast.error(UNAUTHORIZED_ACTION);
        } else if (e.response.data.Description) {
          toast.error(e.response.data.Description);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        isLoading(false);
      });
  };


  return (
    <div className="page user-page">
      <ConfirmationModal
        show={showConfirmation}
        toggleShow={toggleSetShowConfirmation}
        confirm={handleRoleActivationAndDeactivation}
        title={`Are you sure you want to ${selectedRole.isActive ? "Deactivate" : "Activate"
          } this template?`}
      />
      <div className="page-title-row">
        <div className="title">
          <h1>User Roles</h1>
        </div>
      </div>
      <div className="special">
        <div className="filter-section">
          <div className="filter-components">
            <Form className="form w-100" onSubmit={OnSubmit}>
              <Row className="align-items-center">
                <Col md="3" className="my-1">
                  <InputGroup>
                    <InputGroup.Text>
                      <IoSearchOutline />
                    </InputGroup.Text>
                    <FormControl
                      id="inlineFormInputGroupUsername"
                      placeholder="Search"
                      onChange={OnChangeSearchTerm}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <div className="btn-user">
          <ShowForPermission permissions={[ROLE_CREATE]}>
            <Button
              variant="primary"
              id="add-user-button"
              className="btn-special"
              onClick={goToCreateRolePage}
            >
              <AiOutlinePlus /> <span>Role</span>
            </Button>
          </ShowForPermission>
        </div>
      </div>

      <Table>
        <thead>
          <tr>
            <th>Role Name</th>
            <th>Description</th>
            <th>Market</th>
            <th>Status</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>{renderUsers}</tbody>
      </Table>
      <div className="footer-table d-flex justify-content-between">
        <div>
          <Form.Select onChange={(e) => handleChangePageSize(e.target.value)}>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="60">60</option>
          </Form.Select>
        </div>
        <div className="mt-2">{filters.page}</div>
        <div className="pagination">
          <Pagination size="sm">
            <Pagination.First
              onClick={() => setFilters({ ...filters, page: 1 })}
            />
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => setFilters({ ...filters, page: filters.page - 1 })}
            />
            {currentPage > 4 ? <Pagination.Ellipsis disabled /> : null}

            {items}
            {currentPage === noOfPages ? null : (
              <Pagination.Ellipsis disabled />
            )}
            <Pagination.Next
              disabled={currentPage === noOfPages}
              onClick={() => setFilters({ ...filters, page: filters.page + 1 })}
            />
            <Pagination.Last
              onClick={() => setFilters({ ...filters, page: noOfPages })}
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    rolesList: state.role.list,
    currentPage: state.role.currentPage,
    noOfPages: state.role.noOfPages,
    totalCount: state.role.totalCount,
    sub: state.profile?.sub,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchRoles: (pageSize, page, searchTerm, fetchAll) => dispatch(fetchRoles(pageSize, page, searchTerm, fetchAll)),
  changePage: (data) => dispatch(changePage(data)),
  showLoader: (data) => dispatch(showLoader(data)),
  setIsEditFlow: (flag) => dispatch(setIsEditFlow(flag)),
  setSelectedRoleForEdit: (data) => dispatch(setSelectedRoleForEdit(data)),
  isLoading: (flag) => dispatch(showLoader(flag)),
  activateOrDeactivateRole: (data) => dispatch(activateOrDeactivateRole(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
