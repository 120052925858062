import React, { memo, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { showLoader } from "../../redux/actions/templateActions";
import { useForm } from "react-hook-form";

const NotesModal = ({
  type,
  show,
  toggleShow,
  note,
  notes,
  addNote,
  handleChangeNote,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "all",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    clearErrors();
    reset();
    type === "publish" && handleChangeNote("");
  }, [show]);

  const handleChange = (e) => {
    handleChangeNote(e.target.value);
  };

  const setHandleSubmit = (data) => {
    if (type === "publish") {
      if (data.publishConfirmation.toLowerCase() !== "publish") {
        setError("publishConfirmation", {
          type: "manual",
          message: "Please type the correct wording for publish confirmation",
        });
        return false;
      } else {
        clearErrors("publishConfirmation");
        addNote(note);
        return true;
      }
    } else {
      addNote(note);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => toggleShow(false)}
        backdrop="static"
        size="lg"
        dialogClassName="modal-template"
        data-testid="note-modal"
      >
        <Modal.Header>
          <Modal.Title data-testid="modal-title" id="modal-title">
            Notes
          </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit((data) => setHandleSubmit(data))}>
          <Modal.Body>
            {type !== "view" ? (
              <>
                {type === "publish" && (
                  <Form.Group className="mb-3">
                    <Form.Label>
                      To confirm publish, type <b>publish</b> in the text input
                      field
                    </Form.Label>
                    <Form.Control
                      id="publishConfirmation"
                      placeholder={"Type here"}
                      data-testid="publishConfirmation"
                      {...register("publishConfirmation")}
                      autoComplete="off"
                      isInvalid={errors["publishConfirmation"]}
                    />
                    <Form.Control.Feedback
                      id="publishConfirmationErrors"
                      data-testid="publishConfirmationErrorMsg"
                      type="invalid"
                    >
                      {errors["publishConfirmation"]?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                <Form.Group className="mb-3">
                  {type !== "view" && (
                    <Form.Label>Enter Notes (optional)</Form.Label>
                  )}
                  <Form.Control
                    id="note"
                    value={note}
                    as="textarea"
                    placeholder={type !== "view" ? "Type here" : ""}
                    rows={5}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              <div>
                {notes.length > 0 ? (
                  notes.map((note, index) => {
                    return (
                      <div key={index}>
                        {note.comment !== undefined &&
                          note.comment != null &&
                          note.comment !== "" ? (
                          <p>{note.comment}</p>
                        ) : (
                          <p>No notes</p>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p>No notes</p>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-between flex-row">
            <Button
              data-testid="btnCancel"
              variant="primary"
              className="btn-cancel mx-3"
              onClick={() => toggleShow(false)}
            >
              Cancel
            </Button>

            {type !== "view" && (
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  variant="primary"
                  name="saveChanges"
                  data-testid="btnNext"
                  type="submit"
                >
                  Proceed
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: (data) => dispatch(showLoader(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(NotesModal));
