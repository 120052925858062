import {
  RECEIVE_PROFILE_DATA,
  RECEIVE_MARKET_DATA,
  CLEAR_ALL,
} from "../actions/actionTypes";

const defaultState = {
  sub: null,
  email: null,
  firstName: null,
  lastName: null,
  username: null,
  company: null,
  dept: null,
  title: null,
  groups: [],
};

const profileReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case RECEIVE_PROFILE_DATA:
      let tmpMarkets = [];
      if (payload.response.data) {
        tmpMarkets = payload.response.data.markets.map((item) => {
          return {
            value: item.normalizedName,
            displayName: item.name,
            countryCode: item.numericShortCode,
            userGroup: item.userGroupName,
            isoCode: item.shortCode2,
          };
        });
      }
      let market = "unknown";
      const userGroups = payload.profile["cognito:groups"];
      if (userGroups === undefined) {
        market = "unknown";
      } else {
        for (let i = 0; i < tmpMarkets.length; i++) {
          if (userGroups.includes(tmpMarkets[i].userGroup)) {
            market = tmpMarkets[i].value;
            break;
          }
        }
      }
      return {
        sub: payload.profile.sub,
        email: payload.profile.email,
        firstName: payload.profile.name,
        lastName: payload.profile.family_name,
        username: payload.profile.preferred_username,
        company: payload.profile.company,
        dept: payload.profile.department,
        title: payload.profile.title,
        groups: payload.profile["cognito:groups"],
        market,
        markets: tmpMarkets,
      };
    case CLEAR_ALL:
      return null;
    case RECEIVE_MARKET_DATA:
      let markets = [];
      if (payload.response.data) {
        markets = payload.response.data.markets.map((item) => {
          return {
            value: item.normalizedName,
            displayName: item.name,
            countryCode: item.numericShortCode,
            userGroup: item.userGroupName,
            isoCode: item.shortCode2,
          };
        });
      }

      return {
        ...state,
        markets,
      };
    default:
      return state;
  }
};

export default profileReducer;
