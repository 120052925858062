import React from "react";
import { Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { ShowForPermission } from "../ShowForPermission";
import { NETWORK_UPDATE, NETWORK_DEACTIVATE, NETWORK_ACTIVATE } from "../../utils/permissionList";

const NetworkActionMenu = ({
  toggleNetworkActivity,
  openEditNetworkModal,
  network,

}) => {
  return (
    <Dropdown>
      <ShowForPermission permissions={[NETWORK_UPDATE, NETWORK_DEACTIVATE, NETWORK_ACTIVATE]}>
      <Dropdown.Toggle variant="link" id={"dropdown-basic-" + network.id} data-testid="toggle-btn">
        <BiDotsVerticalRounded />
      </Dropdown.Toggle>
      </ShowForPermission>
      <Dropdown.Menu>
        <ShowForPermission permissions={[NETWORK_UPDATE]}>
          <Dropdown.Item
            data-testid="editAction"
            onClick={() => openEditNetworkModal(network)}
          >
            Edit
          </Dropdown.Item>
        </ShowForPermission>
        <ShowForPermission permissions={[NETWORK_DEACTIVATE, NETWORK_ACTIVATE]}>
          <Dropdown.Divider />
          <Dropdown.Item
            data-testid="networkActivityAction"
            onClick={() => toggleNetworkActivity(network)}
          >
            {network.isActive ? 'Deactivate' : 'Reactivate'}
          </Dropdown.Item>
        </ShowForPermission>

      </Dropdown.Menu>
    </Dropdown>
  );
};



export default NetworkActionMenu;