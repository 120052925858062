import {
  FETCH_ROLES,
  SET_IS_ROLE_EDIT_FLOW,
  SET_SELECTED_ROLE_FOR_EDIT
  } from "../actions/actionTypes";
  
  const defaultState = {
    list: [],
    isEditFlow: false,
    selectedRoleForEdit: {
      id: null,
      market: null,
      roleName: null,
      roleDescription: null
    },
    currentPage: 1,
    noOfPages: 0,
    totalCount: 0
  };
  
  const roleReducer = (reduxState = defaultState, { type, payload }) => {
    switch (type) {

      case FETCH_ROLES:
        return {
          ...reduxState,
          list: payload.roles,
          currentPage: payload.currentPage,
          noOfPages: payload.noOfPages,
          totalCount: payload.totalCount,
        };
  
      case SET_IS_ROLE_EDIT_FLOW:
        return {
          ...reduxState,
          isEditFlow: payload,
        };

      case SET_SELECTED_ROLE_FOR_EDIT:
          return {
            ...reduxState,
            selectedRoleForEdit: payload,
          };
  
      default:
        return reduxState;
    }
  };
  
  export default roleReducer;
  