import { authConfig } from "../config/authConfig";
import { UserManager, WebStorageStateStore } from "oidc-client"

export class AuthService {
  userManager = null;
  user = null;

  constructor() {
    this.userManager = new UserManager({
      ...authConfig,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        ...authConfig.metadata
      }
    });
  }

  isLoggedIn() {
    return this.user != null && this.user.access_token && !this.user.expired;
  }

  getUser() {
    return this.userManager.getUser().then((user) => (this.user = user));
  }

  login() {
    this.userManager.clearStaleState();
    return this.userManager.signinRedirect({ state: '32be7a85354b4642b45a5f19fe7001b7' });
  }

  logout() {
    return this.userManager.signoutRedirect({id_token_hint: 'null',extraQueryParams:{
      client_id: authConfig.client_id,
      logout_uri: authConfig.logout_uri
    }});
  }

  completeLogin() {
    this.userManager
      .signinRedirectCallback()
      .then((user) => {
        this.user = user;
      })
      .catch((error) => this.handleError(error));
  }

  handleError(error) {
    console.error(error);
  }
}
