import axios from "../../apis/qrCore";
import { v4 as uuidv4 } from 'uuid';

import {
  FETCH_TEMPLATES,
  CHANGE_SELECTED_TEMPLATE,
  IMPORT_CSV,
  GENERATE_QR_CODES,
  TOGGLE_LOADER,
  GET_TEMPLATE_SIZE,
  GET_TEMPLATE,
  SET_CREATE_TEMPLATE_DETAILS,
  CREATE_TEMPLATE,
  CHANGE_TEMPLATE_FLOW,
  ACTIVATE_TEMPLATE,
  DEACTIVATE_TEMPLATE,
  UPDATE_TEMPLATE,
  CLEAR_TEMPLATE_STORE
} from "./actionTypes";

const QR_ENDPOINT = 'qr-codes';
const TEMPLATES_ENDPOINT = 'qr-templates';

export const clearQRTemplateStore = () => (
  {
    type: CLEAR_TEMPLATE_STORE
  }
)

export const getActiveTemplates = (market) => async (dispatch, getState) => {

  function onSuccess(response) {
    dispatch({
      type: FETCH_TEMPLATES,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: FETCH_TEMPLATES,
      payload: error.data,
    });
    throw error;
  } try {
    const response = await axios.get(`${TEMPLATES_ENDPOINT}/active`, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: { Market: market }
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};


export const getTemplates = (market, isActive) => async (dispatch, getState) => {

  function onSuccess(response) {
    dispatch({
      type: FETCH_TEMPLATES,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: FETCH_TEMPLATES,
      payload: error.data,
    });
    return error;
  } try {
    const response = await axios.get(`${TEMPLATES_ENDPOINT}`, {
      headers: {
        "x-correlation-id": 1,
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: { Market: market, isActive: isActive }
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const createTemplate = (template) => async (dispatch, getState) => {
  template.market.includes("general") ? template.isGeneral = true : template.isGeneral = false
  function onSuccess(response) {
    dispatch({
      type: CREATE_TEMPLATE,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: CREATE_TEMPLATE,
      payload: error.data,
    });
    throw error;
  }
  try {
    const response = await axios.post(`${TEMPLATES_ENDPOINT}`, template, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};


export const createDynamicTemplate = (template) => async (dispatch) => {

  function onSuccess(response) {
    dispatch({
      type: CREATE_TEMPLATE,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: CREATE_TEMPLATE,
      payload: error.data,
    });
    throw error;
  }
  try {
    const response = await axios.post(`${TEMPLATES_ENDPOINT}/dynamic`, template, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};


export const updateDynamicTemplate = (template) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({
      type: UPDATE_TEMPLATE,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: UPDATE_TEMPLATE,
      payload: error,
    });
    throw error;
  }
  try {

    const response = await axios.put(`${TEMPLATES_ENDPOINT}/dynamic`, template, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};



export const changeSelectedTemplate = (payload) => {
  return {
    type: CHANGE_SELECTED_TEMPLATE,
    payload: payload,
  };
};

export const importCSV = (payload) => {
  return {
    type: IMPORT_CSV,
    payload: payload,
  };
};

export const changeTemplateFlow = (payload) => {
  return {
    type: CHANGE_TEMPLATE_FLOW,
    payload: payload,
  };
};

export const showLoader = (payload) => {
  return {
    type: TOGGLE_LOADER,
    payload: payload,
  };
};

export const setCreateTemplateDetails = (payload) => {
  return {
    type: SET_CREATE_TEMPLATE_DETAILS,
    payload: payload,
  };
};

export const generateQRCodes = (jsonData, templateId, isPdf, isQrImg, qrImageSize, market) => async (dispatch, getState) => {
  function onSuccess(success) {
    dispatch({
      type: GENERATE_QR_CODES,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: GENERATE_QR_CODES,
      payload: error,
    });
    throw error;
  }
  try {
    let payload = {
      channelID: "00000",
      networkID: "00000",
      market
    }

    let endpoint = null;

    // find the country code
    const countryCodeArr = getState().profile.markets.filter(x => x.value === payload.market).map(x => x.isoCode);
    const countryCode = countryCodeArr[0];
   
    if (isPdf) {
      payload = {
        ...payload,
        qrList: jsonData,
        qrTemplateId: templateId
      };
      endpoint = "generate-bulk-pdf"
    } else {
      payload = {
        ...payload,
        format: 1,
        size: qrImageSize,
        countryCode,
        qrList: jsonData,
        qrTemplateId: templateId
      };

      if (isQrImg) {
        // generates the raw QR Image
        endpoint = "generate-bulk";
      } else {
        // generates image including the template
        endpoint = "generate-bulk-png";
      }
    }

    const response = await axios.post(`${QR_ENDPOINT}/${endpoint}`, payload, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      responseType: 'blob'
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const generateIndividualQRCode = (jsonData, templateId, isPdf, isQrImg, qrImageSize, market) => async (dispatch, getState) => {
  function onSuccess(success) {
    dispatch({
      type: GENERATE_QR_CODES,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: GENERATE_QR_CODES,
      payload: error,
    });
    throw error;
  }
  try {
    let payload = {
      qrTemplateId: templateId,
      market
    };

    let endpoint = null;

    const countryCodeArr = getState().profile.markets.filter(x => x.value === payload.market).map(x => x.isoCode);
    const countryCode = countryCodeArr[0];
   
    if (isPdf) {
      payload = {
        ...payload,
        qrData: {
          ...jsonData,
          channelID: "00000",
          networkID: "00000"
        },
        qrTemplateId: templateId
      };
      endpoint = "generate-individual-pdf";
    } else {
      payload = {
        ...payload,
        qrData: {
          ...jsonData,
          channelID: "00000",
          networkID: "00000",
          format: 1,
          size: qrImageSize,
          countryCode
        },
        qrTemplateId: templateId
      };

      if (isQrImg) {
        endpoint = "generate-individual";
      } else {
        endpoint = "generate-individual-png";
      }
    }

    const response = await axios.post(`${QR_ENDPOINT}/${endpoint}`, payload, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      responseType: 'blob'
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const getTemplateSize = (data) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({
      type: GET_TEMPLATE_SIZE,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: GET_TEMPLATE_SIZE,
      payload: error,
    });
    throw error;
  }
  try {

    var fd = new FormData();
    fd.append("pdfFile", data.file);
    fd.append("market", data.market)

    const response = await axios.post(`${TEMPLATES_ENDPOINT}/upload-pdf`, fd, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const updateTemplate = (template) => async (dispatch, getState) => {
  function onSuccess(success) {
    dispatch({
      type: UPDATE_TEMPLATE,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: UPDATE_TEMPLATE,
      payload: error,
    });
    throw error;
  }
  try {

    const response = await axios.put(`${TEMPLATES_ENDPOINT}/`, template, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const getTemplate = (templateId) => async (dispatch, getState) => {
  function onSuccess(success) {
    dispatch({
      type: GET_TEMPLATE,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: GET_TEMPLATE,
      payload: error,
    });
    throw error;
  }
  try {
    const response = await axios.get(`${TEMPLATES_ENDPOINT}/${templateId}`, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const activateOrDeactivateTemplate = (data) => async (dispatch, getState) => {
  if (data.isActivated) {
    function onSuccess(success) {
      dispatch({
        type: DEACTIVATE_TEMPLATE,
        payload: success.data,
      });
      return success;
    }

    function onError(error) {
      dispatch({
        type: DEACTIVATE_TEMPLATE,
        payload: error,
      });
      throw error;
    }

    try {
      const payload = {
        id: data.templateId,
        activate: false
      };
      const response = await axios.post(`${TEMPLATES_ENDPOINT}/activate-deactivate`, payload, {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": 'mpa-qr-code-web-app',
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  } else {
    function onSuccess(success) {
      dispatch({
        type: ACTIVATE_TEMPLATE,
        payload: success.data,
      });
      return success;
    }

    function onError(error) {
      dispatch({
        type: ACTIVATE_TEMPLATE,
        payload: error,
      });
      throw error;
    }

    try {
      const payload = {
        id: data.templateId,
        activate: true
      };
      const response = await axios.post(`${TEMPLATES_ENDPOINT}/activate-deactivate`, payload, {
        headers: {
          "x-correlation-id": uuidv4(),
          "x-source-system": 'mpa-qr-code-web-app',
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  }
};
