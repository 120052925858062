import axios from "../../apis/qrCore";
import { v4 as uuidv4 } from 'uuid';
import { RECEIVE_PROFILE_DATA,RECEIVE_MARKET_DATA } from "./actionTypes";

const MARKETS_ENDPOINT = 'markets';

export const receiveProfileData = (profile) => async (dispatch) => {
	function onSuccess(response) {
		dispatch({
			type: RECEIVE_PROFILE_DATA,
			payload: { profile,response },
		});
		return response;
	}
	function onError(error) {
		throw error;
	} try {
		const response = await axios.get(`${MARKETS_ENDPOINT}/list`, {
			headers: {
				"x-correlation-id": uuidv4(),
				"x-source-system": 'mpa-qr-code-web-app',
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
			params: {isActiveOnly: true, isDropdown: true}
		});
		return onSuccess(response);
	} catch (error) {
		return onError(error);
	}
};

export const receiveMarketData = () => async (dispatch) => {
	function onSuccess(response) {
		dispatch({
			type: RECEIVE_MARKET_DATA,
			payload: { response },
		});
		return response;
	}
	function onError(error) {
		throw error;
	} try {
		const response = await axios.get(`${MARKETS_ENDPOINT}/list`, {
			headers: {
				"x-correlation-id": uuidv4(),
				"x-source-system": 'mpa-qr-code-web-app',
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
			params: {isActiveOnly: true, isDropdown: true}
		});
		return onSuccess(response);
	} catch (error) {
		return onError(error);
	}
};