import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Table,
  Col,
  Row,
  Tabs,
  Tab,
  Pagination,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { showLoader } from "../../../redux/actions/commonActions";
import {
  getQRSpecification,
  getQRSpecificationHistory,
} from "../../../redux/actions/qrSpecificationActions";
import { getTransactionTypes } from "../../../redux/actions/transactionTypeActions";
import { capitalizeFirstLetter } from "../../../utils/stringFormat";
import { ShowForPermission } from "../../../components/ShowForPermission";
import { QR_SPECIFICATION_VIEW, QR_SPECIFICATION_VIEW_HISTORY } from "../../../utils/permissionList";

import {
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACTION,
} from "../../../utils/errorMessages";
import { toast } from "react-toastify";
import { MdKeyboardArrowLeft } from "react-icons/md";
import arrow from "../../../assets/images/custom-arrow.svg";
import { useHistory } from "react-router-dom";
import HistoryList from "../../../components/History/HistoryList";
import { checkPermission } from "../../../redux/actions/permissionActions";

const ViewQRSpec = ({
  getQRSpecification,
  selectedSpec,
  showLoader,
  selectedId,
  historyDetails,
  checkPermissions,
  getQRSpecificationHistory,
  currentPage,
  noOfPages,
  trxTypeOptions,
  getTransactionTypes,
}) => {
  const history = useHistory();

  const [filters, setFilters] = useState({
    specificationId: selectedId,
    pageNumber: 1,
    pageSize: 10,
  });
  const [trxTypeFilters] = useState({
    isActiveOnly: true
  });
  useEffect(() => {
    async function fetchQRSpecifications() {
      try {
        showLoader(true);

        const hasViewPermission = await checkPermissions([QR_SPECIFICATION_VIEW]);
        const hasViewHistoryPermission = await checkPermissions([QR_SPECIFICATION_VIEW_HISTORY]);
        await getTransactionTypes(trxTypeFilters);

        if (hasViewPermission) {
          await getQRSpecification(selectedId);
        }
        if (hasViewHistoryPermission) {
          await getQRSpecificationHistory(filters);
        }
        showLoader(false);
      } catch (e) {
        if (e.response) {
          if (e.response.status && e.response.status === 403) {
            toast.error(UNAUTHORIZED_ACTION);
          } else if (e.response.data.Description) {
            toast.error(e.response.data);
          }
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        showLoader(false);
      }
    }
    fetchQRSpecifications();
  }, [showLoader, getQRSpecification, selectedId, filters, checkPermissions]);

  const getTransactionDisplayName = (id) => {
    const trxType = trxTypeOptions.find((x) => x.value === id);
    return trxType ? trxType.label : "Unknown";
  };
  const renderUsers = selectedSpec?.fields.map((field) => {
    return (
      <>
        <tr key={field.id} className="view-field">
          <td>{field.mapFieldName}</td>
          <td>{field.mpaFieldName}</td>
          {selectedSpec.qrFormatType === "EMVCO" ? (
            <td>{field.mapFieldId === null ? "N/A" : field.mapFieldId}</td>
          ) : null}
          {field.validations === null ? (
            <>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
            </>
          ) : (
            <>
              <td>
                {field.validations.format === null
                  ? "N/A"
                  : field.validations.format}
              </td>
              {field.validations.lengthType === null ? (
                <td className="text-center">N/A</td>
              ) : field.validations.lengthType === "ranged" ? (
                <td className="text-center">
                  {field.validations.lengthMin +
                    " - " +
                    field.validations.lengthMax}
                </td>
              ) : (
                <td className="text-center">{field.validations.lengthMin}</td>
              )}
              <td>
                {field.validations.required === null
                  ? "N/A"
                  : field.validations.required
                    ? "Mandatory"
                    : "Optional"}
              </td>
              <td>{field.defaultValue === null ? "-" : field.defaultValue}</td>
              <td>{field.valueMap ? JSON.stringify(field.valueMap) : "-"}</td>
              <td>
                {field.requiredUserInput === null
                  ? "-"
                  : field.requiredUserInput
                    ? "yes"
                    : "no"}
              </td>
              <td>{field.rules ? JSON.stringify(field.rules) : "-"}</td>
            </>
          )}
        </tr>

        {field.children.map((childField, index) => {
          return (
            <tr
              key={childField.id + "" + index}
              className="view-field sub-field"
            >
              <td>
                <span className="arrow-icon">
                  <img src={arrow} alt="custom-arrow" />
                </span>
                {childField.mapFieldName === null
                  ? "N/A"
                  : childField.mapFieldName}
              </td>
              <td>
                {childField.mpaFieldName === null
                  ? "N/A"
                  : childField.mpaFieldName}
              </td>
              {selectedSpec.qrFormatType === "EMVCO" ? (
                <td>
                  {childField.mapFieldId === null
                    ? "N/A"
                    : childField.mapFieldId}
                </td>
              ) : null}
              {childField.validations === null ? (
                <>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                </>
              ) : (
                <>
                  <td>
                    {childField.validations.format === null
                      ? "N/A"
                      : childField.validations.format}
                  </td>
                  {childField.validations.lengthType === null ? (
                    <td className="text-center">N/A</td>
                  ) : childField.validations.lengthType === "ranged" ? (
                    <td className="text-center">
                      {childField.validations.lengthMin +
                        " - " +
                        childField.validations.lengthMax}
                    </td>
                  ) : (
                    <td className="text-center">
                      {childField.validations.lengthMin}
                    </td>
                  )}
                  <td>
                    {childField.validations.required === null
                      ? "N/A"
                      : childField.validations.required
                        ? "Mandatory"
                        : "Optional"}
                  </td>
                  <td>
                    {childField.defaultValue === null
                      ? "-"
                      : childField.defaultValue}
                  </td>
                  <td>
                    {childField.valueMap
                      ? JSON.stringify(childField.valueMap)
                      : "-"}
                  </td>
                  <td>
                    {childField.requiredUserInput === null
                      ? "- "
                      : childField.requiredUserInput
                        ? "yes"
                        : "no"}
                  </td>
                  <td>
                    {childField.rules ? JSON.stringify(childField.rules) : "-"}
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </>
    );
  });

  let items = [];
  let max = 6;
  let start = currentPage - 3 <= 0 ? 1 : currentPage - 3;
  let end = start + max > noOfPages ? noOfPages : start + max;

  for (let number = start; number <= end; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setFilters({ ...filters, pageNumber: number })}
      >
        {number}
      </Pagination.Item>
    );
  }

  const handleChangePageSize = (size) => {
    setFilters({ ...filters, pageNumber: 1, pageSize: size });
  };

  return (
    <div className="page qr-spec-list-page">
      <div className="back-link" onClick={() => history.goBack()}>
        <MdKeyboardArrowLeft /> back
      </div>
      <div className="page-title-row">
        <div className="title-view-detail">
          <h2>View Details</h2>
        </div>
      </div>
      <Tabs
        defaultActiveKey="view"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="view" title="Details">
          <ShowForPermission permissions={[QR_SPECIFICATION_VIEW]} showBanner>
            <div className="qrSpec-details-wrapper">
              <div className="b-detail">
                <h4>Basic Details</h4>
                <span className="border-btm"></span>
              </div>
              <Row className="basic-view-detail">
                <Col md="2">
                  <div className="top-row">Name</div>
                  <div className="bottom-row">{selectedSpec.template}</div>
                </Col>
                <Col md="2">
                  <div className="top-row">Market</div>
                  <div className="bottom-row">
                    {capitalizeFirstLetter(selectedSpec.market)}
                  </div>
                </Col>
                <Col md="2">
                  <div className="top-row">Network</div>
                  <div className="bottom-row">{selectedSpec.network}</div>
                </Col>
                <Col md="2">
                  <div className="top-row">Transaction Types</div>
                  <div className="bottom-row">
                    {selectedSpec.transactionTypes ?
                      selectedSpec.transactionTypes.length > 0 ?
                      selectedSpec.transactionTypes.map((type, i) => {
                        return (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled" className="trx-type-tooltip">
                                {getTransactionDisplayName(type)}
                              </Tooltip>
                            }
                          >
                            <span className="d-inline-block">
                                {selectedSpec.transactionTypes.length-1 > i
                                  ? <label className="trx-type-value">{type+", "}</label>
                                  : <label >{type} </label>}
                            </span>
                          </OverlayTrigger>
                        );
                      }) : "Unknown" : "Unknown"}
                  </div>
                </Col>
                <Col md="1">
                  <div className="top-row">Format</div>
                  <div className="bottom-row">
                    {selectedSpec.qrFormatType}
                    {selectedSpec.isLegacy ? " (Legacy)" : null}
                  </div>
                </Col>
                <Col md="1">
                  <div className="top-row">Stage</div>
                  <div className="bottom-row status-qr-sp">
                    <span className={selectedSpec.stage}>
                      {capitalizeFirstLetter(selectedSpec.stage)}
                    </span>
                  </div>
                </Col>
                <Col md="2">
                  <div className="top-row">Status</div>
                  <div className="bottom-row status-qr-sp">
                    <span className={selectedSpec.status}>
                      {capitalizeFirstLetter(selectedSpec.status)}
                    </span>
                  </div>
                </Col>
              </Row>
              <div className="b-detail">
                <h4 className="additional-padding">Field Mapping</h4>
                <span className="border-btm2"></span>
              </div>
              <Table>
                <thead>
                  <tr>
                    <th>Market Field</th>
                    <th>Mapping</th>
                    {selectedSpec.qrFormatType === "EMVCO" ? <th>Id</th> : null}
                    <th>Format</th>
                    <th className="text-center">Length</th>
                    <th>Presence</th>
                    <th className="text-center">Default Value</th>
                    <th>Value Map</th>
                    <th>Requires User Input</th>
                    <th>Rules</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>{renderUsers}</tbody>
              </Table>
            </div>
          </ShowForPermission>
        </Tab>

        <Tab eventKey="history" title="History">
          <ShowForPermission permissions={[QR_SPECIFICATION_VIEW_HISTORY]} showBanner>
            <HistoryList
              historyDetails={historyDetails}
              handleChangePageSize={handleChangePageSize}
              filters={filters}
              setFilters={setFilters}
              currentPage={currentPage}
              noOfPages={noOfPages}
              items={items}
            />
          </ShowForPermission>
        </Tab>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedSpec: state.qrSpecifiction.current,
    networkList: state.network.list,
    selectedId: state.qrSpecifiction.selectedId,
    historyDetails: state.qrSpecifiction.historyDetails,
    currentPage: state.qrSpecifiction.currentHistoryPage,
    noOfPages: state.qrSpecifiction.noOfHistoryPages,
    trxTypeOptions: state.transactionType.trxTypeOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: (data) => dispatch(showLoader(data)),
  getQRSpecification: (id) => dispatch(getQRSpecification(id)),
  getQRSpecificationHistory: (id) => dispatch(getQRSpecificationHistory(id)),
  checkPermissions: (permission) => dispatch(checkPermission(permission)),
  getTransactionTypes: (filters) => dispatch(getTransactionTypes(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewQRSpec);
