import React, { useEffect, useState } from "react";

const ResponseCard = ({ code, status }) => {
  const [backgroundColor, setBackgroundColor] = useState("#00FF00");
  const [fontColor, setFontColor] = useState("000000");

  useEffect(() => {
    let backgroundColor = "#00FF00";
    let fontColor = "#000000";

    // label colour switcher
    switch (code) {
      case 200:
        backgroundColor = "#6fb973";
        fontColor = "#e7ebe7";
        break;
      case 201:
        backgroundColor = "#6fb973";
        fontColor = "#e7ebe7";
        break;
      case 400:
        backgroundColor = "#d24650";
        fontColor = "#000000";
        break;
      case 401:
        backgroundColor = "#d24650";
        fontColor = "#000000";
        break;
      case 403:
        backgroundColor = "#d24650";
        fontColor = "#000000";
        break;
      case 404:
        backgroundColor = "#d24650";
        fontColor = "#000000";
        break;
      case 500:
        backgroundColor = "#e3b748";
        fontColor = "#000000";
        break;
      default:
        backgroundColor = "#FFFFFF";
        fontColor = "#000000";
        break;
    }

    setBackgroundColor(backgroundColor);
    setFontColor(fontColor);
  }, [code]);
  return (
    <div
      className="response-code"
      style={{ backgroundColor: backgroundColor, color: fontColor }}
    >
      <label data-testid="responseCodeLabel" className="responseCodeLabel">
        {code !== undefined ? code : ""}
      </label>{" "}
      <label data-testid="responseStatusLabel">
        {status !== undefined ? status : ""}
      </label>
    </div>
  );
};

export default ResponseCard;
