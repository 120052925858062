import React, { useCallback, useEffect } from 'react'

import BTable from 'react-bootstrap/Table';

import { useTable, useExpanded } from 'react-table'
import SubRowAsync from './SubRowAsync';

// This could be inlined into SubRowAsync, this this lets you reuse it across tables

const ExpandableTable = ({ columns, data, collapseOnDataChange, expanded = null }) => {
  // Use the state and functions returned from useTable to build your UI

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    toggleAllRowsExpanded,
    toggleRowExpanded
  } = useTable(
    {
      columns,
      data,
      collapseOnDataChange,
      initialState: {
        hiddenColumns: columns.map(column => {
          if (column.show === false) return column.accessor || column.id;
        }),
      },
    },
    useExpanded, // Use the useExpanded plugin hook,

  )

  useEffect(() => {
    toggleAllRowsExpanded(false);
    if (expanded !== null || expanded !== {}) {
      toggleRowExpanded(Object.keys(expanded)[0], true)
    }
  }, [toggleAllRowsExpanded, toggleRowExpanded, data, expanded]);



  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  );


  // Render the UI for your table
  return (
    <BTable className="qr-spec" size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row, i) => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          return (
            // Use a React.Fragment here so the table markup is still valid
            <React.Fragment key={rowProps.key}>
              <tr {...rowProps}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps({ className: cell.column.className })}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
              {/* We could pass anything into this */}
              {row.isExpanded &&
                renderRowSubComponent({ row, rowProps, visibleColumns })}
            </React.Fragment>
          );
        })}
      </tbody>
    </BTable>
  )
}


export default ExpandableTable
