import React from "react";
import { Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MARKET_ACTIVATE, MARKET_DEACTIVATE, MARKET_UPDATE } from "../../utils/permissionList";
import { ShowForPermission } from "../ShowForPermission";

const MarketActionMenu = ({
    toggleMarketActivity,
    openEditMarketModal,
    market
}) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="link" id={"dropdown-basic-" + market.id} data-testid="toggle-btn-market">
                <BiDotsVerticalRounded />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <ShowForPermission permissions={[MARKET_UPDATE]}>
                    <Dropdown.Item
                        data-testid="editAction-market"
                        onClick={() => openEditMarketModal(market)}
                    >
                        Edit
                    </Dropdown.Item>
                    <Dropdown.Divider />
                </ShowForPermission>
                <ShowForPermission permissions={[MARKET_ACTIVATE, MARKET_DEACTIVATE]}>
                    <Dropdown.Item
                        data-testid="marketActivityAction"
                        onClick={() => toggleMarketActivity(market)}
                    >
                        {market.isActive ? 'Deactivate' : 'Reactivate'}
                    </Dropdown.Item>
                </ShowForPermission>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default MarketActionMenu;