import { readString } from "react-papaparse";
import * as Papa from "papaparse";

const config = {
  delimiter: "", // auto-detect
  newline: "", // auto-detect
  quoteChar: '"',
  escapeChar: '"',
  header: true,
  transformHeader: undefined,
  dynamicTyping: false,
  preview: 0,
  encoding: "",
  worker: false,
  comments: false,
  step: undefined,
  complete: undefined,
  error: undefined,
  download: false,
  downloadRequestHeaders: undefined,
  skipEmptyLines: true,
  chunk: undefined,
  fastMode: undefined,
  beforeFirstChunk: undefined,
  withCredentials: undefined,
  transform: undefined,
};

const trxCodeRegex = /^[0-9]?[1-9]$/;
let cpiRegex = /^[a-zA-Z0-9]{5,7}$/;
let cpnRegex = /^.{0,25}$/;

export const csvToString = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => resolve(reader.result);
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const csvToJsonPapa = (text) => {
  try {
    return readString(text, config);
  } catch (ex) {
    console.log(ex);
  }
};

export const addProceedingZero = (arr) => {
  try {
    return arr.map((x) => {
      if (x.trxCode.toString().length === 2) return x;
      else return { ...x, trxCode: "0" + x.trxCode };
    });
  } catch (ex) {
    return [];
  }
};

//var csv is the CSV file with headers
export const csvToJSON = (csv) => {
  try {
    const lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");
      for (var j = 0; j < headers.length; j++) {
        // add additional parameters
        if (j > 2) {
          if (obj.additionalQRParams) {
            obj.additionalQRParams = [{
              mpaFieldName: headers[j],
              mpaFieldValue: currentline[j]
            }]
          } else {
            obj.additionalQRParams.push({
              mpaFieldName: headers[j],
              mpaFieldValue: currentline[j]
            })
          }
        } else {
          obj[headers[j]] = currentline[j];
        }
      }
      result.push(obj);
    }

    return JSON.stringify(result); //JSON
  } catch (e) {
    console.error(e);
  }
};

export const validateCSV = (csv, validations, maxRecords, rawParameters = [], isDynamic = false) => {

  // reomve and add to the front
  const mpaRequiredKeys = [
    "creditpartyname", "creditpartyidentifier", "trxcode"
  ]

  const additionalParameters = rawParameters.filter(x => !mpaRequiredKeys.includes(x.parentFieldKey.toLowerCase()));
  const mainParameters = rawParameters.filter(x => mpaRequiredKeys.includes(x.parentFieldKey.toLowerCase()));

  const cpnObj = mainParameters.filter(x => x.parentFieldKey.toLowerCase() === mpaRequiredKeys[0])[0];
  const cpiObj = mainParameters.filter(x => x.parentFieldKey.toLowerCase() === mpaRequiredKeys[1])[0];

  const cpiValidations = cpiObj.validation
  const cpnValidations = cpnObj.validation

  try {
    const lines = csv.split("\n");

    let errors = [];
    let cleaned = [];

    if (!isDynamic) {
      // check with QR specification for the market
      if (cpiValidations.lengthType === "ranged") {
        if (validations.cpiMinLength < cpiValidations.lengthMin) {
          errors.push("Credit party identifier min length mismatch with QR Specification");
        }

        if (validations.cpiLength > cpiValidations.lengthMax) {
          errors.push("Credit party identifier max length mismatch with QR Specification");
        }
      } else {
        if (validations.cpiMinLength !== cpiValidations.lengthMin || validations.cpiLength !== cpiValidations.lengthMax) {
          errors.push("Credit party identifier length mismatch with QR Specification (QR template length is " + validations.cpiMinLength + "-" + validations.cpiLength + " expected " + cpiValidations.lengthMax + " in the QR specification)");
        }
      }

      //  check for format mismatches 
      switch (cpiValidations.format) {
        case "ANS":
          // any restrictin wil work
          break;
        case "AN":
          if (validations.cpiFormat.hasSpecialChars) {
            errors.push("Credit party identifier format mismatch with QR Specification (QR template allow (S), QR Specification does not allow (s))");
          }
          break;
        case "N":
          if (validations.cpiFormat.hasAlphabets) {
            errors.push("Credit party identifier format mismatch with QR Specification (QR template allow (A), QR Specification does not allow (A))");
          }

          if (validations.cpiFormat.hasSpecialChars) {
            errors.push("Credit party identifier format mismatch with QR Specification (QR template allow (S), QR Specification does not allow (A))");
          }
          break;
        case "A":
          if (validations.cpiFormat.hasNumbers) {
            errors.push("Credit party identifier format mismatch with QR Specification (QR template allow (N), QR Specification does not allow (N))");
          }

          if (validations.cpiFormat.hasSpecialChars) {
            errors.push("Credit party identifier format mismatch with QR Specification (QR template allow (S), QR Specification does not allow (S))");
          }
          break;
        case "SP":
          if (validations.cpiFormat.hasNumbers) {
            errors.push("Credit party identifier format mismatch with QR Specification (QR template allow (N), QR Specification does not allow (N))");
          }

          if (validations.cpiFormat.hasAlphabets) {
            errors.push("Credit party identifier format mismatch with QR Specification (QR template allow (A), QR Specification does not allow (A))");
          }
          break;
        default:
          errors.error("Unrecognized format " + cpiValidations.format)
          break;
      }


      // length mismatch check with the QR specification for the market
      if (cpnValidations.lengthType === "ranged") {

        if (validations.cpnMinLength < cpnValidations.lengthMin) {
          errors.push("Credit party name min length mismatch with QR Specification");
        }

        if (validations.cpnLength > cpnValidations.lengthMax) {
          errors.push("Credit party name max length mismatch with QR Specification");
        }
      } else {
        if (validations.cpnMinLength !== cpnValidations.lengthMin || validations.cpnLength !== cpnValidations.lengthMax) {
          errors.push("Credit party name length mismatch with QR Specification (QR template length is " + validations.cpnMinLength + "-" + validations.cpnLength + " expected " + cpnValidations.lengthMax + " in the QR specification)");
        }
      }

      //  check for format mismatches 
      switch (cpnValidations.format) {
        case "ANS":
          // any restrictin wil work
          break;
        case "AN":
          if (validations.cpnFormat.hasSpecialChars) {
            errors.push("Credit party name format mismatch with QR Specification (QR template allow (S), QR Specification does not allow (s))");
          }
          break;
        case "N":
          if (validations.cpnFormat.hasAlphabets) {
            errors.push("Credit party name format mismatch with QR Specification (QR template allow (A), QR Specification does not allow (A))");
          }

          if (validations.cpnFormat.hasSpecialChars) {
            errors.push("Credit party name format mismatch with QR Specification (QR template allow (S), QR Specification does not allow (A))");
          }
          break;
        case "A":
          if (validations.cpnFormat.hasNumbers) {
            errors.push("Credit party name format mismatch with QR Specification (QR template allow (N), QR Specification does not allow (N))");
          }

          if (validations.cpnFormat.hasSpecialChars) {
            errors.push("Credit party name format mismatch with QR Specification (QR template allow (S), QR Specification does not allow (S))");
          }
          break;
        case "SP":
          if (validations.cpnFormat.hasNumbers) {
            errors.push("Credit party name format mismatch with QR Specification (QR template allow (N), QR Specification does not allow (N))");
          }

          if (validations.cpnFormat.hasAlphabets) {
            errors.push("Credit party name format mismatch with QR Specification (QR template allow (A), QR Specification does not allow (A))");
          }
          break;
        case "S":
          // any restrictin wil work
          break;
        default:
          errors.push("Unrecognized format " + cpiValidations.format)
          break;
      }
    }


    const headers = lines[0].split(",");

    if (headers.length >= (3 + additionalParameters.length)) {
      if (headers[0].trim() !== "trxCode") {
        errors.push("trxCode column not found");
      }
      if (headers[1].trim() !== "creditPartyIdentifier") {
        errors.push("creditPartyIdentifier column not found");
      }
      if (headers[2].trim() !== "creditPartyName") {
        errors.push("creditPartyName column not found");
      }
      for (let m = 0; m < additionalParameters.length; m++) {
        if (headers[3 + m].trim() !== additionalParameters[m].parentFieldKey) {
          errors.push(additionalParameters[m].parentFieldKey + " column not found")
        }
      }
    } else {
      errors.push("Invalid CSV format");
    }

    if (errors.length > 0) {
      return errors;
    }

    for (let i = 1; i < lines.length; i++) {
      if (lines[i].trim() === "") {
        continue;
      }
      cleaned.push(lines[i]);
    }
    if (cleaned.length === 0) {
      errors.push("No records found on the csv file");
    }

    if (errors.length > 0) {
      return errors;
    }

    let count = 0;

    // dynamicaly create the regex to validate the CPN and CPI
    // for CPI special characters are not a option (MPA format doesn't allow special characters for CPI)
    cpiRegex = "";

    if (validations.cpiFormat.hasNumbers && !validations.cpiFormat.hasAlphabets) {
      // only numbers
      cpiRegex = cpiRegex + "^[0-9]";
    } else if (!validations.cpiFormat.hasNumbers && validations.cpiFormat.hasAlphabets) {
      // only alphabet
      cpiRegex = cpiRegex + "^[a-zA-Z]";
    } else if (validations.cpiFormat.hasNumbers && validations.cpiFormat.hasAlphabets) {
      // alphanumeric
      cpiRegex = cpiRegex + "^[a-zA-Z0-9]";
    }

    cpiRegex = cpiRegex + `{${validations.cpiMinLength},${validations.cpiLength}}$`
    cpiRegex = new RegExp(cpiRegex);

    cpnRegex = "";
    if(validations.cpnFormat.hasAnyChars){
      cpnRegex = cpnRegex + "^.";
    } else if (validations.cpnFormat.hasNumbers && !validations.cpnFormat.hasAlphabets && !validations.cpnFormat.hasSpecialChars) {
      // only numbers allowed
      cpnRegex = cpnRegex + "^[0-9]";
    } else if (!validations.cpnFormat.hasNumbers && validations.cpnFormat.hasAlphabets && !validations.cpnFormat.hasSpecialChars) {
      // only alphabatical allowed
      cpnRegex = cpnRegex + "^[a-zA-Z]";
    } else if (!validations.cpnFormat.hasNumbers && !validations.cpnFormat.hasAlphabets && validations.cpnFormat.hasSpecialChars) {
      // only speical chars allowed
      cpnRegex = cpnRegex + "^[^A-Za-z0-9]";
    } else if (!validations.cpnFormat.hasNumbers && validations.cpnFormat.hasAlphabets && validations.cpnFormat.hasSpecialChars) {
      // alphabatical and special 
      cpnRegex = cpnRegex + "[^0-9]";
    } else if (validations.cpnFormat.hasNumbers && !validations.cpnFormat.hasAlphabets && validations.cpnFormat.hasSpecialChars) {
      // numbers and special
      cpnRegex = cpnRegex + "[^A-Za-z]";
    } else if (validations.cpnFormat.hasNumbers && validations.cpnFormat.hasAlphabets && !validations.cpnFormat.hasSpecialChars) {
      // numbers and alphabatical
      cpnRegex = cpnRegex + "^[A-Za-z0-9]";
    } else if (validations.cpnFormat.hasNumbers && validations.cpnFormat.hasAlphabets && validations.cpnFormat.hasSpecialChars) {
      cpnRegex = cpnRegex + "^[ A-Za-z0-9!\"#$%&'()*+,-^_.\/?>=<;:@`{}|~]";
    }

    cpnRegex = cpnRegex + `{${validations.cpnMinLength},${validations.cpnLength}}$`
    cpnRegex = new RegExp(cpnRegex);

    // create the regex for additionalParameters
    for (let m = 0; m < additionalParameters.length; m++) {
      const format = additionalParameters[m].validation.format.toLowerCase();
      const validations = additionalParameters[m].validation;
      let regexForParam = null;
      switch (format) {
        case "ans":
          regexForParam = "^.";
          break;
        case "an":
          regexForParam = "^[A-Za-z0-9]";
          break;
        case "n":
          regexForParam = "^[0-9]";
          break;
        case "a":
          regexForParam = "^[a-zA-Z]";
          break;
        case "sp":
          regexForParam = "^[^A-Za-z0-9]";
          break;
        default:
          regexForParam = null;
          break;
      }
      additionalParameters[m]["regEx"] = new RegExp(regexForParam + `{${validations.lengthMin},${validations.lengthMax}}$`)
    }

    for (let i = 1; i < lines.length; i++) {
      if (lines[i].trim() === "") {
        continue;
      }
      count++;
      var currentline = lines[i].split(",");
      for (var j = 0; j < currentline.length; j++) {
        switch (j) {
          case 0:
            // validate trx code
            if (!trxCodeRegex.test(currentline[j].trim())) {
              errors.push("Invalid value for transaction code in line no " + (i + 1));
            }
            break;
          case 1:
            // validate credit party identifier
            if (!cpiRegex.test(currentline[j].trim())) {
              errors.push("Invalid value for credit party identifier in line no " + (i + 1));
            }
            break;
          case 2:
            // validate credit party name
            if (!cpnRegex.test(currentline[j].trim())) {
              errors.push("Invalid value for credit party name in line no " + (i + 1));
            }
            break;
          default:
            if (j - 3 < additionalParameters.length) {
              if (!additionalParameters[j - 3].regEx.test(currentline[j].trim())) {
                errors.push("Invalid value for " + additionalParameters[j - 3].parentFieldKey + " in line no " + (i + 1))
              }
            } else {
              errors.push("Unknown value at line number " + (i + 1) + " column " + (j + 1));
            }
            break;
        }
      }
    }

    if (count > maxRecords) {
      errors.push(`Maximum Number of QR codes that can be generated at once is ${maxRecords}, found ` + count + " records");
    }
    return errors;
  } catch (e) {
    console.log("Error", e)
  }
};

export const jsonPapaToCsv = (arr, headers, newHeaders) => {
  try {
    let parsedCsv = Papa.unparse(arr, {
      header: false,
      columns: headers
    })
    return newHeaders.concat(parsedCsv)
  } catch (ex) {
    console.log(ex);
  }
};
