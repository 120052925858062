import {
  FETCH_TEMPLATES,
  CHANGE_SELECTED_TEMPLATE,
  IMPORT_CSV,
  TOGGLE_LOADER,
  SET_CREATE_TEMPLATE_DETAILS,
  GET_TEMPLATE,
  CHANGE_TEMPLATE_FLOW,
  CLEAR_TEMPLATE_STORE
} from "../actions/actionTypes";


const defaultState = {
  list: [],
  current: null,
  importedData: null,
  loading: false,
  selectedTemplate: null,
  flow: null,
  createTemplateData: {
    path: null,
    market: null,
    templateName: null,
    isDynamic: false,
    additionalFields: [],
    displayCPNInTemplate: true,
    removedFields: [],
    newlyAddedFields: [],
    fullyRemovedFields: [],
    configurations: {
      qrCode: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
      creditPartyIdentifier: [
        {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        },
      ],
      cpiFontSize: 0,
      cpiFontColor: {
        r: 0,
        g: 0,
        b: 0,
      },
      creditPartyName: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
      cpnFontSize: 0,

      cpnTextAlignment: 'Left',

      cpnFontColor: {
        r: 0,
        g: 0,
        b: 0,
      },
    },
    validations: {
      cpiLength: 25,
      cpiFormat: {
        hasAlphabets: true,
        hasNumbers: true,
      },
      cpnLength: 25,
      cpnFormat: {
        hasAlphabets: true,
        hasNumbers: true,
        hasSpecialChars: true,
        hasAnyChars: true,
      },
    },
    language: null,
    trxCodeHelpText: null,
    cpiHelpText: null,
    cpnHelpText: null,
    isDefault: false,
    isGeneral: false,
  },
};

const templateReducer = (reduxState = defaultState, { type, payload }) => {
  switch (type) {
    case CLEAR_TEMPLATE_STORE: 
    return {
      ...reduxState,
      list: []
    };
    case FETCH_TEMPLATES:
      return {
        ...reduxState,
        list: payload,
      };
    case CHANGE_TEMPLATE_FLOW: 
      return {
        ...reduxState,
        flow: payload,
      }
    case GET_TEMPLATE:
      return {
        ...reduxState,
        selectedTemplate: payload
      };
    case CHANGE_SELECTED_TEMPLATE:
      return {
        ...reduxState,
        current: payload,
      };
    case IMPORT_CSV:
      return {
        ...reduxState,
        importedData: payload,
      };

    case TOGGLE_LOADER:
      return {
        ...reduxState,
        loading: payload,
      };
    case SET_CREATE_TEMPLATE_DETAILS:
      let createTemplateData = reduxState.createTemplateData;
      createTemplateData.language = payload.language;
      createTemplateData.market = payload.market;
      createTemplateData.isDynamic = payload.isDynamic;
      createTemplateData.validations = {
        cpiLength: payload.cpiLength,
        cpiMinLength: payload.cpiMinLength,
        cpiFormat: {
          hasAlphabets: payload.cpiFormatAZ,
          hasNumbers: payload.cpiFormat09,
        },
        cpnLength: payload.cpnLength,
        cpnMinLength: payload.cpnMinLength,
        cpnFormat: {
          hasAlphabets: payload.cpnFormatAZ,
          hasNumbers: payload.cpnFormat09,
          hasSpecialChars: payload.cpnFormatSpecial,
          hasAnyChars: payload.cpnFormatAny,
        },
      };
      createTemplateData.additionalFields = payload.additionalFields;
      createTemplateData.removedFields = payload.removedFields;
      createTemplateData.newlyAddedFields  = payload.newlyAddedFields;
      createTemplateData.fullyRemovedFields  = payload.fullyRemovedFields;
      createTemplateData.displayCPNInTemplate = payload.displayCPNInTemplate;

      return {
        ...reduxState,
        createTemplateData,
      };
    default:
      return reduxState;
  }
};

export default templateReducer;
