import React from "react";
import { Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";

const ReviewSpecTableMenu = ({ remove, file , index
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="link"
        data-testid="toggle-btn"
      >
        <BiDotsVerticalRounded />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          data-testid="remove-item"
          onClick={() => remove(index)}
        >
          Remove
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ReviewSpecTableMenu;
