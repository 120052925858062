import React from "react";
import { GoCheck } from "react-icons/go";
import { MdClose } from "react-icons/md";
import { FaAngleDoubleRight } from "react-icons/fa";
import { GoDash } from "react-icons/go";
import OverlayToolTip from "./OverlayToolTip";

const QRSpecPipeline = ({ stage, status }) => {
  return (
    <>
      {stage === "draft" ? (
        <>
          <OverlayToolTip stage={stage} status={status}>
            <div className="fill-arrow">
              <FaAngleDoubleRight />
            </div>
          </OverlayToolTip>
          <span className="dash dash-dark">
            <GoDash />
          </span>
          <div className="arrow">
            <FaAngleDoubleRight />
          </div>
          <span className="dash dash-dark">
            <GoDash />
          </span>
          <div className="arrow">
            <FaAngleDoubleRight />
          </div>
          <span className="dash dash-dark">
            <GoDash />
          </span>
          <div className="arrow">
            <FaAngleDoubleRight />
          </div>
        </>
      ) : null}

      {stage === "testing" ? (
        <>
          <OverlayToolTip stage="draft" status="passed">
          <div className="correct"><GoCheck /></div>
        </OverlayToolTip>
        <span className={status === "rejected" ? "dash dash-red" : "dash dash-black"}>
          <GoDash />
        </span>
        <OverlayToolTip stage={stage} status={status}>
          {status === "rejected" ? 
              <div className="reject">
                <MdClose />
              </div> : 
              <div className="fill-arrow">
                <FaAngleDoubleRight />
              </div>
          } 
        </OverlayToolTip>
        <span className="dash dash-dark">
          <GoDash />
        </span>
        <div className="arrow"><FaAngleDoubleRight /></div>
        <span className="dash dash-dark">
          <GoDash />
        </span>
        <div className="arrow"><FaAngleDoubleRight /></div>
        </>
      ) : null}

      {stage === "in-review" ? (
        <>
          <OverlayToolTip stage="draft" status="passed">
            <div className="correct">
              <GoCheck />
            </div>
          </OverlayToolTip>
          <span className="dash dash-green">
            <GoDash />
          </span>
          <OverlayToolTip stage="testing" status="passed">
            <div className="correct">
              <GoCheck />
            </div>
          </OverlayToolTip>
          <span className={status === "rejected" ? "dash dash-red" : "dash dash-black"}>
            <GoDash />
          </span>
          <OverlayToolTip stage={stage} status={status}>
            {status === "rejected" ? 
              <div className="reject">
                <MdClose />
              </div> : 
              <div className="fill-arrow">
                <FaAngleDoubleRight />
              </div>
            } 
          </OverlayToolTip>
          <span className="dash dash-dark">
            <GoDash />
          </span>
          <div className="arrow">
            <FaAngleDoubleRight />
          </div>
        </>
      ) : null}

      {stage === "published" ? (
        <>
          <OverlayToolTip stage="draft" status="passed">
            <div className="correct">
              <GoCheck />
            </div>
          </OverlayToolTip>
          <span className="dash dash-green">
            <GoDash />
          </span>
          <OverlayToolTip stage="testing" status="passed">
            <div className="correct">
              <GoCheck />
            </div>
          </OverlayToolTip>
          <span className="dash dash-green">
            <GoDash />
          </span>
          <OverlayToolTip stage="in-review" status="passed">
            <div className="correct">
              <GoCheck />
            </div>
          </OverlayToolTip>
          <span className="dash dash-green">
            <GoDash />
          </span>
          <OverlayToolTip stage="publised" status="passed">
            <div className="correct">
              <GoCheck />
            </div>
          </OverlayToolTip>
        </>
      ) : null}
    </>
  );
};

export default QRSpecPipeline;
