import React from 'react';
import '../../styles/style.scss';
import { ToastContainer } from 'react-toastify';


// Components
import Main from './Main';

const AppLayout = (props) => {
  return (
    <div className="w-100">
      <Main>{props.children}</Main>
      <ToastContainer />
    </div>
  );
};

export default AppLayout;
