


import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { BiInfoCircle } from "react-icons/bi";
import { connect } from 'react-redux';

const InfoToolTip = ({content}) => {
  return (
    <OverlayTrigger
    overlay={
      <Tooltip id="tooltip-disabled">
        {content}
      </Tooltip>
    }
  >
    <span className="d-inline-block">
      <BiInfoCircle />
    </span>
  </OverlayTrigger>
  );
};


const mapStateToProps = (state) => {
  return {
    userGroups: state.profile.groups,
  };
};

export default connect(mapStateToProps, null)(InfoToolTip);


