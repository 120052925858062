import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Table, Col, Row, Pagination, Button } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { showLoader } from "../../redux/actions/commonActions";
import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG, UNAUTHORIZED_ACTION } from "../../utils/errorMessages";
import { getMarkets, activateOrDeactivateMarket } from "../../redux/actions/marketActions";
import MarketActionMenu from "../../components/action-menus/MarketActionMenu";
import CreateMarketModal from "../../components/modals/CreateMarketModal";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { ShowForPermission } from "../../components/ShowForPermission";
import { MARKET_ACTIVATE, MARKET_CREATE, MARKET_DEACTIVATE, MARKET_LIST, MARKET_UPDATE } from "../../utils/permissionList";

const MarketsPage = ({
  getMarketList,
  marketList = [],
  noOfPages,
  currentPage,
  activateOrDeactivateMarket,
  showLoader,
}) => {
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 15,
  });

  const [isEditFlow, setIsEditFlow] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {

    async function init() {
      try {
        showLoader(true);
        getMarketList(filters);
      } catch (e) {
        if (e.response.status && e.response.status === 403) {
          toast.error(UNAUTHORIZED_ACTION);
        } else if (e.response.data.Description) {
          toast.error(e.response.data.Description);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        showLoader(false);
      }
      showLoader(false);
    }
    init();
  }, [getMarketList, filters, showLoader]);

  const [showCreateMarketModal, setShowCreateMarketModal] = useState(false);
  const toggleShow = () => setShowCreateMarketModal((p) => !p);

  const toggleSetShowConfirmation = () => setShowConfirmation((p) => !p);

  const fetchMarkets = async () => {
    try {
      showLoader(true);
      await getMarketList(filters);
      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast.error(SOMETHING_WENT_WRONG);
    }
  }

  const handleCreateMarket = () => {
    setIsEditFlow(false);
    toggleShow();
  }

  const handleEditMarket = (market) => {
    setIsEditFlow(true);
    setSelectedMarket(market);
    toggleShow();
  }

  const handleChangePageSize = (size) => {
    setFilters({ ...filters, pageNumber: 1, pageSize: size });
  };

  const toggleMarketActivityModal = (market) => {
    setSelectedMarket(market);
    setShowConfirmation(true)
  };

  const handleMarketActivationAndDeactivation = async () => {
    try {
      showLoader(true);
      await activateOrDeactivateMarket(selectedMarket)
      await fetchMarkets();
      showLoader(false)
      toast.success(`Market ${selectedMarket?.isActive ? "Deactivated" : "Activated"}`);
    } catch (e) {
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data.Description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
      showLoader(false);
    }
  };

  const renderMarkets = marketList.map((market, idx) => {
    return (
      <tr key={idx}>
        <td>{market.name}</td>
        <td>{market.shortCode2}</td>
        <td>{market.shortCode3}</td>
        <td>{"+" + market.numericShortCode}</td>
        <td className="word-break">{market.description}</td>
        <td>
          <td>
            <div className="status">
              <span className={market.isActive ? "active" : "inactive"}>
                {market.isActive ? "Active" : "Inactive"}
              </span>
            </div>
          </td>
        </td>
        <td>
          <ShowForPermission permissions={[MARKET_ACTIVATE, MARKET_DEACTIVATE, MARKET_UPDATE]}>
            <MarketActionMenu
              market={market}
              isDeactivationFlow={market.isActive}
              toggleMarketActivity={toggleMarketActivityModal}
              openEditMarketModal={handleEditMarket}
            ></MarketActionMenu>
          </ShowForPermission>
        </td>
      </tr>
    );
  });

  let items = [];
  let max = 6;
  let start = currentPage - 3 <= 0 ? 1 : currentPage - 3;
  let end = start + max > noOfPages ? noOfPages : start + max;

  for (let number = start; number <= end; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setFilters({ ...filters, pageNumber: number })}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <div className="page page-audit">
      <div className="page-title-row">
        <div className="title">
          <h1>Markets</h1>
        </div>
      </div>

      <div className="filter-section row">
        <div className="filter-components">
          <Form className="form w-100">
            <Row className="align-items-center">
              <Col xl="9" lg="10" md="9" sm="12"></Col>
              <Col xl="3" lg="2" md="3" sm="12" className="btn-csv">
                <ShowForPermission permissions={[MARKET_CREATE]}>
                  <Button
                    variant="primary"
                    id="add-market-button"
                    data-testid="add-market-button"
                    className="btn-special"
                    onClick={handleCreateMarket}
                  >
                    <AiOutlinePlus /> <span>Market</span>
                  </Button>
                </ShowForPermission>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <CreateMarketModal
        show={showCreateMarketModal}
        getMarketList={fetchMarkets}
        isEditFlow={isEditFlow}
        market={selectedMarket}
        toggleShow={toggleShow}
      />
      <ConfirmationModal
        show={showConfirmation}
        toggleShow={toggleSetShowConfirmation}
        confirm={handleMarketActivationAndDeactivation}
        title={`Are you sure you want to ${selectedMarket?.isActive ? "Deactivate" : "Activate"
          } this Market?`}
      />
      <ShowForPermission permissions={[MARKET_LIST]} showBanner={true}>
        <Table data-testid="market-list-table">
          <thead>
            <tr>
              <th data-testid="table-header-name">Market Name</th>
              <th data-testid="table-header-twoLetterCode">2 Letter Code</th>
              <th data-testid="table-header-threeLetterCode">3 Letter Code</th>
              <th data-testid="table-header-numaricCode">Numaric Short Code</th>
              <th data-testid="table-header-description">Description</th>
              <th data-testid="table-header-status">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{renderMarkets}</tbody>
        </Table>
        <div className="footer-table d-flex justify-content-between">
          <div>
            <Form.Select onChange={(e) => handleChangePageSize(e.target.value)}>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="60">60</option>
            </Form.Select>
          </div>
          <div className="mt-2">{filters.pageNumber}</div>
          <div className="pagination">
            <Pagination size="sm">
              <Pagination.First
                onClick={() => setFilters({ ...filters, pageNumber: 1 })}
              />
              <Pagination.Prev
                disabled={currentPage === 1}
                onClick={() => setFilters({ ...filters, pageNumber: filters.page - 1 })}
              />
              {currentPage > 4 ? <Pagination.Ellipsis disabled /> : null}

              {items}
              {currentPage === noOfPages ? null : (
                <Pagination.Ellipsis disabled />
              )}
              <Pagination.Next
                disabled={currentPage === noOfPages}
                onClick={() => setFilters({ ...filters, pageNumber: filters.pageNumber + 1 })}
              />
              <Pagination.Last
                onClick={() => setFilters({ ...filters, pageNumber: noOfPages })}
              />
            </Pagination>
          </div>
        </div>
      </ShowForPermission>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    marketList: state.market.list,
    currentPage: state.market.currentPage,
    noOfPages: state.market.noOfPages,
    totalCount: state.market.totalCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMarketList: (filters) => dispatch(getMarkets(filters)),
  showLoader: (data) => dispatch(showLoader(data)),
  activateOrDeactivateMarket: (data) => dispatch(activateOrDeactivateMarket(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketsPage);
