import { FETCH_LANGUAGES } from "../actions/actionTypes";

const defaultState = {
  list: [],
  current: null
};

const languageReducer = (reduxState = defaultState, { type, payload }) => {
  switch (type) {
    case FETCH_LANGUAGES:
      return {
        ...reduxState,
        list: payload.languages,
        currentPage: payload.currentPage,
        noOfPages: payload.noOfPages,
        totalCount: payload.totalCount
      };
    default:
      return reduxState;
  }
};

export default languageReducer;
