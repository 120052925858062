import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Alert } from "react-bootstrap";

const ShowForPermissionComponent = (props) => {
    
    const couldShow = props.userPermissions.some(i => props.permissions.includes(i));
    const showBanner = props.showBanner? props.showBanner : false;
    // var success = array_a.every(function(val) {
    //     return array_b.indexOf(val) !== -1;
    // });
    return couldShow ? props.children :  showBanner ? (<Alert  variant="danger">
        You don't have permisson to view this content
    </Alert>) : null;
};

ShowForPermissionComponent.propTypes = {
    permissions: PropTypes.array.isRequired,
    userPermissions: PropTypes.array.isRequired
};


const mapStateToProps = state => ({
    userPermissions: state.permission.currentUserPermissions //<--- here you will get permissions for your user from Redux store
});

export const ShowForPermission = connect(mapStateToProps)(ShowForPermissionComponent);