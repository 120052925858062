import axios from "../../apis/qrCore";
import { v4 as uuidv4 } from 'uuid';

import {
	FETCH_MARKETS,
	CREATE_MARKET,
	UPDATE_MARKET,
	ACTIVATE_MARKET,
	DEACTIVATE_MARKET
} from "./actionTypes";

const MARKETS_ENDPOINT = 'markets';

export const getMarkets = (filters) => async (dispatch, getState) => {
	function onSuccess(response) {
		dispatch({
			type: FETCH_MARKETS,
			payload: response.data,
		});
		return response;
	}
	function onError(error) {
		dispatch({
			type: FETCH_MARKETS,
			payload: error.data,
		});
		throw error;
	} try {
		const response = await axios.get(`${MARKETS_ENDPOINT}/list`, {
			headers: {
				"x-correlation-id": 1,
				"x-source-system": 'mpa-qr-code-web-app',
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
			params: filters
		});
		return onSuccess(response);
	} catch (error) {
		return onError(error);
	}
};

export const createMarket = (market) => async (dispatch, getState) => {
	function onSuccess(response) {
		dispatch({
			type: CREATE_MARKET,
			payload: response.data,
		});
		return response;
	}
	function onError(error) {
		dispatch({
			type: CREATE_MARKET,
			payload: error.data,
		});
		throw error;
	}
	try {
		const response = await axios.post(`${MARKETS_ENDPOINT}`, market, {
			headers: {
				"x-correlation-id": uuidv4(),
				"x-source-system": 'mpa-qr-code-web-app',
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		});
		return onSuccess(response);
	} catch (error) {
		return onError(error);
	}
};

export const updateMarket = (market) => async (dispatch, getState) => {
	function onSuccess(success) {
		dispatch({
			type: UPDATE_MARKET,
			payload: success.data,
		});
		return success;
	}
	function onError(error) {
		dispatch({
			type: UPDATE_MARKET,
			payload: error,
		});
		throw error;
	}
	try {
		
		const response = await axios.put(`${MARKETS_ENDPOINT}/`,
		 market, {
			headers: {
				"x-correlation-id": uuidv4(),
				"x-source-system": 'mpa-qr-code-web-app',
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			}
		});
		return onSuccess(response);
	} catch (error) {
		return onError(error);
	}
};

export const activateOrDeactivateMarket = (data) => async (dispatch, getState) => {
	if (data.isActive) {
	  function onSuccess(success) {
		dispatch({
		  type: DEACTIVATE_MARKET,
		  payload: success.data,
		});
		return success;
	  }
	  function onError(error) {
		dispatch({
		  type: DEACTIVATE_MARKET,
		  payload: error,
		});
		throw error;
	  }
	  
	  try {
		const payload = {
		  id: data.id
		};
		const response = await axios.put(`${MARKETS_ENDPOINT}/deactivate?id=${data.id}`,payload, {
		  headers: {
			"x-correlation-id": uuidv4(),
			"x-source-system": 'mpa-qr-code-web-app',
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		  },
		});
		return onSuccess(response);
	  } catch (error) {
		return onError(error);
	  }
	} else {
	  function onSuccess(success) {
		dispatch({
		  type: ACTIVATE_MARKET,
		  payload: success.data,
		});
		return success;
	  }
	  function onError(error) {
		dispatch({
		  type: ACTIVATE_MARKET,
		  payload: error,
		});
		throw error;
	  }
	  
	  try {
		const payload = {
		  id: data.id
		};
		const response = await axios.put(`${MARKETS_ENDPOINT}/activate?id=${data.id}`,payload, {
		  headers: {
			"x-correlation-id": uuidv4(),
			"x-source-system": 'mpa-qr-code-web-app',
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		  },
		});
		return onSuccess(response);
	  } catch (error) {
		return onError(error);
	  }
	}
  };