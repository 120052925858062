import React from "react";
import { Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { connect } from "react-redux";
import { ShowForPermission } from "../ShowForPermission";
import {
  LANGUAGE_UPDATE,
  LANGUAGE_DEACTIVATE,
  LANGUAGE_ACTIVATE
} from "../../utils/permissionList";

const LanguageActionMenu = ({
  editLanguage,
  language,
  toggleLanguageActivity,
  languageIsActive
}) => {
  return (
    <Dropdown>
      <ShowForPermission permissions={[LANGUAGE_UPDATE, LANGUAGE_ACTIVATE, LANGUAGE_DEACTIVATE]}>
        <Dropdown.Toggle variant="link" id={"dropdown-basic-" + language.id} data-testid="toggle-btn">
          <BiDotsVerticalRounded />
        </Dropdown.Toggle>
      </ShowForPermission>
      <Dropdown.Menu>
        <ShowForPermission permissions={[LANGUAGE_UPDATE]}>
          <Dropdown.Item
            data-testid="editAction"
            href="#/edit"
            onClick={() => editLanguage(language)}
          >
            Edit
          </Dropdown.Item>
        </ShowForPermission>
        <ShowForPermission permissions={[LANGUAGE_ACTIVATE, LANGUAGE_DEACTIVATE]}>
          <Dropdown.Divider />
          <Dropdown.Item
            href="#/activity"
            data-testid="languageActivityAction"
            onClick={() => toggleLanguageActivity(language)}
          >
            {languageIsActive ? 'Deactivate' : 'Reactivate'}
          </Dropdown.Item>
        </ShowForPermission>

      </Dropdown.Menu>
    </Dropdown>
  )
}

const mapStateToProps = (state) => {
  return {
    languageGroups: state.profile.groups,
  };
};

export default connect(mapStateToProps, null)(LanguageActionMenu);
