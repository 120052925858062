import axios from "axios";
import { qrAPIConfig } from "../config/apiConfig";

export default axios.create({
  baseURL: qrAPIConfig.baseURL,
  headers: {
    "Content-Type": "application/json",
    "x-correlation-id": 1, 
    "x-source-system": 'mpa-qr-code-web-app',
  },
});