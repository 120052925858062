import React, { useEffect, useState , memo} from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";

//eslint-disable-next-line
const phoneRegExp = /^\+\d{8,15}$/;
//eslint-disable-next-line
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^\$\*\.\[\]\{\}\(\)\?\\\-“!@#%&/,><’:;|_~`])\S{8,99}$/;

const createSchema = yup.object().shape({
  isEditFlow: yup.bool().default(false),
  email: yup
    .string()
    .email("Invalid format for email")
    .max(100)
    .required("Email is required"),
  firstName: yup.string().max(100)
    .required("First Name is required")
    .matches(/^(?!\s*$)[-a-zA-Z\s]*$/, 'Frist Name can only contain alphabet letters'),
  lastName: yup.string().max(100)
    .required("Last Name is required")
    .matches(/^(?!\s*$)[-a-zA-Z\s]*$/, 'Last Name can only contain alphabet letters'),
  designation: yup.string().max(100),
  phoneNo: yup
    .string()
    .required("Phone Number is required")
    .matches(
      phoneRegExp,
      "Phone number is not valid, Country code is required e.g +xxxxxxxxxxxx, minimum length is 8 and maximum 15"
    ),
  market: yup.string().required("Market is required"),
  role: yup.string().required("Role is required"),
  autogeneratePassword: yup.boolean(),
  password: yup.string().nullable()
  .when(["autogeneratePassword", "isEditing"], {
    is: (autogeneratePassword, isEditFlow) => !autogeneratePassword && !isEditFlow,
    then: yup
      .string()
      .max(99)
      .matches(
        passwordRegExp,
        "Password must contains 8 characters, (including 1 upper-case, 1 lower-case, 1 numeric and 1 special) "
      )
      .required("Password is required"),
  }),
  confirmPassword: yup.string().nullable()
  .when(["autogeneratePassword", "isEditing"], {
    is: (autogeneratePassword, isEditFlow) => !autogeneratePassword && !isEditFlow,
    then: yup
      .string()
      .max(99)
      .required("Confirm the password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  }),
});

function UserCreateForm({
  initialValues,
  handleCreateUser,
  handleEditUser,
  isEditFlow = false,
  showExternal,
  hideExternal,
  changeExternalState,
  isSuperAdmin,
  groups,
  market,
  allRoles,
  markets =[]
}) {
  const [show, setShow] = useState(false);
  const [disabledMarket, setDisabledMarket] = useState(false);
  const [autogeneratePassword, setAutoGeneratePassword] = useState(true);
  const [roles, setRoles] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [countryCode ,setCountryCode] = useState("+xxx");
  const [marketOptions] = useState(markets);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: { ...initialValues, autogeneratePassword: true },
    reValidateMode: "onChange",
    resolver: yupResolver(createSchema),
    mode: "all",
  });

  useEffect(() => {
    setRoles(allRoles);
    setFilteredRoles(allRoles);
  }, [groups, allRoles]);

  useEffect(() => {
    setShow(showExternal);
  }, [showExternal]);


  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'market') {
        const filtered = roles.filter(x => x.market === value.market || x.market == null);
        setFilteredRoles(filtered);
        const market = marketOptions.filter(x => x.value === value.market);
        setCountryCode(market[0] ? market[0]?.countryCode: "+xxx")
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, roles, marketOptions]);

  useEffect(() => {
    let payload = { ...initialValues };
    if (!isSuperAdmin) {
      payload = { ...payload, market };
      const filterdMarket = marketOptions.filter(x => x.value === market);
      setCountryCode(filterdMarket[0] ? filterdMarket[0].countryCode: "+xxx");
      setDisabledMarket(true);
    }

    if (isEditFlow) {
      payload = {
        ...payload,
        isEditFlow: true,
        autogeneratePassword: true,
        password: null,
        confirmPassword: null,
      };
      if (initialValues.designation === null) {
        payload.designation = "";
      }
    } else {
      payload.email = null;
      payload.isEditFlow = false;
      payload.firstName = null;
      payload.lastName = null;
      payload.designation = "";
      payload.phoneNo = null;
      payload.role = null;
      if (isSuperAdmin) {
        payload.market = null;
      }
    }
    reset(payload);
  }, [initialValues, reset, isSuperAdmin, isEditFlow, groups, market, show, marketOptions]);

  const onSubmit = (data) => {
    if (isEditFlow) {
      let payload = data;
      delete payload.password;
      delete payload.confirmPassword;
      handleEditUser(payload);
    } else {
      if (data.autogeneratePassword) {
        delete data.password;
        delete data.confirmPassword;
      }
      handleCreateUser(data);
    }
  };

  const handleClose = () => {
    reset({});
    changeExternalState(false);
    hideExternal(false);
    setShow(false);
    setDisabledMarket(false);
  };
  const handleShow = () => {
    isEditFlow = false;
    reset({});
    setShow(true);
    changeExternalState(true);
  };

  const renderMarkets = marketOptions.map((market, index) => {
    return (
      <option key={index} value={market.value}>{market.displayName}</option>
    )
  })

  return (
    <>
      <Button
        variant="primary"
        id="add-user-button"
        className="btn-special"
        onClick={handleShow}
      >
        <AiOutlinePlus /> <span>User</span>
      </Button>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {isEditFlow ? "Edit" : "Create"} User
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form data-testid="userCreateForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="frm-content">
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email *</Form.Label>
                <Form.Control
                  data-testid="email"
                  type="email"
                  disabled={isEditFlow}
                  {...register("email", { required: true })}
                  autoComplete="off"
                  placeholder="Enter Email"
                  isInvalid={errors.email}
                />
                <Form.Control.Feedback id="emailErrors" type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>First Name *</Form.Label>
                <Form.Control
                  data-testid="firstName"
                  type="text"
                  {...register("firstName", { required: true })}
                  autoComplete="off"
                  placeholder="Enter First Name"
                  isInvalid={errors.firstName}
                />
                <Form.Control.Feedback id="firstNameErrors" type="invalid">
                  {errors.firstName?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="lastName">
                <Form.Label>Last Name *</Form.Label>
                <Form.Control
                  data-testid="lastName"
                  type="text"
                  {...register("lastName", { required: true })}
                  autoComplete="off"
                  placeholder="Enter Last Name"
                  isInvalid={errors.lastName}
                />
                <Form.Control.Feedback id="lastNameErrors" type="invalid">
                  {errors.lastName?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phoneNo">
                <Form.Label>Phone Number *</Form.Label>
                <Form.Control
                  data-testid="phoneNo"
                  type="phone"
                  {...register("phoneNo", { required: true })}
                  autoComplete="off"
                  placeholder={`Enter Phone Number e.g ${countryCode}xxxxxxxxxx`}
                  isInvalid={errors.phoneNo}
                />
                <Form.Control.Feedback id="phoneErrors" type="invalid">
                  {errors.phoneNo?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="market">
                <Form.Label>Market *</Form.Label>
                <Form.Select
                  data-testid="market"
                  disabled={disabledMarket}
                  {...register("market", { required: true })}
                  aria-label="Select a Market"
                  isInvalid={errors.market}
                >
                  <option value="">Select Market</option>
                  {renderMarkets}
                </Form.Select>
                <Form.Control.Feedback id="marketErrors" type="invalid">
                  {errors.market?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="designation">
                <Form.Label>Designation</Form.Label>
                <Form.Control
                  data-testid="designation"
                  type="text"
                  {...register("designation", { required: true })}
                  placeholder="Enter Designation"
                  isInvalid={errors.designation}
                />
                <Form.Control.Feedback id="designationErrors" type="invalid">
                  {errors.designation?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="role">
                <Form.Label>Role *</Form.Label>
                <Form.Select
                  {...register("role", { required: true })}
                  aria-label="Select a Role"
                  data-testid="role"
                  isInvalid={errors.role}
                >
                  <option value="">Select Role</option>
                  {filteredRoles.map((role, index) => {
                    return (
                      <option key={index} value={role.value}>
                        {role.display}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback id="roleErrors" type="invalid">
                  {errors.role?.message}
                </Form.Control.Feedback>
              </Form.Group>
              {!isEditFlow ? (
                <>
                  <Form.Group className="mb-3" controlId="autogeneratePassword">
                    <Form.Check
                      type="checkbox"
                      data-testid="autogeneratePassword"
                      {...register("autogeneratePassword", { required: false })}
                      onChange={(e) =>
                        setAutoGeneratePassword(e.target.checked)
                      }
                      checked={autogeneratePassword}
                      label="Autogenerate Password"
                    />
                  </Form.Group>
                  {autogeneratePassword ? null : (
                    <>
                      <Form.Group className="mb-3" controlId="password">
                        <Form.Label>Password *</Form.Label>
                        <Form.Control
                          data-testid="password"
                          type="password"
                          {...register("password", { required: false })}
                          autoComplete="new-password"
                          placeholder="Password"
                          isInvalid={errors.password}
                        />
                        <Form.Control.Feedback
                          id="passwordErrors"
                          type="invalid"
                        >
                          {errors.password?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="confirmPassword">
                        <Form.Label>Confirm Password *</Form.Label>
                        <Form.Control
                          data-testid="confirmPassword"
                          type="password"
                          {...register("confirmPassword", { required: false })}
                          autoComplete="new-password"
                          placeholder="Confirm Password"
                          isInvalid={errors.confirmPassword}
                        />
                        <Form.Control.Feedback
                          id="confirmPasswordErrors"
                          type="invalid"
                        >
                          {errors.confirmPassword?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>
                  )}
                </>
              ) : null}
            </div>
            <div className="frm-btn">
              <Button
                data-testid="saveButton"
                variant="primary"
                type="submit"
                disabled={!isValid || !isDirty}
              >
                {isEditFlow ? "Save" : "Create"}
              </Button>
              <Button
                data-testid="cancelButton"
                variant="primary"
                className="btn-cancel mx-3"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    groups: state.profile.groups,
    market: state.profile.market,
    markets: state.profile.markets,
    isSuperAdmin: state.permission.isSuperAdmin,
    allRoles: state.role.list.map(x => { return { value: x.id, display: x.roleName, market: x.market, isActive: x.isActive } }).filter(y => y.isActive)
  };
};

export default connect(mapStateToProps, null)(memo(UserCreateForm));
