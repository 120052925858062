import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import {
  Form,
  Table,
  Col,
  InputGroup,
  FormControl,
  Row,
  Button,
  Badge,
} from "react-bootstrap";

import {
  FAILED_TO_CREATE_USER,
  CREATE_USER_SUCCESS,
  FAILED_TO_DEACTIVATE_USER,
  REACTIVATE_USER_SUCCESS,
  FAILED_TO_REACTIVATE_USER,
  DEACTIVATE_USER_SUCCESS,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILED,
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACTION,
} from "../utils/errorMessages";

import {
  jsonPapaToCsv,
} from "../utils/csvParser";

import { USER_LIST } from "../utils/permissionList";
import { USER_CREATE } from "../utils/permissionList";
import { IoSearchOutline } from "react-icons/io5";
import { RiInformationLine } from "react-icons/ri";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

import UserCreateForm from "../components/forms/UserCreateForm";
import UserActionMenu from "../components/action-menus/UserActionMenu";
import DeactivateUserModal from "../components/modals/DeactivateUserModal";
import ResetPasswordModal from "../components/modals/ResetPasswordModal";
import ConfirmationModal from "../components/modals/ConfirmationModal";

import {
  changePage,
  createUser,
  deactivateUser,
  editUser,
  fetchUsers,
  reactivateUser,
  getDeactivationReason,
  resendUserInvite,
  resetUserMFA,
} from "../redux/actions/userActions";

import { capitalizeFirstLetter } from "../utils/stringFormat";
import { showLoader } from "../redux/actions/commonActions";
import { ShowForPermission } from "../components/ShowForPermission";
import { checkPermission } from "../redux/actions/permissionActions";
import { fetchRoles } from "../redux/actions/roleActions";
import DeactivateReasonModal from "../components/modals/DeactivateReasonModal";
import ViewUserModal from "../components/modals/ViewUserModal";
import download from "downloadjs";
import {BiExport} from "react-icons/bi";

const Users = ({
  getUsers,
  createUser,
  usersList = [],
  allUsersList,
  deactivateUser,
  reactivateUser,
  editUser,
  hasMorePages,
  changePage,
  getDeactivationReason,
  reason,
  resendUserInvite,
  resetUserMFA,
  sub,
  showLoader,
  fetchRoles,
  allRoles,
  checkPermission,
}) => {
  const [showDeactivationForm, setShowDeactivationForm] = useState(false);
  const [showResetPasswordForm, setShowResetPasswordModal] = useState(false);
  const [showEditUserForm, setShowEdiUserForm] = useState(false);
  const [showDeactivateReasonModal, setShowDeactivateReasonModal] = useState(false);
  const [isDeactivation, setIsDeactivation] = useState(false);
  const [isEditFlow, setIsEditFlow] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedRole, setSelectedRole] = useState("all");
  const [searchTerm, setSearchTerm] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [page, setPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState({});
  const [showViewModal, setShowViewModal] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [showMFAResetConfirmation, setShowMFAResetConfirmation] = useState(false);

  useEffect(() => {
    async function fetchUsers() {
      try {
        showLoader(true);
        if (await checkPermission([USER_LIST])) {
          await getUsers(pageSize, searchTerm, selectedRole);
        }
        fetchRoles(100, 1, null, true);
        showLoader(false);
      } catch (e) {
        if (e.response) {
          if (e.response.status) {
            if (e.response.status === 403) {
              toast.error(UNAUTHORIZED_ACTION);
            } else {
              toast.error(SOMETHING_WENT_WRONG)
            }
          } else if (e.response.data.Description) {
            toast.error(e.response.data.Description);
          }
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        showLoader(false);
      }
    }
    fetchUsers();
  }, [searchTerm, selectedRole, getUsers, fetchRoles, pageSize, showLoader, checkPermission]);
  
  const getUserList = () => {
    showLoader(true)
    getUsers(pageSize, searchTerm, selectedRole, page);
    showLoader(false)
  };

  const setDataFormMFARest = (user) => {
    setSelectedUser(user);
    toggleShowResetMFAModal();
  };
  
  const toggleShowResetMFAModal = () => {
    setShowMFAResetConfirmation((p) => !p);
  };

  const toggleUserDeactivationForm = (flag, isDeactivationFlow, username) => {
    setIsDeactivation(isDeactivationFlow);
    setSelectedUserName(username);
    setShowDeactivationForm(flag);
  };

  const toggleShowRestPasswordModal = (flag, user) => {
    showLoader(true)
    setSelectedUser(user);
    setShowResetPasswordModal(flag);
    getUserList();
    setPage(1)
    showLoader(false)
  };

  const toggleUserCreationModal = (flag, username, user = {}) => {
    setSelectedUserName(username);
    setSelectedUser(user);
    setIsEditFlow(flag);
    setShowEdiUserForm(flag);
  };

  const toggleUserViewModal = (flag, user = {}) => {
    setSelectedUser(user);
    setShowViewModal(flag);
  };

  const changeExternalState = (flag) => {
    setShowEdiUserForm(flag);
  };
  
  const handleCreateUser = async (data) => {
    try {
      await createUser(data);
      toast.success(CREATE_USER_SUCCESS);
      getUserList();
      setPage(1)
      setShowEdiUserForm(false);
      setIsEditFlow(false);
    } catch (e) {
      if (e.response) {
        if (e.response.status) {
          if (e.response.status === 403) {
            toast.error(UNAUTHORIZED_ACTION);
          } else if(e.response.status === 400){
            toast.error(e.response.data.Description);
          } else {
            toast.error(SOMETHING_WENT_WRONG)
          }
        } else if (e.response.data.Description) {
          toast.error(e.response.data.Description);
        }
      } else {
        toast.error(FAILED_TO_CREATE_USER);
      }
    }
  };
  
  const handleUserListExport = async () => {
    showLoader(true);
    let headerNames = "ID,Email,First Name,Last Name,Role,Status,Created Date (UTC),Phone Number,Market,Designation\n"
    let requiredHeaders = [ "id", "email"	,"firstName",	"lastName",	"roleName"	,"isActiveTxt"	,"createdDate",	"phoneNo"	,"market"	,"designation" ]
  
    allUsersList.some(function(obj){
      if (obj.isActive && !obj.firstTimeLoginStatus) {
        obj.isActiveTxt = 'New';
      }else if (obj.isActive && obj.firstTimeLoginStatus){
        obj.isActiveTxt = 'Active';
      }else{
        obj.isActiveTxt = 'Inactive';
      }
    });
    
    const csvUsers = jsonPapaToCsv(allUsersList, requiredHeaders, headerNames)
    download(csvUsers, "user-list.csv");
    showLoader(false);
  };
  
  const handleEditUser = async (data) => {
    try {
      await editUser(data);
      toast.success(EDIT_USER_SUCCESS);
      getUserList();
      setPage(1)
      setShowEdiUserForm(false);
      setIsEditFlow(false);
    } catch (e) {
      if (e.response) {
        if (e.response.status) {
          if (e.response.status === 403) {
            toast.error(UNAUTHORIZED_ACTION);
          } else if(e.response.status === 400){
            toast.error(e.response.data.Description);
          } else {
            toast.error(SOMETHING_WENT_WRONG)
          }
        } else if (e.response.data.Description) {
          toast.error(e.response.data);
        }
      } else {
        toast.error(EDIT_USER_FAILED);
      }
    }
  };

  const handleUserDeactivation = async (data) => {
    try {
      await deactivateUser(data);
      toast.success(DEACTIVATE_USER_SUCCESS);
      getUserList();
      setPage(1)
      setShowDeactivationForm(false);
    } catch (e) {
      if (e.response) {
        if (e.response.status) {
          if (e.response.status === 403) {
            toast.error(UNAUTHORIZED_ACTION);
          } else if(e.response.status === 400){
            toast.error(e.response.data.Description);
          } else {
            toast.error(SOMETHING_WENT_WRONG)
          }
        } else if (e.response.data.Description) {
          toast.error(e.response.data);
        }
      } else {
        toast.error(FAILED_TO_DEACTIVATE_USER);
      }
    }
  };

  const handleUserReactivation = async (data) => {
    try {
      await reactivateUser(data);
      toast.success(REACTIVATE_USER_SUCCESS);
      getUserList();
      setPage(1)
      setShowDeactivationForm(false);
    } catch (e) {
      if (e.response) {
        if (e.response.status) {
          if (e.response.status === 403) {
            toast.error(UNAUTHORIZED_ACTION);
          } else if(e.response.status === 400){
            toast.error(e.response.data.Description);
          } else {
            toast.error(SOMETHING_WENT_WRONG)
          }
        } else if (e.response.data.Description) {
          toast.error(e.response.data);
        }
      } else {
        toast.error(FAILED_TO_REACTIVATE_USER);
      }
    }
  };

  const handleResendUserInvite = async (user) => {
    try {
      await resendUserInvite(user);
      toast.success("User Invitation sent successfully");
    } catch (e) {
      if (e.response) {
        if (e.response.status) {
          if (e.response.status === 403) {
            toast.error(UNAUTHORIZED_ACTION);
          } else if(e.response.status === 400){
            toast.error(e.response.data.Description);
          } else {
            toast.error(SOMETHING_WENT_WRONG)
          }
        } else if (e.response.data.Description) {
          toast.error(e.response.data);
        }
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
  };

  const handleResetUserMFA = async () => {
    try {
      showLoader(true)
      await resetUserMFA(selectedUser.email);
      toast.success("User MFA has been reset");
      getUserList();
      setPage(1)
    } catch (e) {
      if (e.response) {
        if (e.response.status) {
          if (e.response.status === 403) {
            toast.error(UNAUTHORIZED_ACTION);
          } else if(e.response.status === 400){
            toast.error(e.response.data.Description);
          } else {
            toast.error(SOMETHING_WENT_WRONG)
          }
        } else if (e.response.data.Description) {
          toast.error(e.response.data);
        }
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
    showLoader(false)
  };

  const handleGetUserDeactivationReason = async (email) => {
    try {
      await getDeactivationReason(email);
      setShowDeactivateReasonModal(true);
    } catch (error) {
      toast.error(SOMETHING_WENT_WRONG);
    }
  };

  const handleChangePageSize = (size) => {
    setPageSize(size);
    setPage(1);
  };

  const handleNextPage = (p) => {
    changePage({ page: page + 1, role: selectedRole });
    setPage(page + 1);
  };

  const handlePreviousPage = (p) => {
    changePage({ page: page - 1, role: selectedRole });
    setPage(page - 1);
  };

  const OnSubmit = (e) => {
    e.preventDefault();
  };

  const OnChangeSearchTerm = (e) => {
    e.preventDefault();
    clearTimeout(timeoutId);
    const id = setTimeout(() => {
      setSearchTerm(e.target.value);
    }, 600);
    setTimeoutId(id);
  };
  
  const renderRolesList = allRoles.map((role) => {
    return (<option key={role.value} id={role.value} value={role.value}>
      {role.display}
    </option>)
  })
  const renderUsers = usersList.map((user) => {
    return (
      <tr key={user.id}>
        <td className="word-break">{user.email}</td>
        <td className="word-break">{user.firstName}</td>
        <td className="word-break">{user.lastName}</td>
        <td>
          {user.market === "drc" ? "DRC" : capitalizeFirstLetter(user.market)}
        </td>
        <td>{user.roleName}</td>
        <td>
          {
            user.isActive && !user.firstTimeLoginStatus ? (
            <div className="status"> <span className="new"> New </span> </div>
          ) :
            user.isActive && user.firstTimeLoginStatus ? (
            <div className="status"> <span className="active"> Active </span> </div>
          ) : (
            <div className="status wrap-inactive">
              <span className="inactive"> Inactive </span>
              <RiInformationLine className="icon" onClick={() => handleGetUserDeactivationReason(user.email)} />
            </div>
          )}
        </td>
        <td>
          {sub === user.id ? (
            <Badge bg="light" text="dark">
              It's you
            </Badge>
          ) : (
            <UserActionMenu
              openDeactivationForm={toggleUserDeactivationForm}
              openResetPasswordForm={toggleShowRestPasswordModal}
              openUserEditForm={toggleUserCreationModal}
              openViewModal={toggleUserViewModal}
              resendUserInvite={handleResendUserInvite}
              resetUserMFA={setDataFormMFARest}
              isDeactivationFlow={user.isActive}
              username={user.email}
              user={user}
            />
          )}
        </td>
      </tr>
    );
  });

  return (
    <div className="page user-page">
      <div className="page-title-row">
        <div className="title">
          <h1>Users</h1>
        </div>
      </div>
      <div className="special">
        <div className="filter-section">
          <div className="filter-components">
            <Form className="form w-100" onSubmit={OnSubmit}>
              <Row className="align-items-center">
                <Col md="2" className="my-1">
                  <Form.Select
                    id="marketSelect"
                    onChange={(e) => setSelectedRole(e.target.value)}
                  >
                    <option id="option1" value="all">
                      All (Roles)
                    </option>
                    {renderRolesList}
                  </Form.Select>
                </Col>
                <Col md="3" className="my-1">
                  <InputGroup>
                    <InputGroup.Text>
                      <IoSearchOutline />
                    </InputGroup.Text>
                    <FormControl
                      id="inlineFormInputGroupUsername"
                      placeholder="Search"
                      onChange={OnChangeSearchTerm}
                    />
                  </InputGroup>
                </Col>
                <Col className="btn-csv">
                  <ShowForPermission permissions={[USER_LIST]}>
                    <Button
                      data-testid="exportButton"
                      variant="primary"
                      id="export-users-button"
                      className="btn-cancel"
                      onClick={handleUserListExport}
                    >
                      <span className="mr-2 ic-export"> <BiExport /> </span>
                      <span className="mx-2">Export CSV</span>
                    </Button>
                  </ShowForPermission>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <div className="btn-user">
          <DeactivateReasonModal
            toggleExternal={showDeactivateReasonModal}
            changeExternalState={setShowDeactivateReasonModal}
            reason={reason}
          />
          <ShowForPermission permissions={[USER_CREATE]}>
            <UserCreateForm
              handleCreateUser={handleCreateUser}
              handleEditUser={handleEditUser}
              isEditFlow={isEditFlow}
              showExternal={showEditUserForm}
              hideExternal={toggleUserCreationModal}
              changeExternalState={changeExternalState}
              initialValues={selectedUser}
            />
          </ShowForPermission>
        </div>
      </div>
      <ConfirmationModal
        show={showMFAResetConfirmation}
        toggleShow={toggleShowResetMFAModal}
        confirm={handleResetUserMFA}
        body={selectedUser?.email}
        title="Are you sure you want to reset MFA for user?"
      />
      {showResetPasswordForm ? (
        <ResetPasswordModal
          user={selectedUser}
          toggleShowRestPasswordModal={toggleShowRestPasswordModal}
        />
      ) : null}
      {showDeactivationForm ? (
        <DeactivateUserModal
          toggleShowDeactivateModal={toggleUserDeactivationForm}
          handleUserDeactivation={handleUserDeactivation}
          handleUserReactivation={handleUserReactivation}
          isDeactivationFlow={isDeactivation}
          selectedUser={selectedUserName}
        />
      ) : null}
      {showViewModal ? (
        <ViewUserModal user={selectedUser} hideExternal={toggleUserViewModal} />
      ) : null}
      <ShowForPermission permissions={[USER_LIST]} showBanner requiresAll>
        <Table>
          <thead>
            <tr>
              <th>Email</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Market</th>
              <th>Role</th>
              <th>Status</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>{renderUsers}</tbody>
        </Table>

        <div className="footer-table d-flex justify-content-between">
          <div>
            <Form.Select onChange={(e) => handleChangePageSize(e.target.value)}>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="60">60</option>
            </Form.Select>
          </div>
          <div className="mt-2">{page}</div>
          <div className="pagination">
            <Button
              variant="primary"
              disabled={page === 1}
              className="btn-link"
              onClick={() => handlePreviousPage()}
            >
              <span>
                {" "}
                <MdKeyboardArrowLeft />{" "}
              </span>
              Back
            </Button>

            <Button
              variant="primary"
              disabled={!hasMorePages}
              className="btn-link mx-3"
              onClick={() => handleNextPage()}
            >
              Next{" "}
              <span>
                {" "}
                <MdKeyboardArrowRight />{" "}
              </span>
            </Button>
          </div>
        </div>
      </ShowForPermission>
      </div>
  );
};

const mapStateToProps = (state) => {
  return {
    usersList: state.users.usersList,
    allUsersList: state.users.rawUsersList,
    hasMorePages: state.users.hasMorePages,
    reason: state.users.reason,
    sub: state.profile?.sub,
    allRoles: state.role.list.map(x => { return { value: x.id, display: x.roleName, market: x.market, isActive: x.isActive } }).filter(y => y.isActive)
  };
};

const mapDispatchToProps = (dispatch) => ({
  createUser: (data) => dispatch(createUser(data)),
  getUsers: (pageSize, searchTerm, role, paginationToken) => dispatch(fetchUsers(pageSize, searchTerm, role, paginationToken)),
  getDeactivationReason: (email) => dispatch(getDeactivationReason(email)),
  deactivateUser: (data) => dispatch(deactivateUser(data)),
  reactivateUser: (data) => dispatch(reactivateUser(data)),
  changePage: (data) => dispatch(changePage(data)),
  editUser: (data) => dispatch(editUser(data)),
  showLoader: (data) => dispatch(showLoader(data)),
  resetUserMFA: (data) => dispatch(resetUserMFA(data)),
  resendUserInvite: (data) => dispatch(resendUserInvite(data)),
  checkPermission: (permission) => dispatch(checkPermission(permission)),
  fetchRoles: (size, page, searchTerm, fetchAll) => dispatch(fetchRoles(size, page, searchTerm, fetchAll))
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
