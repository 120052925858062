import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useCanvas } from "../../context/CanvasContext";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, Button, Form } from "react-bootstrap";
import { createDynamicTemplate, createTemplate, updateDynamicTemplate, updateTemplate } from "../../redux/actions/templateActions";
import { showLoader } from "../../redux/actions/commonActions";
import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG, UNAUTHORIZED_ACTION } from "../../utils/errorMessages";


let createTemplateSchema = yup.object().shape({
  templateName: yup.string().max(50).required("Template name is required"),
  trxCodeHelpText: yup
    .string()
    .max(150, "Maximum length for transaction code help text is 150")
    .required("Transaction code help text is required"),
  cpiHelpText: yup
    .string()
    .max(150, "Maximum length for credit party identifier help text is 150")
    .required("Credit party identifier help text is required"),
  cpnHelpText: yup
    .string()
    .max(150, "Maximum length for credit party name help text is 150")
    .required("Credit party name help text is required"),
});

const SaveTempateModal = ({
  show,
  toggleShow,
  createTemplate,
  updateTemplate,
  updateDynamicTemplate,
  showLoader,
  templateData,
  template = null,
  isEditFlow = false,
  createDynamicTemplate
}) => {
  const history = useHistory();
  const { getConfigs } = useCanvas();

  const handleToggleShow = () => {
    toggleShow();
    reset();
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(createTemplateSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (show) {
      showLoader(true);
      let payload = null;
      if (isEditFlow) {
        payload = {
          templateName: template.templateName,
          trxCodeHelpText: template.trxCodeHelpText,
          cpiHelpText: template.cpiHelpText,
          cpnHelpText: template.cpnHelpText,
        };
      } else {
        payload = {
          templateName: null,
          trxCodeHelpText: null,
          cpiHelpText: null,
          cpnHelpText: null,
        };
      }
      reset(payload);
      showLoader(false);
    }
  }, [reset, isEditFlow, template, showLoader, show]);

  const handleCreateTemplate = async (data) => {
    try {
      showLoader(true);
      templateData.templateName = data.templateName;
      templateData.trxCodeHelpText = data.trxCodeHelpText;
      templateData.cpiHelpText = data.cpiHelpText;
      templateData.cpnHelpText = data.cpnHelpText;


      // handle dynamic templates save
      if (templateData.market === "general" && templateData.isDynamic) {
        templateData.isGeneral = true;
        if (isEditFlow) {
          templateData.id = template.id;
          await updateDynamicTemplate(templateData);
          toast.success("Template updated successfully");
        } else {
          await createDynamicTemplate(templateData);
          toast.success("New dynamic template created successfully");
        }
        toggleShow();
      } else {
        // gets the configs form the editor
        const configs = getConfigs();
        templateData.configurations = configs.configurations;
        templateData.additionalFields = configs.additionalFields;
        templateData.path = configs.path;
        templateData.isDefault = false;

        if (isEditFlow) {
          templateData.id = template.id;
          templateData.path = configs.path;
          await updateTemplate(templateData);
          toast.success("Template updated successfully");
        } else {
          await createTemplate(templateData);
          toast.success("Template created successfully");
        }
        history.push("/templates");
      }
      showLoader(false);

    } catch (e) {
      showLoader(false);
      console.log(e)
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data.Description);
      } else if (e.response.data) {
        toast.error(e.response.data);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleToggleShow}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title data-testid="modal-title" id="modal-title">
            {isEditFlow ? "Edit" : "Save"} Template
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(handleCreateTemplate)}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="templateName">
              <Form.Label>Template Name *</Form.Label>
              <Form.Control
                data-testid="templateName"
                type="text"
                {...register("templateName", { required: true })}
                autoComplete="off"
                placeholder="Template Name"
                isInvalid={errors.templateName}
              />
              <Form.Control.Feedback id="templateNameErrors" type="invalid">
                {errors.templateName?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="trxCodeHelpText">
              <Form.Label>Transaction Code Help Text *</Form.Label>
              <Form.Control
                data-testid="trxCodeHelpText"
                type="text"
                as="textarea"
                rows={4}
                {...register("trxCodeHelpText", { required: true })}
                autoComplete="off"
                placeholder="Transaction code help text"
                isInvalid={errors.trxCodeHelpText}
              />
              <Form.Control.Feedback id="trxCodeHelpTextErrors" type="invalid">
                {errors.trxCodeHelpText?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="cpiHelpText">
              <Form.Label>Credit Party Identifier Help Text *</Form.Label>
              <Form.Control
                data-testid="cpiHelpText"
                type="text"
                as="textarea"
                rows={4}
                {...register("cpiHelpText", { required: true })}
                autoComplete="off"
                placeholder="Credit party name help text"
                isInvalid={errors.cpiHelpText}
              />
              <Form.Control.Feedback id="cpiHelpTextErrors" type="invalid">
                {errors.cpiHelpText?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="cpnHelpText">
              <Form.Label>Credit Party Name Help Text *</Form.Label>
              <Form.Control
                data-testid="cpnHelpText"
                type="text"
                as="textarea"
                rows={4}
                {...register("cpnHelpText", { required: true })}
                autoComplete="off"
                placeholder="Credit party name help text"
                isInvalid={errors.cpnHelpText}
              />
              <Form.Control.Feedback id="cpnHelpTextErrors" type="invalid">
                {errors.cpnHelpText?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              data-testid="btnCancel"
              variant="primary"
              className="btn-cancel mx-3"
              onClick={() => handleToggleShow()}
            >
              Cancel
            </Button>
            {isEditFlow ? (
              <Button
                variant="primary"
                type="submit"
                data-testid="btnSaveChangesTemplate"
              >
                Save Changes
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                data-testid="btnSaveTemplate"
                disabled={!isValid || !isDirty}
              >
                Save Template
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    templateData: state.templates.createTemplateData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createTemplate: (data) => dispatch(createTemplate(data)),
  showLoader: (data) => dispatch(showLoader(data)),
  updateTemplate: (data) => dispatch(updateTemplate(data)),
  updateDynamicTemplate: (data) => dispatch(updateDynamicTemplate(data)),
  createDynamicTemplate: (data) => dispatch(createDynamicTemplate(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveTempateModal);
