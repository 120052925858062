import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Col, Row, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { GrLanguage } from "react-icons/gr";
import Badge from "react-bootstrap/Badge";
import {
  changeSelectedTemplate,
  getTemplates,
  showLoader,
  changeTemplateFlow,
  clearQRTemplateStore,
  activateOrDeactivateTemplate,
} from "../../redux/actions/templateActions";
import {
  QR_TEMPLATE_CREATE,
  QR_TEMPLATE_LIST,
} from "../../utils/permissionList";
import CreateTemplateModal from "../../components/modals/CreateTemplateModal";
import TemplateMenu from "../../components/action-menus/TemplateMenu";
import ViewTemplateModal from "../../components/modals/ViewTemplateModal";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { ShowForPermission } from "../../components/ShowForPermission";
import {
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACTION,
} from "../../utils/errorMessages";
import { getLanguages } from "../../redux/actions/languageActions";
import { receiveMarketData } from "../../redux/actions/profileActions";
import SaveTemplateModal from "../../components/modals/SaveTemplateModal";

const CustomTemplates = ({
  templates = [],
  selectTemplate,
  userGroups,
  isLoading,
  getTemplateList,
  changeTemplateFlow,
  getLanguageList,
  activateOrDeactivateTemplate,
  isSuperAdmin,
  marketOptions = [],
  clearStore,
  receiveMarketData,
}) => {
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState("tanzania");
  const [template, setTemplate] = useState(false);
  const [isEditFlow, setIsEditFlow] = useState(false);
  const [showViewTemplateModal, setShowViewTemplateModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);

  const toggleTemplateActivityModal = (template) => {
    setTemplate(template);
    setShowConfirmation(true);
  };

  const toggleViewTemplateModal = (template) => {
    setTemplate(template);
    toggleShowViewTemplateModal();
  };
  const toggleEditTemplateModal = (template) => {
    changeTemplateFlow("EDIT");
    selectTemplate(template);
    setTemplate(template);
    setIsEditFlow(true);
    toggleShow();
  };

  useEffect(() => {
    isLoading(true);
    getLanguageList({ isDropdown: true, isActiveOnly: true });
    getTemplateList(selectedMarket)
      .then((result) => {
        isLoading(false);
      })
      .catch((e) => {
        if (e.response.status && e.response.status === 403) {
          toast.error(UNAUTHORIZED_ACTION);
        } else if (e.response.data.Description) {
          toast.error(e.response.data.Description);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        isLoading(false);
      });
  }, [selectedMarket, getTemplateList, isLoading, changeTemplateFlow]);

  useEffect(() => {
    receiveMarketData();
  }, []);


  // clear the store on unmount 
  useEffect(() => () => clearStore(), [clearStore]);

  const handleCreateTemplate = () => {
    changeTemplateFlow("CREATE");
    setIsEditFlow(false);
    toggleShow();
  };

  const toggleShow = () => setShowCreateTemplateModal((p) => !p);
  const toggleShowViewTemplateModal = () => setShowViewTemplateModal((p) => !p);
  const toggleShowSaveTemplateModal = () => setShowSaveTemplateModal((p) => !p);

  const handleTemplateActivationAndDeactivation = () => {
    const data = { templateId: template.id, isActivated: template.isActive };
    isLoading(true);
    activateOrDeactivateTemplate(data)
      .then((result) => {
        getTemplateList(selectedMarket)
          .then((result) => {
            toast.success(
              `Template ${template.isActive ? "Deactivated" : "Activated"}`
            );
            isLoading(false);
          })
          .catch((err) => {
            toast.error("Something went wrong");
            isLoading(false);
          });
      })
      .catch((e) => {
        if (e.response.status && e.response.status === 403) {
          toast.error(UNAUTHORIZED_ACTION);
        } else if (e.response.data.Description) {
          toast.error(e.response.data.Description);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        isLoading(false);
      });
  };

  const renderTemplates = templates.map((template) => {
    return (
      <Col lg={3} md={6} sm={6} key={template.id}>
        <Card id={template.id + "-" + template.language}>
          <div className="temp-card-dropdown d-flex justify-content-end">
            {template.isActive ? (
              <TemplateMenu
                template={template}
                toggleTemplateActivity={toggleTemplateActivityModal}
                viewTemplate={toggleViewTemplateModal}
                editTemplate={toggleEditTemplateModal}
                templateIsActive={template?.isActive}
                templateIsGeneral={template?.market.includes("general")}
                userIsSuperAdmin={isSuperAdmin}
              />
            ) : (
              <div className="d-flex w-100 align-items-center justify-content-between">
                <Badge bg="dark" text="light">
                  Deactivated
                </Badge>
                <TemplateMenu
                  template={template}
                  toggleTemplateActivity={toggleTemplateActivityModal}
                  viewTemplate={toggleViewTemplateModal}
                  editTemplate={toggleEditTemplateModal}
                  templateIsActive={template?.isActive}
                  templateIsGeneral={template?.market.includes("general")}
                  userIsSuperAdmin={isSuperAdmin}
                />
              </div>
            )}
          </div>
          {template.isActive ? (
            <div>
              <div className="wrap-img">
                <Card.Img
                  variant="top"
                  src={template.pngTemplatePath}
                  alt={template.templateName}
                />
              </div>
              <Card.Body className="d-flex justify-content-between">
                <Card.Text id={template.id + "-" + template.language + "-name"}>
                  {template.templateName}
                </Card.Text>

                {template.isDefault ? (
                  <Button
                    data-testid="cancelButton"
                    variant="primary"
                    className="btn-cancel"
                    id={
                      "btn-" + template.id + "-" + template.language + "-lang"
                    }
                  >
                    <span>Default</span>
                  </Button>
                ) : (
                  <Button
                    data-testid="cancelButton"
                    variant="primary"
                    className="btn-cancel"
                    id={
                      "btn-" + template.id + "-" + template.language + "-lang"
                    }
                  >
                    <GrLanguage className="lcon-lang" />
                    <span className="txt-lan">{template.language}</span>
                  </Button>
                )}
              </Card.Body>
            </div>
          ) : (
            <div className="deactivated">
              <div className="wrap-img">
                <Card.Img
                  variant="top"
                  src={template.pngTemplatePath}
                  alt={template.templateName}
                />
              </div>
              <Card.Body className="d-flex justify-content-between">
                <Card.Text id={template.id + "-" + template.language + "-name"}>
                  {template.templateName}
                </Card.Text>

                {template.isDefault ? (
                  <Button
                    data-testid="cancelButton"
                    variant="primary"
                    className="btn-cancel"
                    id={
                      "btn-" + template.id + "-" + template.language + "-lang"
                    }
                  >
                    <span>Default</span>
                  </Button>
                ) : (
                  <Button
                    data-testid="cancelButton"
                    variant="primary"
                    className="btn-cancel"
                    id={
                      "btn-" + template.id + "-" + template.language + "-lang"
                    }
                  >
                    <GrLanguage className="lcon-lang" />
                    <span className="txt-lan">{template.language}</span>
                  </Button>
                )}
              </Card.Body>
            </div>
          )}
        </Card>
      </Col>
    );
  });

  const toggleSetShowConfirmation = () => setShowConfirmation((p) => !p);

  return (
    <div className="page qr-genarate-page">
      <ViewTemplateModal
        template={template}
        show={showViewTemplateModal}
        toggleShow={toggleShowViewTemplateModal}
      />
        <CreateTemplateModal
          show={showCreateTemplateModal}
          template={template}
          selectedMarket={selectedMarket}
          toggleShow={toggleShow}
          isEditFlow={isEditFlow}
          openSaveModal={toggleShowSaveTemplateModal}
        />
      <ConfirmationModal
        show={showConfirmation}
        toggleShow={toggleSetShowConfirmation}
        confirm={handleTemplateActivationAndDeactivation}
        title={`Are you sure you want to ${
          template.isActive ? "Deactivate" : "Activate"
        } this template?`}
      />

      <SaveTemplateModal show={showSaveTemplateModal} toggleShow={toggleShowSaveTemplateModal} template={template} isEditFlow={isEditFlow} />

      <div className="page-title-row d-flex justify-content-between">
        <div className="title">
          <h1>Templates</h1>
          <div className="subtitle-1">
            Create and edit custom qr code templates
          </div>
        </div>
        <div className="filter-section">
          <div className="filter-components">
            <Form className="form w-100">
              <div className="align-items-center d-flex">
                <div className="my-1 mx-2">
                  {isSuperAdmin ? (
                    <Form.Select
                      style={{ paddingRight: "36px" }}
                      id="marketSelect"
                      onChange={(e) => setSelectedMarket(e.target.value)}
                    >
                      {marketOptions.map((market) => {
                        return (
                          <option key={market.value} value={market.value}>
                            {market.displayName}
                          </option>
                        );
                      })}
                      <option value="general">General</option>
                    </Form.Select>
                  ) : (
                    <Form.Select
                      style={{ paddingRight: "36px" }}
                      id="marketSelect"
                      onChange={(e) => setSelectedMarket(e.target.value)}
                    >
                      {marketOptions
                        ? marketOptions.length > 0 &&
                          marketOptions
                            .filter((x) => userGroups && userGroups.includes(x.userGroup))
                            .map((y) => {
                              return (
                                <option value={y.value}>{y.displayName}</option>
                              );
                            })
                        : null}
                    </Form.Select>
                  )}
                </div>
                <div className="btn-csv">
                  <ShowForPermission permissions={[QR_TEMPLATE_CREATE]}>
                    <Button
                      variant="primary"
                      id="createTemplate"
                      onClick={handleCreateTemplate}
                    >
                      <span className="mx-2">Create Template</span>
                    </Button>
                  </ShowForPermission>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <div className="card-set-qr">
        <ShowForPermission permissions={[QR_TEMPLATE_LIST]} showBanner>
          <Row>{renderTemplates}</Row>
        </ShowForPermission>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    templates: state.templates.list,
    userGroups: state.profile?.groups,
    isSuperAdmin: state.permission.isSuperAdmin,
    marketOptions: state.profile.markets,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTemplateList: (market) => dispatch(getTemplates(market)),
  selectTemplate: (data) => dispatch(changeSelectedTemplate(data)),
  isLoading: (flag) => dispatch(showLoader(flag)),
  changeTemplateFlow: (flag) => dispatch(changeTemplateFlow(flag)),
  activateOrDeactivateTemplate: (data) =>
    dispatch(activateOrDeactivateTemplate(data)),
  getLanguageList: (filters) => dispatch(getLanguages(filters)),
  clearStore: () => dispatch(clearQRTemplateStore()),
  receiveMarketData: () => dispatch(receiveMarketData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomTemplates);
