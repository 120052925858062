import { authConfig } from "../config/authConfig";
import { UserManager, WebStorageStateStore } from "oidc-client"

export default class AuthService {
  UserManager;
  accessToken;

  constructor() {
    this.UserManager = new UserManager({
      ...authConfig,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        ...authConfig.metadata
      }
    });
    // Logger
    // Log.logger = console;
    // Log.level = Log.DEBUG;

    this.signinCallbackPath = 'signin'

    this.UserManager.events.addUserLoaded(user => {
      this.setUserInfo({
        accessToken: user.access_token,
        idToken: user.id_token
      });

    });
    this.UserManager.events.addSilentRenewError(e => {
      console.log("silent renew error", e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      // console.log("token expired");
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    return this.UserManager.signinRedirectCallback()
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = token => {
    const base64Url = token.split(".")[1];
    if(base64Url) {
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(window.atob(base64));
    } else {
      try {
        const parsedToken = JSON.parse(window.atob(token))
        return parsedToken
      }
      catch(error) {
        console.log(error.message)
        return token
      }
    }
  };

  setUserInfo = authResult => {
    const accessData = this.parseJwt(authResult.accessToken);
    const idData = this.parseJwt(authResult.idToken);

    // attempt to normalize result data
    const data1 = typeof accessData === 'string' ? {accessToken: authResult.accessToken} : {...accessData}
    const data2 = typeof idData === 'string' ? {idToken: authResult.idToken} : {...idData}

    const data = {
      ...data1,
      ...data2
    }

    this.setSessionInfo(authResult);
    this.setUser(data);
  };

  signinRedirect = () => {
    // localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  setUser = data => {
    localStorage.setItem("userId", data.sub);
  };

  navigateToScreen = () => {
    const redirectUri = !!localStorage.getItem("redirectUri")
      ? localStorage.getItem("redirectUri")
      : "/dashboard";

    // navigate(redirectUri);
  };

  setSessionInfo(authResult) {
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
  }

  isAuthenticated = () => {
    const access_token = localStorage.getItem("access_token");
    return !!access_token;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then(user => {
        // console.log("signed in", user);
      })
      .catch(err => {
        console.log(err);
      });
  };
  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({id_token_hint: 'null', extraQueryParams:{
      client_id: authConfig.client_id,
      logout_uri: authConfig.logout_uri
    }});
    this.UserManager.clearStaleState();
  };

  expireSession = () => {
    this.UserManager.clearStaleState();
    localStorage.clear();
    this.UserManager.signoutRedirect({ id_token_hint: 'null',extraQueryParams:{
      client_id: authConfig.client_id,
      logout_uri: authConfig.session_expire_url
    }});
  };


  signoutRedirectCallback = () => {
    this.UserManager.clearStaleState();
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      // window.location.replace(process.env.REACT_APP_PUBLIC_URL);
      // navigate(process.env.REACT_APP_PUBLIC_URL)
    });
  };
}