import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { clearReason } from "../../redux/actions/userActions";

const CSVErrosModal = ({ errors = [], toggleExternal, changeExternalState }) => {
  const [show, setShow] = useState(false);

  useEffect(()=> {
    setShow(toggleExternal) 
  },[toggleExternal])

  const handleClose = () => {
    setShow(false);
    changeExternalState(false);
  };

  const renderErrors = errors.map((error, i) =>{ 
    return (<React.Fragment key={i}><div className="message-error">{error}</div></React.Fragment>)
  })

  return (
    <div className="csv">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="pb-3"  closeButton>
          <Modal.Title id="view-reason-modal-title" data-testid="csv-errors-modal-title" className="question">CSV Errors</Modal.Title>
        </Modal.Header>
        <Modal.Body id="error-body-csv" data-testid="error-body-csv">
          {renderErrors}
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearReason: () => dispatch(clearReason()),
});

export default connect(null, mapDispatchToProps)(CSVErrosModal);
