import axios from "axios";
import {
  QR_SPEC_TEST_ENCODE_SUCCESS,
  QR_SPEC_TEST_ENCODE_ERROR,
  QR_SPEC_TEST_DECODE_SUCCESS,
  QR_SPEC_TEST_DECODE_ERROR,
  TOGGLE_LOADER,
  SET_INITIAL_STATE,
} from "../actions/actionTypes";

// test QR specification
export const testApiEncode = (payload) => async (dispatch) => {

  const data = JSON.stringify(payload.dataParams);
  const headerFields = JSON.stringify(payload.headerFields);
  const isCorrelationId = headerFields.includes("X-CORRELATION_ID")
    ? true
    : false;
  const isSourceSystem = headerFields.includes("X-SOURCE_SYSTEM")
    ? true
    : false;
  const header = {
    Authorization: `Bearer ${
      payload.authorization !== "" && headerFields.includes("AUTHORIZATION")
        ? payload.authorization
        : localStorage.getItem("access_token")
    }`,
    "Content-Type": "application/json",
  };

  if (isCorrelationId) {
    header["x-correlation-id"] = payload["x-correlation-id"];
  }

  if (isSourceSystem) {
    header["x-source-system"] = payload["x-source-system"];
  }

  const config = {
    method: "post",
    url: payload.url,
    headers: header,
    data,
  };

  axios(config)
    .then(function (response) {
      payload.type === "encode" &&
        dispatch({
          type: QR_SPEC_TEST_ENCODE_SUCCESS,
          payload: {
            responseBody: response.data,
            responseHeaders: response.headers,
            status: response.status,
            statusText: response.statusText,
          },
        });

      payload.type === "decode" &&
        dispatch({
          type: QR_SPEC_TEST_DECODE_SUCCESS,
          payload: {
            responseBody: response.data,
            responseHeaders: response.headers,
            status: response.status,
            statusText: response.statusText,
          },
        });

      dispatch({
        type: TOGGLE_LOADER,
        payload: false,
      });
    })
    .catch(function (error) {
      if (error.response) {
        payload.type === "encode" &&
          dispatch({
            type: QR_SPEC_TEST_ENCODE_ERROR,
            payload: {
              responseBody: error.response.data,
              responseHeaders: error.response.headers,
              status: error.response.status,
              statusText: "ERROR",
            },
          });

        payload.type === "decode" &&
          dispatch({
            type: QR_SPEC_TEST_DECODE_ERROR,
            payload: {
              responseBody: error.response.data,
              responseHeaders: error.response.headers,
              status: error.response.status,
              statusText: "ERROR",
            },
          });
      }

      dispatch({
        type: TOGGLE_LOADER,
        payload: false,
      });
    });
};

export const setInitialState = () => async (dispatch) => {
  dispatch({
    type: SET_INITIAL_STATE,
  });
}


