import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import history from "./history";

import { CLEAR_ALL } from "../redux/actions/actionTypes";
import { connect } from "react-redux";

import Users from "./UsersPage";
import RolesPage from "./settings/roles/RolesPage";
import CreateRolePage from "./settings/roles/CreateRolePage";
import SelectTemplate from "./generate-qr/SelectTemplatePage";
import Canvas from "./dynamic-templates/Canvas";
import DynamicTemplates from "./dynamic-templates/DynamicTemplates";
import FillParameters from "./generate-qr/FillParametersPage";
import ProtectedRoute from "./ProtectedRoute";
import LoginPage from "./auth/LoginPage";
import RouteNotFound from "./RouteNotFound";
import Callback from "./auth/Callback";
import AppLayout from "../components/layouts/AppLayout";
import TransparentLoader from "../components/loaders/TransparentLoader";
import AuditPage from "./audits/AuditPage";
import MFASetup from "./auth/MFASetup";
import SessionExpired from "./auth/SessionExpired";
import QRSpecsList from "./settings/qr-specs/QRSpecsList";
import StepOne from "./settings/qr-specs/new-spec/StepOne";
import StepTwo from "./settings/qr-specs/new-spec/StepTwo";
import StepThree from "./settings/qr-specs/new-spec/StepThree";
import ViewQRSpec from "./settings/qr-specs/ViewQRSpec";
import LanguagesPage from "./settings/LanguagesPage";
import NetworksPage from "./settings/NetworksPage";
import MarketsPage from "./settings/MarketsPage";
import TestQRSpec from "./settings/qr-specs/TestQRSpec";
import InReview from "./settings/qr-specs/transitions/InReview";
import SubmitForReview from "./settings/qr-specs/transitions/SubmitForReview";
import TransactionTypesPage from "./settings/TransactionTypesPage";

const Routes = ({ loggedIn, loading, logout }) => {
  return (
    <>
      {loading ? <TransparentLoader /> : null}
      <Router history={history}>
        <Switch>
          <Route
            path="/callback"
            exact
            render={(props) => <Callback {...props} />}
          ></Route>
          <ProtectedRoute
            exact
            component={LoginPage}
            open={!loggedIn}
            path="/"
          />
          <ProtectedRoute
            exact
            open={!loggedIn}
            component={SessionExpired}
            path="/session-expired"
          />
          <ProtectedRoute
            exact
            open={!loggedIn}
            component={RouteNotFound}
            path="/not-found"
          />
          <AppLayout>
            <Switch>
              <ProtectedRoute
                roles={["super_admin", "market_admin"]}
                exact
                open={loggedIn}
                component={MFASetup}
                path="/mfa-setup"
                redirect="/"
              />
              <ProtectedRoute
                roles={["super_admin", "market_admin"]}
                exact
                open={loggedIn}
                component={Users}
                path="/users"
                redirect="/"
              />
              <ProtectedRoute
                roles={["super_admin", "market_admin", "general_user"]}
                exact
                open={loggedIn}
                component={SelectTemplate}
                path="/generate-qr"
                redirect="/"
              />
              {/* <CanvasProvider> */}
                <ProtectedRoute
                  roles={["super_admin", "market_admin"]}
                  exact
                  open={loggedIn}
                  component={DynamicTemplates}
                  path="/templates"
                  redirect="/"
                />
                <ProtectedRoute
                  roles={[]}
                  exact
                  open={loggedIn}
                  component={Canvas}
                  path="/templates/editor"
                  redirect="/"
                />
              {/* </CanvasProvider> */}

              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={FillParameters}
                path="/generate-qr/fill-params"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={AuditPage}
                path="/audits"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={QRSpecsList}
                path="/settings/qr-specs"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={StepOne}
                path="/settings/qr-specs/create/step1"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={StepTwo}
                path="/settings/qr-specs/create/step2"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={StepThree}
                path="/settings/qr-specs/create/step3"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={ViewQRSpec}
                path="/settings/qr-specs/view"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={TestQRSpec}
                path="/settings/qr-specs/test"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={SubmitForReview}
                path="/settings/qr-specs/submit-for-review"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={InReview}
                path="/settings/qr-specs/review"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={RolesPage}
                path="/settings/roles"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={CreateRolePage}
                path="/settings/roles/create"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={CreateRolePage}
                path="/settings/roles/edit"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={LanguagesPage}
                path="/settings/languages"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={NetworksPage}
                path="/settings/networks"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={TransactionTypesPage}
                path="/settings/transaction-types"
                redirect="/"
              />
              <ProtectedRoute
                roles={[]}
                exact
                open={loggedIn}
                component={MarketsPage}
                path="/settings/markets"
                redirect="/"
              />
              <Route path="*">
                <Redirect to="/not-found"></Redirect>
              </Route>
            </Switch>
          </AppLayout>
        </Switch>
      </Router>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: !!state.token.accessToken,
  loading: state.templates.loading,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch({ type: CLEAR_ALL }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
