import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { generateStateAndNonce } from "../../redux/actions/tokenActions";
import { AuthService } from "../../components/AuthService";
import {
  Form,
  Button,
  Image,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { showLoader } from "../../redux/actions/commonActions";
import { getMFAQRCode, verifyMFACode } from "../../redux/actions/userActions";
import { getCurrentUserPermissions } from "../../redux/actions/permissionActions";
import { showQRCodeSideBarItem, showAuditLogsSideBarItem, showSettingsSideBarItem, showTemplatesSideBarItem, showNetworksSecondarySideBarItem, showQRSpecSecondarySideBarItem, showLanguagesSecondarySideBarItem, showRolesSecondarySideBarItem, showUserManagementSideBarItem, showMarketsSecondarySideBarItem, showTransactionTypesSecondarySideBarItem } from "../../utils/permissionList";

const createSchema = yup.object().shape({
  totp: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6)
    .max(6)
    .required("code is required"),
});
const MFASetup = ({ getUserPermissions, showLoader, getMFAQRCode, verifyMFACode }) => {

  const [qrCode, setQRCode] = useState(null);
  const history = useHistory();

  useEffect(() => {
    async function getQRCodeAsync() {
      showLoader(true);
      const result = await getMFAQRCode();
      setQRCode(result.qrCode);
      showLoader(false);
    };
    getQRCodeAsync();
  }, [getMFAQRCode, showLoader]);

  const getPathForSettings = (currentUserPermissions) => {
    if (showRolesSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/roles";
    } else if (showQRSpecSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/qr-specs";
    } else if (showLanguagesSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/languages"
    } else if (showNetworksSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/networks";
    } else if (showMarketsSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/markets";
    } else if (showTransactionTypesSecondarySideBarItem(currentUserPermissions)) {
      return "/settings/transaction-types";
    }
  }

  const onSubmit = async (data) => {
    try {
      showLoader(true);
      const result = await verifyMFACode(data.totp);

      if (result.response && (result.response.status === 400)) {
        toast.error("Invalid or Expired Code");
      } else if (result.isOTPValid) {
        const result = await getUserPermissions();
        const currentUserPermissions = result.data.map(x => x.permissionId);

        if (showQRCodeSideBarItem(currentUserPermissions)) {
          history.push("/generate-qr");
        }

        else if (showUserManagementSideBarItem(currentUserPermissions)) {
          history.push("/users");
        }

        else if (showAuditLogsSideBarItem(currentUserPermissions)) {
          history.push("/audits");
        }

        else if (showTemplatesSideBarItem(currentUserPermissions)) {
          history.push("/templates");
        }

        else if (showSettingsSideBarItem(currentUserPermissions)) {
          history.push(getPathForSettings(currentUserPermissions));
        }
        showLoader(false);
      }
    } catch (e) {
      if (e.response) {
        toast.error(e.response.data.Description);
      } else {
        toast.error("Something went wrong");
      }
      showLoader(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: { totp: null },
    resolver: yupResolver(createSchema),
    mode: "all",
  });

  return (
    <div className="page mfa-setup-page">
      <div className="page-title-row">
        <div className="title m-auto">
          <h1 test-id="page-header">Setup Authenticator</h1>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        {qrCode == null ? null : (<div className="card mfa-setup-instructions">
          <div className="card-body">
            <Form className="form" onSubmit={handleSubmit(onSubmit)}>

              <div className="row">
                <div className="col-12 col-lg-6 col-md-6 col-xl-5">
                  <div className="mt-4">
                    <Image src={`data:image/png;base64,${qrCode}`} rounded height="250" width="250" />
                  </div>
                  <Form.Group className="mt-3 p-2 mb-3" controlId="email">
                    <Form.Label>TOTP</Form.Label>
                    <Form.Control
                      data-testid="totp"
                      type="totp"
                      {...register("totp", { required: true })}
                      autoComplete="off"
                      placeholder="Enter code"
                      isInvalid={errors.totp}
                    />
                    <Form.Control.Feedback id="totpErrors" type="invalid">
                      {errors.totp?.message}
                    </Form.Control.Feedback>
                    <Button
                      data-testid="verifyButton"
                      className="mt-2"
                      variant="primary"
                      type="submit"
                      disabled={!isValid || !isDirty}
                    >
                      Verify
                    </Button>
                  </Form.Group>

                </div>
                <div className="col-12 col-lg-6 col-md-6 col-xl-7">
                  <div className="mt-5">
                    1. Install Google authentictor app on your mobile device.
                  </div>
                  <div className="mt-4">
                    2. To setup up your multi-factor authentication, scan the QR code
                    using the Google Authenticator App
                  </div>
                  <div className="mt-4">
                    3. Enter the 6 digin verification code generated by Google
                    Authenticator App
                  </div>
                </div>

              </div>
            </Form>
          </div>
        </div>)}

      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile
});

const mapDispatchToProps = (dispatch) => ({
  getUserPermissions: () => dispatch(getCurrentUserPermissions()),
  generateParams: () => dispatch(generateStateAndNonce()),
  login: () => dispatch(AuthService.login()),
  getMFAQRCode: () => dispatch(getMFAQRCode()),
  verifyMFACode: (code) => dispatch(verifyMFACode(code)),
  showLoader: (data) => dispatch(showLoader(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MFASetup);
