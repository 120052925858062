import React, { useState, useEffect } from "react";
import { Modal, ListGroup, Card, Tabs, Tab, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { getTemplate, showLoader } from "../../redux/actions/templateActions";
import { formatDateTime } from "../../utils/timeFormat";
import { capitalizeFirstLetter } from "../../utils/stringFormat";


const ViewTemplateModal = ({
  template,
  show,
  toggleShow,
  getTemplate,
  showLoader,
  languages = [],
  market
}) => {
  const [detailedTemplate, setDetailedTemplate] = useState(null);


  useEffect(() => {
    if (show) {
      async function getTemplateAsync() {
        showLoader(true);
        const result = await getTemplate(template.id);
        setDetailedTemplate(result.data);
        showLoader(false);
      }
      getTemplateAsync();
    }
  }, [template, getTemplate, showLoader, show]);

  const filterLanguageName = (shortCode, market) => {
    const filterd = languages.filter(x => x.shortCode3 === shortCode && x.market === market);
    if (filterd.length > 0) {
      return filterd[0].name;
    }
    return null
  }

  return (
    <>
      {show &&
        <Modal show={show} onHide={toggleShow} dialogClassName="view-template-modal" size="lg">
          <Modal.Header closeButton>
            <Modal.Title id="view-modal-title">View Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              <ListGroup.Item style={{ width: "100%", flexWrap: "nowrap" }}>
                <Tabs
                  defaultActiveKey="home"
                  transition={false}
                  id="noanim-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="home" title="Uploaded PDF">
                    <img
                      style={{ maxWidth: "100%" }}
                      src={template.pngTemplatePath}
                      alt={template.templateName}
                    />
                  </Tab>
                  <Tab eventKey="profile" title="Template Details">
                    <div>
                      <Card>
                        <Card.Body>
                          <ListGroup>
                            <ListGroup.Item>
                              <label id="template-name-label">
                                Template Name
                              </label>
                              <span id="template-name-value">
                                {detailedTemplate?.templateName}
                              </span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <label id="template-language-label">
                                Language
                              </label>
                              <span
                                id="template-language-value"
                                className="word-break"
                              >
                                {filterLanguageName(detailedTemplate?.language, detailedTemplate?.market)}
                              </span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <label id="template-market-label"> Market </label>
                              <span id="market-value">
                                {detailedTemplate?.market === null ||
                                  detailedTemplate?.market === null
                                  ? "-"
                                  : capitalizeFirstLetter(
                                    detailedTemplate?.market
                                  )}
                              </span>
                            </ListGroup.Item>

                            <ListGroup.Item>
                              <label id="template-active-label"> Status </label>
                              {detailedTemplate?.isActive ? (
                                <span
                                  id="template-active-value"
                                  className="word-break"
                                  style={{ color: "green" }}
                                >
                                  Active
                                </span>
                              ) : (
                                <span
                                  id="template-active-value"
                                  className="word-break"
                                  style={{ color: "red" }}
                                >
                                  Inactive
                                </span>
                              )}
                            </ListGroup.Item>

                            <ListGroup.Item>
                              <label id="created-date-label">Created Date</label>
                              <span id="created-date-value">
                                {formatDateTime(detailedTemplate?.createdDate)}
                              </span>
                            </ListGroup.Item>
                          </ListGroup>
                        </Card.Body>
                      </Card>
                      <div className="credit-party-details">

                        <Card className="view-template-stats">
                          <Card.Header as="h6">Credit Party Identifier</Card.Header>
                          <ListGroup.Item>
                            <div className="d-flex w-100 align-items-center justify-content-between">
                              <div>
                                <label id="action-label"> Min Length</label>
                                <span id="action-value">
                                  {detailedTemplate?.validations.cpiMinLength}
                                </span>
                              </div>
                              <div>
                                <label id="action-label"> Max Length</label>
                                <span id="action-value">
                                  {detailedTemplate?.validations.cpiLength}
                                </span>
                              </div>
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <label id="action-label"> Format(Allow A-Z)</label>
                            <span id="action-value">
                              {detailedTemplate?.validations.cpiFormat.hasAlphabets ? "Yes" : "No"}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <label id="action-label"> Format(Allow 0-9)</label>
                            <span id="action-value">
                              {detailedTemplate?.validations.cpiFormat.hasNumbers ? "Yes" : "No"}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <label id="action-label"> Help Text</label>
                            <span id="action-value">
                              {detailedTemplate?.cpiHelpText}
                            </span>
                          </ListGroup.Item>
                        </Card>
                        <Card className="view-template-stats">
                          <Card.Header as="h6">Credit Party Name</Card.Header>
                          <ListGroup.Item>
                            <div className="d-flex w-100 align-items-center justify-content-between">
                              <div>
                                <label id="action-label"> Min Length</label>
                                <span id="action-value">
                                  {detailedTemplate?.validations.cpnMinLength}
                                </span>
                              </div>
                              <div>
                                <label id="action-label"> Max Length</label>
                                <span id="action-value">
                                  {detailedTemplate?.validations.cpnLength}
                                </span>
                              </div>
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <label id="action-label">Display In Template</label>
                            <span id="action-value">
                              {detailedTemplate?.displayCPNInTemplate ? "Yes" : "No"}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <label id="action-label"> Format(Allow A-Z)</label>
                            <span id="action-value">
                              {detailedTemplate?.validations.cpnFormat.hasAlphabets ? "Yes" : "No"}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <label id="action-label"> Format(Allow 0-9)</label>
                            <span id="action-value">
                              {detailedTemplate?.validations.cpnFormat.hasNumbers ? "Yes" : "No"}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <label id="action-label">
                              {" "}
                              Format(Allow Special Characters)
                            </label>
                            <span id="action-value">
                              {detailedTemplate?.validations.cpnFormat.hasSpecialChars ? "Yes" : "No"}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <label id="action-label">
                              {" "}
                              Format(Allow Any Characters)
                            </label>
                            <span id="action-value">
                              {detailedTemplate?.validations.cpnFormat.hasAnyChars ? "Yes" : "No"}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <label id="action-label"> Help Text</label>
                            <span id="action-value">
                              {detailedTemplate?.cpnHelpText}
                            </span>
                          </ListGroup.Item>
                        </Card>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="additionalFields" title="Additional Fields" className="pt-3">
                    <Table >
                      <thead>
                        <tr>
                          <th>Field Name</th>
                          <th className="text-center">User Input</th>
                          <th className="text-center">Display in Template</th>
                        </tr>
                      </thead>
                      <tbody>

                        {detailedTemplate?.additionalFields.map((field, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {field.mpaFieldName}
                              </td>
                              <td className="text-center">
                                {field.requiresUserInput === true ? "Yes" : "No"}
                              </td>
                              <td className="text-center">
                                {field.displayInTemplate === true ? "Yes" : "No"}
                              </td>
                            </tr>)
                        })}

                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
              </ListGroup.Item>
            </ListGroup>
          </Modal.Body>
        </Modal>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sub: state.profile.sub,
    groups: state.profile.groups,
    market: state.profile.market,
    languages: state.language.list
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTemplate: (templateId) => dispatch(getTemplate(templateId)),
  showLoader: (data) => dispatch(showLoader(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewTemplateModal);
