import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Button, Col, Row, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { GrLanguage } from "react-icons/gr";
import {
  changeSelectedTemplate,
  clearQRTemplateStore,
  getActiveTemplates,
  showLoader,
} from "../../redux/actions/templateActions";
import { receiveProfileData } from "../../redux/actions/profileActions";
import { ShowForPermission } from "../../components/ShowForPermission";
import { checkPermission } from "../../redux/actions/permissionActions";
import { receiveMarketData } from "../../redux/actions/profileActions";
import jwtDecode from "jwt-decode";
import {
  QR_GENERATE_BULK,
  QR_GENERATE_INDIVIDUAL,
  QR_GENERATE_TEMPLATE_LIST,
} from "../../utils/permissionList";
import {
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACTION,
} from "../../utils/errorMessages";

const SelectTemplate = ({
  templates = [],
  selectTemplate,
  userGroups,
  isLoading,
  getTemplateList,
  checkPermission,
  isSuperAdmin,
  marketOptions = [],
  clearStore,
  receiveMarketData,
  receiveProfile,
}) => {
  const history = useHistory();

  const [selectedMarket, setSelectedMarket] = useState("tanzania");

  useEffect(() => {
    isLoading(true);
    getTemplateList(selectedMarket, true)
      .then((result) => {
        isLoading(false);
      })
      .catch((e) => {
        isLoading(false);
        if (e.response.status && e.response.status === 403) {
          toast.error(UNAUTHORIZED_ACTION);
        } else if (e.response.data.Description) {
          toast.error(e.response.data.Description);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
      });
  }, [selectedMarket, getTemplateList, isLoading]);

  useEffect(() => {
    receiveMarketData();
    receiveProfile(jwtDecode(localStorage.id_token));
  }, []);

  // clear the store on unmount
  useEffect(() => () => clearStore(), [clearStore]);

  const goToNext = async (template) => {
    isLoading(true);
    const hasPermissions = await checkPermission([
      QR_GENERATE_BULK,
      QR_GENERATE_INDIVIDUAL,
    ]);
    if (hasPermissions) {
      selectTemplate({ ...template, isActive: true });
      isLoading(false);
      history.push("/generate-qr/fill-params");
    } else {
      isLoading(false);
      toast.error("You don't have permission to Generate QR Codes");
    }
  };

  const renderTemplates = templates.map((template) => {
    return (
      <Col lg={3} md={6} sm={6} key={template.id}>
        <Card
          onClick={() => goToNext(template)}
          id={template.id + "-" + template.language}
        >
          <div className="wrap-img">
            <Card.Img
              variant="top"
              src={template.pngTemplatePath}
              alt={template.templateName}
            />
          </div>
          <Card.Body className="d-flex justify-content-between">
            <Card.Text id={template.id + "-" + template.language + "-name"}>
              {template.templateName}
            </Card.Text>

            {template.isDefault ? (
              <Button
                data-testid="cancelButton"
                variant="primary"
                className="btn-cancel"
                id={"btn-" + template.id + "-" + template.language + "-lang"}
              >
                <span>Default</span>
              </Button>
            ) : (
              <Button
                data-testid="cancelButton"
                variant="primary"
                className="btn-cancel"
                id={"btn-" + template.id + "-" + template.language + "-lang"}
              >
                <GrLanguage className="lcon-lang" />
                <span className="txt-lan">{template.language}</span>
              </Button>
            )}
          </Card.Body>
        </Card>
      </Col>
    );
  });

  return (
    <div className="page qr-genarate-page">
      <div className="page-title-row d-flex justify-content-between">
        <div className="title">
          <h1>Generate QR</h1>
          <div className="subtitle-1">
            Please select one of the following templates to generate static QR
            Code(s)
          </div>
        </div>
        <div className="my-1 mx-2">
          {isSuperAdmin ? (
            <Form.Select
              style={{ paddingRight: "36px" }}
              id="marketSelect"
              onChange={(e) => setSelectedMarket(e.target.value)}
            >
              {marketOptions.map((market) => {
                return (
                  <option key={market.value} value={market.value}>
                    {market.displayName}
                  </option>
                );
              })}
              <option value="general">General</option>
            </Form.Select>
          ) : (
            <Form.Select
              style={{ paddingRight: "36px" }}
              id="marketSelect"
              onChange={(e) => setSelectedMarket(e.target.value)}
            >
              {marketOptions
                ? marketOptions.length > 0
                  ? marketOptions
                      .filter((x) => userGroups && userGroups.includes(x.userGroup))
                      .map((y) => {
                        return <option value={y.value}>{y.displayName}</option>;
                      })
                  : null
                : null}
              <option value="general">General</option>
            </Form.Select>
          )}
        </div>
      </div>
      <ShowForPermission permissions={[QR_GENERATE_TEMPLATE_LIST]} showBanner>
        <div className="card-set-qr">
          <Row>{renderTemplates}</Row>
        </div>
      </ShowForPermission>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    templates: state.templates.list,
    userGroups: state.profile?.groups,
    isSuperAdmin: state.permission.isSuperAdmin,
    marketOptions: state.profile.markets,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTemplateList: (market, isActive) =>
    dispatch(getActiveTemplates(market, isActive)),
  selectTemplate: (data) => dispatch(changeSelectedTemplate(data)),
  isLoading: (flag) => dispatch(showLoader(flag)),
  checkPermission: (permissions) => dispatch(checkPermission(permissions)),
  clearStore: () => dispatch(clearQRTemplateStore()),
  receiveMarketData: () => dispatch(receiveMarketData()),
  receiveProfile: (data) => dispatch(receiveProfileData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectTemplate);
