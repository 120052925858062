import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Table, Pagination, Button } from "react-bootstrap";
import { TRANSACTION_TYPE_CREATE, TRANSACTION_TYPE_LIST } from "../../utils/permissionList";
import { AiOutlinePlus } from "react-icons/ai";
import { ShowForPermission } from "../../components/ShowForPermission";
import { checkPermission } from "../../redux/actions/permissionActions";
import { showLoader } from "../../redux/actions/commonActions";
import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG, UNAUTHORIZED_ACTION } from "../../utils/errorMessages";
import { activateOrDeactivateTransactionType, getTransactionTypes } from "../../redux/actions/transactionTypeActions";
import TransactionTypeActionMenu from "../../components/action-menus/TransactionTypeActionMenu";
import CreateTransactionTypeModal from "../../components/modals/CreateTransactionTypeModal";
import ConfirmationModal from "../../components/modals/ConfirmationModal";


const TransactionTypesPage = ({
  getTransactionTypeList,
  transactionTypeList = [],
  noOfPages,
  currentPage,
  activateOrDeactivateTransactionType,
  showLoader,
}) => {

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 15,
    isDropdown: false,
    isActiveOnly: false
  });

  const [isEditFlow, setIsEditFlow] = useState(false);
  const [selectedTransactionType, setSelectedTransactionType] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {

    async function init() {
      try {
        showLoader(true);
        const test = await checkPermission([TRANSACTION_TYPE_LIST]);
        if (test) {
        getTransactionTypeList(filters);
        }
      } catch (e) {
        if (e.response.status && e.response.status === 403) {
          toast.error(UNAUTHORIZED_ACTION);
        } else if (e.response.data.Description) {
          toast.error(e.response.data.Description);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        showLoader(false);
      }
      showLoader(false);
    }
    init();
  }, [getTransactionTypeList,checkPermission, filters, showLoader]);

  const [showCreateTransactionTypeModal, setShowCreateTransactionTypeModal] = useState(false);
  const toggleShow = () => setShowCreateTransactionTypeModal((p) => !p);

  const toggleSetShowConfirmation = () => setShowConfirmation((p) => !p);

  const fetchTransactionTypes = async () => {
    try {
      showLoader(true);
      await getTransactionTypeList(filters);
      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast.error(SOMETHING_WENT_WRONG);
    }
  }

  const handleCreateTransactionType = () => {
    setIsEditFlow(false);
    toggleShow();
  }

  const handleEditTransactionType = (transactionType) => {
    setIsEditFlow(true);
    setSelectedTransactionType(transactionType);
    toggleShow();
  }

  const handleChangePageSize = (size) => {
    setFilters({ ...filters, pageNumber: 1, pageSize: size });
  };

  const toggleTransactionTypeActivityModal = (transactionType) => {
    setSelectedTransactionType(transactionType);
    setShowConfirmation(true)
  };

  const handleTransactionTypeActivationAndDeactivation = async () => {
    try {
      showLoader(true);
      await activateOrDeactivateTransactionType(selectedTransactionType)
      await fetchTransactionTypes();
      showLoader(false)
      toast.success(`Transaction Type ${selectedTransactionType?.isActive ? "Deactivated" : "Activated"}`);
    } catch (e) {
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data.Description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
      showLoader(false);
    }
  };

  const renderTransactionTypes = transactionTypeList.map((transactionType) => {
    return (
      <tr key={transactionType.id} >
        <td>{transactionType.name}</td>
        <td className="word-break">{transactionType.description}</td>
        <td>{transactionType.typeId}</td>
        <td>
          <td>
            <div className="status">
              <span className={transactionType.isActive ? "active" : "inactive"}>
                {transactionType.isActive ? "Active" : "Inactive"}
              </span>
            </div>
          </td>
        </td>
        <td><TransactionTypeActionMenu
          transactionType={transactionType}
          isDeactivationFlow={transactionType.isActive}
          toggleTransactionTypeActivity={toggleTransactionTypeActivityModal}
          openEditTransactionTypeModal={handleEditTransactionType}
        ></TransactionTypeActionMenu></td>
      </tr>
    );
  });

  let items = [];
  let max = 6;
  let start = currentPage - 3 <= 0 ? 1 : currentPage - 3;
  let end = start + max > noOfPages ? noOfPages : start + max;

  for (let number = start; number <= end; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setFilters({ ...filters, pageNumber: number })}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <div className="page page-audit">
      <div className="page-title-row">
        <div className="title">
          <h1>Transaction Types</h1>
        </div>
      </div>
      <div className="filter-section row">
            <div className="text-end">
            <ShowForPermission permissions={[TRANSACTION_TYPE_CREATE]}>
                  <Button
                    variant="primary"
                    id="add-transaction-type-button"
                    data-testid="add-transaction-type-button"
                    className="btn-special"
                    onClick={handleCreateTransactionType}
                  >
                    <AiOutlinePlus /> <span>Transaction Type</span>
                  </Button>
                  </ShowForPermission>
            </div>
      </div>
      <CreateTransactionTypeModal
        show={showCreateTransactionTypeModal}
        getTransactionTypeList={fetchTransactionTypes}
        isEditFlow={isEditFlow}
        transactionType={selectedTransactionType}
        toggleShow={toggleShow}
      />
      <ConfirmationModal
        show={showConfirmation}
        toggleShow={toggleSetShowConfirmation}
        confirm={handleTransactionTypeActivationAndDeactivation}
        title={`Are you sure you want to ${selectedTransactionType?.isActive ? "Deactivate" : "Activate"
          } this Transaction Type?`}
      />
        <Table data-testid="transaction-type-list-table">
          <thead>
            <tr>
              <th data-testid="table-header-name">Transaction Type Name</th>
              <th data-testid="table-header-description">Description</th>
              <th data-testid="table-header-value">Value</th>
              <th data-testid="table-header-status">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{renderTransactionTypes}</tbody>
        </Table>
      <div className="footer-table d-flex justify-content-between">
        <div>
          <Form.Select onChange={(e) => handleChangePageSize(e.target.value)}>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="60">60</option>
          </Form.Select>
        </div>
        <div className="mt-2">{filters.pageNumber}</div>
        <div className="pagination">
          <Pagination size="sm">
            <Pagination.First
              onClick={() => setFilters({ ...filters, pageNumber: 1 })}
            />
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => setFilters({ ...filters, pageNumber: filters.page - 1 })}
            />
            {currentPage > 4 ? <Pagination.Ellipsis disabled /> : null}

            {items}
            {currentPage === noOfPages ? null : (
              <Pagination.Ellipsis disabled />
            )}
            <Pagination.Next
              disabled={currentPage === noOfPages}
              onClick={() => setFilters({ ...filters, pageNumber: filters.pageNumber + 1 })}
            />
            <Pagination.Last
              onClick={() => setFilters({ ...filters, pageNumber: noOfPages })}
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    transactionTypeList: state.transactionType.list,
    currentPage: state.transactionType.currentPage,
    noOfPages: state.transactionType.noOfPages,
    totalCount: state.transactionType.totalCount
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTransactionTypeList: (filters) => dispatch(getTransactionTypes(filters)),
  showLoader: (data) => dispatch(showLoader(data)),
  activateOrDeactivateTransactionType: (data) => dispatch(activateOrDeactivateTransactionType(data)),
  checkPermission: (permissions) => dispatch(checkPermission(permissions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionTypesPage);
