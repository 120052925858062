import axios from "../../apis/roleAPI";
import { v4 as uuidv4 } from 'uuid';
import {
  FETCH_ROLES,
  GET_ROLE,
  CREATE_ROLE,
  UPDATE_ROLE,
  SET_IS_ROLE_EDIT_FLOW,
  SET_SELECTED_ROLE_FOR_EDIT,
  DEACTIVATE_ROLE
} from "./actionTypes";

export const setIsEditFlow = (payload) => {
  return {
    type: SET_IS_ROLE_EDIT_FLOW,
    payload: payload,
  };
};

export const setSelectedRoleForEdit = (payload) => {
  return {
    type: SET_SELECTED_ROLE_FOR_EDIT,
    payload: payload,
  };
};

export const fetchRoles = (pageSize, page, search, isDropdown) => async (dispatch, getState) => {

  function onSuccess(response) {
    dispatch({
      type: FETCH_ROLES,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: FETCH_ROLES,
      payload: {
        roles: [],
        currentPage: 1,
        noOfPages: 0,
        totalCount: 0
      }
    });
    throw error;
  }
  try {
    let params = { PageSize: pageSize };
    if (search != null) {
      params.Search = search;
    }

    params.PageSize = pageSize;
    params.PageNumber = page;

    // if true fetches the whole list
    params.isDropdown = isDropdown;

    const response = await axios.get(`/`, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: params
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const createRole = (role) => async (dispatch, getState) => {

  function onSuccess(response) {
    dispatch({
      type: CREATE_ROLE,
      payload: response.data,
    });
    return response;
  }
  function onError(error) {
    dispatch({
      type: CREATE_ROLE,
      payload: error.data,
    });
    throw error;
  }
  try {
    const response = await axios.post(`/`, role, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const getRole = (roleId) => async (dispatch, getState) => {
  function onSuccess(success) {
    dispatch({
      type: GET_ROLE,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: GET_ROLE,
      payload: error,
    });
    throw error;
  }
  try {
    const response = await axios.get(`/${roleId}`, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const updateRole = (role) => async (dispatch, getState) => {
  function onSuccess(success) {
    dispatch({
      type: UPDATE_ROLE,
      payload: success.data,
    });
    return success;
  }
  function onError(error) {
    dispatch({
      type: UPDATE_ROLE,
      payload: error,
    });
    throw error;
  }
  try {
    const response = await axios.put(`/`, role, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export const activateOrDeactivateRole = (data) => async (dispatch, getState) => {
  function onSuccess(success) {
    dispatch({
      type: DEACTIVATE_ROLE,
      payload: success.data,
    });
    return success;
  }

  function onError(error) {
    dispatch({
      type: DEACTIVATE_ROLE,
      payload: error,
    });
    throw error;
  }

  try {
    const payload = {
      roleId: data.roleId,
      activate: !data.isActivated
    };
    const response = await axios.post(`/activate-deactivate-role`, payload, {
      headers: {
        "x-correlation-id": uuidv4(),
        "x-source-system": 'mpa-qr-code-web-app',
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
}