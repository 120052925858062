export const FAILED_TO_CREATE_USER = 'Failed to create user';
export const CREATE_USER_SUCCESS = 'User created successfully';

export const FAILED_TO_DEACTIVATE_USER = 'Failed to deactivate user';
export const FAILED_TO_REACTIVATE_USER = 'Failed to reactivate user';

export const DEACTIVATE_USER_SUCCESS = 'User deactivated successfully';
export const REACTIVATE_USER_SUCCESS = 'User reactivated successfully';

export const EDIT_USER_SUCCESS = 'User edited successfully';
export const EDIT_USER_FAILED = 'Failed to edit user';

export const PASSWORD_RESET_SUCCESS = 'Password reset successfully'
export const FAILED_TO_RESET_PASSWORD = 'Failed to reset password'

export const SOMETHING_WENT_WRONG = "Something went wrong";


export const FAILED_TO_CREATE_ROLE = 'Failed to create role'
export const CREATE_ROLE_SUCCESS = 'Role created successfully';

export const FAILED_TO_DEACTIVATE_ROLE = 'Failed to deactivate  role';
export const FAILED_TO_REACTIVATE_ROLE = 'Failed to reactivate role';

export const REACTIVATE_ROLE_SUCCESS = 'Role reactivated successfully';
export const DEACTIVATE_ROLE_SUCCESS = 'Role deactivated successfully';

export const EDIT_ROLE_SUCCESS = 'Role edited successfully';
export const EDIT_ROLE_FAILED = 'Failed to edit role';

export const UNAUTHORIZED_ACTION = "You are not authorized to perform this action";

export const CREATE_NETWORK_SUCCESS = "Network created successfully"
export const UPDATE_NETWORK_SUCCESS = "Network edited successfully"
export const FAILED_TO_CREATE_NETWORK = "Failed to create network"

export const FAILED_TO_CREATE_LANGUAGE = "FAILED_TO_CREATE_LANGUAGE"
export const CREATE_LANGUAGE_SUCCESS = "Language created successfully"
export const UPDATE_LANGUAGE_SUCCESS = "Language edited successfully"

export const CREATE_TRANSACTION_TYPE_SUCCESS = "Transaction type created successfully"
export const UPDATE_TRANSACTION_TYPE_SUCCESS = "Transaction type edited successfully"
export const FAILED_TO_CREATE_TRANSACTION_TYPE = "Failed to create Transaction type"

export const CREATE_MARKET_SUCCESS = "Market created successfully"
export const UPDATE_MARKET_SUCCESS = "Market edited successfully"
export const FAILED_TO_CREATE_MARKET = "Failed to create market"
