export const authConfig = {
  authority:"https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_7jnkoSjuN",
  client_id: "4s8f0rqnph864t1k4cpqn0r7dg",
  redirect_uri: "https://mpa.qr.web.m-pesa.com/callback",
  post_logout_redirect_uri: "https://mpa.qr.web.m-pesa.com/logout",
  response_type: "code",
  automaticSilentRenew: false,
  scope: "profile openid email aws.cognito.signin.user.admin qr/api.write qr/api.read",
  state: "32be7a85354b4642b45a5f19fe7001b7",
  //filterProtocolClaims: environment.openID.filterProtocolClaims,
  loadUserInfo: true,
  monitorSession: false,
  silent_redirect_uri: "https://mpa.qr.web.m-pesa.com/silent-reniew.html",
  accessTokenExpiringNotificationTime: 20, //default 60
  //checkSessionInterval: 86400, //default 2000
  //silentRequestTimeout: 2000,
  session_expire_url: "https://mpa.qr.web.m-pesa.com/session-expired",
  logout_uri: 'https://mpa.qr.web.m-pesa.com',
  metadata: {
    authorization_endpoint:"https://mpa.qr.auth.m-pesa.com/oauth2/authorize",
    issuer: "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_7jnkoSjuN",
    jwks_uri: "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_7jnkoSjuN/.well-known/jwks.json",
    end_session_endpoint:"https://mpa.qr.auth.m-pesa.com/logout",
    userinfo_endpoint: "https://mpa.qr.auth.m-pesa.com/oauth2/userInfo",
    token_endpoint: "https://mpa.qr.auth.m-pesa.com/oauth2/token",
  },
};