import {
  TOGGLE_LOADER,
} from "./actionTypes";

export const showLoader = (payload) => {
  return {
    type: TOGGLE_LOADER,
    payload: payload,
  };
};
