import React, { useRef, useState } from "react";
import { Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { toast } from "react-toastify";

const FileUploader = ({
  handleFile,
  accept = ".csv,application/vnd.ms-excel,text/csv",
  errors,
  maxRecords = 200,
  disabled = false,
  disabledMessage = "",
  fileNameText=""
}) => {
  const hiddenFileInput = useRef(null);
  const [fileName, setFileName] = useState(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setFileName(fileUploaded.name)
    if (!accept.includes(fileUploaded.type) && fileUploaded.type !== "") {
      toast.error("Invalid file type");
    } else {
      handleFile(fileUploaded);
    }
    event.target.value = "";
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {disabledMessage}
    </Tooltip>
  );
  

  return (
    <>
      {disabled ? (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <Button
            variant="primary"
            className="btn-cancel mt-2 px-3 pt-0 pb-0"
          >
            Upload
          </Button>
        </OverlayTrigger>
      ) : (<Button
        variant="primary"
        className="btn-cancel mt-2 px-3 pt-0 pb-0"
        onClick={() => handleClick()}
      >
        Upload
      </Button>)}

      <span> {errors.file?.message}</span>
      <input
        ref={hiddenFileInput}
        onChange={handleChange}
        accept={accept}
        type="file"
        style={{ display: "none" }}
      />
      <Form.Label>{fileNameText}</Form.Label>
      <br/>
      <br/>
      <Form.Label>NOTE: Maximum number of records that can be uploaded is {maxRecords}</Form.Label>
    </>
  );
};
export default FileUploader;
