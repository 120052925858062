import {
  FETCH_QR_SPECIFICATIONS,
  GET_QR_SPECIFICATION,
  SET_CREATE_STEP_ONE_DATA,
  SET_CREATE_STEP_TWO_DATA,
  RESET_CREATE_FLOW,
  SET_QR_SPEC_SELECTED_ID,
  SET_QR_SPECIFICATION_FLOW,
  FETCH_MPA_FIELDS,
  FETCH_CRC_FIELDS,
  GET_QR_FIELD_VALIDATIONS,
  GET_QR_SPECIFICATION_NOTES,
  ACTIVATE_SPECIFICATION,
  DEACTIVATE_SPECIFICATION,
  CREATE_VERSION,
  CLEAR_SPECIFICATION_STORE,
  GET_QR_SPECIFICATION_HISTORY,
} from "../actions/actionTypes";

const defaultState = {
  list: [],
  flow: "create",
  mpaFields: [],
  current: {
    fields: [],
  },
  additionalParams: [],
  selectedId: null,
  clickedId: null,
  openedSubComponents: {},
  createData: {
    template: null,
    market: null,
    network: null,
    transactionType: null,
    transactionTypes: [],
    mpaBaseVersion: "1.0",
    rank: 0,
    stage: "draft",
    draftStep: 1,
    qrFormatType: null,
    isLegacy: false,
    fields: [],
    crcFormat: null,
    version: null,
  },
  initialStep: 0, // original step where the draft is saved
  currentStep: 0,
  previousStep: 0,
  loading: false,
  notes: [],
  historyDetails: [],
};

const qrSpecificationReducer = (
  reduxState = defaultState,
  { type, payload }
) => {
  switch (type) {
    case CLEAR_SPECIFICATION_STORE:
      return {
        ...reduxState,
        clickedId: null,
        list: [],
        openedSubComponents: {},
      };
    case CREATE_VERSION:
    case ACTIVATE_SPECIFICATION:
    case DEACTIVATE_SPECIFICATION:
      return {
        ...reduxState,
        clickedId: payload,
        openedSubComponents: {},
      };
    case FETCH_QR_SPECIFICATIONS:
      const index = payload.specifications.findIndex(
        (x) => x.version?.specificationId === reduxState.clickedId
      );
      const subComp = { [`${index}`]: true };
      return {
        ...reduxState,
        list: payload.specifications,
        openedSubComponents: subComp,
        currentPage: payload.currentPage,
        noOfPages: payload.noOfPages,
        totalCount: payload.totalCount,
      };

    case SET_QR_SPEC_SELECTED_ID:
      return {
        ...reduxState,
        selectedId: payload,
      };

    case GET_QR_SPECIFICATION:
      return {
        ...reduxState,
        initialStep: payload.draftStep,
        current: payload,
      };

    case SET_QR_SPECIFICATION_FLOW:
      return {
        ...reduxState,
        flow: payload,
      };

    case FETCH_MPA_FIELDS:
      let localOptions = [
        { value: "noMapping", label: "No Mapping", actualKey: "No Mapping" },
      ];
      let childOptions = [];
      payload.forEach((field) => {
        if (field.children.length > 0) {
          field.children.forEach((childField) => {
            childOptions.push({
              value: childField.key,
              label: field.fieldName + ": " + childField.fieldName,
              actualKey: childField.fieldName,
              mpaParentKey: field.key,
              originalFieldValidations: childField.validations,
              mpaFieldId: childField.fieldId,
            });
          });
        } else {
          localOptions.push({
            value: field.key,
            label: field.fieldName,
            actualKey: field.fieldName,
            originalFieldValidations: field.validations,
            mpaFieldId: field.fieldId,
            mpaParentKey: null,
          });
        }
      });
      localOptions.push(...childOptions);
      return {
        ...reduxState,
        mpaFields: localOptions,
      };

    case FETCH_CRC_FIELDS:
      return {
        ...reduxState,
        crcList: payload,
      };

    case RESET_CREATE_FLOW:
      return {
        ...reduxState,
        createData: {
          template: null,
          market: null,
          network: null,
          transactionType: null,
          mpaBaseVersion: "1.0",
          rank: 0,
          stage: "draft",
          draftStep: 1,
          qrFormatType: null,
          isLegacy: false,
          fields: [],
          crcFormat: "",
          version: null,
        },
        previousStep: 0,
        currentStep: 0,
        current: {
          fields: [],
        },
      };
    case GET_QR_FIELD_VALIDATIONS:
      return {
        ...reduxState,
        additionalParams: payload,
      };

    case SET_CREATE_STEP_ONE_DATA:
      return {
        ...reduxState,
        createData: {
          ...reduxState.createData,
          market: payload.market,
          network: payload.network,
          transactionTypes: payload.transactionTypes,
          template: payload.template,
          qrFormatType: payload.qrFormatType,
          isLegacy: payload.isLegacy,
          version: payload.version,
          crcFormat: payload.crcFormat,
        },
        previousStep: 1,
        currentStep: 2,
      };

    case SET_CREATE_STEP_TWO_DATA:
      // convert object to string for value map;
      const findFieldNameByKey = (key, validations) => {
        const found = reduxState.mpaFields.filter((x) => x.value === key);
        if (found.length > 0) {
          return {
            mpaFieldName: found[0].actualKey,
            mpaFieldId: found[0].mpaFieldId,
            mpaParentKey: found[0].mpaParentKey,
            validations: {
              ...validations,
              mpaFieldValidations: found[0].originalFieldValidations,
            },
          };
        } else {
          return {
            mpaFieldName: null,
            mpaFieldId: null,
            mpaParentKey: null,
            validations: { ...validations, mpaFieldValidations: null },
          };
        }
      };

      let flatten = [];
      payload.forEach((x) => {
        for (let i = 0; i < 2; i++) {
          if (typeof x.valueMap === "string") {
            x.valueMap = JSON.parse(x.valueMap);
          }
          if (typeof x.rules === "string") {
            x.rules = JSON.parse(x.rules);
          }
        }
        const valueMap =
          x.valueMap === null ? null : JSON.stringify(x.valueMap);
        const rules = x.rules === null ? null : JSON.stringify(x.rules);
        if (x.children.length > 0) {
          const children = x.children;
          x.children = [];
          flatten.push({ ...x, valueMap, rules });
          children.forEach((child) => {
            const valueMap =
              child.valueMap === null ? null : JSON.stringify(child.valueMap);
            const rules =
              child.rules === null ? null : JSON.stringify(child.rules);
            flatten.push({ ...child, indented: true, valueMap, rules });
          });
        } else {
          flatten.push({ ...x, valueMap, rules });
        }
      });
      flatten = flatten.map((x, index) => {
        return {
          ...x,
          mpaFieldId: {
            label: x.mpaFieldName,
            value: x.mpaKey,
            mpaFieldId: x.mpaFieldId,
          },
        };
      });
      flatten = flatten.map((x) => {
        return {
          ...x,
          mpaKey: x.mpaFieldId.value,
          ...findFieldNameByKey(x.mpaFieldId.value, x.validations),
        };
      });
      return {
        ...reduxState,
        createData: { ...reduxState.createData, fields: flatten },
        previousStep: 2,
        currentStep: 3,
      };

    case GET_QR_SPECIFICATION_NOTES:
      return {
        ...reduxState,
        notes: payload,
      };
    case GET_QR_SPECIFICATION_HISTORY:
      return {
        ...reduxState,
        historyDetails: payload.history,
        currentHistoryPage: payload.currentPage,
        noOfHistoryPages: payload.noOfPages,
      };
    default:
      return reduxState;
  }
};

export default qrSpecificationReducer;
