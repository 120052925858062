import React from "react";
import { Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { connect } from "react-redux";

const MarketFieldActionMenu = ({
  indentHierarchy,
  unIndentHierarchy,
  removeRow,
  disableIndentation = false,
  addRowAt,
  row,
  index,
  rowCount=0,
  isStringFormat = false
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" id={"market-field-action-" + row.order} data-testid={"toggle-btn-"+row.order}>
        <BiDotsVerticalRounded />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {row.indented ? (
          <Dropdown.Item
            data-testid="unindentAction"
            onClick={()=>unIndentHierarchy(row)}
          > Outdent Hierarchy </Dropdown.Item>
        ) : (
          <Dropdown.Item
            data-testid="indentAction"
            disabled={disableIndentation}
            onClick={()=>indentHierarchy(row)}
          > Indent Hierarchy </Dropdown.Item>
        )}
        <Dropdown.Divider/>
        <Dropdown.Item
          disabled={rowCount===1 && isStringFormat}
          data-testid="removeAction"
          onClick={() => removeRow(row)}
        >
          Remove Row
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          disabled={isStringFormat}
          data-testid="addRowAboveAction"
          onClick={() => addRowAt(index)}
        >
          Add Row Above
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          disabled={isStringFormat}
          data-testid="addRowBelowAction"
          onClick={() => addRowAt(index + 1)}
        >
          Add Row Below
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => {
  return {
    userGroups: state.profile.groups,
  };
};

export default connect(mapStateToProps, null)(MarketFieldActionMenu);
