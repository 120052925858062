import {
  GET_ROLE_ACTIONS,
  GET_USER_PERMISSIONS
} from "../actions/actionTypes";

const defaultState = {
  allActions: [],
  currentUserPermissions: [],
  isSuperAdmin: false,
};

const permissionReducer = (reduxState = defaultState, { type, payload }) => {
  switch (type) {

    case GET_ROLE_ACTIONS:
      return {
        ...reduxState,
        allActions: payload,
      };
    case GET_USER_PERMISSIONS:
      return {
        ...reduxState,
        currentUserPermissions: payload.map(x => { return x.permissionId }),
        isSuperAdmin: payload.findIndex(x => x.permissionId === 1) >= 0
      };

    default:
      return reduxState;
  }
};

export default permissionReducer;
