import { FETCH_MARKETS } from "../actions/actionTypes";

const defaultState = {
  list: [],
  markets: [],
  currentPage: 1,
  noOfPages: 0,
  totalCount: 0,
};

const marketReducer = (reduxState = defaultState, { type, payload }) => {
  switch (type) {
    case FETCH_MARKETS:
      let tmpMarkets = [];
      if (payload.markets) {
        tmpMarkets = payload.markets.map((item) => {
          return {
            value: item.normalizedName,
            displayName: item.name,
            countryCode: "+" + item.numericShortCode,
            userGroup: item.userGroupName,
            isoCode: item.shortCode2,
          };
        });
        tmpMarkets.reverse();
      }
      return {
        ...reduxState,
        list: payload.markets,
        markets: tmpMarkets,
        currentPage: payload.currentPage,
        noOfPages: payload.noOfPages,
        totalCount: payload.totalCount,
      };

    default:
      return reduxState;
  }
};

export default marketReducer;
