import {
    TOGGLE_LOADER,
  } from "../actions/actionTypes";
  
  const defaultState = {
    loading: false
  };
  
  const commonReducer = (reduxState = defaultState, { type, payload }) => {
    switch (type) {

      case TOGGLE_LOADER:
        return {
          ...reduxState,
          loading: payload,
        };
  
      default:
        return reduxState;
    }
  };
  
  export default commonReducer;
  