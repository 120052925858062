import React, { useState, useEffect } from "react";
import { Form, Col, Row, Button, Tabs, Tab } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { useForm } from "react-hook-form";
import ResponseCard from "../ResponseCard";

const fmt2json = require("format-to-json");

const TestingForm = ({
  type,
  url,
  payload,
  setPayload,
  responseTabKey,
  setResponseTabKey,
  onSubmit,
  response,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "all",
    reValidateMode: "onChange",
  });

  const [headerFields, setHeaderFields] = useState([
    "X-CORRELATION_ID",
    "X-SOURCE_SYSTEM",
    "PAYLOAD",
  ]);

  const [respBody, setRespBody] = useState();
  const [respHeaders, setRespHeaders] = useState();

  useEffect(() => {
    setRespBody(response.responseBody);
    setRespHeaders(response.responseHeaders);
  }, [response]);

  useEffect(() => {
    if (!headerFields.includes("X-CORRELATION_ID")) {
      delete errors["x-correlation-id"];
      register("x-correlation-id", { required: false });
    } else {
      register("x-correlation-id", {
        required: "X-Correlation Id is required",
      });
    }

    if (!headerFields.includes("X-SOURCE_SYSTEM")) {
      delete errors["x-source-system"];
      register("x-source-system", { required: false });
    } else {
      register("x-source-system", { required: "X-Source System is required" });
    }

    if (!headerFields.includes("AUTHORIZATION")) {
      delete errors["authorization"];
      register("authorization", { required: false });
    } else {
      register("authorization", {
        required: "Authorization token is required",
      });
    }

    if (!headerFields.includes("PAYLOAD")) {
      delete errors["payload"];
      register("payload", { required: false });
    } else {
      register("payload", { required: "Payload is required" });
    }

  }, [errors, headerFields, register]);

  const handleChangeCheckbox = (e) => {
    const id = e.target.id;
    const checked = e.target.checked;

    if (id === "x-correlation-id-check") {
      checked
        ? setHeaderFields([...headerFields, "X-CORRELATION_ID"])
        : setHeaderFields(
          headerFields.filter((item) => item !== "X-CORRELATION_ID")
        );
    }

    if (id === "x-source-system-check") {
      checked
        ? setHeaderFields([...headerFields, "X-SOURCE_SYSTEM"])
        : setHeaderFields(
          headerFields.filter((item) => item !== "X-SOURCE_SYSTEM")
        );
    }

    if (id === "authorization-check") {
      checked
        ? setHeaderFields([...headerFields, "AUTHORIZATION"])
        : setHeaderFields(
          headerFields.filter((item) => item !== "AUTHORIZATION")
        );
    }
  };

  const isValidJson = (json) => {
    try {
      JSON.parse(json);
      return true;
    } catch (e) {
      return false;
    }
  };

  const validateReqBody = (value) => {
    if (isValidJson(value)) {
      setPayload(value);
      clearErrors("payload");
      return true;
    } else {
      setError("payload", {
        type: "manual",
        message: "Invalid JSON Format",
      });
      return false;
    }
  };

  const setHandleSubmit = async (data) => {
    const fmtPayload = await fmt2json(data.payload);

    if (validateReqBody(fmtPayload.result)) {
      const submitedData = {
        ...data,
        headerFields,
        type,
        url,
      };

      onSubmit(submitedData);
    }
  };

  return (
    <div>
      <Form
        className="form-2"
        id="testing-form"
        onSubmit={handleSubmit((data) => setHandleSubmit(data))}
      >
        <div className="url-wrapper">
          <div className="label-wrapper">
            <div className="req-type-label" data-testid="reqTypeLabel">
              POST
            </div>
            <div className="req-label" data-testid="urlLabel">
              {url}
            </div>
          </div>
          <Button
            variant="primary"
            className="send-btn"
            type="submit"
            data-testid="sendBtn"
          >
            Send <MdKeyboardArrowRight />
          </Button>
        </div>

        <div className="form-field-wrapper">
          <Row>
            <Col>
              <div className="b-detail">
                <h4 data-testid="headersTitle">Headers</h4>
              </div>
              <Row>
                <Col sm={4}>
                  <Form.Check
                    inline
                    type="checkbox"
                    name="group1"
                    id="x-correlation-id-check"
                    data-testid="xCorrelationIdCheck"
                    checked={headerFields.includes("X-CORRELATION_ID")}
                    onChange={handleChangeCheckbox}
                  />

                  <Form.Label>X-correlation-id*</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    data-testid="correlationIdField"
                    {...register("x-correlation-id")}
                    autoComplete="off"
                    placeholder="x-correlation-id"
                    isInvalid={errors["x-correlation-id"]}
                  />
                  <Form.Control.Feedback
                    id="correlationIdErrors"
                    data-testid="xcorrelationIdErrorMsg"
                    type="invalid"
                  >
                    {errors["x-correlation-id"]?.message}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row className="single-field-set">
                <Col sm={4}>
                  <Form.Check
                    inline
                    type="checkbox"
                    name="group2"
                    id="x-source-system-check"
                    data-testid="xSourceSystemCheck"
                    checked={headerFields.includes("X-SOURCE_SYSTEM")}
                    onChange={handleChangeCheckbox}
                  />

                  <Form.Label>X-source-system*</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    data-testid="sourceSystemField"
                    {...register("x-source-system")}
                    autoComplete="off"
                    placeholder="x-source-system"
                    isInvalid={errors["x-source-system"]}
                  />
                  <Form.Control.Feedback
                    id="sourceSystemErrors"
                    type="invalid"
                    data-testid="xsourceSystemErrorMsg"
                  >
                    {errors["x-source-system"]?.message}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row className="single-field-set">
                <Col sm={4}>
                  <Form.Check
                    inline
                    type="checkbox"
                    name="group3"
                    id="authorization-check"
                    checked={headerFields.includes("AUTHORIZATION")}
                    data-testid="authorizationCheck"
                    onChange={handleChangeCheckbox}
                  />

                  <Form.Label>Authorization</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    data-testid="authorizationField"
                    {...register("authorization")}
                    autoComplete="off"
                    placeholder="authorization"
                    isInvalid={errors["authorization"]}
                  />
                  <Form.Control.Feedback
                    data-testid="authorizationErrorMsg"
                    id="authorizationErrors"
                    type="invalid"
                  >
                    {errors["authorization"]?.message}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Col>
            <Col>
              <div className="b-detail payload-header">
                <h4 className="additional-padding" data-testid="bodyTitle">
                  Body
                </h4>
                <label className="side-text">JSON</label>
              </div>
              <div className="code-editor-wrapper">
                <CodeEditor
                  value={payload}
                  data-testid="bodyField"
                  {...register("payload")}
                  language="json"
                  placeholder="Please enter payload."
                  onChange={(evn) => validateReqBody(evn.target.value)}
                  style={{
                    fontSize: 12,
                    backgroundColor: "#f5f5f5",
                    fontFamily:
                      "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                    wordSpacing: "0px",
                    minHeight: "300px"
                  }}
                  padding={15}
                />
              </div>
              <Form.Group className="mb-3" controlId="payload">
                <Form.Control
                  className="payload-hidden-field"
                  isInvalid={errors["payload"]}
                />
                <Form.Control.Feedback
                  id="payloadErrors"
                  type="invalid"
                  data-testid="payloadErrorMsg"
                >
                  {errors["payload"]?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Form>

      <div className="response-wrapper">
        <Row>
          <Col>
            <div className="b-detail">
              <h4 className="additional-padding" data-testid="responseTitle">
                Response
              </h4>
            </div>
          </Col>
          <Col>
            <ResponseCard code={response.status} status={response.statusText} />
          </Col>
        </Row>
      </div>

      <Tabs
        id="controlled-tab-example"
        activeKey={responseTabKey}
        onSelect={(k) => setResponseTabKey(k)}
        className=""
      >
        <Tab eventKey="body" title="Body">
          <div className="tab-wrapper resp-panel">
            <CodeEditor
              data-testid="responseBodyDisplay"
              value={JSON.stringify(respBody, null, '\t')}
              language="json"
              style={{
                fontSize: 12,
                backgroundColor: "#f5f5f5",
                fontFamily:
                  "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                wordSpacing: "0px",
                maxWidth: "80vw",
                pointerEvents: "none"
              }}
              padding={15}
            />
          </div>
        </Tab>
        <Tab eventKey="headers" title="Headers">
          <div className="tab-wrapper resp-panel">
            <CodeEditor
              data-testid="responseHeadersDisplay"
              value={JSON.stringify(respHeaders, null, '\t')}
              language="json"
              style={{
                fontSize: 12,
                backgroundColor: "#f5f5f5",
                fontFamily:
                  "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                wordSpacing: "0px",
                maxWidth: "80vw",
                pointerEvents: "none"
              }}
              padding={15}
            />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default TestingForm;
