import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Table, Col, Row, Pagination, Button } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { NETWORK_CREATE, NETWORK_LIST } from "../../utils/permissionList";
import { capitalizeFirstLetter } from "../../utils/stringFormat";
import { ShowForPermission } from "../../components/ShowForPermission";
import { checkPermission } from "../../redux/actions/permissionActions";
import { showLoader } from "../../redux/actions/commonActions";
import { toast } from "react-toastify";
import {
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACTION,
} from "../../utils/errorMessages";
import {
  activateOrDeactivateNetwork,
  getNetworks,
} from "../../redux/actions/networkActions";
import { receiveMarketData } from "../../redux/actions/profileActions";
import NetworkActionMenu from "../../components/action-menus/NetworkActionMenu";
import CreateNetworkModal from "../../components/modals/CreateNetworkModal";
import ConfirmationModal from "../../components/modals/ConfirmationModal";

const NetworksPage = ({
  getNetworkList,
  networkList = [],
  noOfPages,
  currentPage,
  checkPermission,
  activateOrDeactivateNetwork,
  showLoader,
  marketOptions = [],
  receiveMarketData,
}) => {
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 15,
    market: "all",
    isDropdown: false,
    IsActiveOnly: false,
  });

  const [isEditFlow, setIsEditFlow] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    async function init() {
      try {
        showLoader(true);
        const test = await checkPermission([NETWORK_LIST]);
        if (test) {
          getNetworkList(filters);
          receiveMarketData();
        }
      } catch (e) {
        if (e.response.status && e.response.status === 403) {
          toast.error(UNAUTHORIZED_ACTION);
        } else if (e.response.data.Description) {
          toast.error(e.response.data.Description);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        showLoader(false);
      }
      showLoader(false);
    }
    init();
  }, [getNetworkList, filters, checkPermission, showLoader]);

  const [showCreateNetworkModal, setShowCreateNetworkModal] = useState(false);
  const toggleShow = () => setShowCreateNetworkModal((p) => !p);

  const toggleSetShowConfirmation = () => setShowConfirmation((p) => !p);

  const fetchNetworks = async () => {
    try {
      showLoader(true);
      await getNetworkList(filters);
      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast.error(SOMETHING_WENT_WRONG);
    }
  };

  const handleCreateNetwork = () => {
    setIsEditFlow(false);
    toggleShow();
  };

  const handleEditNetwork = (network) => {
    setIsEditFlow(true);
    setSelectedNetwork(network);
    toggleShow();
  };

  const handleChangeMarket = (market) => {
    setFilters({ ...filters, pageNumber: 1, market });
  };

  const handleChangePageSize = (size) => {
    setFilters({ ...filters, pageNumber: 1, pageSize: size });
  };

  const toggleNetworkActivityModal = (network) => {
    setSelectedNetwork(network);
    setShowConfirmation(true);
  };

  const handleNetworkActivationAndDeactivation = async () => {
    try {
      showLoader(true);
      await activateOrDeactivateNetwork(selectedNetwork);
      await fetchNetworks();
      showLoader(false);
      toast.success(
        `Network ${selectedNetwork?.isActive ? "Deactivated" : "Activated"}`
      );
    } catch (e) {
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data.Description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
      showLoader(false);
    }
  };

  const renderNetworks = networkList.map((network) => {
    return (
      <tr key={network.id}>
        <td>{network.name}</td>
        <td>{network.networkId}</td>
        <td>{capitalizeFirstLetter(network.market)}</td>
        <td className="word-break">{network.description}</td>
        <td>
          <td>
            <div className="status">
              <span className={network.isActive ? "active" : "inactive"}>
                {network.isActive ? "Active" : "Inactive"}
              </span>
            </div>
          </td>
        </td>
        <td>
          <NetworkActionMenu
            network={network}
            isDeactivationFlow={network.isActive}
            toggleNetworkActivity={toggleNetworkActivityModal}
            openEditNetworkModal={handleEditNetwork}
          ></NetworkActionMenu>
        </td>
      </tr>
    );
  });

  let items = [];
  let max = 6;
  let start = currentPage - 3 <= 0 ? 1 : currentPage - 3;
  let end = start + max > noOfPages ? noOfPages : start + max;

  for (let number = start; number <= end; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setFilters({ ...filters, pageNumber: number })}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <div className="page page-audit">
      <div className="page-title-row">
        <div className="title">
          <h1>Networks</h1>
        </div>
      </div>

      <div className="filter-section row">
        <div className="filter-components">
          <Form className="form w-100">
            <Row className="align-items-center">
              <Col xl="3" lg="4" md="6" sm="12">
                <Form.Select
                  id="market-select"
                  data-testid="market-select"
                  onChange={(e) => handleChangeMarket(e.target.value)}
                >
                  <option value="all">All (Markets)</option>
                  {marketOptions.map((market) => {
                    return (
                      <option key={market.value} value={market.value}>
                        {market.displayName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col xl="6" lg="6" md="3" sm="12"></Col>
              <Col xl="3" lg="2" md="3" sm="12" className="btn-csv">
                <ShowForPermission permissions={[NETWORK_CREATE]}>
                  <Button
                    variant="primary"
                    id="add-network-button"
                    data-testid="add-network-button"
                    className="btn-special"
                    onClick={handleCreateNetwork}
                  >
                    <AiOutlinePlus /> <span>Network</span>
                  </Button>
                </ShowForPermission>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <CreateNetworkModal
        show={showCreateNetworkModal}
        getNetworkList={fetchNetworks}
        isEditFlow={isEditFlow}
        network={selectedNetwork}
        toggleShow={toggleShow}
      />
      <ConfirmationModal
        show={showConfirmation}
        toggleShow={toggleSetShowConfirmation}
        confirm={handleNetworkActivationAndDeactivation}
        title={`Are you sure you want to ${
          selectedNetwork?.isActive ? "Deactivate" : "Activate"
        } this Network?`}
      />
      <ShowForPermission permissions={[NETWORK_LIST]} showBanner={true}>
        <Table data-testid="network-list-table">
          <thead>
            <tr>
              <th data-testid="table-header-name">Name</th>
              <th data-testid="table-header-networkId">Network Id</th>
              <th data-testid="table-header-market">Market</th>
              <th data-testid="table-header-description">Description</th>
              <th data-testid="table-header-status">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{renderNetworks}</tbody>
        </Table>
      </ShowForPermission>
      <div className="footer-table d-flex justify-content-between">
        <div>
          <Form.Select onChange={(e) => handleChangePageSize(e.target.value)}>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="60">60</option>
          </Form.Select>
        </div>
        <div className="mt-2">{filters.pageNumber}</div>
        <div className="pagination">
          <Pagination size="sm">
            <Pagination.First
              onClick={() => setFilters({ ...filters, pageNumber: 1 })}
            />
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() =>
                setFilters({ ...filters, pageNumber: filters.page - 1 })
              }
            />
            {currentPage > 4 ? <Pagination.Ellipsis disabled /> : null}

            {items}
            {currentPage === noOfPages ? null : (
              <Pagination.Ellipsis disabled />
            )}
            <Pagination.Next
              disabled={currentPage === noOfPages}
              onClick={() =>
                setFilters({ ...filters, pageNumber: filters.pageNumber + 1 })
              }
            />
            <Pagination.Last
              onClick={() => setFilters({ ...filters, pageNumber: noOfPages })}
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    networkList: state.network.list,
    currentPage: state.network.currentPage,
    noOfPages: state.network.noOfPages,
    totalCount: state.network.totalCount,
    marketOptions: state.profile.markets,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNetworkList: (filters) => dispatch(getNetworks(filters)),
  showLoader: (data) => dispatch(showLoader(data)),
  checkPermission: (permissions) => dispatch(checkPermission(permissions)),
  activateOrDeactivateNetwork: (data) =>
    dispatch(activateOrDeactivateNetwork(data)),
    receiveMarketData: () => dispatch(receiveMarketData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworksPage);
