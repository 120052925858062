import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { BiRightArrowAlt, BiLeftArrowAlt } from "react-icons/bi";
import {
  DRAFT,
  TESTING,
  PUBLISH,
  REJECT,
  REVIEW,
} from "../utils/qrSpecStageColors";

const ActionDropdown = ({ title, actionMenuItems, onChangeActionItem,selectedActionItem,selectedItemTitle,setSelectedItemTitle }) => {
  const handleClickItem = (type, selectedTitle) => {
    setSelectedItemTitle(selectedTitle);
    onChangeActionItem(type);
  };

  const [btnColor, setBtnColor] = useState(
    title === "Test" ? TESTING : REVIEW
  );
  
  

  useEffect(() => {
    let btnColor = title === "Test" ? TESTING : REVIEW;
    switch (selectedActionItem) {
      case "draft":
        btnColor = DRAFT;
        break;
      case "test":
        btnColor = TESTING;
        break;
      case "publish":
        btnColor = PUBLISH;
        break;
      case "reject":
        btnColor = REJECT;
        break;
      case "review":
        btnColor = REVIEW;
        break;
      default:
        btnColor = title === "Test" ? TESTING : REVIEW;
        break;
    }
    setBtnColor(btnColor);
  }, [selectedActionItem]);
  
  return (
    <Dropdown align="end" data-testid="action-dropdown">
      <Dropdown.Toggle
        style={{ backgroundColor: btnColor }}
        id="dropdown-basic"
        data-testid="dropdown-btn"
      >
        {selectedItemTitle}
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="action-dropdown-menu"
        data-testid="dropdown-menu"
      >
        {actionMenuItems.map((item, index) => (
          <Dropdown.Item key={index} className="dropdown-item-wrapper" role="menuItems">
            <div
              className="flex dropdown-content-wrapper"
              onClick={() => handleClickItem(item.type, item.selectedTitle)}
            >
              {item.title}
              <div className="dropdown-right-content-wrapper">
                {item.iconType === "FORWARD" && <BiRightArrowAlt />}
                {item.iconType === "BACKWARD" && <BiLeftArrowAlt />}
                <label className="stage-badge" style={{ backgroundColor: item.bgColor }}>{item.badge}</label>
              </div>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionDropdown;
