import { FETCH_TRANSACTION_TYPE } from "../actions/actionTypes";

const defaultState = {
  list: [],
  current: null,
  trxTypeOptions: [],
};

const transactionTypeReducer = (
  reduxState = defaultState,
  { type, payload }
) => {
  switch (type) {
    case FETCH_TRANSACTION_TYPE:

      let tmpTrxTypes = [];
      if (payload.data.transactionTypes) {
        tmpTrxTypes = payload.data.transactionTypes.map((item) => {
          return {
            value: item.typeId,
            label: item.name,
          }
        });
      }

      let respArr = {};

      if (payload.config.params.isActiveOnly) {
        respArr = {
          ...reduxState,
          list: payload.data.transactionTypes,
          currentPage: payload.data.currentPage,
          noOfPages: payload.data.noOfPages,
          totalCount: payload.data.totalCount,
          trxTypeOptions: tmpTrxTypes,
        };
      } else {
        respArr = {
          ...reduxState,
          list: payload.data.transactionTypes,
          currentPage: payload.data.currentPage,
          noOfPages: payload.data.noOfPages,
          totalCount: payload.data.totalCount,
        };
      }

      return respArr;
    default:
      return reduxState;
  }
};

export default transactionTypeReducer;
