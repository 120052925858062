import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Table, Col, Row, Pagination, Button } from "react-bootstrap";
import { BiExport } from "react-icons/bi";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import {
  getAudits,
  exportAudits,
  changeSelectedAudit,
} from "../../redux/actions/auditActions";
import { AUDIT_EXPORT, AUDIT_VIEW } from "../../utils/permissionList";
import { capitalizeFirstLetter } from "../../utils/stringFormat";
import ViewAuditModal from "../../components/modals/ViewAuditModal";
import { formatDateTime } from "../../utils/timeFormat";
import moment from "moment";
import { ShowForPermission } from "../../components/ShowForPermission";
import { checkPermission } from "../../redux/actions/permissionActions";
import { fetchRoles } from "../../redux/actions/roleActions";
import { showLoader } from "../../redux/actions/commonActions";
import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG, UNAUTHORIZED_ACTION } from "../../utils/errorMessages";

const AuditPage = ({
   getAuditLogs,
   exportAuditLogs,
   auditList = [],
   noOfPages,
   currentPage,
   checkPermission,
   changeSelectedAudit,
   fetchRoles,
   allRoles,
   showLoader,
 }) => {
  const [selectedRole, setSelectedRole] = useState("all");
  const [pageSize, setPageSize] = useState(15);
  const [action, setAction] = useState(-1);
  const [showViewModal, setShowViewModal] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [userNameInput, setUserNameInput] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    startDate: moment().startOf("month").utc().toISOString(),
    endDate: null,
    pageSize: 15,
    selectedRole: -1,
    action: -1,
    userNameInput:""
  });
  
  useEffect(() => {
    async function init() {
      try {
        showLoader(true);
        const test = await checkPermission([AUDIT_VIEW]);
        if (test) {
          getAuditLogs(
            selectedRole,
            filters.startDate,
            filters.endDate,
            action,
            filters.page,
            pageSize,
            userNameInput,
          );
          await fetchRoles(1000, 1, null, true);
        }
      } catch (e) {
        if (e.response.status && e.response.status === 403) {
          toast.error(UNAUTHORIZED_ACTION);
        } else if (e.response.data.Description) {
          toast.error(e.response.data.Description);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        showLoader(false);
      }
      showLoader(false);
    }
    init();
  }, [getAuditLogs, fetchRoles, selectedRole, filters, pageSize, action, checkPermission, showLoader]);
  
  const toggleUserViewModal = (flag, audit = null) => {
    changeSelectedAudit(audit);
    setShowViewModal(flag);
  };
  
  const handleChangePageSize = (size) => {
    setPageSize(size);
    setFilters({ ...filters, page: 1 });
  };
  
  const handleExportAuditLogs = async () => {
    try {
      const result = await exportAuditLogs(
        selectedRole,
        filters.startDate,
        filters.endDate,
        action,
        filters.page,
        pageSize,
        userNameInput
      );
      if (result != null) {
        const contentType = "application/octet-stream";
        var a = document.createElement("a");
        var blob = new Blob([result.data], { type: contentType });
        a.href = window.URL.createObjectURL(blob);
        a.download = "audit-log-mpesa.csv";
        a.click();
      }
    } catch (e) {
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
  };
  
  const OnSubmit = (e) => {
    e.preventDefault();
  };
  
  const handleDateRangeSelection = (event, picker) => {
    if (event.type === "apply") {
      setFilters({
        ...filters,
        page: 1,
        startDate: moment(picker.startDate).utc().toISOString(),
        endDate: moment(picker.endDate).utc().toISOString(),
      });
    }
  };
  
  const handleSelectedRole = (event) => {
    setFilters({ ...filters, page: 1 });
    setSelectedRole(event);
  };
  
  const handleSelectedAction = (event) => {
    setFilters({ ...filters, page: 1 });
    setAction(event);
  };
  
  const handleUsernameInput = (event) => {
    event.preventDefault();
    clearTimeout(timeoutId);
    setUserNameInput(event.target.value);
    const id = setTimeout(() => {
      setFilters({ ...filters, page: 1, userNameInput: event.target.value });
    }, 600);
    setTimeoutId(id);
  };
  
  const renderAudits = auditList.map((audit) => {
    return (
      <tr key={audit.id} onClick={() => toggleUserViewModal(true, audit)} data-testid="auditLogRow">
        <td>{formatDateTime(audit.createdDate)}</td>
        <td>{audit.action}</td>
        <td>{audit.user}</td>
        <td>
          {audit.market == null || audit.market === ""
            ? "-"
            : audit.market === "drc"
              ? "DRC"
              : capitalizeFirstLetter(audit.market)}
        </td>
        <td>{audit.role === "Auditor" ? "Audit User" : audit.role}</td>
        <td className="word-break">{audit.description}</td>
      </tr>
    );
  });
  
  const renderRolesList = allRoles.map((role) => {
    return (<option key={role.value} id={role.value} value={role.value}>
      {role.display}
    </option>)
  })
  
  let items = [];
  let max = 6;
  let start = currentPage - 3 <= 0 ? 1 : currentPage - 3;
  let end = start + max > noOfPages ? noOfPages : start + max;
  
  for (let number = start; number <= end; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setFilters({ ...filters, page: number })}
      >
        {number}
      </Pagination.Item>
    );
  }
  
  return (
    <div className="page page-audit">
      <div className="page-title-row">
        <div className="title">
          <h1>Audit Log</h1>
        </div>
      </div>
      <div className="filter-section row">
        <div className="filter-components">
          <Form className="form w-100" onSubmit={OnSubmit}>
            <Row className="align-items-center">
              <Col xl="2" lg="3" md="5" sm="12" className="my-1">
                <DateRangePicker
                  id="date-rage-picker-wrap"
                  initialSettings={{
                    locale: {format: 'DD/MM/YYYY'},
                    startDate: moment().startOf("month").format("DD/MM/YYYY"),
                  }}
                  onEvent={handleDateRangeSelection}
                >
                  <input
                    id="date-rage-picker-input"
                    type="text"
                    className="form-control"
                  />
                </DateRangePicker>
              </Col>
              <Col xl="3" lg="3" md="6" sm="12" className="my-1">
                <Form.Select
                  id="action-select"
                  onChange={(e) =>
                    handleSelectedAction(parseInt(e.target.value))
                  }
                >
                  <option id="option1" value="-1">
                    All (Actions)
                  </option>
                  <option id="option2" value="1">
                    Create User
                  </option>
                  <option id="option3" value="2">
                    Edit User
                  </option>
                  <option id="option4" value="3">
                    Deactivate User
                  </option>
                  <option id="option5" value="4">
                    Reactivate User
                  </option>
                  <option id="option6" value="5">
                    Reset Password
                  </option>
                  <option id="option7" value="6">
                    Get User Status
                  </option>
                  <option id="option9" value="8">
                    Get All Users
                  </option>
                  <option id="option27" value="7">
                    Get All Templates
                  </option>
                  <option id="option10" value="9">
                    Export Audit Report
                  </option>
                  <option id="option11" value="10">
                    Export PDF Bulk Static QR
                  </option>
                  <option id="option12" value="11">
                    Export PDF Individual Static QR
                  </option>
                  <option id="option13" value="12">
                    Export Image Bulk Static QR
                  </option>
                  <option id="option13" value="13">
                    Export Image Individual Static QR
                  </option>
                  <option id="option13" value="16">
                    Get MFA Status
                  </option>
                  <option id="option13" value="17">
                    Get MFA QR Code
                  </option>
                  <option id="option13" value="18">
                    Verify TOTP
                  </option>
                  <option id="option13" value="19">
                    Resend User Invitation
                  </option>
                  <option id="option13" value="20">
                    Reset MFA
                  </option>
                  <option id="option21" value="21">
                    Upload QR Template
                  </option>
                  <option id="option22" value="22">
                    Save QR Template
                  </option>
                  <option id="option23" value="23">
                    Update QR Template
                  </option>
                  <option value="37">
                    QR Specification Save as Draft Step 1
                  </option>
                  <option value="38">
                    QR Specification Save as Draft Step 2
                  </option>
                  <option value="39">
                    QR Specification Save as Draft Step 3
                  </option>
                  <option value="40">
                    QR Specification Submit for Testing
                  </option>
                  <option value="41">
                    Save Network
                  </option>
                  <option value="42">
                    Update Network
                  </option>
                  {/* <option value="43">
                    Get Network
                  </option> */}
                  <option value="44">
                    Get All Network
                  </option>
                  <option value="45">
                    Reactivate or Deactivate QR Specification
                  </option>
                  <option value="46">
                    Get QR Specification
                  </option>
                  <option value="47">
                    Save Language
                  </option>
                  <option value="48">
                    Update Language
                  </option>
                  {/* <option value="49">
                    Get Language
                  </option> */}
                  <option value="50">
                    Get All Language
                  </option>
                  <option value="51">
                    Rectivate Language
                  </option>
                  <option value="52">
                    Deactivate Language
                  </option>
                  <option value="53">
                    Reactivate Network
                  </option>
                  <option value="54">
                    Deactivate Network
                  </option>
                  <option value="55">
                    Download QR Specification JSON
                  </option>
                  <option value="56">
                    Download Published QR Specification JSON By Market
                  </option>
                  <option value="59">
                    Create Market
                  </option>
                  <option value="60">
                    Update Market
                  </option>
                  <option value="61">
                    Get All Markets
                  </option>
                  <option value="62">
                    View Market
                  </option>
                  <option value="63">
                    Reactivate Market
                  </option>
                  <option value="64">
                    Deactivate Market
                  </option>
                  <option value="72">
                    Create Transaction Type
                  </option>
                  <option value="74">
                    Update Transaction Type
                  </option>
                  <option value="73">
                    Get All Transaction Types
                  </option>
                  <option value="76">
                    View Transaction Type
                  </option>
                  <option value="77">
                    Reactivate or Deactivate Transaction Type
                  </option>
                </Form.Select>
              </Col>
              <Col xl="2" lg="2" md="4" sm="12" className="my-1">
                <Form.Select
                  id="role-select"
                  onChange={(e) => handleSelectedRole(e.target.value)}
                >
                  <option id="option1" value="all">
                    All (Roles)
                  </option>
                  {renderRolesList}
                </Form.Select>
              </Col>
              <Col xl="2" lg="2" md="4" sm="12" className="my-1">
                <Form.Control
                  data-testid="usernameInputField"
                  type="text"
                  placeholder="Search for a user"
                  onChange={handleUsernameInput}
                  value={userNameInput}
                />
              </Col>
              <Col xl="3" lg="3" md="3" sm="12" className="btn-csv">
                <ShowForPermission permissions={[AUDIT_EXPORT]}>
                  <Button
                    variant="primary"
                    className="btn-cancel"
                    id="export-csv-button"
                    onClick={handleExportAuditLogs}
                  >
                    <span className="mr-2 ic-export">
                      <BiExport />
                    </span>
                    <span className="mx-2">Export CSV</span>
                  </Button>
                </ShowForPermission>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      
      {showViewModal ? (
        <ViewAuditModal hideExternal={toggleUserViewModal} />
      ) : null}
      <ShowForPermission permissions={[AUDIT_VIEW]} showBanner={true} >
        <Table responsive>
          <thead>
          <tr>
            <th>Created Date & Time</th>
            <th>Action</th>
            <th>Performed by</th>
            <th>Market</th>
            <th>Role</th>
            <th>Description</th>
          </tr>
          </thead>
          <tbody data-testid="auditLogRenderedRows">{renderAudits}</tbody>
        </Table>
      </ShowForPermission>
      <div className="footer-table d-flex justify-content-between">
        <div>
          <Form.Select onChange={(e) => handleChangePageSize(e.target.value)}>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="60">60</option>
          </Form.Select>
        </div>
        <div className="mt-2">{filters.page}</div>
        <div className="pagination">
          <Pagination size="sm">
            <Pagination.First
              onClick={() => setFilters({ ...filters, page: 1 })}
            />
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => setFilters({ ...filters, page: filters.page - 1 })}
            />
            {currentPage > 4 ? <Pagination.Ellipsis disabled /> : null}
            
            {items}
            {currentPage === noOfPages ? null : (
              <Pagination.Ellipsis disabled />
            )}
            <Pagination.Next
              disabled={currentPage === noOfPages}
              onClick={() => setFilters({ ...filters, page: filters.page + 1 })}
            />
            <Pagination.Last
              onClick={() => setFilters({ ...filters, page: noOfPages })}
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auditList: [...state.audits.list],
    currentPage: state.audits.currentPage,
    noOfPages: state.audits.noOfPages,
    totalCount: state.audits.totalCount,
    sub: state.profile?.sub,
    groups: state.profile?.groups,
    allRoles: state.role.list.map(x => { return { value: x.id, display: x.roleName, market: x.market } })
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAuditLogs: (role, startDate, endDate, action, page, pageSize, username) => dispatch(getAudits(role, startDate, endDate, action, page, pageSize, username)),
  exportAuditLogs: (role, startDate, endDate, action, page, pageSize, username) => dispatch(exportAudits(role, startDate, endDate, action, page, pageSize, username)),
  changeSelectedAudit: (audit) => dispatch(changeSelectedAudit(audit)),
  showLoader: (data) => dispatch(showLoader(data)),
  fetchRoles: (size, page, searchTerm, fetchAll) => dispatch(fetchRoles(size, page, searchTerm, fetchAll)),
  checkPermission: (permissions) => dispatch(checkPermission(permissions))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditPage);
