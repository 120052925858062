import React from 'react';
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

const Empty = ({ title = 'Sorry, No Data', icon = false }) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  }
  return (
    <div className="d-flex flex-column justify-content-center align-items-center empty">
      <h1>404</h1>
      <h3>Not Found</h3>
      <p className="text-muted">{title}</p>
      <Button	className="btn-cancel" variant="outline-dark" onClick={goBack}>Go Back</Button>
    </div>
  );
};

export default Empty;
