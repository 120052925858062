import React, { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import { showLoader } from "../../redux/actions/commonActions";
import {
  createTransactionType,
  updateTransactionType,
} from "../../redux/actions/transactionTypeActions";
import { toast } from "react-toastify";
import {
  CREATE_TRANSACTION_TYPE_SUCCESS,
  UPDATE_TRANSACTION_TYPE_SUCCESS,
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACTION,
} from "../../utils/errorMessages";
import {regexForNames} from "../../utils/common";

let createTransactionTypeSchema = yup.object().shape({
  name: yup
    .string()
    .matches(regexForNames, "Allows only a-z, A-Z, 0-9, space, - and _")
    .max(50, "Transaction Type name must be at most 50 characters")
    .required("Transaction Type name is required"),
  value: yup
    .string()
    .required("Transaction Type value is required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(2, "Minimum value length is 2")
    .max(2, "Maximum value length is 2"),
  description: yup
    .string()
    .max(50, "Transaction Type description must be at most 50 characters"),
});

const CreateTransactionTypeModal = ({
  show,
  toggleShow,
  showLoader,
  getTransactionTypeList,
  updateTransactionType,
  isEditFlow = false,
  createTransactionType,
  transactionType,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(createTransactionTypeSchema),
    mode: "onChange",
  });

  useEffect(() => {
    async function initModal() {
      if (isEditFlow) {
        reset({ ...transactionType, value: transactionType.typeId });
      } else {
        reset({});
      }
    }
    initModal();
  }, [reset, isEditFlow, transactionType, showLoader, show]);

  const handleToggleShow = () => {
    toggleShow();
    reset({});
  };

  const handleCreateTransactionType = async (data) => {
    showLoader(true);
    try {
      if (isEditFlow) {
        data = { ...data, typeId: data.value };
        await updateTransactionType(data);
        toast.success(UPDATE_TRANSACTION_TYPE_SUCCESS);
      } else {
        data = { ...data, isActive: true, typeId: data.value };
        await createTransactionType(data);
        toast.success(CREATE_TRANSACTION_TYPE_SUCCESS);
      }
      await getTransactionTypeList();

      handleToggleShow();
      showLoader(false);
      reset();
    } catch (e) {
      if (e.response.status && e.response.status === 403) {
        toast.error(UNAUTHORIZED_ACTION);
      } else if (e.response.data.Description) {
        toast.error(e.response.data.Description);
      } else {
        toast.error(SOMETHING_WENT_WRONG);
      }
      showLoader(false);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleToggleShow}
        backdrop="static"
        keyboard={false}
        size="md"
        dialogClassName="modal-transaction-type"
        data-testid="modal-transaction-type"
      >
        <Modal.Header>
          <Modal.Title data-testid="modal-title" id="modal-title">
            {isEditFlow ? "Edit" : "Create"} Transaction Type
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(handleCreateTransactionType)}>
          <Modal.Body>
            <Form.Label> Name* </Form.Label>
            <Form.Control
              data-testid="name"
              type="string"
              {...register("name", { required: true })}
              autoComplete="off"
              placeholder="Name"
              isInvalid={errors.name}
            />
            <Form.Control.Feedback
              id="nameErrors"
              data-testid="nameErrors"
              type="invalid"
            >
              {errors.name?.message}
            </Form.Control.Feedback>
            <br />
            <Form.Label> Value* </Form.Label>
            <Form.Control
              data-testid="value"
              type="string"
              {...register("value", { required: true })}
              autoComplete="off"
              placeholder="Value"
              isInvalid={errors.value}
            />
            <Form.Control.Feedback
              id="valueErrors"
              data-testid="valueErrors"
              type="invalid"
            >
              {errors.value?.message}
            </Form.Control.Feedback>
            <br /> <Form.Label> Description </Form.Label>
            <Form.Control
              data-testid="description"
              type="string"
              {...register("description", { required: true })}
              autoComplete="off"
              placeholder="Description"
              isInvalid={errors.description}
            />
            <Form.Control.Feedback
              id="descriptionErrors"
              data-testid="descriptionErrors"
              type="invalid"
            >
              {errors.description?.message}
            </Form.Control.Feedback>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              data-testid="btnCancel"
              variant="primary"
              className="btn-cancel"
              onClick={() => handleToggleShow()}
            >
              Cancel
            </Button>

            <Button
              variant="primary"
              type="submit"
              name="next"
              data-testid="btnSave"
              className="ms-3"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: (data) => dispatch(showLoader(data)),
  updateTransactionType: (data) => dispatch(updateTransactionType(data)),
  createTransactionType: (data) => dispatch(createTransactionType(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTransactionTypeModal);
